import { useCallback, useEffect, useRef } from "react";

export const ThreadCard = ({
  id,
  active,
  open,
  children,
  onClick,
  onClickOutside,
}) => {
  const cardRef = useRef();
  const handleClick = useCallback(() => {
    if (onClick) {
      onClick(id);
    }
  }, [id, onClick]);

  useEffect(() => {
    if (!active) {
      return () => null;
    }

    const clickHandler = onClickOutside
      ? (event) => {
          if (!cardRef.current) {
            return;
          }

          if (!cardRef.current.contains(event.target)) {
            onClickOutside();
          }
        }
      : null;

    if (clickHandler) {
      document.addEventListener("click", clickHandler);
    }

    return () => {
      if (clickHandler) {
        document.removeEventListener("click", clickHandler);
      }
    };
  }, [active, onClickOutside]);

  return (
    <div
      ref={cardRef}
      className={`thread${open ? " is-open" : ""}${active ? " is-active" : ""}`}
      style={{
        border: active ? "1px solid blue" : "1px solid gray",
        boxShadow: active ? "0 0 10px rgba(0, 0, 255, 0.3)" : "none",
      }}
      onClick={handleClick}
    >
      {children}
    </div>
  );
};
