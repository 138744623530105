import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Card,
  Form,
  CardBody,
  CardTitle,
  CardSubtitle,
  Container,
  Modal,
} from 'reactstrap';
import Dropzone from 'react-dropzone';

// Breadcrumb

import { Link } from 'react-router-dom';
import useDatacallswithtoken from '../../../Data Apis/apifunctions';
import { useDispatch } from 'react-redux';
import {
  changeFileKey,
  changeFileName,
  changeFileSenseS3Location,
  changeFileSenseBucketName,
} from '../../../store/actions';
const FileUpload = (props) => {
  const dispatch = useDispatch();
  const { useForFileUpload } = useDatacallswithtoken();
  const [selectedFiles, setselectedFiles] = useState([]);
  const [filestoupload, setFile] = useState();
  const [filestoupload1, setFiles] = useState();
  const [uploadLoading, setuploadLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errormessage, seterrormessage] = useState('');
  const [uploadProgress, setUploadProgress] = useState(0);
  const { settimeTakenToUpload, sets3Locations, setbuckName } = props;
  const [uploading, setUploading] = useState(false);

  const [timer, setTimer] = useState(0);

  const startTimer = () => {
    setTimer(0); // Reset timer
    setUploading(true);
  };

  const stopTimer = () => {
    setUploading(false);
  };

  function handleAcceptedFiles(files, fileRejections) {
    if (fileRejections.length > 0) {
      setIsError(true);
      seterrormessage(
        fileRejections[0].errors[0].code === 'file-too-large'
          ? `File size should be less than 10MB`
          : fileRejections[0].errors[0].code === 'file-too-small'
          ? `File size should be greater than 1KB`
          : fileRejections[0].errors[0].message
      );
      return;
    }
    const filesToProcess = files.slice(0, 2);

    // Set the files in the state
    setFile(filesToProcess);

    // Create an array of file objects containing information about each selected file
    const filesArray = filesToProcess.map((file) => ({
      name: file.name,
      size: file.size,
      formattedSize: formatBytes(file.size),
      preview: URL.createObjectURL(file),
    }));

    // Set the selected files array with the created file objects
    setselectedFiles(filesArray);
  }

  /**
   * Formats the size
   */
  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;

    if (minutes > 0) {
      return `${minutes}m ${remainingSeconds}s`;
    } else {
      return `${remainingSeconds}s`;
    }
  };

  const HandleFileSubmit = async () => {
    const startTime = new Date().getTime();
    setuploadLoading(true);
    startTimer();

    const formData = new FormData();

    for (const file of filestoupload) {
      formData.append('files', file);
    }

    await useForFileUpload(formData)
      .then((res) => {
        // Assuming res.fileKey and res.fileName are relevant properties from the API response
        dispatch(changeFileKey(res.fileKey));
        dispatch(changeFileSenseS3Location(res?.s3_location));
        // sets3Locations(res?.s3_location);
        // setbuckName(res?.bucketName);
        dispatch(changeFileSenseBucketName(res?.bucketName));

        // Set all file names from the selectedFiles array
        const fileNames = selectedFiles.map((file) => file.name);
        dispatch(changeFileName(fileNames));

        setselectedFiles([]);
        setFile([]);
        setuploadLoading(false);
        const endTime = new Date().getTime();
        const duration = endTime - startTime;

        const totalSeconds = Math.floor(duration / 1000);
        // const secondsOnly = totalSeconds % 60;
        settimeTakenToUpload(totalSeconds);
      })
      .catch((err) => {
        console.error(err);
        setuploadLoading(false);
        setIsError(true);
        seterrormessage('Something went wrong. Please try again later.');
      })
      .finally(() => {
        stopTimer();
      });
  };

  useEffect(() => {
    let interval;

    if (uploading) {
      interval = setInterval(() => {
        setTimer((prev) => prev + 1);
      }, 1000); // Increment every second
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [uploading]);

  return (
    <React.Fragment>
      <>
        {isError ? (
          <>
            <div className='alert alert-danger' role='alert'>
              <strong>Oh snap!</strong> {errormessage}
            </div>
            <Modal
              isOpen={isError}
              toggle={() => {
                setIsError(false);
                setFile();
                setselectedFiles([]);
              }}
            >
              {/* for error */}
              <div className='modal-header'>
                <h5 className='modal-title mt-0' id='myModalLabel'>
                  Error
                </h5>
                <button
                  type='button'
                  className='close'
                  data-dismiss='modal'
                  aria-label='Close'
                  onClick={() => {
                    setIsError(false);
                    setFile();
                    setselectedFiles([]);
                  }}
                >
                  <span aria-hidden='true'>&times;</span>
                </button>
              </div>
              <div className='modal-body'>{errormessage}</div>
              <div className='modal-footer'>
                <button
                  type='button'
                  className='btn btn-secondary'
                  data-dismiss='modal'
                  onClick={() => {
                    setIsError(false);
                    setFile();
                    setselectedFiles([]);
                  }}
                >
                  Close
                </button>
              </div>
            </Modal>
          </>
        ) : null}

        <>
          <CardTitle className='mb-4'>Upload file and ask questions</CardTitle>
          <CardSubtitle className='mb-3'>
            {' '}
            You can upload a maximum of 2 (Word, PDF or PPT) file, with file's
            size limit being 30 MB
          </CardSubtitle>
          <Form className='dropzone' style={{ minHeight: '160px' }}>
            <Dropzone
              // decrease the height of dropzone
              // accept only pdf and Excel files
              accept='.pdf, .txt, .doc, .docx, .ppt, .pptx'
              minSize={1000}
              maxSize={30000000}
              onDrop={(acceptedFiles, fileRejections) => {
                handleAcceptedFiles(acceptedFiles, fileRejections);
              }}
            >
              {({ getRootProps, getInputProps }) => (
                <div style={{ textAlign: 'center' }}>
                  <div className='dz-message needsclick' {...getRootProps()}>
                    <input {...getInputProps()} />
                    <div className='mb-3'>
                      <i className='display-4 text-muted mdi mdi-cloud-upload-outline'></i>
                    </div>
                    <h4>Drop or Click here to upload file</h4>
                  </div>
                </div>
              )}
            </Dropzone>
            {/* for loader */}
            {uploadLoading ? (
              <div className='text-center mt-4'>
                <button
                  style={{ width: '11%', minWidth: '120px' }}
                  type='button'
                  className='btn btn-primary '
                  disabled
                >
                  <span
                    style={{ marginRight: '1em' }}
                    className='spinner-border spinner-border-sm mr-2'
                    role='status'
                    aria-hidden='true'
                  ></span>
                  {formatTime(timer)}
                </button>
                {uploading && <p> Uploading...</p>}
              </div>
            ) : null}
            <div className='dropzone-previews mt-3' id='file-previews'>
              {selectedFiles.map((f, i) => {
                return (
                  <Card
                    className='mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete'
                    key={i + '-file'}
                  >
                    <div className='p-2'>
                      <Row className='align-items-center'>
                        {/* <Col className="col-auto">
                                                    <img
                                                        data-dz-thumbnail=""
                                                        height="80"
                                                        className="avatar-sm rounded bg-light"
                                                        alt={f.name}
                                                        src={f.preview}
                                                    />
                                                </Col> */}
                        <Col>
                          <Link to='#' className='text-muted font-weight-bold'>
                            {f.name}
                          </Link>
                          <p className='mb-0'>
                            <strong>{f.formattedSize}</strong>
                          </p>
                        </Col>
                        <button
                          type='button'
                          className='close'
                          style={{ position: 'relative' }}
                          data-dismiss='modal'
                          aria-label='Close'
                          onClick={() => {
                            setselectedFiles((current) =>
                              current.filter(
                                (selectedFiles) => selectedFiles.name !== f.name
                              )
                            );
                          }}
                          disabled={uploadLoading}
                        ></button>
                      </Row>
                    </div>
                  </Card>
                );
              })}
            </div>
          </Form>

          <div className='text-center mt-4'>
            <button
              type='button'
              className='btn btn-primary '
              onClick={() => HandleFileSubmit()}
              disabled={selectedFiles.length === 0 || uploadLoading}
            >
              Upload document
            </button>
          </div>
        </>
      </>
    </React.Fragment>
  );
};

export default FileUpload;
