import React, { useState, useEffect, useRef } from 'react';

import {
  Row,
  Col,
  Container,
  Card,
  CardBody,
  CardTitle,
  Button,
  Modal,
  Spinner,
} from 'reactstrap';
import DataTable from 'react-data-table-component';

import Defaultrender from './Components/Defaultrender';
import Chatrender from './Components/Chatrender';
// import FileUpload from "./Components/uploadfile";
// import FileUploadWizard from "./Components/uploadFileFormWizard";

// Import data apis
import useDatacallswithtoken from '../../Data Apis/apifunctions';

// store
import { useSelector, useDispatch } from 'react-redux';

// import actions
import {
  changeStructuredQuestion,
  changeFeatureList,
  changeTrailExpiredMessage,
  changeSearchDisabled,
} from '../../store/actions';

import { useNavigate } from 'react-router-dom';

import ReactJoyride from 'react-joyride';

const Dashboard = () => {
  document.title = 'Home | Actalyst AI Platform';
  // const featureList = useSelector(state => state.PageData.featureList)
  const navigate = useNavigate();
  const question = useSelector((state) => state.PageData.structuredquestion);
  const [homepagedetails, sethomepagedetails] = useState([]);
  const [chatDriveData, setchatDriveData] = useState();
  const [schemaid, setschemaid] = useState();
  const [currentDB, setcurrentDB] = useState();
  const [ispageloading, setispageloading] = useState(true);
  const { useHomepage, useGettopdata } = useDatacallswithtoken();
  const [isServererror, setisServererror] = useState(false);
  // const [areConnections, setareConnections] = useState(true)
  const [isUserValid, setisUserValid] = useState();
  // const [userError, setuserError] = useState()
  const [shouldletfileupload, setshouldletfileupload] = useState(false);
  // const [sampleData, setSampleData] = useState();
  // const [columns, setColumns] = useState();
  const [isSampleData, setIsSampleData] = useState(false);

  const steps = [
    {
      target: '.tour-step-1',
      content: 'All the data sources you have connected will be shown here',
      // disableBeacon: true,
    },
    {
      target: '.tour-step-2',
      content:
        'You can view the sample data by clicking on the view smaple data button',
    },
    {
      target: '.tour-step-3',
      content:
        'You can add a new data source by clicking on the upload file button',
    },
    {
      target: '.tour-step-4',
      content: 'You can type your question here and get the answer',
    },
    {
      target: '.tour-step-5',
      content: 'Try these questions to get started',
    },
    {
      target: '.tour-step-6',
      content: 'You can always contact us for any help using support',
    },
  ];

  const forHeight = useRef(null);

  const dispatch = useDispatch();

  const Gethomepagedetails = () => {
    useHomepage()
      .then((data) => {
        if (data.error !== undefined) {
          // setareConnections(false)
          // setuserError(data.error)
          dispatch(changeTrailExpiredMessage(data.error));
          dispatch(changeFeatureList([]));
          setispageloading(false);
          dispatch(changeSearchDisabled(true));
        } else {
          sethomepagedetails(data?.userDBList);
          setchatDriveData(data?.data);
          setispageloading(false);
          setcurrentDB(data?.userDBList[0]?.dbId);
          setschemaid(data?.userDBList[0]?.schModel[0]?.schemaId);
          setisUserValid(data?.user_valid);
          setshouldletfileupload(data?.userDBList.length === 0 ? true : false);
          dispatch(
            changeFeatureList(
              data?.featureList === null ? [] : data?.featureList
            )
          );
          if (data?.featureList.find((item) => item.code === 'DM')) {
            navigate('/app/demo-home', { replace: true });
          }
          if (!data?.featureList.find((item) => item.code === 'GN')) {
            navigate('/app/subscribe', { replace: true });
          }
          dispatch(changeTrailExpiredMessage(''));
          dispatch(
            changeSearchDisabled(data?.userDBList.length === 0 ? true : false)
          );
        }
      })
      .catch((error) => {
        console.log(error);
        setispageloading(false);
        setisServererror(true);
      });
  };
  const updateQuestion = (question) => {
    dispatch(changeStructuredQuestion(question));
  };

  // useEffect(() => {
  //   ReactGA.send({
  //     hitType: 'pageview',
  //     page: 'window.location.pathname',
  //   });
  // }, []);
  useEffect(() => {
    Gethomepagedetails();
  }, []);

  const Gettopdata = () => {
    const data_config = {
      schemaId: schemaid,
    };
    useGettopdata(data_config).then((data) => {
      setchatDriveData(data);
    });
  };
  useEffect(() => {
    dispatch(changeStructuredQuestion(''));
    if (schemaid) {
      Gettopdata();
    }
  }, [schemaid, currentDB, dispatch]);

  return (
    <React.Fragment>
      <div className='page-content'>
        <Container fluid={true}>
          {isServererror ? (
            // server is down or not responding
            <div className='text-center d-flex justify-content-center flex-direction-column'>
              <div>
                <img
                  src='https://cdn.dribbble.com/users/1059583/screenshots/4171367/coding-freak.gif'
                  alt='server down'
                />
                <p>
                  <h3>Server is down or not responding! ...</h3>
                  <p>please try again later</p>
                </p>
              </div>
            </div>
          ) : (
            <>
              {ispageloading ? (
                <Spinner
                  style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    // transform: "translate(-50%, -50%)",
                  }}
                  animation='border'
                  variant='primary'
                />
              ) : (
                <>
                  {isUserValid === true && shouldletfileupload === true ? (
                    navigate('/app/add-file-as-connection')
                  ) : (
                    <>
                      {/* <ReactJoyride
                        steps={steps}
                        continuous={true}
                        showProgress={true}
                        showSkipButton={true}
                        scrollToFirstStep={true}
                        disableScrolling={true}
                        disableOverlayClose={true}
                        disableCloseOnEsc={true}
                        styles={{
                          options: {
                            arrowColor: "#fff",
                            backgroundColor: "#fff",
                            overlayColor: "rgba(0, 0, 0, 0.5)",
                            primaryColor: "black",
                            textColor: "#000",
                            zIndex: 10000,
                          },
                        }}
                      /> */}
                      <Row>
                        <Col lg={10}>
                          <Row>
                            {/* <Col lg={3} >
                              <div className="form-floating mb-3">
                                <select
                                  className="form-select"
                                  id="floatingSelectGrid"
                                  aria-label="Floating label select example"
                                  onChange={(e) => { setcurrentDB(e.target.value); setschemaid(homepagedetails?.find((item) => item?.dbId === e.target.value)?.schModel[0]?.schemaId) }}
                                >
                                  {homepagedetails?.map((item, index) => {
                                    return (
                                      <option key={item.dbId} value={item.dbId}>
                                        {item.dbName}
                                      </option>
                                    );
                                  })}
                                </select>
                                <label htmlFor="floatingSelectGrid">
                                  Select Database
                                </label>
                              </div>
                            </Col> */}
                            <Col lg={3} className='tour-step-1'>
                              <div className='form-floating mb-3'>
                                <select
                                  ref={forHeight}
                                  className='form-select'
                                  id='floatingSelectGrid'
                                  aria-label='Floating label select example'
                                  onChange={(e) => {
                                    setschemaid(e.target.value);
                                  }}
                                >
                                  {/* {homepagedetails?.find((item) => item?.dbId === currentDB)?.schModel.map((item, index) => {
                                    return (
                                      <option key={index} value={item.schemaId}>
                                        {item.aliasName ? item.aliasName : item.schemaName}
                                      </option>
                                    );
                                  })} */}
                                  {/* show all schemas including different databases */}
                                  {homepagedetails?.map((item, index) => {
                                    return item?.schModel.map((item, index) => {
                                      return (
                                        <option
                                          key={index}
                                          value={item.schemaId}
                                        >
                                          {item.aliasName
                                            ? item.aliasName
                                            : item.schemaName}
                                        </option>
                                      );
                                    });
                                  })}
                                </select>
                                <label htmlFor='floatingSelectGrid'>
                                  Choose your data
                                </label>
                              </div>
                            </Col>
                            <Col lg={9} className='tour-step-2'>
                              <div
                                className='d-flex justify-content-center align-items-center btn'
                                style={{
                                  height: forHeight.current?.offsetHeight,
                                  backgroundColor: 'white',
                                  color: 'black',
                                  border: '1px solid #ced4da',
                                  borderRadius: '.25rem',
                                  cursor: 'pointer',
                                  width: '100%',
                                }}
                                onClick={() => {
                                  setIsSampleData(true);
                                }}
                              >
                                View Sample Data
                              </div>
                              <Modal
                                size='xl'
                                isOpen={isSampleData}
                                toggle={() => setIsSampleData(!isSampleData)}
                              >
                                <div className='modal-header'>
                                  <h5
                                    className='modal-title mt-0'
                                    id='exampleModalFullscreenLabel'
                                  >
                                    Sample Data
                                  </h5>
                                  <button
                                    onClick={() => {
                                      setIsSampleData(false);
                                    }}
                                    type='button'
                                    className='close'
                                    data-dismiss='modal'
                                    aria-label='Close'
                                  >
                                    <span aria-hidden='true'>&times;</span>
                                  </button>
                                </div>
                                <div className='modal-body'>
                                  <DataTable
                                    columns={chatDriveData?.buss_names?.map(
                                      (item, index) => {
                                        return {
                                          name: item,
                                          selector: (row) => row[index],
                                          sortable: true,
                                        };
                                      }
                                    )}
                                    data={
                                      chatDriveData?.sample_data
                                        ? chatDriveData?.sample_data
                                        : []
                                    }
                                    // pagination
                                    highlightOnHover
                                    pointerOnHover
                                    responsive
                                    noDataComponent='No Data'
                                    paginationPerPage={5}
                                    // paginationRowsPerPageOptions={[5, 10, 15, 20, 25, 30]}
                                  />
                                </div>
                              </Modal>
                            </Col>
                          </Row>
                        </Col>

                        <Col lg={2} className='tour-step-3'>
                          <Row>
                            <div
                              className='d-flex justify-content-center align-items-center btn'
                              style={{
                                height: forHeight.current?.offsetHeight,
                                backgroundColor: '#cbcdd1',
                                color: 'black',
                              }}
                              onClick={() => setshouldletfileupload(true)}
                            >
                              Upload File{' '}
                              <i className='mdi mdi-arrow-right'></i>
                            </div>
                          </Row>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={10}>
                          <div>
                            {question === '' ? (
                              <Defaultrender />
                            ) : (
                              <Chatrender
                                question={question}
                                currentDB={currentDB}
                                schemaid={schemaid}
                              />
                            )}
                          </div>
                        </Col>
                        <Col lg={2}>
                          <Row>
                            <Card className='tour-step-5'>
                              <CardBody>
                                <CardTitle>Most Asked Questions </CardTitle>
                                <div className='d-flex flex-wrap gap-4'>
                                  {chatDriveData?.questionList?.map(
                                    (item, index) => {
                                      return (
                                        <Button
                                          onClick={() =>
                                            updateQuestion(item.question)
                                          }
                                          key={index}
                                          color='light'
                                          type='button'
                                          className='btn position-relative'
                                          style={{
                                            width: '100%',
                                          }}
                                        >
                                          {item.question}
                                        </Button>
                                      );
                                    }
                                  )}
                                </div>
                              </CardBody>
                            </Card>
                          </Row>
                          {/* <Row>
                    <Card>
                      <CardBody>
                        <CardTitle>Active Users</CardTitle>
                        <div className="d-flex flex-wrap gap-4">
                          {chatDriveData?.topUserList?.map((item, index) => {
                            return (
                              <Button
                                key={index}
                                type="button"
                                color="light"
                                className="btn position-relative"
                                style={
                                  {
                                    width: "100%",
                                  }
                                }
                              >
                                {item.email}
                              </Button>
                            )
                          }
                          )}
                        </div>
                      </CardBody>
                    </Card>
                  </Row> */}
                        </Col>
                      </Row>
                    </>
                  )}
                </>
              )}
            </>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Dashboard;
