import {
  CHANGE_STRUCTURED_QUESTION,
  CHANGE_UNSTRUCTURED_QUESTIONS,
  CHANGE_SUMMARY_QUESTION,
  REFRESH_QUESTIONS,
  CHANGE_FILE_KEY,
  CHANGE_FILE_NAME,
  CHANGE_SEARCH_DISABLED,
  CHANGE_FILTER_DATA,
  CHANGE_ORIGINAL_DATA,
  CHANGE_FEATURE_LIST,
  CHANGE_TRAIL_EXPIRED_MESSAGE,
  CHANGE_CONTENT_SEARCH_QUESTION,
  CHANGE_CHAT_QUESTIONS,
  CHANGE_WEBSOCKET,
  CHANGE_WEBSOCKET_UNSTRUCTURED,
  CHANGE_CHAT_QUESTIONS_UNSTRUCTURED,
  CHANGE_GROUPS,
  CHANGE_APPS_STRUCTURED,
  CHANGE_CURRENT_APP,
  SHOWNOTFICATION,
  CHANGE_NOTFICATION_MESSAGE,
  APP_DISPLAY_HANDLER,
  FILESENSE_APP_DISPLAY_HANDLER,
  IS_SIDEBAR_OPEN,
  FILE_SENSE_S3_LOCATION,
  FILE_SENSE_BUCKET_NAME,
  SHOW_SNACKBAR,
  HIDE_SNACKBAR,
  PROVIDER,
  THREADS,
  EDITOR,
} from "./actionTypes";

export const changeStructuredQuestion = (question) => ({
  type: CHANGE_STRUCTURED_QUESTION,
  payload: question,
});

export const appDispayHandler = (flag) => ({
  type: APP_DISPLAY_HANDLER,
  payload: flag,
});
export const filesenseAppDisplayHandler = (flag) => ({
  type: FILESENSE_APP_DISPLAY_HANDLER,
  payload: flag,
});

export const changeUnStructuredQuestion = (question) => ({
  type: CHANGE_UNSTRUCTURED_QUESTIONS,
  payload: question,
});

export const changeSummaryQuestion = (question) => ({
  type: CHANGE_SUMMARY_QUESTION,
  payload: question,
});

export const refreshQuestions = () => ({
  type: REFRESH_QUESTIONS,
});

export const changeFileKey = (key) => ({
  type: CHANGE_FILE_KEY,
  payload: key,
});

export const changeFileName = (name) => ({
  type: CHANGE_FILE_NAME,
  payload: name,
});

export const changeSearchDisabled = (disabled) => ({
  type: CHANGE_SEARCH_DISABLED,
  payload: disabled,
});

export const changeFilterData = (data) => ({
  type: CHANGE_FILTER_DATA,
  payload: data,
});

export const changeOriginalData = (data) => ({
  type: CHANGE_ORIGINAL_DATA,
  payload: data,
});

export const changeFeatureList = (data) => ({
  type: CHANGE_FEATURE_LIST,
  payload: data,
});

export const changeTrailExpiredMessage = (message) => ({
  type: CHANGE_TRAIL_EXPIRED_MESSAGE,
  payload: message,
});

export const changeContentSearchQuestion = (question) => ({
  type: CHANGE_CONTENT_SEARCH_QUESTION,
  payload: question,
});

export const changeChatQuestions = (question) => ({
  type: CHANGE_CHAT_QUESTIONS,
  payload: question,
});

export const changeWebSocket = (socket) => ({
  type: CHANGE_WEBSOCKET,
  payload: socket,
});

export const changeWebSocketUnstructured = (socket) => ({
  type: CHANGE_WEBSOCKET_UNSTRUCTURED,
  payload: socket,
});

export const changeChatQuestionsUnstructured = (question) => ({
  type: CHANGE_CHAT_QUESTIONS_UNSTRUCTURED,
  payload: question,
});

export const changeGroups = (groups) => ({
  type: CHANGE_GROUPS,
  payload: groups,
});

export const changeCurrentApp = (app) => ({
  type: CHANGE_CURRENT_APP,
  payload: app,
});

export const changeAppsStructured = (apps) => ({
  type: CHANGE_APPS_STRUCTURED,
  payload: apps,
});

export const showNotfication = (show) => ({
  type: SHOWNOTFICATION,
  payload: show,
});

export const changeNotficationMessage = (message) => ({
  type: CHANGE_NOTFICATION_MESSAGE,
  payload: message,
});

export const changeIsSidebarOpen = (flag) => ({
  type: IS_SIDEBAR_OPEN,
  payload: flag,
});

export const changeFileSenseS3Location = (location) => ({
  type: FILE_SENSE_S3_LOCATION,
  payload: location,
});

export const changeFileSenseBucketName = (bucketName) => ({
  type: FILE_SENSE_BUCKET_NAME,
  payload: bucketName,
});

// Add these to your existing actions.js
export const showSnackbar = (message, action = null) => ({
  type: SHOW_SNACKBAR,
  payload: { message, action },
});

export const hideSnackbar = () => ({
  type: HIDE_SNACKBAR,
});
export const changeProvider = (provider) => ({
  type: PROVIDER,
  payload: provider,
});
export const changeThreads = (threads) => ({
  type: THREADS,
  payload: threads,
});
export const changeEditor = (editor) => ({
  type: EDITOR,
  payload: editor,
});
