import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef,
} from 'react';
import SimpleBar from 'simplebar-react';
import { PlusOutlined } from '@ant-design/icons';
import { Button, Card, Col, Menu, notification, Typography } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import './MenuWithButton.css';
import { ThreadsList } from '../TipTap/Components/threadsList';
import { useSelector } from 'react-redux';
import { ThreadsProvider } from '../TipTap/Components/threadsProvider';
import { useAuth0 } from '@auth0/auth0-react';
import { getRandomElement } from '../Utils/GenerateTableFromJson';

const MenuWithButton = ({
  menuItems = [],
  onButtonClick = () => {},
  onMenuItemClick = () => {},
  buttonLabel = 'Create Job Description',
  buttonIcon = <PlusOutlined />,
  buttonStyle = {},
  menuStyle = {},
  selectedKeys = [],
}) => {
  const { user } = useAuth0();
  const navigate = useNavigate();
  const threads = useSelector((state) => state.PageData.threads);
  const provider = useSelector((state) => state.PageData.provider);
  const editor = useSelector((state) => state.PageData.editor);

  const [showComments, setShowComments] = useState(true);
  const [availableHeight, setAvailableHeight] = useState(0);
  const [isSticky, setIsSticky] = useState(false);
  const commentsRef = useRef(null);
  const commentsContainerRef = useRef(null);
  const menuDivRef = useRef(null);
  const colors = [
    '#958DF1',
    '#F98181',
    '#FBBC88',
    '#FAF594',
    '#70CFF8',
    '#94FADB',
    '#B9F18D',
  ];

  const calculateHeight = () => {
    const headerHeight = 100;
    const marginBottom = 15;
    const calculatedHeight = window.innerHeight - headerHeight - marginBottom;
    setAvailableHeight(calculatedHeight);
  };

  useEffect(() => {
    calculateHeight();
    window.addEventListener('resize', calculateHeight);

    const handleScroll = () => {
      if (
        commentsRef.current &&
        commentsContainerRef.current &&
        menuDivRef.current
      ) {
        const containerRect =
          commentsContainerRef.current.getBoundingClientRect();
        const commentsRect = commentsRef.current.getBoundingClientRect();
        const menuDivRect = menuDivRef.current.getBoundingClientRect();

        const viewportHeight =
          window.innerHeight || document.documentElement.clientHeight;
        const menuDivBottomVisible =
          menuDivRect.bottom >= 0 && menuDivRect.bottom <= viewportHeight;

        if (
          containerRect.top <= 0 &&
          commentsRect.top <= 0 &&
          !menuDivBottomVisible
        ) {
          setIsSticky(true);
        } else {
          setIsSticky(false);
        }
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('resize', calculateHeight);
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const userdetails = useMemo(
    () => ({ name: user.email, color: getRandomElement(colors) }),
    [user.email]
  );

  const handleMenuClick = (e) => {
    onMenuItemClick(e);
    const clickedItem =
      menuItems
        .flatMap((item) => item?.children || [item])
        .find((item) => item?.key === e?.key) || {};
    const route = clickedItem?.route || '';
  };

  const toggleComments = () => {
    setShowComments(!showComments);
  };

  const selectThreadInEditor = useCallback(
    (threadId) => {
      editor?.chain().selectThread({ id: threadId }).run();
    },
    [editor]
  );

  const deleteThread = useCallback(
    (threadId) => {
      const thread = threads.find((t) => t.id === threadId);
      if (thread && thread.data.userData.id === user?.email) {
        provider.deleteThread(threadId);
        editor.commands.removeThread({ id: threadId });
      } else {
        notification.error({
          message: 'Permission Denied',
          description: 'You are not allowed to delete this thread.',
        });
      }
    },
    [editor, provider, threads, user?.email]
  );

  const updateComment = useCallback(
    (threadId, commentId, content, metaData) => {
      const thread = threads.find((t) => t.id === threadId);
      const comment = thread?.comments.find((c) => c.id === commentId);

      if (comment && comment.data.userData.id === user?.email) {
        editor.commands.updateComment({
          threadId,
          id: commentId,
          content,
          data: metaData,
        });
      } else {
        notification.error({
          message: 'Permission Denied',
          description: 'You are not allowed to edit this comment.',
        });
      }
    },
    [editor, threads, user?.email]
  );

  const onHoverThread = useCallback(
    (threadId) => {
      const { tr } = editor.state;
      tr.setMeta('threadMouseOver', threadId);
      editor.view.dispatch(tr);
    },
    [editor]
  );

  const onLeaveThread = useCallback(
    (threadId) => {
      const { tr } = editor.state;
      tr.setMeta('threadMouseOut', threadId);
      editor.view.dispatch(tr);
    },
    [editor]
  );

  const scrollToThread = (threadId) => {
    if (!editor) return;

    // Find the thread in the document
    const thread = editor.state.doc.descendants((node, pos) => {
      if (
        node.marks.find(
          (mark) =>
            mark.type.name === 'comment' && mark.attrs.threadId === threadId
        )
      ) {
        return true;
      }
    });

    if (thread) {
      const [node, pos] = thread;

      // Get the DOM node
      const domNode = editor.view.domAtPos(pos).node;

      // Scroll to the node
      domNode.scrollIntoView({ behavior: 'smooth', block: 'center' });

      // Highlight the text
      editor.commands.setTextSelection({ from: pos, to: pos + node.nodeSize });
    }
  };

  return (
    <div className='menu-div mb-4' ref={commentsContainerRef}>
      <div className='menu mb-4' ref={menuDivRef}>
        <Link to='/app/writing-assistant/create-job-description'>
          <Button
            type='primary'
            icon={buttonIcon}
            onClick={onButtonClick}
            style={{
              width: '100%',
              backgroundColor: 'black',
              color: 'white',
              marginBottom: '10px',
              borderRadius: '30px',
              border: 'solid 1px black',
              ...buttonStyle,
            }}
          >
            {buttonLabel}
          </Button>
        </Link>
        <Menu
          onClick={handleMenuClick}
          selectedKeys={selectedKeys}
          style={{
            backgroundColor: '#f2f2f2',
            border: 'none',
            ...menuStyle,
          }}
          defaultOpenKeys={menuItems?.map((item) => item?.key)}
          mode='inline'
          items={menuItems?.map((item) => ({
            ...item,
            label: (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <span>{item?.label}</span>
                {item?.count !== undefined && (
                  <span className='menu-item-count'>{item?.count}</span>
                )}
              </div>
            ),
            children: item?.children?.map((child) => ({
              ...child,
              label: (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <span>{child?.label}</span>
                  {child?.count !== undefined && (
                    <span className='menu-item-count'>{child?.count}</span>
                  )}
                </div>
              ),
            })),
          }))}
        />
      </div>
      {editor !== null && (
        <div
          id='tiptapComments'
          ref={commentsRef}
          style={{
            position: isSticky ? 'fixed' : 'relative',
            top: isSticky ? '0' : 'auto',
            zIndex: 1000,
            width: '100%',
            maxWidth: '280px', // Adjust this value based on your layout
          }}
        >
          <ThreadsProvider
            onClickThread={selectThreadInEditor}
            onDeleteThread={deleteThread}
            onHoverThread={onHoverThread}
            onLeaveThread={onLeaveThread}
            onUpdateComment={updateComment}
            selectedThreads={editor?.storage?.comments?.focusedThreads}
            threads={threads}
          >
            {threads?.length > 0 && (
              <Col>
                <div>
                  <Card
                    className=''
                    style={{
                      boxShadow: 'none',
                      width: '100%',
                      flex: '1 0 auto',
                    }}
                  >
                    <Button
                      type='primary'
                      onClick={toggleComments}
                      style={{
                        color: 'white',
                        background: 'black',
                        display: 'flex',
                        width: '100%',
                      }}
                    >
                      {showComments ? 'Hide Comments' : 'Show Comments'}
                    </Button>
                    {showComments && (
                      <SimpleBar
                        style={{
                          maxHeight: `${availableHeight}px`,
                          padding: '10px',
                          margin: '15px 0px',
                          overflowX: 'hidden',
                        }}
                      >
                        <h4 className='text-center mt-2 mb-3'>Comments</h4>
                        <ThreadsList
                          scrollToComment={scrollToThread}
                          provider={provider}
                          threads={threads}
                        />
                      </SimpleBar>
                    )}
                  </Card>
                </div>
              </Col>
            )}
          </ThreadsProvider>
        </div>
      )}
    </div>
  );
};

export default MenuWithButton;
