export function getDefaultObject(page,featureList) {
  // console.log(page,featureList)
  const groupsOfDsiplayedPage=featureList[page]
  const defaultObject = groupsOfDsiplayedPage.find((obj) => obj.is_default === true);

  return defaultObject || groupsOfDsiplayedPage[0];
}

export const extractPdfLocations = (arr) => {
  return arr.map((obj) => obj.pdf_location);
};

export const isPdf = (value, index) => {
  return value?.split('/')[index]?.slice(0, 40) + '...';
};

export const formatTime = (seconds) => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = (seconds % 60).toFixed(2);

  if (minutes > 0) {
    return `${minutes}m ${remainingSeconds}s`;
  } else {
    return `${remainingSeconds}s`;
  }
};
