import React, { useEffect, useState } from 'react';
import { Collapse, Skeleton, Tooltip } from 'antd';
import { Flex, Input } from 'antd';
// import { stepData } from '../../data';
import Markdown from 'react-markdown';
import AccordionItem from './AccordionItem';
import { useNotification } from '../NotificationProvider';
import "../../../style/style.css";
import useDatacallswithtoken from '../../../../../Data Apis/apifunctions';
import GradientBtn from './Elements/GradientBtn';
import { CheckOutlined, EditOutlined } from '@ant-design/icons';
const { TextArea } = Input;


const Step3 = ({ step3Data, analysisId, setAllQuestionsSaved }) => {
    const { useForRegenerateAIResponse, useForFSUpdateSingleTopicSummay } = useDatacallswithtoken()
    const [stepData, setStepData] = useState(step3Data);
    const [promptsSaved, setPromptsSaved] = useState({});
    const [sessionFlag, setSessionFlag] = useState(false);
    const notification = useNotification();
    const [editingSummary, setEditingSummary] = useState(false)
    const [aiGeneratedRed, setAiGeneratedRed] = useState(stepData?.topic_stage_data[0]?.output);
    const [systemPrompt, setSystemPrompt] = useState(stepData?.topic_stage_data[0]?.prompt)
    const [loadingRegenerate, setLoadingRegenerate] = useState(false)


    useEffect(() => {
        setAllQuestionsSaved(prev => ({ ...prev, ...promptsSaved }))
    }, [promptsSaved])



    const handleAiTextArea = (e) => {
        console.log(e.target.value)
        setAiGeneratedRed(e.target.value)
    }
    const handeRegenerate = () => {
        if (!Object.values(promptsSaved).every(value => value === true)) {
            notification.warning({
                message: 'Save all the prompts to regenerate response',
                // description: '',
                placement: 'topRight',
            })

        }
        else {

            const data = {
                analysisStageId: stepData?.topic_stage_data[0]?.analysis_stages_id,
                sessionFlag: sessionFlag
            }
            RegenateAiResponse(data)

        }

    }

    const RegenateAiResponse = (data) => {
        setLoadingRegenerate(true)
        useForRegenerateAIResponse(data).then((res) => {
            setLoadingRegenerate(false)
            console.log(res)
            setAiGeneratedRed(res.output)
            notification.success({
                message: 'Answer is successfully regenerated',
                // description: '',
                placement: 'topRight',
            });
        }).catch(err => {
            setLoadingRegenerate(false)
            notification.error({
                message: 'Something went wrong',
                description: err.message,
                placement: 'topRight',
            })

        })

    }
    console.log(step3Data)
    const handleSaveAiResponse =()=>{
        setEditingSummary(false)
        if(aiGeneratedRed != stepData?.topic_stage_data[0]?.output) SaveSummary();
    }
    const SaveSummary = () => {
        useForFSUpdateSingleTopicSummay(aiGeneratedRed, step3Data?.analysis_topic_id
        ).then((res) => {
            
            
            
            notification.success({
                message: `Response is been saved`,
                placement: 'topRight',
            });

        }).catch((err) => {
          
            notification.error({
                message: "Error",
                description: err.message,
                placement: 'topRight',
            });
        })
    }


    // console.log(stepData?.topic_stage_data[0])
    return (
        <Flex className='w100-h100' vertical gap={20} align='start'>

            {stepData?.topic_stage_data[0]?.prompt &&
                <AccordionItem
                    question={"System Prompt"}
                    prompt={stepData?.topic_stage_data[0]?.prompt}
                    topicObj={stepData?.topic_stage_data[0]}
                    analysisId={analysisId}
                    setSystemPrompt={setSystemPrompt}
                    setPromptsSaved={setPromptsSaved}
                />}

            <AccordionItem
                question={"Session Prompt"}
                sessionPrompt={stepData?.topic_stage_data[0]?.session_prompt}
                topicObj={stepData?.topic_stage_data[0]}
                analysisId={analysisId}
                setPromptsSaved={setPromptsSaved}
                systemPrompt={systemPrompt}
                setSessionFlag={setSessionFlag}
            />
            {console.log(stepData?.topic_stage_data[0])}

            {/* {stepData?.topic_stage_data[0].example && */}
            <AccordionItem
                topicObj={stepData?.topic_stage_data[0]}
                question={"Examples"}
                example={stepData?.topic_stage_data[0]?.example}
                setPromptsSaved={setPromptsSaved}
                analysisId={analysisId}
            />


            {/* <TextArea
                // className='editable-text-area'
                autoSize
                allowClear
                // style={{padding:"5px 10px"}}
                value={aiGeneratedRed}
                // disabled={!isEditing}
                onChange={handleAiTextArea}
            /> */}





            <Flex style={{ alignSelf: "center" }}>


                <GradientBtn onBtnClick={() => handeRegenerate()} name={"Regenerate"} disable={false} />
            </Flex>



            <Flex className='p20 br-ra15-sld1-e6e6 w100 ' vertical align='end' >
                {editingSummary ?
                    <CheckOutlined className='cursor-pointer' style={{ color: "#b3b3b3" }} onClick={() => { handleSaveAiResponse() }} /> :
                    <EditOutlined className='cursor-pointer' style={{ color: "#b3b3b3" }} onClick={() => setEditingSummary(true)} />
                }
                {/* {loadingRegenerate ? <Skeleton title={false} paragraph={{ rows: 2 }} /> : aiGeneratedRed && <Markdown className='markDown-col-666' >{aiGeneratedRed}</Markdown>} */}
                {loadingRegenerate ? <Skeleton title={false} paragraph={{ rows: 2 }} /> : aiGeneratedRed &&
                    <TextArea className='editable-text-area'
                        autoSize allowClear variant='borderless'
                        disabled={!editingSummary}
                        value={aiGeneratedRed}
                        onChange={(e) => setAiGeneratedRed(e.target.value)}
                    />
                }



                {/* {aiGeneratedRed} */}
            </Flex>



        </Flex>
    );
};

export default Step3;
