import React, { useState, useEffect } from 'react'
import "../../style/style.css";
import { Alert, Button, Flex, Skeleton, Typography, Input } from 'antd';
// import { singleAnalysis } from '../data';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import StepComponent from './Steps/StepComponent';
import SimpleBar from 'simplebar-react';
import { useNotification } from './NotificationProvider';
import useDatacallswithtoken from '../../../../Data Apis/apifunctions';
import { set } from 'lodash';
import BlackBtn from './Steps/Elements/BlackBtn';
import SingleSumary from './SingleSumary';

const { Text } = Typography;
const { TextArea } = Input;



const Analysis = ({ analysisItem }) => {
    const { useForAnalysisTopicSummaries, useForFSPdfDownload, useForFSGetFileList, useForFSPPTDownload } = useDatacallswithtoken();
    // console.log(analysisItem);
    const notification = useNotification();
    const [allQuestionsSaved, setAllQuestionsSaved] = useState({});
    const [singleAnalysis, setSingleAnalysis] = useState();
    const [displaySteps, setDisplaySteps] = useState(false);
    const isObject = (value) => Object.prototype.toString.call(value) === '[object Object]';
    const [selecTedTopicItem, setSelecTedTopicItem] = useState(null);
    const [pdfLoading, setisPdfDownloading] = useState(false);
    const [pptLoading, setPptLoading] = useState(false)
    const [fileList, setFileList] = useState()

    const [loading, setLoading] = useState(true);






    useEffect(() => {
        GetFileList(analysisItem?.analysis_id)
    }, [analysisItem])

    useEffect(() => {

        console.log(analysisItem?.analysis_id);
        setDisplaySteps(false);
        setSelecTedTopicItem(null);

        setAllQuestionsSaved({})
        FetchAnalysisTopicSummary(analysisItem?.analysis_id)

        // setDisplaySteps(false)

    }, [analysisItem])

    const GetFileList = (analysisId) => {
        setLoading(true)
        useForFSGetFileList(analysisId).then(res => {
            console.log(res)
            // setLoading(false)
            if (res && res?.s3_location) setFileList(res)
            else setFileList(null)

        }).
            catch(err => {
                // setLoading(false)
                setFileList(null)
                notification.error({
                    message: "Error",
                    description: err.message,
                    placement: 'topRight',
                });
            })
    }

    const FetchAnalysisTopicSummary = (analysisId) => {
        setSingleAnalysis(null);
        setLoading(true)
        useForAnalysisTopicSummaries(analysisId).then(res => {
            // console.log(res)
            if (isObject(res)) {
                console.log(res);
                
                if (res?.analysis_list && res.analysis_list.length > 0) setSingleAnalysis(res)
                else setSingleAnalysis(null)

            }
            else
                setSingleAnalysis(null)
            setLoading(false)

        }).catch(err => {
            console.log(err)
            setLoading(false);
            setSingleAnalysis(null);
            notification.error({
                message: "Error",
                description: err.message,
                placement: 'topRight',
            });

        })

    }

    const DownloadPdf = async (analysisItem) => {
        setisPdfDownloading(true);
        const data_config = { analysisId: analysisItem?.analysis_id };
        // topicId: "", questionId: "" 
        useForFSPdfDownload(data_config)
            .then((res) => {
                const url = URL.createObjectURL(
                    new Blob([res.data], { type: res.headers['content-type'] })
                );
                const today = new Date();
                const formattedDate = today.toISOString().slice(0, 10); // Formats date as "YYYY-MM-DD"
                const link = document.createElement('a');
                link.href = url;


                link.setAttribute('download', `${analysisItem?.analysis_name ? analysisItem?.analysis_name : "Analysis"}_${formattedDate}`);
                link.click();
                URL.revokeObjectURL(url);
            })
            .catch((err) => {
                let error = new Blob([err.response.data]);
                error.text().then((text) => { });
            })
            .finally(() => {
                setisPdfDownloading(false);
            });
    };

    function handleShowStep(item) {
        if (!Object.values(allQuestionsSaved).every(value => value === true)) {
            const btn = <BlackBtn name={"Change analysis"} onBtnClick={() => { setSelecTedTopicItem(item); setAllQuestionsSaved({}) }} />

            notification.warning({
                message: 'Save all the changes to  change analysis else changes will be lost',
                description: 'If you want to change analysis  with out saving click on change analysis',
                btn,
                placement: 'topRight',
            })

        } else {
            setDisplaySteps(true)
            setSelecTedTopicItem(item)
        }

    }


    const handleDisplaySteps = (flag) => {
        console.log(flag)
        if (flag == false) FetchAnalysisTopicSummary(analysisItem?.analysis_id)
        setDisplaySteps(flag)
    }

    const DownloadPPt = async (analysisItem) => {
        setPptLoading(true);
        const data_config = { analysisId: analysisItem?.analysis_id };
        // topicId: "", questionId: "" 
        useForFSPPTDownload(data_config)
            .then((res) => {
                setPptLoading(false);
                const url = URL.createObjectURL(
                    new Blob([res.data], { type: res.headers['content-type'] })
                );
                const today = new Date();
                const formattedDate = today.toISOString().slice(0, 10); // Formats date as "YYYY-MM-DD"
                const link = document.createElement('a');
                link.href = url;


                link.setAttribute('download', `${analysisItem?.analysis_name ? analysisItem?.analysis_name : "Analysis"}_${formattedDate}`);
                link.click();
                URL.revokeObjectURL(url);
            })
            .catch((err) => {
                setPptLoading(false);
                let error = new Blob([err.response.data]);
                error.text().then((text) => { });
            })
            .finally(() => {
                setPptLoading(false);
            });
    }


    return (
        <Flex className='br-ra15-sld1-e6e6 w100-h100' vertical gap={20}>
            {displaySteps ?
                <StepComponent
                    singleAnalysis={singleAnalysis}
                    selecTedTopicItem={selecTedTopicItem}
                    analysisId={analysisItem?.analysis_id}
                    handleDisplaySteps={handleDisplaySteps}
                    allQuestionsSaved={allQuestionsSaved}
                    fileList={fileList}
                    setAllQuestionsSaved={setAllQuestionsSaved}
                />
                : <Flex className='p20 w100-h100' vertical gap={20}>
                    <Flex gap={20}>
                        {/* <Button className='lg-btn-pd-20' size='large'>Generate PPT</Button> */}
                        {/* <Button className='lg-btn-pd-20' size='large' type='primary'>Download PPT</Button> */}
                        {singleAnalysis && <>
                            <Button className='lg-btn-pd-20' size='large' onClick={() => DownloadPdf(analysisItem)} loading={pdfLoading}>Download Summary </Button>
                            <Button className='lg-btn-pd-20' size='large' type='primary' onClick={() => { DownloadPPt(analysisItem) }} loading={pptLoading}> Download PPT</Button>
                        </>}
                    </Flex>
                    <SimpleBar style={{ height: "90%", maxHeight: "90%" }}>
                        {loading ? <Skeleton title={false} paragraph={{ rows: 2 }} /> :
                            <Flex vertical gap={20}>


                                {singleAnalysis && singleAnalysis?.analysis_list && singleAnalysis?.analysis_list.length > 0 ?
                                    singleAnalysis?.analysis_list.map((item, index) => {
                                        return (
                                            <SingleSumary key={index} summaryItem={item} onEditClicked={() => handleShowStep(item)} />
                                        )
                                    }) : <Alert message="No analysis found" type="info" />
                                }


                            </Flex>
                        }


                    </SimpleBar>
                </Flex>

            }

        </Flex>
    )
}

export default Analysis
