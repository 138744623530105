import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { changeFeatureList, changeTrailExpiredMessage, } from '../../store/actions';
import useDatacallswithtoken from '../../Data Apis/apifunctions';
import { useNavigate } from 'react-router-dom';

import ContentSearch from "./Components/stru-components/ContentSearchLayouts/ContentSearch";
import NewContentSearch from "./Components/NewContentSearch";

const ShowContentSearchLayout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const featureList = useSelector((state) => state.PageData.featureList);
  const [loadingLayout, setLoadingLayout] = useState(true);

  const { useForFeatureList } = useDatacallswithtoken();
  const GetFeatureList = () => {
    // setLoadingLayout(true)
    useForFeatureList().then((res) => {
      console.log(res);
      if (typeof res === 'object') {
        setLoadingLayout(false);
        dispatch(changeFeatureList(res));
        console.log(Object.keys(res))
        const containsIN = Object.keys(res).some(item => item === 'IN');
        if (!containsIN) {
          navigate('/app/subscribe', { replace: true });

        }
      } else {
        dispatch(changeTrailExpiredMessage(res));
      }

    }).catch(err => {
      console.log(err)
      dispatch(changeTrailExpiredMessage(err?.response?.data))
    });
  };
  useEffect(() => {
    if (featureList?.length === 0) {
      GetFeatureList();
    } else {
      setLoadingLayout(false)
    }
    // if "FS" not in featureList, then navigate to subscribe
    // else if (!featureList.find((item) => item.code === 'FS')) {
    //   navigate('/app/subscribe', { replace: true });
    // }
  }, [featureList]);

  const displayGroup = useSelector((state) => state.PageData.appDisplay);

  const renderContentSerachLayout = () => {
    return <>{!loadingLayout && <NewContentSearch />}
    </>;
  };

  return <>{renderContentSerachLayout()}</>;
};

export default ShowContentSearchLayout;
