import React from "react";
import { Table, Typography } from "antd";
import "./JobDescriptionDisplay.css";
import JobDescriptionDisplayTipTap from "../TipTap/Index";
import { generateTableFromJson } from "../../Utils/Utils";
const JobDescriptionDisplay = ({ jobDetails }) => {
  const {
    job_purpose: jobPurpose,
    job_context: jobContext,
    kra: keyResultAreas,
    relationships,
  } = jobDetails;

  // Transform relationships for the table
  const formattedRelationships = relationships.map((rel) => ({
    Type: rel.Type,
    Details: rel.Details,
  }));
  return (
    <div
      className="JDDisplay"
      style={{
        padding: "20px",
        backgroundColor: "#fff",
        margin: "20px 0px 0px 0px",
        borderRadius: "7px",
        border: "1px solid #E6E6E6", // Assuming var(--Primary-1) is #E6E6E6
        background: "#FFF",
        flex: "1",
      }}
    >
      <Typography
        style={{
          fontSize: "28px",
          fontStyle: "normal",
          fontWeight: 400,
          lineHeight: "28px",
          //   gap: "35px",
        }}
      >
        {jobDetails.positionDetails.department}
      </Typography>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          gap: "35px",
          alignSelf: "stretch",
        }}
      >
        <h2>{jobDetails.title}</h2>

        <div>
          <h3>Job Purpose</h3>
          <p>{jobPurpose}</p>
        </div>
        <div>
          <h3>Dimensions</h3>
          <p>Business/SBU/Unit Revenue</p>
          <p>Business Workforce Number</p>
          <p>Unit Workforce Number</p>
          <p>Function Workforce Number</p>
          <p>Department Workforce Number</p>
        </div>
        <div>
          {/* Job Context & Major Challenges */}
          <h3>Job Context & Major Challenges</h3>
          <p>{jobContext}</p>
        </div>
        <div style={{ flex: "1", width: "100%" }}>
          {/* Key Result Areas/Accountabilities */}
          <h3>Key Result Areas/Accountabilities</h3>
          {/* <JobDescriptionDisplayTipTap
            content={generateTableFromJson(jobDetails)}
          /> */}
        </div>
        <div style={{ flex: "1", width: "100%" }}>
          <h3>Job Purpose of Direct Reports</h3>
          <Table
            columns={[
              {
                title: "Position Title",
                dataIndex: "positionTitle",
                key: "positionTitle",
              },
              {
                title: "Job Purpose",
                dataIndex: "jobPurpose",
                key: "jobPurpose",
              },
              {
                title: "Position No.",
                dataIndex: "positionNo",
                key: "positionNo",
              },
            ]}
            dataSource={[
              {
                key: "1",
                positionTitle: "Support CSR",
                jobPurpose: "Job Purpose Description",
                positionNo: "0014522",
              },
              {
                key: "2",
                positionTitle: "AH Rural Development",
                jobPurpose: `To ensure sustainable social & economic development of the community in which the organization operates through planning & implementation of need participatory Rural Development programme and to cooperate with all concerned internal & external partners to build a better, sustainable way of life for the weaker section of society`,
                positionNo: "0014522",
              },
            ]}
            pagination={false}
          />
        </div>

        <div style={{ flex: "1", width: "100%" }}>
          <h3>Relationships</h3>
          <Table
            dataSource={formattedRelationships}
            columns={[
              {
                dataIndex: "Type",
                render: (text) => <div>{text}</div>, // Custom render to hide title
              },
              {
                dataIndex: "Details",
                render: (text) => <div>{text}</div>, // Custom render to hide title
              },
            ]}
            pagination={false}
            bordered
            rowKey="Type" // Ensure each row has a unique key
          />
        </div>
        <div style={{ flex: "1", width: "100%" }}>
          <h3>Organisation Relationships</h3>
          <Table
            columns={[
              {
                title: "Position that it reports to",
                dataIndex: "positionThatItReportsTo",
                key: "positionThatItReportsTo",
              },
              {
                title: "Position and its peers (NoP=3)",
                dataIndex: "positionAndItsPeers",
                key: "positionAndItsPeers",
              },
              {
                title: "Position/s that reports into (NoP=0)",
                dataIndex: "positionThatReports",
                key: "positionThatReports",
              },
            ]}
            dataSource={[
              {
                key: "1",
                positionThatItReportsTo: "Lead Administration",
                positionAndItsPeers:
                  "AH Administration TM Administration Support Administration",
                positionThatReports: "",
              },
            ]}
            pagination={false}
          />
        </div>
      </div>
    </div>
  );
};

export default JobDescriptionDisplay;
