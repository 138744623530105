import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Routes, Route } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import Legal from '../Groups/Legal';
import QBR from '../Groups/QBR';
import DefaultComponent from './DefaultComponet';
import Sambalpur from '../Groups/Sambalpur';
import EkaayanPage from '../Groups/EKaayan';
import BlueStar from '../Groups/BlueStar';
import ApolloTyres from '../Groups/Apollo';
import AtlResearch from '../Groups/AtlResearch/AtlResearch';

const GroupComponent = ({ currentGroup, news, insights, userGroup }) => {
  switch (currentGroup) {
    case 'Legal':
      return <Legal userGroup={userGroup} />;
    case 'Sambalpur-KPI':
      return <Sambalpur />;
    case 'Aluminium Market Outlook':
      return <QBR news={news} insights={insights} userGroup={userGroup} />;
    case 'Ekaayan-SOP':
      return <EkaayanPage />;
    case 'Blue Star':
      return <BlueStar userGroup={userGroup} />;
    case 'ATL Marketing Intelligence':
      return <ApolloTyres />;
    case 'ATL Research':
      return <AtlResearch/>;
    default:
      return <DefaultComponent news={news} insights={insights} />;
  }
};

const Defaultrender = ({ news, insights, userGroup }) => {
  const appDisplay = useSelector((state) => state.PageData.appDisplay);
  const navigate = useNavigate();
  useEffect(() => {
    if (appDisplay === 'Aluminium Market Outlook') {
      const formattedAppDisplay = appDisplay.replaceAll(' ', '-');
      navigate(`${formattedAppDisplay.toLowerCase()}`);
    } else if (appDisplay === 'Legal') {
      navigate(`${appDisplay.toLowerCase()}`);
    } else if (appDisplay === 'Sambalpur-KPI') {
      navigate(`${appDisplay.toLowerCase()}`);
    } else if (appDisplay === 'Ekaayan-SOP') {
      const formattedAppDisplay = appDisplay.toLowerCase().replaceAll(' ', '-');
      navigate(`${formattedAppDisplay}`);
    } else if (appDisplay === 'Blue Star') {
      navigate(`${appDisplay.toLowerCase().replaceAll(' ', '-')}`);
    } else if (appDisplay === 'ATL Marketing Intelligence') {
      navigate(`${appDisplay.toLowerCase().replaceAll(' ', '-')}`);
    }else if(appDisplay === 'ATL Research'){
      const formattedAppDisplay = appDisplay.toLowerCase().replaceAll(' ', '-');
      navigate(`${formattedAppDisplay}`);
    } else {
      navigate(
        `/app/insights/${appDisplay.toLowerCase().replaceAll(' ', '-')}`
      );
    }
  }, [appDisplay]);

  return (
    <Routes>
      <Route
        path={`/${appDisplay?.toLowerCase()?.replaceAll(' ', '-')}`}
        element={
          <GroupComponent
            currentGroup={appDisplay}
            news={news}
            insights={insights}
            userGroup={userGroup}
          />
        }
      />
      <Route
        path='/'
        element={<DefaultComponent news={news} insights={insights} />}
      />
    </Routes>
  );
};

export default Defaultrender;
