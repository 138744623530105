import React, { useState } from 'react'
import { sources } from '../../data'
import { Flex, Tag, Tooltip, Typography } from 'antd'
import { useNotification } from '../NotificationProvider'
import useDatacallswithtoken from '../../../../../Data Apis/apifunctions'
import { FileOutlined, LinkOutlined } from '@ant-design/icons'
import "../../../style/style.css"
import BlackBtn from './Elements/BlackBtn'
const { Text } = Typography;


const StepSidebar = ({ singleAnalysis, selecTedTopicItem, onTopicChange, allQuestionsSaved, setAllQuestionsSaved, fileList }) => {
    const { useForPdfDownload } = useDatacallswithtoken();
    const notification = useNotification();
    const [topicItem, setTopicItem] = useState(selecTedTopicItem);
    const [pdfLoading, setPdfLoading] = useState(false);
    const titleForNotification = 'Unsaved changes detected'

    function handleTopicDisplay(item) {
        const key = `open${Date.now()}`;
        if (!Object.values(allQuestionsSaved).every(value => value === true)) {
            const btn = <BlackBtn name={"Change topic"} onBtnClick={() => { notification.destroy(key); changeTopic(item); setAllQuestionsSaved({}) }} />

            notification.warning({
                key,
                message: titleForNotification,
                description: `Save your changes before switching topics or click 'Change topic' to continue without saving`,
                btn,
                placement: 'topRight',
            })

        } else {
            changeTopic(item)
        }

    }
    const changeTopic = (item) => {
        onTopicChange(item)
        setTopicItem(item)
    }

    const DownloadPdf = async (s3Location, source, bucketName) => {
        setPdfLoading(true);
        const data_config = { filekey: s3Location, bucketName: bucketName };
        useForPdfDownload(data_config)
            .then((res) => {
                setPdfLoading(false);
                const url = URL.createObjectURL(
                    new Blob([res.data], { type: res.headers['content-type'] })
                );
                const today = new Date();
                const formattedDate = today.toISOString().slice(0, 10); // Formats date as "YYYY-MM-DD"
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${s3Location.split('/')[s3Location.split('/').length - 1]}`);
                link.click();
                URL.revokeObjectURL(url);
            })
            .catch((err) => {
                setPdfLoading(false);
                let error = new Blob([err.response.data]);
                error.text().then((text) => {
                    notification.error({
                        message: "Error",
                        description: text,
                        placement: 'topRight',
                    });
                });
            })
            .finally(() => {
                setPdfLoading(false);
            });
    };

    return (
        <Flex vertical gap={20} style={{ width: "23%", borderRight: "1px solid #e6e6e6", padding: "20px 15px" }}>
            {fileList?.s3_location &&
                <Flex vertical gap={7}>
                    <Text className='font-col-666' strong>Sources</Text>
                    <Flex wrap style={{ width: "100%" }}>


                        {fileList?.s3_location.map((source, index) => (
                            <Tooltip
                                key={index}
                                placement='bottomLeft'
                                title={source.s3_path && source.s3_path.split('/')[source.s3_path.split('/').length - 1]}
                            >
                                <Tag
                                    onClick={() => {

                                        DownloadPdf(source?.s3_path, source?.source, fileList?.s3_bucket)

                                    }}
                                    icon={
                                        source.s3_path?.endsWith('.pdf') ? (
                                            <FileOutlined />
                                        ) : (
                                            <LinkOutlined />
                                        )
                                    }
                                    // onClose={() => { handleTagClose(source, index) }}
                                    style={{
                                        marginBottom: '5px',
                                        padding: '5px 10px',
                                        fontSize: '.8rem',
                                        cursor: 'pointer',
                                        borderRadius: '5px',
                                        background: 'white',
                                        color: 'black',
                                    }}
                                >
                                    {/* {console.log(source)} */}
                                    {source.source}
                                </Tag>
                            </Tooltip>
                        ))}
                    </Flex>
                </Flex>
            }
            {singleAnalysis &&
                <Flex className='scrolly-h100 hidden-scrollbar' vertical gap={7}>
                    <Text strong>Topic</Text>

                    {singleAnalysis?.analysis_list.length > 0 &&

                        singleAnalysis?.analysis_list.map((item, index) => {
                            return (
                                <Flex className='cursor-pointer' key={index} gap={3} align='start'
                                    style={{
                                        marginBottom: "10px", borderBottom: ".4px solid #ccc", padding: '5px',
                                        background: item?.analysis_topic_id == topicItem?.analysis_topic_id ? "#f5f5f5" : "",
                                        borderRadius: "4px 4px 0px 0px"
                                    }} vertical
                                    onClick={() => handleTopicDisplay(item)}
                                >
                                  {item?.topic_name &&  <Text className='font-col-666'  >{item?.topic_name}</Text> } 
                                   {item?.summary && <Text className='font-col-666' style={{ width: "100%", fontSize: "12px", fontWeight: "300" }} >{ item?.summary.slice(0, 55)}</Text> } 
                                </Flex>
                            )
                        })}

                </Flex>
            }

        </Flex>
    )
}

export default StepSidebar
