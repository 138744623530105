import React, { useState, useEffect } from "react";
import { message, Skeleton } from "antd";
import { useAuth0 } from "@auth0/auth0-react";
import * as Y from "yjs";
import { TiptapCollabProvider } from "@hocuspocus/provider";
import "./JobDescription.css";
import JobDescriptionDisplay from "./JobDescriptionDisplay";
import FloatButtons from "./FloatButtons";
import JobDescriptionDisplayTipTap from "../TipTap/Index";
import { getCollabToken, getThreads, getDocument } from "../TipTap/Api/api";
import { useNavigate } from "react-router-dom";
import useDatacallswithtoken from "../../../../Data Apis/apifunctions";
import ApprovalHistory from "../ApprovalHistory/ApprovalHistory";
import JobDescriptionForm from "./Utils/JDForm";
import useJDAPI from "./Utils/api";
import { parseJobDescriptionHTML } from "./Utils/parseJobDescriptionHTML";
import { useJobDescriptionHandlers } from "./Utils/JobDescriptionHandlers";

const EditJD = ({ title = "", record }) => {
  const {
    DownloadExcelFile,
    DownloadWordFile,
    FetchPositionDetails,
    FetchJobDetails,
    ApproveJD,
    ReviseJD,
    SaveJD,
    SubmitForApproval,
  } = useJDAPI();
  const { user, getAccessTokenSilently } = useAuth0();
  const [isPositionIdSelected, setIsPositionIdSelected] = useState(false);
  const [positionDetails, setPositionDetails] = useState([]);
  const [jobDetails, setJobDetails] = useState([]);
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [generatedJobDescription, setGeneratedJobDescription] = useState([]);

  // States for selected form fields
  const [selectedQualifications, setSelectedQualifications] = useState([]);
  const [selectedSpecializations, setSelectedSpecializations] = useState([]);
  const [selectedIndustryPreferences, setSelectedIndustryPreferences] =
    useState([]);
  const [selectedExperience, setSelectedExperience] = useState("");
  const [internalRelationship, setInternalRelationship] = useState("");
  const [externalRelationship, setExternalRelationship] = useState("");
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [provider, setProvider] = useState(null);
  const [currentDocumentThreads, setCurrentDocumentThreads] = useState([]);
  const [showApprovalHistory, setShowApprovalHistory] = useState(false);
  const [doc, setDoc] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const positionId = record?.positionId || record?.position_id;

    if (positionId) {
      handlePositionIdChange(positionId);
    }
  }, [record]);
  useEffect(() => {
    if (jobDetails) {
      // Map qualifications
      const qualifications =
        jobDetails?.qualifications?.map((q) => q?.qualification) || [];
      setSelectedQualifications(qualifications);

      // Map specializations
      const specializations =
        jobDetails?.specializations?.map((s) => s?.specialization) || [];
      setSelectedSpecializations(specializations);

      // Map industry preferences
      const industries =
        jobDetails?.industries?.map((i) => i?.industry_preference) || [];
      setSelectedIndustryPreferences(industries);

      // Map experience
      setSelectedExperience(jobDetails?.experience || "");

      // Map internal and external relationships

      setInternalRelationship(jobDetails?.relationship_internal || "");
      setExternalRelationship(jobDetails?.relationship_external || "");

      // Map skills
      const skills = jobDetails?.skills?.map((s) => s?.skill) || [];
      setSelectedSkills(skills);
    }
  }, [jobDetails]);
  const handleViewStatusClick = () => {
    setShowApprovalHistory(!showApprovalHistory); // Toggle approval history visibility
  };
  const handlePositionIdChange = async (value) => {
    if (value !== "select") {
      setIsPositionIdSelected(true);
      setLoading(true);
      try {
        const accessToken = await getAccessTokenSilently();
        const positionId = String(value);
        const posbody = { position_id: positionId };
        const posRes = await FetchPositionDetails(posbody);

        const positionDetailsData = posRes.data;

        // Update positionDetails state
        setPositionDetails(positionDetailsData[0]);
        const jobBody = {
          jd_key: record?.key || record?.jd_key,
          user_id: user?.sub,
        };
        const jobRes = await FetchJobDetails(jobBody);

        const jobDetailsData = jobRes.data;

        const document = await getDocument(jobDetailsData?.jd_key);
        const threads = await getThreads(jobDetailsData?.jd_key);
        setCurrentDocumentThreads(threads);
        const newYdoc = new Y.Doc();
        Y.applyUpdate(newYdoc, new Uint8Array(document));

        // Create a new provider for this specific document
        const newProvider = new TiptapCollabProvider({
          appId: "x9l4z8kr",
          name: jobDetailsData?.jd_key,
          document: newYdoc,
          token: await getCollabToken(user),
        });
        setDoc(newYdoc);
        setProvider(newProvider);

        setJobDetails({
          ...jobDetailsData,
          positionDetails: positionDetailsData[0], // Use the fetched positionDetails
        });
      } catch (error) {
        console.error("Error during fetch:", error);
        message.error("Error fetching job details");
      } finally {
        setLoading(false);
      }
    } else {
      setIsPositionIdSelected(false);
      setPositionDetails(null);
      setJobDetails(null);
    }
  };

  const {
    handleSaveJD,
    handleSubmitForApproval,
    handleDownloadFile,
    handleApproveJD,
    handleReviseJD,
  } = useJobDescriptionHandlers({
    user,
    getAccessTokenSilently,
    positionDetails,
    selectedSkills,
    selectedQualifications,
    selectedIndustryPreferences,
    selectedSpecializations,
    internalRelationship,
    externalRelationship,
    selectedExperience,
    setSubmitting,
    setDoc,
    setProvider,
    setCurrentDocumentThreads,
    setGeneratedJobDescription,
    navigate,
    generatedJobDescription: jobDetails,
    positionList: [], // Not needed here
    FetchPositionDetails,
    GenerateJD: null, // Not used in EditJD
    SaveJD,
    SubmitForApproval,
    ApproveJD,
    ReviseJD,
    DownloadWordFile,
    DownloadExcelFile,
    record,
  });

  if (loading) {
    return (
      <div className="CJDmain">
        <Skeleton active paragraph={{ rows: 15 }} />
      </div>
    );
  }

  const mapApprovalHistory = (history) => {
    return history.map((entry) => ({
      date: entry.approval_date
        ? new Date(entry.approval_date).toLocaleDateString()
        : null,
      name: entry.Name,
      status:
        entry.action.toLowerCase() === "approved" || "submitted"
          ? "approved"
          : "pending",
      description: entry.comments || "No comments provided",
    }));
  };
  const approvalHistoryData = mapApprovalHistory(jobDetails?.jd_history || []);

  return (
    <div className="CJDmain">
      <JobDescriptionForm
        mode="edit"
        title={jobDetails.edit_details.editable ? title : undefined}
        initialValues={{
          positionId: record?.positionId || record?.position_id,
        }}
        positionList={[]} // Empty since positionId is fixed
        positionDetails={positionDetails}
        isPositionIdSelected={isPositionIdSelected}
        handlePositionIdChange={handlePositionIdChange}
        selectedQualifications={selectedQualifications}
        setSelectedQualifications={setSelectedQualifications}
        selectedSpecializations={selectedSpecializations}
        setSelectedSpecializations={setSelectedSpecializations}
        selectedIndustryPreferences={selectedIndustryPreferences}
        setSelectedIndustryPreferences={setSelectedIndustryPreferences}
        selectedExperience={selectedExperience}
        setSelectedExperience={setSelectedExperience}
        internalRelationship={internalRelationship}
        setInternalRelationship={setInternalRelationship}
        externalRelationship={externalRelationship}
        setExternalRelationship={setExternalRelationship}
        selectedSkills={selectedSkills}
        setSelectedSkills={setSelectedSkills}
        submitting={submitting}
        generatedJobDescription={generatedJobDescription}
        jdHistory={jobDetails?.jd_history} // Pass jd_history to the form
        onViewStatusClick={handleViewStatusClick} // Pass the click handler
        showApprovalHistory={showApprovalHistory} // Control the text of the button
      />
      <div
        style={{
          backgroundColor: "#fff",
          margin: "20px 0px 0px 0px",
          borderRadius: "7px",
          border: "1px solid #E6E6E6", // Assuming var(--Primary-1) is #E6E6E6
          background: "#FFF",
          flex: "1",
        }}
      >
        {jobDetails.jd_key && (
          <>
            <JobDescriptionDisplayTipTap
              content={null}
              onSave={handleSaveJD}
              onCommentSubmit={handleSubmitForApproval}
              isEditable={jobDetails.edit_details.editable}
              provider={provider}
              initialThreads={currentDocumentThreads}
              setCurrentDocumentThreads={setCurrentDocumentThreads}
              doc={doc}
              onDownload={handleDownloadFile}
              page="EditJD"
              onApprove={handleApproveJD}
              onRevise={handleReviseJD}
              actions={jobDetails.edit_details.actions || "download"}
            />
          </>
        )}
      </div>
      {showApprovalHistory && (
        <div id="aphistory" style={{ margin: "20px 0px 20px 0px" }}>
          <ApprovalHistory approvalData={approvalHistoryData} />
        </div>
      )}
    </div>
  );
};

export default EditJD;
