import React, { useEffect, useState } from "react";
import "../Components/Boxes/Boxes.css";
import Boxes from "../Components/Boxes/Boxes";
import { useAuth0 } from "@auth0/auth0-react";
import { message, Skeleton } from "antd"; // Import Skeleton
import useDatacallswithtoken from "../../../Data Apis/apifunctions";

const capitalizeWords = (str) =>
  str.replace(/\b\w/g, (char) => char.toUpperCase());

const MyTask = ({ onViewMore, onEditClick, setPagesCount }) => {
  const { useForLandingPageData } = useDatacallswithtoken();
  const { user, getAccessTokenSilently } = useAuth0();
  const [cards, setCards] = useState({ pendingApproval: [], needRevision: [] });
  const [greeting, setGreeting] = useState(getGreeting());
  const [loading, setLoading] = useState(true); // Add loading state
  const userName = capitalizeWords(user?.email?.split("@")[0] || "User");
  const FetchLandingPageData = async (body) => {
    const res = await useForLandingPageData(JSON.stringify(body));
    if (res.status === 200) {
      return res; // Return the response data
    } else {
      throw new Error("Error fetching landing page data");
    }
  };
  function getGreeting() {
    const hours = new Date().getHours();
    if (hours < 12) return "Good morning";
    if (hours >= 12 && hours < 16) return "Good afternoon";
    return "Good evening";
  }

  useEffect(() => {
    const fetchLandingPageData = async () => {
      const jsonData = { userId: user?.sub };
      try {
        const res = await FetchLandingPageData(jsonData);
        const data = res.data;
        const pendingApproval = data.pending || [];
        const needRevision = data.need_revision || [];
        const totalTasks = pendingApproval.length + needRevision.length;

        setCards({
          pendingApproval,
          needRevision,
        });

        setPagesCount({ myTask: totalTasks });
      } catch (error) {
        console.error("Error during fetch:", error);
        message.error("Error fetching data");
      } finally {
        setLoading(false); // Data has been fetched, stop loading
      }
    };

    fetchLandingPageData();
    setGreeting(getGreeting());
    const interval = setInterval(() => setGreeting(getGreeting()), 60000);

    document.documentElement.style.backgroundColor = "#fff";
    return () => clearInterval(interval);
  }, [getAccessTokenSilently, user]);

  const handleCardClick = (card) => {
    console.log(card);
    onEditClick(card);
  };

  return (
    <>
      {loading ? (
        <Skeleton active paragraph={{ rows: 6 }} /> // Display Skeleton when loading
      ) : (
        <Boxes
          dashboardGreeting={greeting}
          taskSummary={{
            totalTasks:
              cards.pendingApproval.length + cards.needRevision.length,
            pendingApproval: cards.pendingApproval.length,
            needRevision: cards.needRevision.length,
          }}
          cards={cards}
          onViewMore={onViewMore}
          onCardClick={handleCardClick}
        />
      )}
    </>
  );
};

export default MyTask;
