export const generateTableFromJson = (data) => {
  // Extract the first row to be used as the title
  const titleRow = data?.slice(0, 1)[0];
  // Slice the remaining rows for the table
  const tableData = data;

  // Start building the table HTML
  let table =
    '<table border="1" cellspacing="0" cellpadding="10" style="border-collapse: collapse; width: 100%;border: 1px solid black;">';

  // Define the colgroup to hide the ID column
  table += `<colgroup>
                <col style="width: 50%;">
                <col style="width: 50%;">
                <col style="display: none;">
              </colgroup>`;

  // Table headers: including 'jd_kra_id' or 'jd_relationship_id'
  // table += `<thead style="flex:1;"><tr>
  //   <th>${titleRow?.Type}</th>
  //   <th>${titleRow?.Details}</th>
  //   <th>ID</th></tr></thead>`;

  // Table body
  table += "<tbody>";

  // Iterate over the remaining rows and add them to the table
  tableData?.forEach((item) => {
    table += `<tr>              
    <td>${item?.Type}</td>
    <td>${item?.Details}</td>
   </tr>`;
  });

  table += "</tbody></table>";

  return table;
};

export const getRandomElement = (list) =>
  list[Math.floor(Math.random() * list.length)];
