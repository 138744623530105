import React, { useState } from 'react'
import { Flex, Typography, Collapse, Alert } from 'antd'
import "../../../style/style.css"
import { EditOutlined } from '@ant-design/icons'
import SingleQuestion from './SingleQuestion'
import "../../../style/style.css"


const { Panel } = Collapse;
const { Text, Title } = Typography

const Step1 = ({ step1Data, analysisId, setAllQuestionsSaved }) => {
    console.log(step1Data, analysisId)
    // const [allQuestionsSaved, setAllQuestionsSaved] = useState({});
    const googleSearchQuestions = step1Data?.topic_stage_data.filter(item => item.question_type === "Google Search");
    const structuredQuestions = step1Data?.topic_stage_data.filter(item => item.question_type === "Structured");
    const unstructuredQuestions = step1Data?.topic_stage_data.filter(item => item.question_type === "Unstructured");
    // console.log(allQuestionsSaved)
    return (
        <>

            {step1Data ?
                <Flex vertical style={{ width: "100%" }} gap={20}>
                    {
                        googleSearchQuestions && googleSearchQuestions.length > 0 &&
                        <Collapse bordered={false} accordion style={{ width: "100%" }}>
                            <Panel header={(
                                <Flex justify='space-between'>
                                    <Title className='margin0' level={5}>Google Search</Title>
                                    <Text className='font-col-999'>{googleSearchQuestions.length}</Text>
                                </Flex>
                            )}>
                                {
                                    googleSearchQuestions.map((item, index) => {
                                        return (
                                            <SingleQuestion key={index} questionObj={item} analysisId={analysisId} setAllQuestionsSaved={setAllQuestionsSaved} />
                                        )
                                    })
                                }
                            </Panel >
                        </Collapse >
                    }

                    {
                        structuredQuestions && structuredQuestions.length > 0 &&
                        <Collapse bordered={false} accordion style={{ width: "100%" }}>
                            <Panel header={(
                                <Flex justify='space-between'>
                                    <Title className='margin0' level={5}>Structured</Title>
                                    <Text className='font-col-999'>{structuredQuestions.length}</Text>
                                </Flex>
                            )}>
                                {
                                    structuredQuestions.map((item, index) => {
                                        return (
                                            <SingleQuestion key={index} questionObj={item} analysisId={analysisId} setAllQuestionsSaved={setAllQuestionsSaved} />
                                        )

                                    })

                                }
                            </Panel >
                        </Collapse >
                    }

                    {
                        unstructuredQuestions && unstructuredQuestions.length > 0 &&
                        <Collapse bordered={false} accordion style={{ width: "100%" }}>
                            <Panel header={(
                                <Flex justify='space-between'>
                                    <Title className='margin0' level={5}>UnStructured</Title>
                                    <Text className='font-col-999'>{unstructuredQuestions.length}</Text>
                                </Flex>
                            )}>
                                {
                                    unstructuredQuestions.map((item, index) => {
                                        return (
                                            <SingleQuestion key={index} questionObj={item} analysisId={analysisId} setAllQuestionsSaved={setAllQuestionsSaved} />
                                        )

                                    })

                                }
                            </Panel >
                        </Collapse >
                    }



                </Flex >
                :
                <Alert type='info' message='No Data for step 1' />}
        </>
    )
}

export default Step1
