import React, { createContext, useCallback, useContext, useState } from "react";

export const ThreadsContext = createContext({
  threads: [],
  selectedThreads: [],
  selectedThread: null,
  onClickThread: () => null,
  deleteThread: () => null,
  onUpdateComment: () => null,
  onHoverThread: () => null,
  onLeaveThread: () => null,
});

export const ThreadsProvider = ({
  children,
  threads = [],
  selectedThreads = [],
  onClickThread = () => null,
  onDeleteThread = () => null,
  onUpdateComment = () => null,
  onHoverThread = () => null,
  onLeaveThread = () => null,
}) => {
  const [selectedThread, setSelectedThread] = useState(null);

  const handleThreadClick = useCallback(
    (threadId) => {
      setSelectedThread((currentThreadId) => {
        if (currentThreadId !== threadId) {
          onClickThread(threadId);
          setSelectedThread(threadId);
        }
        return currentThreadId !== threadId ? threadId : null;
      });
    },
    [onClickThread]
  );

  const providerValue = {
    threads,
    selectedThreads,
    selectedThread,
    deleteThread: onDeleteThread,
    onClickThread: handleThreadClick,
    onUpdateComment,
    onHoverThread,
    onLeaveThread,
  };

  return (
    <ThreadsContext.Provider value={providerValue}>
      {children}
    </ThreadsContext.Provider>
  );
};

export const useThreadsState = () => {
  return useContext(ThreadsContext);
};
