import React from "react";
import Table from "../Components/Table/TableDiv";
import {
  TeamOutlined,
  UserOutlined,
  PlusOutlined,
  FundOutlined,
} from "@ant-design/icons";
import { MdOutlineDashboard } from "react-icons/md";
import { FaTasks } from "react-icons/fa";
import { useLocation } from "react-router-dom";
const Dashboard = () => {
  const location = useLocation();

  return (
    <div className="main">
      <h1>Dashboard</h1>
    </div>
  );
};
export default Dashboard;
