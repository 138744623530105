import { Button,ConfigProvider } from 'antd'
import React from 'react'

const BlackBtn = ({onBtnClick,name}) => {
  return (
    <ConfigProvider theme={{ token: { colorPrimary: 'black' } }}>

    <Button size='small' onClick={()=>onBtnClick()}>
        {name}
    </Button>
    </ConfigProvider>
  )
}

export default BlackBtn
