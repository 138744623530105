import React, { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
// import sidebarData from "./SidebarData";
import { SidebarData, SidebarData1 } from "./SidebarData";
// import useSidebarData from './SidebarData';
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
//Simple bar
import SimpleBar from "simplebar-react";
// MetisMenu
import MetisMenu from "metismenujs";
import withRouter from "../../components/Common/withRouter";

import { Link, useNavigate } from "react-router-dom";
import useDatacallswithtoken from "../../Data Apis/apifunctions";
//i18n
import { withTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import LogoutPopUp from "../../components/Common/LogoutPopUp";

import {
  changeStructuredQuestion,
  changeContentSearchQuestion,
  changeChatQuestions,
  changeChatQuestionsUnstructured,
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
  changeUnStructuredQuestion,
  changeSummaryQuestion,
  changeIsSidebarOpen,
} from "../../store/actions";
import logoDark from "../../assets/images/actalyst_logo_borders_dark.png";
import logoLight from "../../assets/images/actalyst_letter_logo_black.png";
import logoSmDark from "../../assets/images/AtlasSmLight.png";
import logoSmLight from "../../assets/images/actalyst_letter_logo_white.png";
import ProfileMenu from "../../components/Common/TopbarDropdown/ProfileMenu";
import { connect } from "react-redux";
const Sidebar = (props) => {
  const dispatch = useDispatch();
  // const { SidebarData, SidebarData1 } = useSidebarData();
  const { useForFeatureList, useForLoadKx } = useDatacallswithtoken();
  const navigate = useNavigate();
  const featureList = useSelector((state) => state.PageData.featureList);
  const location = useLocation();
  const isSideBarOpen = useSelector((state) => state.PageData.isSideBarOpen);
  const appDisplay = useSelector((state) => state.PageData.appDisplay);
  const trailexpairedMessage = useSelector(
    (state) => state.PageData.trailExpiredMessage
  );
  const featureListArr= featureList?Object.keys(featureList):[]
  // console.log("key",featureListArr)
  const ref = useRef();
  const { user, logout } = useAuth0();
  const [isLogoutPopupOpen, setIsLogoutPopupOpen] = useState(false);

  const handleLogout = () => {
    setIsLogoutPopupOpen(true);
  };

  //   const GetFeatureList = () => {
  //     useForFeatureList().then(res => {
  //         if (typeof res === 'object') {
  //             dispatch(changeFeatureList(res))
  //         }
  //         else {
  //             dispatch(changeTrailExpiredMessage(res))
  //         }
  //     })

  // }

  const activateParentDropdown = useCallback((item) => {
    item.classList.add("active");
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show");
    }
    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;
      if (parent2) {
        parent2.classList.add("mm-show"); // ul tag
        const parent3 = parent2.parentElement; // li tag
        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add("mm-show"); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add("mm-show"); // li
              parent5.childNodes[0].classList.add("mm-active"); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }, []);
  const removeActivation = (items) => {
    for (var i = 0; i < items.length; ++i) {
      var item = items[i];
      const parent = items[i].parentElement;
      if (item && item.classList.contains("active")) {
        item.classList.remove("active");
      }
      if (parent) {
        const parent2El =
          parent.childNodes && parent.childNodes.length && parent.childNodes[1]
            ? parent.childNodes[1]
            : null;
        if (parent2El && parent2El.id !== "side-menu") {
          parent2El.classList.remove("mm-show");
        }
        parent.classList.remove("mm-active");
        const parent2 = parent.parentElement;
        if (parent2) {
          parent2.classList.remove("mm-show");
          const parent3 = parent2.parentElement;
          if (parent3) {
            parent3.classList.remove("mm-active"); // li
            parent3.childNodes[0].classList.remove("mm-active");
            const parent4 = parent3.parentElement; // ul
            if (parent4) {
              parent4.classList.remove("mm-show"); // ul
              const parent5 = parent4.parentElement;
              if (parent5) {
                parent5.classList.remove("mm-show"); // li
                parent5.childNodes[0].classList.remove("mm-active"); // a tag
              }
            }
          }
        }
      }
    }
  };

  const handleConfirmLogout = () => {
    setIsLogoutPopupOpen(false);
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  };

  // const handleLogout = () => {
  //   logout({
  //     logoutParams: {
  //       returnTo: window.location.origin,
  //     },
  //   });
  // };
  const activeMenu = useCallback(() => {
    const pathName = props.router.location.pathname;
    const fullPath = pathName;
    let matchingMenuItem = null;
    const ul = document.getElementById("side-menu-item");
    const items = ul.getElementsByTagName("a");
    removeActivation(items);
    for (let i = 0; i < items.length; ++i) {
      if (fullPath === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem);
    }
  }, [props?.router?.location?.pathname, activateParentDropdown]);

  useEffect(() => {
    if (trailexpairedMessage !== "") {
      navigate("/app/user-status");
      // setsearchDisabledMessage("Trail Expired!");
    }
  }, [trailexpairedMessage, navigate]);

  useEffect(() => {
    ref.current.recalculate();
  }, []);
  useEffect(() => {
    new MetisMenu("#side-menu-item");
    activeMenu();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  function tToggle() {
    var body = document.body;
    // setisSideBarOpen(!isSideBarOpen);
    dispatch(changeIsSidebarOpen(!isSideBarOpen));
    if (window.screen.width <= 998) {
      body.classList.toggle("sidebar-enable");
    } else {
      body.classList.toggle("vertical-collpsed");
      body.classList.toggle("sidebar-enable");
    }
  }
  useEffect(() => {
    activeMenu();
  }, [activeMenu]);
  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  }
  return (
    <React.Fragment>
      <div
        className="vertical-menu"
        style={{
          position: "fixed",
          top: "0px",
          backgroundColor: "#f4f4f4",
          height: "100vh",
        }}
      >
        <SimpleBar className="h-100" ref={ref}>
          <div
            id="sidebar-menu"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              height: "100vh",
            }}
          >
            <ul className="metismenu list-unstyled  " id="side-menu-item">
              <li className="d-flex justify-content-start">
                <button
                  style={{ color: "#636E75" }}
                  type="button"
                  className="btn btn-sm px-4 font-size-24 header-item waves-effect"
                  id="vertical-menu-btn"
                  onClick={() => {
                    tToggle();
                  }}
                >
                  <i className="ri-menu-2-line align-middle"></i>
                </button>
              </li>
              {(SidebarData || [])?.map((item, key) => (
                <React.Fragment key={key}>
                  {item.isMainMenu ? (
                    item.label === 'Contact Us' ||
                    featureListArr.find(
                      (feature) => feature === item.code
                    ) ? (
                      <li className="menu-title">{props.t(item.label)}</li>
                    ) : null
                  ) : item.label === 'Contact Us' ||
                    featureListArr.find(
                      (feature) => feature === item.code
                    ) ? (
                    <li key={key}>
                      <Link
                        to={
                          item.url && item.label === "Intelligence"
                            ? `/app/insights/${appDisplay
                                ?.toLowerCase()
                                .replaceAll(' ', '-')}`
                            : item.url
                            ? item.url
                            : "#"
                        }
                        target={props.t(item.label) === "Contact Us" ? "" : ""}
                        className={
                          item.issubMenubadge || item.isHasArrow
                            ? " "
                            : "has-arrow"
                        }
                        onClick={() => {
                          dispatch(changeChatQuestions([]));
                          if (props.t(item.label) === "Intelligence") {
                            dispatch(changeContentSearchQuestion(""));
                            dispatch(changeChatQuestions([]));
                          }
                          if (props.t(item.label) === "Adv-Intelligence") {
                            dispatch(changeChatQuestions(""));
                          }
                          // else if (props.t(item.label) === 'KnowledgeX') {
                          //   dispatch(changeStructuredQuestion(''))
                          // }
                          else if (props.t(item.label) === "Assistant") {
                            dispatch(changeChatQuestions([]));
                          } else if (props.t(item.label) === "Filesense") {
                            dispatch(changeChatQuestionsUnstructured([]));
                          }
                        }}
                      >
                        <i
                          className={item.icon}
                          style={{ marginRight: "5px" }}
                        ></i>
                        {item.issubMenubadge && (
                          <span
                            className={
                              "badge rounded-pill float-end " + item.bgcolor
                            }
                          >
                            {" "}
                            {item.badgeValue}{" "}
                          </span>
                        )}
                        <span>{props.t(item.label)}</span>
                      </Link>
                      {item.subItem && (
                        <ul className="sub-menu">
                          {item.subItem.map((item, key) => (
                            <li key={key}>
                              <Link
                                to={item.link}
                                className={
                                  item.subMenu && "has-arrow waves-effect"
                                }
                                onClick={() => {
                                  console.log(item);
                                }}
                              >
                                {props.t(item.sublabel)}
                              </Link>
                              {item.subMenu && (
                                <ul className="sub-menu">
                                  {item.subMenu.map((item, key) => (
                                    <li key={key}>
                                      <Link
                                        to="#"
                                        onClick={() => {
                                          console.log(item);
                                        }}
                                      >
                                        {props.t(item.title)}
                                      </Link>
                                    </li>
                                  ))}
                                </ul>
                              )}
                            </li>
                          ))}
                        </ul>
                      )}
                    </li>
                  ) : null}
                </React.Fragment>
              ))}
            </ul>

            <ul className="metismenu list-unstyled" id="side-menu-item">
              {SidebarData1.map((item, key) => (
                <li key={key}>
                  {item.label === "Logout" ? (
                    <Link
                      to="#"
                      onClick={handleLogout}
                      className="waves-effect"
                    >
                      <i className={item.icon}></i>
                      <span>{props.t(item.label)}</span>
                    </Link>
                  ) : (
                    <Link
                      to={item.url}
                      className={item.issubMenubadge ? " " : "has-arrow"}
                    >
                      <i
                        className={item.icon}
                        style={{ marginRight: "5px" }}
                      ></i>
                      {item.issubMenubadge && (
                        <span
                          className={
                            "badge rounded-pill float-end " + item.bgcolor
                          }
                        >
                          {item.badgeValue}
                        </span>
                      )}
                      <span>{props.t(item.label)}</span>
                    </Link>
                  )}
                </li>
              ))}

              {isSideBarOpen ? (
                <li>
                  <Link
                    className="p-0 mt-3"
                    to={`/app/insights/${appDisplay
                      ?.toLowerCase()
                      .replaceAll(' ', '-')}`}
                    onClick={() => {
                      dispatch(changeChatQuestions([]));
                      dispatch(changeContentSearchQuestion(""));
                      dispatch(changeChatQuestions([]));
                    }}
                  >
                    <img
                      style={{
                        width: "60%",
                        marginLeft: "1.5em",
                      }}
                      src={logoDark}
                      alt="logo-dark"
                    />
                  </Link>
                </li>
              ) : (
                <li>
                  <Link
                    style={{ marginRight: ".5em" }}
                    className="d-flex"
                    to={`/app/insights/${appDisplay
                      ?.toLowerCase()
                      .replaceAll(' ', '-')}`}
                    onClick={() => {
                      dispatch(changeChatQuestions([]));
                      dispatch(changeContentSearchQuestion(""));
                      dispatch(changeChatQuestions([]));
                    }}
                  >
                    <img
                      style={{ marginRight: "" }}
                      src={logoSmLight}
                      alt="logo-dark"
                      height="30"
                    />
                    <span style={{ marginLeft: "1.2em" }}>Home</span>
                  </Link>
                </li>
              )}
            </ul>
          </div>
        </SimpleBar>
      </div>
      <LogoutPopUp
        isOpen={isLogoutPopupOpen}
        toggle={() => setIsLogoutPopupOpen(!isLogoutPopupOpen)}
        onConfirmLogout={handleConfirmLogout}
      />
    </React.Fragment>
  );
};
Sidebar.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};
const mapStatetoProps = (state) => {
  const { layoutType, showRightSidebar, leftMenu, leftSideBarType, question } =
    state.Layout;
  return { layoutType, showRightSidebar, leftMenu, leftSideBarType, question };
};
const ConnectedSidebar = connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
  changeStructuredQuestion,
  changeUnStructuredQuestion,
  changeSummaryQuestion,
})(Sidebar);

const RoutedSidebar = withRouter(ConnectedSidebar);

export default withTranslation()(RoutedSidebar);
