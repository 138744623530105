import React, { useEffect,useState } from 'react'
import HeaderWithoutGreet from '../../components/Common/HeaderWithoutGreet'
import { Routes, Route } from "react-router-dom";
import Unstructured from '../Unstructured';
import { notification,ConfigProvider,Spin } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { filesenseAppDisplayHandler } from '../../store/actions';
import AdvancedFilesense from './AdvancedFilesense/AdvancedFilesense';
import { NotificationProvider } from './AdvancedFilesense/Components/NotificationProvider';
import Header from './AdvancedFilesense/Components/Header';

const Filesense = () => {
    const filesenseAppDisplay = useSelector((state) => state.PageData.filesenseAppDisplay);
    document.title = 'Filesense | Actalyst AI Platform';
    const [loading, setLoading] = useState(true);
    const featureList = useSelector((state) => state.PageData.featureList);

   


    return (
        <NotificationProvider>
            <ConfigProvider theme={{ token: { colorPrimary: 'black', fontFamily: 'Roboto, sans-serif',wordSpacing:"10px" } }}>
                <div style={{ padding: "1em 0em", minHeight: "100vh", position: "relative" }}>
                    <Header loading={loading} setLoading={setLoading} />
                    {loading ?
                        <Spin style={{ position: "absolute", top: "50%", left: "50%" }} />
                        :
                        <>
                            <Routes>
                                <Route path="/advance" element={<AdvancedFilesense />} />
                                <Route path="/" element={<Unstructured />} />
                            </Routes>
                        </>
                    }



                </div>
            </ConfigProvider>
        </NotificationProvider>
    )
}

export default Filesense
