import React from "react";
import { Timeline, Tooltip } from "antd";
import {
  CheckOutlined,
  Loading3QuartersOutlined,
  RedoOutlined,
} from "@ant-design/icons";
import "./ApprovalHistory.css"; // You can style as per your design

// Function to format the date as "29-Aug-2024"
const formatDate = (dateString) => {
  if (!dateString) return ""; // If date is null or undefined
  const options = { day: "2-digit", month: "short", year: "numeric" };
  const date = new Date(dateString);
  return date.toLocaleDateString("en-GB", options).replace(/ /g, "-");
};

const ApprovalHistory = ({ approvalData }) => {
  return (
    <div className="approval-history-container">
      <h2>Approval History</h2>
      <Timeline style={{ margin: "30px 10px 0px 10px" }}>
        {approvalData.map((entry, index) => {
          const statusLower = entry.status.toLowerCase(); // Convert status to lowercase for comparison

          return (
            <Timeline.Item
              key={index}
              dot={
                statusLower === "approved" ? (
                  <span
                    style={{
                      display: "inline-block",
                      width: "32px",
                      height: "32px",
                      borderRadius: "50%",
                      border: "2px solid #52C41A", // Green border for approved
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <CheckOutlined
                      style={{
                        fontSize: "16px",
                        color: "#52C41A",
                      }}
                    />
                  </span>
                ) : statusLower === "pending" ? (
                  <span
                    style={{
                      display: "inline-block",
                      width: "32px",
                      height: "32px",
                      borderRadius: "50%",
                      border: "2px solid gray", // Grey border for pending
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Loading3QuartersOutlined
                      style={{
                        fontSize: "16px",
                        color: "gray",
                      }}
                    />
                  </span>
                ) : statusLower === "revised" ? (
                  <span
                    style={{
                      display: "inline-block",
                      width: "32px",
                      height: "32px",
                      borderRadius: "50%",
                      border: "2px solid rgb(250, 140, 22)", // Sunset orange border for revised
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <RedoOutlined
                      style={{
                        fontSize: "16px",
                        color: "rgb(250, 140, 22)", // Orange color for revised
                      }}
                    />
                  </span>
                ) : (
                  <Loading3QuartersOutlined
                    style={{
                      fontSize: "16px",
                      color: "gray", // Default color grey
                      maxHeight: "32px",
                      maxWidth: "32px",
                    }}
                  />
                )
              }
              color={
                statusLower === "approved"
                  ? "#52C41A"
                  : statusLower === "revised"
                  ? "rgb(250, 140, 22)" // Sunset orange color for revised
                  : "gray"
              }
            >
              <div className="approval-entry">
                {statusLower !== "pending" && entry.date && (
                  <div className="approval-date">{formatDate(entry.date)}</div>
                )}
                <div
                  className={`approval-name ${
                    statusLower === "approved"
                      ? "approved"
                      : statusLower === "revised"
                      ? "revised"
                      : "pending"
                  }`}
                >
                  {entry.name}
                </div>
                {statusLower !== "pending" && (
                  <Tooltip title={entry.description}>
                    <div className="approval-description">
                      {entry.description}
                    </div>
                  </Tooltip>
                )}
                {statusLower === "pending" && (
                  <div className="waiting-status">Waiting for Approval</div>
                )}
                {statusLower === "revised" && (
                  <div className="revised-status">Revised for Changes</div>
                )}
              </div>
            </Timeline.Item>
          );
        })}
      </Timeline>
    </div>
  );
};

export default ApprovalHistory;
