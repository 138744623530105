import React, { useState, useEffect, createRef, memo } from 'react';
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Spinner,
  Modal,
  ModalBody,
} from 'reactstrap';
// import { useSelector} from "react-redux";
import NewsRender from '../../kx-components/newsRender';
import Summary from '../../kx-components/Summary';
import Prices from '../../kx-components/Prices';
import InvestorAndSentenment from '../../kx-components/InvestorAndSentenment';
import Sustainability from '../../kx-components/sustainability';
import CostCurves from '../../kx-components/costCurves';
import MarketShare from '../../kx-components/marketShare';
import TradeData from '../../kx-components/tradeData';
import MacroIndicator from '../../kx-components/macroIndicator';
import UiRangeSlider from '../../kx-components/for7';
// import useDatacallswithtoken from "../../../../Data Apis/apifunctions";
import useDatacallswithtoken from '../../../../../Data Apis/apifunctions';
import Network from '../../../../../components/Common/Network';
// import Legal from "../Groups/Legal";

const QBR = ({ news, insights, userGroup }) => {
  // let appDisplay = useSelector(state => state.PageData.appDisplay);
  const forNewsHeight = createRef(null);
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [groupNews, setgroupNews] = useState([]);
  const [groupInsights, setgroupInsights] = useState({});
  const [newHeight, setNewHeight] = useState(0);
  const [view, setView] = useState('Production & Consumption');
  const { usePowerBiToken, useForLoadForGroupsKx } = useDatacallswithtoken();
  const [token, setToken] = useState('');
  const [showNetwork, setshowNetwork] = useState(false);
  const [insightImages, setinsightImages] = useState(null);
  const GetToken = () => {
    usePowerBiToken().then((res) => {
      setToken(res.access_token);
      setIsPageLoading(false);
    });
  };

  const GetLoadForGroupKx = () => {
    useForLoadForGroupsKx(userGroup)
      .then((res) => {
        setgroupNews(res?.news);
        setgroupInsights(res?.insights);
        setinsightImages(res?.insightImages);
      })
      .catch((err) => {
        setIsPageLoading(false);
      });
  };
  useEffect(() => {
    GetToken();
    GetLoadForGroupKx();
  }, []);

  const GetView = () => {
    return (
      <>
        {token !== '' ? (
          <>
            <div
              style={{
                height: view === 'Production & Consumption' ? 'auto' : '0px',
                overflow: 'hidden',
              }}
            >
              {(insights?.['Production & Consumption']?.headers ||
                groupInsights?.['Production & Consumption']?.headers) && (
                <Summary
                  image={insightImages?.Production_Consumption}
                  insights={
                    insights['Production & Consumption']?.headers ||
                    groupInsights['Production & Consumption']?.headers
                  }
                  token={token}
                />
              )}
              {/* <Summary insights={insights?.["Summary"].headers} token={token} /> */}
            </div>
            <div
              style={{
                height: view === 'Prices and Premium' ? 'auto' : '0px',
                overflow: 'hidden',
              }}
            >
              {(insights?.['Prices and Premium']?.headers ||
                groupInsights?.['Prices and Premium']?.headers) && (
                <Prices
                  image1={insightImages?.LME_SHFE}
                  image2={insightImages?.premiums}
                  insights={
                    insights['Prices and Premium']?.headers ||
                    groupInsights['Prices and Premium']?.headers
                  }
                  token={token}
                />
              )}
              {/* <Prices insights={insights?.["Prices and Premium"].headers} token={token} /> */}
            </div>
            <div
              style={{
                height: view === 'Investor and sentiment' ? 'auto' : '0px',
                overflow: 'hidden',
              }}
            >
              {(insights?.['Investor and sentiment']?.headers ||
                groupInsights?.['Investor and sentiment']?.headers) && (
                <InvestorAndSentenment
                  image={insightImages?.COTR}
                  insights={
                    insights['Investor and sentiment']?.headers ||
                    groupInsights['Investor and sentiment']?.headers
                  }
                  token={token}
                />
              )}
              {/* <InvestorAndSentenment
                insights={insights?.['Investor and sentiment'].headers}
                token={token}
              /> */}
            </div>
            {/* <div
              style={{
                height: view === 'Sustainability' ? 'auto' : '0px',
                overflow: 'hidden',
              }}
            >
              {(insights?.['Sustainability']?.headers ||
                groupInsights?.['Sustainability']?.headers) && (
                <Sustainability
                  insights={
                    insights['Sustainability']?.headers ||
                    groupInsights?.['Sustainability']?.headers
                  }
                  token={token}
                />
              )}
              <Sustainability insights={insights?.["Sustainability"].headers} token={token} />
            </div> */}
            {/* <div
              style={{
                height: view === 'Cost Curves' ? 'auto' : '0px',
                overflow: 'hidden',
              }}
            >
              {(insights?.['Cost Curves']?.headers ||
                groupInsights?.['Cost Curves']?.headers) && (
                <CostCurves
                  insights={
                    insights['Cost Curves']?.headers ||
                    groupInsights['Cost Curves']?.headers
                  }
                  token={token}
                />
              )}
              <CostCurves insights={insights?.["Cost Curves"].headers} token={token} />
            </div> */}
            {/* <div
              style={{
                height: view === 'Market Share' ? 'auto' : '0px',
                overflow: 'hidden',
              }}
            >
              {(insights?.['Market Share']?.headers ||
                groupInsights?.['Market Share']?.headers) && (
                <MarketShare
                  insights={
                    insights['Market Share']?.headers ||
                    groupInsights['Market Share']?.headers
                  }
                  token={token}
                />
              )}
              <MarketShare insights={insights?.["Market Share"].headers} token={token} />
            </div> */}
            <div
              style={{
                height: view === 'Trade Data' ? 'auto' : '0px',
                overflow: 'hidden',
              }}
            >
              {(insights?.['Trade Data']?.headers ||
                groupInsights?.['Trade Data']?.headers) && (
                <TradeData
                  image1={insightImages?.trademap_1}
                  image2={insightImages?.trademap_2}
                  insights={
                    insights['Trade Data']?.headers ||
                    groupInsights['Trade Data']?.headers
                  }
                  token={token}
                />
              )}
              {/* <TradeData insights={insights?.["Trade Data"].headers} token={token} /> */}
            </div>
            {/* <div
              style={{
                height: view === 'Macro Indicator' ? 'auto' : '0px',
                overflow: 'hidden',
              }}
            >
              {(insights?.['Macro Indicator']?.headers ||
                groupInsights?.['Macro Indicator']?.headers) && (
                <MacroIndicator
                  insights={
                    insights['Macro Indicator']?.headers ||
                    groupInsights['Macro Indicator']?.headers
                  }
                  token={token}
                />
              )}
              <MacroIndicator insights={insights?.["Macro Indicator"].headers} token={token} />
            </div> */}
            <div
              style={{
                height: view === 'Data sources' ? 'auto' : '0px',
                overflow: 'hidden',
              }}
            >
              <Network />
            </div>

            <div
              style={{
                height: view === 'Scenario Analysis' ? 'auto' : '0px',
                overflow: 'hidden',
              }}
            >
              <UiRangeSlider token={token} />
            </div>
          </>
        ) : null}
      </>
    );
  };

  // function to convert px to vh
  const pxtoVh = (px) => {
    const vh = Math.max(
      document.documentElement.clientHeight || 0,
      window.innerHeight || 0
    );
    return (px * 100) / vh;
  };

  useEffect(() => {
    if (forNewsHeight?.current?.clientHeight) {
      setNewHeight(forNewsHeight?.current?.clientHeight);
    }
  }, [forNewsHeight]);

  let buttons = [
    'Production & Consumption',
    'Prices and Premium',
    'Investor and sentiment',
    // 'Sustainability',
    // 'Cost Curves',
    // 'Market Share',
    'Trade Data',
    // 'Macro Indicator',
  ];

  const renderButtons = () => {
    const filteredBtn = buttons.filter((singleButton) =>
      insights?.hasOwnProperty(singleButton)
    );
    const newFilteredBtn = [...filteredBtn, 'Data sources'];
    return newFilteredBtn.map((button, index) => {
      return (
        <button
          key={index}
          style={{
            backgroundColor: view === button ? '#bbc2c7' : 'white',
            color: view === button ? 'white' : 'black',
            minWidth: '70px',
          }}
          type='button'
          className='btn btn-sm btn-outline-secondary waves-effect waves-light'
          onClick={() => {
            setView(button);
          }}
        >
          {button}
        </button>
      );
    });
  };

  return (
    <>
      {isPageLoading ? (
        <Spinner
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            // transform: "translate(-50%, -50%)",
          }}
          animation='border'
          variant='primary'
        />
      ) : (
        // appDisplay === '' ? (
        <>
          <Modal
            // style={{
            //   backgroundImage: `url(${networkBagroundImage})`,
            // }}
            size='xl'
            className='special_modal modal-fullscreen'
            isOpen={showNetwork}
            toggle={() => setshowNetwork(!showNetwork)}
            centered={true}
          >
            <div
              className='modal-content'
              // style={{ backgroundColor: "#191919", color: "#191919", borderColor: "#191919" }}
            >
              {/* <ModalHeader
      toggle={() => setshowNetwork(!showNetwork)}>Network</ModalHeader> */}
              <ModalBody>
                <Network />
              </ModalBody>
            </div>
          </Modal>
          <Row>
            <Col lg={10}>
              <Card
                style={{
                  border: '1px solid rgba(116, 120, 141, 0.15)',
                  padding: '0.25em',
                }}
              >
                <Row>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      gap: '10px',
                      flexWrap: 'wrap',
                    }}
                  >
                    {renderButtons()} {/* Call renderButtons function */}
                  </div>
                </Row>
                <Row
                  style={{
                    marginTop: '20px',
                  }}
                >
                  <Col lg={12}>
                    <div ref={forNewsHeight}>
                      {GetView()} {/* Call GetView function */}
                    </div>
                  </Col>
                </Row>
              </Card>
            </Col>

            <Col lg={2}>
              <Card
                style={{
                  backgroundColor: '#f3f3f4',
                }}
              >
                <CardBody
                  style={{
                    paddingTop: '0px',
                  }}
                >
                  <CardTitle>
                    News
                    <i
                      className='mdi mdi-18px mdi-newspaper-variant-multiple-outline'
                      style={{
                        marginLeft: '10px',
                      }}
                    />
                  </CardTitle>
                  <div
                    style={{
                      height: `${pxtoVh(newHeight) - 5}vh`,
                      overflowY: 'scroll',
                      overflowX: 'hidden',
                    }}
                  >
                    <Row>
                      <NewsRender news={news || groupNews} />
                    </Row>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default QBR;
