import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap';
import { useLocation } from 'react-router-dom';
import useDatacallswithtoken from '../../Data Apis/apifunctions';
import { useNavigate } from 'react-router-dom';
import {
  changeCurrentApp,
  changeChatQuestions,
  appDispayHandler,
  changeFeatureList,
  filesenseAppDisplayHandler,
  changeTrailExpiredMessage


} from '../../store/actions';

const HeaderWithoutGreet = () => {

  const dispatch = useDispatch();
  const Navigate = useNavigate();
  const { useForFeatureList } = useDatacallswithtoken();
  const appDisplay = useSelector((state) => state.PageData.appDisplay);
  const filesenseAppDisplay = useSelector((state) => state.PageData.filesenseAppDisplay);
  const location = useLocation();
  const groups = useSelector((state) => state.PageData.groups);
  const featureList = useSelector((state) => state.PageData.featureList);
  const currentApp = useSelector((state) => state.PageData.currentApp);
  const [btnsuccess1, setBtnsuccess1] = useState(false);
  const [currentFeature, setCurrentFeature] = useState();
  const [filteredGroups, setFilteredGroups] = useState([])


  function currentFeatureSetter(path) {

    if (path?.includes("insights")) setCurrentFeature("IN")
    else if (path?.includes("filesense")) setCurrentFeature("FS")
  }

  const GetFeatureList = () => {
    useForFeatureList().then(res => {
      if (typeof res === 'object') {
        dispatch(changeFeatureList(res))
      }
      else {
        dispatch(changeTrailExpiredMessage(res))
      }
    }).catch(err => {
      console.log(err)
      dispatch(changeTrailExpiredMessage(err?.response?.data))
    })


  }

  useEffect(() => {
    currentFeatureSetter(location.pathname)
    // console.log(!featureList , Object.keys(featureList) )
    if (!featureList || Object.keys(featureList).length == 0) {
      GetFeatureList();
      // console.log(featureList)

    }
    else if (currentFeature && Object.keys(featureList).length >= 0) {
      // console.log(featureList)
      setFilteredGroups(featureList[currentFeature]); 

    }
  }, [location.pathname, currentFeature, featureList])



  

  // console.log(filteredGroups,featureList)


  return (
    <div style={{ textAlign: 'right', display: 'flex', justifyContent: "flex-end", padding: "0px 20px" }}>
      <Dropdown
        isOpen={btnsuccess1}
        toggle={() => setBtnsuccess1(!btnsuccess1)}
      >
        <DropdownToggle
          drop={'down-centered'}
          style={{
            backgroundColor: btnsuccess1 ? '#f4f4f4' : '',
            fontSize:
              appDisplay === 'ATL Marketing Intelligence' && currentFeature == "IN"
                ? '0.830rem'
                : '1rem',
          }}
          tag='button'
          className='btn'
        >
          { appDisplay } <i className='mdi mdi-chevron-down' />
        </DropdownToggle>
        <DropdownMenu>
          {filteredGroups && filteredGroups.map((item, i) => {
            return (
              <Link
                key={i}
                onClick={() => {
                  
                  
                    dispatch(appDispayHandler(item.group_name));
                    dispatch(changeCurrentApp(item));
                    setBtnsuccess1(!btnsuccess1);
                    if (currentApp !== item) {
                      dispatch(changeChatQuestions(''));
                    }
                  

                }}
              // to={
              //   item?.group_name
              //     ? `/app/${currentFeature == "IN" ? "insights" : "filesense"}/${item?.group_name?.toLowerCase()?.replaceAll(' ', '-')}`
              //     : '/'
              // }
              >
                <DropdownItem>{item?.group_name}</DropdownItem>
              </Link>
            );
          })}
        </DropdownMenu>
      </Dropdown>
    </div>
  )
}

export default HeaderWithoutGreet
