import React, { useState, useEffect } from 'react'
import { Form, Button, Dropdown, Menu, Input, Flex } from 'antd';

import { useNotification } from './NotificationProvider';
import GradientBtn from './Steps/Elements/GradientBtn';
import { DownOutlined } from '@ant-design/icons';
const { TextArea } = Input;
const ParameterForm = ({ setAnaLysisName, analysisName, allowGenerate, HandleFileSubmit }) => {

    const [selectedOption, setSelectedOption] = useState(null);
    const optionsForDropdown = ["MBR", "QBR", "Financial analysis"];
    const notification = useNotification();
    const [promptVal, setPromptVal] = useState(analysisMessages[selectedOption]);

    const handleMenuClick = (e) => {
        console.log("menu", e, e.key);
        setSelectedOption(e.key); // Update state when a menu item is clicked
        setAnaLysisName(e.key); // Update state when a dropdown option is selected
        setPromptVal(analysisMessages[e.key])

    };

    const menu = (
        <Menu onClick={handleMenuClick}>
            {optionsForDropdown.map(option => (
                <Menu.Item key={option}>
                    {option}
                </Menu.Item>
            ))}
        </Menu>
    );




    return (
        <Flex justify='space-between' style={{ width: "100%" }}>



            {allowGenerate && <Flex gap={20} style={{ width: "70%" }} >

                <Dropdown overlay={menu} trigger={["click"]}>
                    <Button>
                        {selectedOption ? selectedOption : "Select Analysis"} <DownOutlined />
                    </Button>
                </Dropdown>


                {selectedOption && <TextArea autoSize value={promptVal} onChange={(e) => setPromptVal(e.target.value)} />}
            </Flex>}




            <Flex style={{ marginLeft: "auto" }}>

                <GradientBtn onBtnClick={() => { HandleFileSubmit(); setPromptVal(""); setSelectedOption(undefined) }} name={"Generate analysis"} disable={!allowGenerate || selectedOption !== "MBR" || !selectedOption } />
            </Flex>


        </Flex>
    )
}

export default ParameterForm

const analysisMessages = {
    "MBR": `Generate the analysis for Monthly Business review on the following topics : Production, Consumption , Monetary Policies, Demand Drivers. Below are some instructions:
-Use CRU and Antaike reports shared for Production, Consumption and demand drivers related data
-Use Marklines reports for Auto related data
-Focus on future outlook of Monetary policy
-Include data about restarts and closures
-Provide data backed statements`,
    "QBR": `Generate the analysis for Quarterly Business review on the following topics : Production, Consumption , Monetary Policies, Demand Drivers. Below are some instructions:
-Use CRU and Antaike reports shared for Production, Consumption and demand drivers related data
-Provide insights on Country wise demand drivers (Auto, Solar, Electrical, construction , packaging industries) data
-Use Marklines reports for Auto related data
-Include data about restarts and closures
-Provide data backed statements`,
    "Financial analysis": `Generate the analysis for Quarterly Financial review on the following topics : Capex, Production, Downstream investments, Sustainability activities, Expansion plans. Below are some instructions:
-Use Internal reports and quarterly financial reports for Production ,Capex  and Downstream investment information
-Provide insights on Sustainability initiatives from company websites
-Identify expansion plans from news, annual reports and investor transcripts
-Provide data backed statements`
}
