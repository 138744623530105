import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import useDatacallswithtoken from '../../../../Data Apis/apifunctions';

import SingleQuestion from './Component/SingleQuestion';
import SingleChat from './Component/SingleChat';
import SearchBar from './Component/SearchBar';
import {
  Image,
  Modal,
  Spin,
  Tag,
  ConfigProvider,
  Card,
  Typography,
  Flex,
  Collapse,
} from 'antd';
import RenderPdf from '../../../New Content Search/Components/kx-components/renderPdf';
import AdobePdfViewer from '../../../../components/Common/AdobePdfRender/FullWindow';
import './Component/style/style.css';
import ChartRenderComp from './Component/ChartRenderComp';
import {
  CloseOutlined,
  FilePdfOutlined,
  LinkOutlined,
  PictureFilled,
  PictureOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import SuggestedQuestion from './Component/SuggestedQuestion';

const { Panel } = Collapse;

const CommonAnswersLayout = ({ currentApp }) => {
  // console.log("schema",currentApp?.schema_id)
  const { useForPdfDownload } = useDatacallswithtoken();
  const contentSearchQuestion = useSelector(
    (state) => state.PageData.contentSearchQuestion
  );
  const [contentSearchQuestionArr, setContentSearchQuestionArr] = useState([]);
  const [showSearchBar, setshowSearchBar] = useState(true);
  const [renderPdf, setRenderPdf] = useState(false);
  const [pdfByteArray, setPdfByteArray] = useState();
  const [questionsToRender, setQuestionsToRender] = useState([]);
  const [chatResponseComplete, setChatResponseComplete] = useState(true);
  const [chartData, setChartData] = useState();
  const [imageArr, setImageArr] = useState();
  const [controllerRef, setcontrollerRef] = useState([]);
  const [sourcesArr, SetSourcesArr] = useState();
  const [currentSourceChatIndex, setCurrentSourceChatIndex] = useState();
  const [index, setIndex] = useState(0);
  const [pdfloading, setPdfloading] = useState(true);
  const [latestChatImg, setLatestChatImg] = useState();
  const [latestGraph, setLatestGraph] = useState();
  const [visible, setVisible] = useState(false);
  const [eachImage, setEachImaege] = useState();
  const [fileNameforImagePrev, setFileNameforImagePrev] = useState();
  const [chatContext, setChatContext] = useState();
  const [fileDetails, setfileDetails] = useState({});

  // const [imageIndexholder, setImageIndexholder] = useState()

  const [tagActiveIndex, setTagActiveIndex] = useState({});

  const [updatedSourcesArr, setUpdatedSourcesArr] = useState();

  useEffect(() => {
    setContentSearchQuestionArr((arr) => [...arr, contentSearchQuestion]);
  }, []);

  function searchbarHandler(followUpQuestion) {
    setContentSearchQuestionArr((arr) => [...arr, followUpQuestion]);
  }
  function relevantQuestionHandler(relevantQuestions) {
    // console.log(relevantQuestions)
    setQuestionsToRender(relevantQuestions);
  }

  const DownloadPdf = async (source) => {
    setRenderPdf(true);
    setfileDetails(source);
    const dataConfig = {
      filekey: source?.pdf_location,
      group: currentApp.folder,
    };
    useForPdfDownload(dataConfig).then((res) => {
      setPdfByteArray(res?.data);

      setPdfloading(false);
    });
  };
  // console.log(renderPdf)
  function pdfOpenHandler(source, questionId) {
    setChartData(undefined);
    setPdfByteArray(false);

    // Check if there's a current active tag index and compare sources
    if (
      tagActiveIndex &&
      tagActiveIndex?.source &&
      tagActiveIndex?.source?.pdf_location === source?.pdf_location &&
      tagActiveIndex?.questionId === questionId
    ) {
      // If the sources and questionIds are the same, set active index to null and close PDF
      setTagActiveIndex({});
      handlePdfClose();
      return;
    }
    // If sources are different or there was no previous active index, set the new one
    setTagActiveIndex({ questionId: questionId, imgId: null, source: source });

    if (!source) {
      setRenderPdf(false);
      return;
    } else {
      DownloadPdf(source);
    }
    setPdfloading(true);
    setImageArr(undefined);
  }

  function chartDataHandler(data, columns, graphDataAll, id, questionId) {
    setTagActiveIndex({
      questionId: questionId,
      imgId: null,
      source: null,
      graph: id,
    });

    setRenderPdf(undefined);
    setImageArr(undefined);
    setChartData({ data: data, columns: columns, graphDataAll: graphDataAll });
    setImageArr(undefined);
  }
  function imageDataHandler(imageArr, Id, questionId, imageOnly) {
    const tempImageObjWithFileName = {};
    if (imageArr)
      imageArr.forEach((img) => {
        if (img !== null) {
          const key = img.split('/')[5];
          if (!tempImageObjWithFileName[key]) {
            tempImageObjWithFileName[key] = [];
          }
          tempImageObjWithFileName[key] = [
            ...tempImageObjWithFileName[key],
            img,
          ];
        }
      });
    const filteredImageArr = imageArr?.filter((image) => image !== null);
    filteredImageArr && filteredImageArr?.length > 0
      ? setImageArr(tempImageObjWithFileName)
      : setImageArr(undefined);
    if (filteredImageArr && filteredImageArr?.length > 0)
      setTagActiveIndex((prev) => ({
        ...prev,
        questionId: questionId,
        imgId: Id,
        source: null,
      }));
    setRenderPdf(undefined);
    if (filteredImageArr && filteredImageArr.length > 0)
      setChartData(undefined);
  }

  function newChatHandler() {
    controllerRef.forEach((element) => {
      element.current.abort();
    });
    setContentSearchQuestionArr([]);
    setcontrollerRef([]);
    setImageArr();
    setChartData();
    setQuestionsToRender();
    setLatestChatImg();
    setLatestGraph();
    setVisible();
    setEachImaege();
    setChatResponseComplete(true);
    righBarClear();
    // handlePdfClose();
  }
  const handleMouseEnter = (e) => {
    e.currentTarget.querySelector('.text').style.display = 'block';
  };
  function controllerRefHandler(controllerRef) {
    console.log(controllerRef);
    setcontrollerRef((prev) => [...prev, controllerRef]);
    // controllerRef.current.abort()
  }
  function sourcesArrHandler(sources, files, index) {
    console.log('sourcesArr', sources, files, index, updatedSourcesArr);
    setIndex(index);
    // setCurrentSourceChatIndex(index)
    setUpdatedSourcesArr();
    sources ? SetSourcesArr(files.slice(0, sources.length)) : SetSourcesArr();
  }
  function tagHandler() {
    setTagActiveIndex();
    setPdfByteArray();
    setRenderPdf();
  }

  function righBarClear() {
    setImageArr(undefined);
    SetSourcesArr(undefined);
    setRenderPdf(undefined);
    setChartData(undefined);
  }
  const handleMouseLeave = (e) => {
    e.currentTarget.querySelector('.text').style.display = 'none';
  };

  function handlePauseStreaming() {
    setChatResponseComplete(true);
    // setQuestionsToRender();
    controllerRef.forEach((element) => {
      element.current.abort();
    });
  }
  function handlePdfClose() {
    setPdfByteArray();
    setRenderPdf(false);
    // if(latestGraph.question != contentSearchQuestionArr[contentSearchQuestionArr.length - 1]) return

    if (latestGraph) {
      setChartData({
        data: latestGraph.data,
        columns: latestGraph.columns,
        graphDataAll: latestGraph.graphDataAll,
      });
      setTagActiveIndex({
        questionId: latestGraph.questionId,
        imgId: null,
        source: null,
        graph: 'graph',
      });
    } else if (latestChatImg && latestChatImg['img']) {
      // if(latestChatImg.question != contentSearchQuestionArr[contentSearchQuestionArr.length - 1]) return
      setTagActiveIndex({
        questionId: latestChatImg.questionId,
        imgId: 'img-tag',
        source: null,
      });
      let tempImageObjWithFileName = {};
      console.log('img com', latestChatImg);
      const filteredImagesArray = latestChatImg?.img?.filter(
        (image) => image !== null
      );
      if (filteredImagesArray) {
        filteredImagesArray.forEach((img) => {
          const key = img.split('/')[5];
          if (!tempImageObjWithFileName.hasOwnProperty(key)) {
            tempImageObjWithFileName[key] = [];
          }
          tempImageObjWithFileName[key] = [
            ...tempImageObjWithFileName[key],
            img,
          ];
          console.log(tempImageObjWithFileName);
        });
        setImageArr(tempImageObjWithFileName);
      }
    }
  }
  return (
    <ConfigProvider theme={{ token: { colorPrimary: 'black', fontFamily: 'Roboto, sans-serif'} }}>
      <div>
        <div style={{ height: '91vh', display: 'flex', marginBottom: '45px' }}>
          <div
            style={{
              width: '50%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-end',
            }}
          >
            {contentSearchQuestionArr.length == 0 ? (
              <div
                style={{
                  height: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                {' '}
                <SuggestedQuestion
                  currentApp={currentApp}
                  onSearchbarHandlerChange={searchbarHandler}
                />{' '}
              </div>
            ) : (
              <div
                className='hidden-scrollbar'
                style={{
                  display: 'flex',
                  padding: '20px',
                  flexDirection: 'column',
                  overflowY: 'auto',
                  paddingBottom: '20px',
                  maxHeight: '100%',
                  gap: '20px',
                  justifyContent: 'flex-start',
                  height: '100%',
                }}
              >
                {contentSearchQuestionArr &&
                  contentSearchQuestionArr.map((contentSearchQuestion, index) => (
                    <div key={index}>
                      <SingleQuestion chatQuestion={contentSearchQuestion} />
                      <SingleChat
                        currentApp={currentApp}
                        contentSearchQuestion={contentSearchQuestion}
                        onRelevantQuestionChange={relevantQuestionHandler}
                        onpdfOpen={pdfOpenHandler}
                        onchatResponseComplete={(flag) => {
                          setChatResponseComplete(flag);
                        }}
                        onchartDataRecieve={chartDataHandler}
                        onImageDataChange={imageDataHandler}
                        oncontrollerRef={controllerRefHandler}
                        onSourcesArrChange={sourcesArrHandler}
                        index={index}
                        tagActiveIndex={tagActiveIndex}
                        ontagChange={tagHandler}
                        questionsLength={contentSearchQuestionArr.length}
                        setLatestChatImg={setLatestChatImg}
                        setLatestGraph={setLatestGraph}
                        prevObj={
                          index > 0
                            ? [
                              chatContext[contentSearchQuestionArr[index - 1]],
                              contentSearchQuestionArr[index - 1],
                            ]
                            : null
                        }
                        setChatContext={setChatContext}
                        isLastAnswer={
                          index === contentSearchQuestionArr.length - 1
                        }
                        lastQuestion={
                          contentSearchQuestionArr[
                          contentSearchQuestionArr?.length - 1
                          ]
                        }
                      />{' '}
                    </div>
                  ))}
              </div>
            )}
            <div
              style={{
                padding: '5px 20px 0px 30px',
                borderTop: '1px solid #e6e6e6',
              }}
            >
              <div
                style={{ display: 'flex', gap: '10px', alignItems: 'flex-end' }}
              >
                <div
                  className='new-chat-btn-container'
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  onClick={newChatHandler}
                  style={{ background: 'black' }}
                >
                  <PlusOutlined className='plus-icon' />
                  <span
                    style={{
                      lineHeight: '15px',
                      display: 'none',
                      minWidth: '80px',
                    }}
                    className='text'
                  >
                    New Chat
                  </span>
                </div>
                <SearchBar
                  groupName={currentApp?.group_name}
                  isLoading={!chatResponseComplete}
                  showSearchBar={showSearchBar}
                  questionsToRender={questionsToRender}
                  onSearchbarHandlerChange={searchbarHandler}
                  onPauseStreaming={handlePauseStreaming}
                />
              </div>

              <div
                style={{
                  textAlign: 'center',
                  color: '#666666',
                  fontSize: '12px',
                  paddingTop: '6px',
                }}
              >
                AI can make mistakes. Please double check responses.
              </div>
              {/* <p className='text-center' style={{fontSize: ".75rem",color: "#666666"}}>Crafted by Actalyst.</p> */}
            </div>
          </div>
          <div
            style={{
              background: '#E6E6E6',
              // background: "linear-gradient(180deg, rgba(230, 230, 230, 0.00) -7.51%, #E6E6E6 33.53%)",
              width: '1px',
              height: '150%',
              zIndex: '2',
            }}
          ></div>
          <div
            style={{
              width: '50%',
              padding: '20px',
              display: 'flex',
              paddingBottom: '0px',
              flexDirection: 'column',
            }}
          >
            <div
              style={{ display: 'flex', gap: '15px', height: 'fit-content' }}
            ></div>
            {renderPdf &&
              (pdfloading ? (
                
                  <Spin style={{ top: '50%' }} />
               
              ) : (
                <div
                  style={{
                    height: '100%',
                    width: '100%',
                    borderRadius: '20px',
                    border: 'none',
                  }}
                >
                  <CloseOutlined
                    onClick={() => {
                      handlePdfClose();
                    }}
                    style={{
                      fontSize: '10px',
                      color: 'black',
                      position: 'absolute',
                      background: 'white',
                      right: '2.5%',
                      margin: '7px',
                      padding: '5px',
                      borderRadius: '3px',
                    }}
                  />
                  <RenderPdf pdfByteArray={pdfByteArray} />
                  {/* <AdobePdfViewer
                  pdfBlob={pdfByteArray}
                  fileDetails={fileDetails}
                /> */}
                </div>
              ))}
            {chartData && (
              <ChartRenderComp
                data={chartData.data}
                columns={chartData.columns}
                graphDataAll={chartData.graphDataAll}
              />
            )}

            {imageArr && (
              <div
                className='hidden-scrollbar'
                style={{
                  height: '100%',
                  width: '100%',
                  gap: '10px',
                  overflowY: 'auto',
                  flexWrap: 'wrap',
                  alignContent: 'flex-start',
                }}
              >
                <>
                  <Collapse
                    bordered={true}
                    defaultActiveKey={['0']}
                    style={{
                      marginBottom: '20px',
                      width: '100%',
                      background: 'white',
                      border: 'none',
                    }}
                  >
                    {Object.keys(imageArr).map((fileName, i) => (
                      <Panel
                        style={{}}
                        header={fileName
                          .replace(/%20/g, ' ')
                          .replace(/_/g, ' ')
                          .replace(/%/g, ' ')}
                        key={i}
                        extra={<PictureFilled style={{ fontSize: '16px' }} />}
                      >
                        <Flex
                          vertical={true}
                          style={{ padding: '10px', borderRadius: '15px' }}
                        >
                          <Flex gap={10} style={{ flexWrap: 'wrap' }}>
                            {imageArr[fileName].map((eachImage, j) => (
                              <Image
                                preview={{ visible: false }}
                                key={`${fileName}-${j}`}
                                width='48%'
                                src={eachImage}
                                height={200}
                                onClick={() => {
                                  setVisible(true);
                                  setEachImaege(eachImage);
                                  setFileNameforImagePrev(fileName);
                                }}
                                style={{
                                  objectFit: 'cover',
                                  objectPosition: 'top center',
                                  borderRadius: '9px',
                                  border: '1px solid #e6e6e6',
                                }}
                              />
                            ))}
                          </Flex>
                        </Flex>
                      </Panel>
                    ))}
                  </Collapse>

                  {visible && (
                    <div style={{ display: 'none' }}>
                      <Image.PreviewGroup
                        preview={{
                          visible,
                          onVisibleChange: (vis) => setVisible(vis),
                        }}
                      >
                        <Image src={eachImage} />
                        {imageArr[fileNameforImagePrev]
                          .filter((img) => img != eachImage)
                          .map((eachImage, i) => (
                            <Image key={i} src={eachImage} />
                          ))}
                      </Image.PreviewGroup>
                    </div>
                  )}
                </>
              </div>
            )}
          </div>
        </div>
      </div>
    </ConfigProvider>
  );
};

export default CommonAnswersLayout;
