import React, {
  useEffect,
  useRef,
  useState,
  lazy,
  Suspense,
  useCallback,
  useMemo,
} from "react";
import {
  Routes,
  Route,
  useNavigate,
  useLocation,
  Link,
} from "react-router-dom";
import {
  changeFeatureList,
  changeTrailExpiredMessage,
} from "../../store/actions";
import { FaTasks } from "react-icons/fa";
import { UserOutlined, TeamOutlined, FundOutlined } from "@ant-design/icons";
import { MdOutlineDashboard } from "react-icons/md";
import AbgLogo from "../../assets/images/AbgLogo.png";
import MenuWithButton from "./Components/Menu/MenuWithButton";
import useDatacallswithtoken from "../../Data Apis/apifunctions";
import "./index.css";

import { useDispatch, useSelector } from "react-redux";
import { safeJsonParse } from "./Components/Utils/JsonParse";
import { ConfigProvider, Spin } from "antd";

import FeedbackButton from "./Components/Utils/FeedbackForm";
// Lazy load pages
const Drafts = lazy(() => import("./Pages/Self/Drafts"));
const Approved = lazy(() => import("./Pages/Self/Approved"));
const SubmittedForApproval = lazy(() =>
  import("./Pages/Self/SubmittedForApproval")
);
const NeedRevision = lazy(() => import("./Pages/Self/NeedRevision"));
const Withdrawn = lazy(() => import("./Pages/Self/Withdrawn"));
const PendingApproval = lazy(() => import("./Pages/Team/PendingApproval"));
const TeamApproved = lazy(() => import("./Pages/Team/TeamApproved"));
const Admin = lazy(() => import("./Pages/Admin"));
const Dashboard = lazy(() => import("./Pages/Dashboard"));
const MyTask = lazy(() => import("./Pages/MyTasks"));
const CreateJobDescription = lazy(() => import("./Pages/CreateJobDescription"));
const EditJobDescription = lazy(() => import("./Pages/EditJobDescription"));
const SentForApproval = lazy(() => import("./Pages/Team/SentForApproval"));
const OrgApproved = lazy(() => import("./Pages/Org/Approved"));
const OrgSubmittedForApproval = lazy(() =>
  import("./Pages/Org/SubmittedForApproval")
);
const OrgNeedRevision = lazy(() => import("./Pages/Org/NeedRevision"));
// Helper function to convert kebab-case to PascalCase
const kebabToPascalCase = (str) => {
  return str
    .split("-")
    .map((word) => word?.charAt(0).toUpperCase() + word?.slice(1))
    .join("");
};

const WritingAssistant = () => {
  const navigate = useNavigate();
  const [renderKey, setRenderKey] = useState(0); // Key to force re-render
  document.title = "Actalyst AI Platform | Writing Assistant";
  const location = useLocation(); // Get the current URL
  const dispatch = useDispatch();
  const featureList = useSelector((state) => state.PageData.featureList);
  const [selectedComponent, setSelectedComponent] = useState("1");
  const [editRecord, setEditRecord] = useState(null); // State to hold the record being edited
  const [lastPage, setLastPage] = useState(() => {
    // Retrieve lastPage from session storage on initial render
    return sessionStorage.getItem("lastPage") || "1";
  }); // Track the last visited page

  const getIsTeamsEnabledFromSession = useCallback(() => {
    const storedValue = sessionStorage.getItem("isTeamsEnabled");
    return safeJsonParse(storedValue, null);
  }, []);

  const getIsAdminEnabledFromSession = useCallback(() => {
    const storedValue = sessionStorage.getItem("isAdminEnabled");
    return safeJsonParse(storedValue, null);
  }, []);
  const isTeamsEnabled = getIsTeamsEnabledFromSession(); // Lift state to parent component
  const isAdminEnabled = getIsAdminEnabledFromSession(); // Lift state to parent component

  const [pagesCount, setPagesCount] = useState({
    myTasks: 0,
    drafts: 0,
    approved: 0,
    submittedForApproval: 0,
    needRevision: 0,
    withdrawn: 0,
    pendingApproval: 0,
    teamApproved: 0,
    sentForApproval: 0,
    orgApproved: 0,
    orgSubmittedForApproval: 0,
    orgNeedRevision: 0,
  }); // State to hold total tasks
  const mainDivRef = useRef(null);
  const { useForFeatureList } = useDatacallswithtoken();
  // Retrieve the isTeamEnabled prop from location state

  // Update the selected component based on the current URL
  useEffect(() => {
    const path = location.pathname.split("/app/writing-assistant/")[1] || ""; // Get the first part of the path or fallback to empty string
    if (location.pathname === "/app/writing-assistant/") {
      setSelectedComponent("MyTasks");
    } else if (path.startsWith("get-jd")) {
      setSelectedComponent("EditJobDescription");
    } else {
      const component = kebabToPascalCase(path.split("/")[0] || "MyTasks");
      setSelectedComponent(component);
    }
  }, [location]);
  // Store the lastPage in session storage whenever it changes
  useEffect(() => {
    sessionStorage.setItem("lastPage", lastPage);
  }, [lastPage]);
  const GetFeatureList = () => {
    useForFeatureList()
      .then((res) => {
        if (typeof res === "object") {
          dispatch(changeFeatureList(res));
          const containsIN = Object.keys(res).some((item) => item === "WA");
          if (!containsIN) {
            navigate("/app/subscribe", { replace: true });
          }
        } else {
          dispatch(changeTrailExpiredMessage(res));
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch(changeTrailExpiredMessage(err?.response?.data));
      });
  };

  useEffect(() => {
    if (featureList?.length === 0) {
      GetFeatureList();
    }
  }, [featureList]);

  // Re-render logic by updating the renderKey
  const onMenuItemClick = useCallback((e) => {
    setSelectedComponent(e.key);
    setLastPage(e.key);
    // navigate(e.route, { replace: true });
    setRenderKey((prevKey) => prevKey + 1); // Force re-render by updating renderKey
  }, []);

  const onButtonClick = useCallback(() => {
    // Set the selected component state
    sessionStorage.removeItem("selectedPositionId");
    setSelectedComponent("CreateJobDescription");

    // Navigate to the create job description page
    // navigate("/app/writing-assistant/create-job-description");
    // Increment renderKey to trigger re-render
    setRenderKey((prevKey) => prevKey + 1);
  }, []);

  const onViewMore = useCallback((section) => {
    if (section === "pendingApproval") {
      setSelectedComponent("PendingApproval");
      setLastPage("3-2"); // Update last page for pending approval
      // navigate("/app/writing-assistant/pending-approval");
    } else if (section === "needRevision") {
      setSelectedComponent("NeedRevision");
      setLastPage("2-4"); // Update last page for need revision
      // navigate("/app/writing-assistant/need-revision");
    }
  }, []);

  const onEditClick = useCallback(
    (record) => {
      setEditRecord(record); // Store the record being edited

      // Extract the current menu key before navigating to edit page
      const path = location?.pathname.split("/app/writing-assistant")[1];
      const componentKey = kebabToPascalCase(path?.split("/")[1] || "");

      setSelectedComponent("EditJobDescription"); // Set component to EditJobDescription

      setLastPage(componentKey); // Store the current key (e.g., "Drafts") as lastPage

      navigate(
        `/app/writing-assistant/get-jd/${record?.key || record?.jd_key}`,
        {
          state: { record, lastPage: location?.pathname }, // Pass the record as state
        }
      );
    },
    [navigate, location]
  );

  const menuItems = useMemo(
    () =>
      [
        {
          key: "MyTasks",
          label: <Link to="/app/writing-assistant">Home / My Tasks</Link>,
          icon: <FaTasks />,
          count: pagesCount?.myTasks,
        },
        {
          key: "Self",
          label: "Self",
          icon: <UserOutlined />,
          children: [
            {
              key: "Drafts",
              label: <Link to="/app/writing-assistant/drafts">Drafts</Link>,
              count: pagesCount?.drafts,
            },
            {
              key: "SubmittedForApproval",
              label: (
                <Link to="/app/writing-assistant/submitted-for-approval">
                  Submitted for Approval
                </Link>
              ),
              count: pagesCount?.submittedForApproval,
            },
            {
              key: "NeedRevision",
              label: (
                <Link to="/app/writing-assistant/need-revision">
                  Need Revision
                </Link>
              ),
              count: pagesCount?.needRevision,
            },
            {
              key: "Approved",
              label: <Link to="/app/writing-assistant/approved">Approved</Link>,
              count: pagesCount?.approved,
            },
            {
              key: "Withdrawn",
              label: (
                <Link to="/app/writing-assistant/withdrawn">Withdrawn</Link>
              ),
              count: pagesCount?.withdrawn,
            },
          ],
        },
        isTeamsEnabled && {
          key: "Team",
          label: "Team",
          icon: <TeamOutlined />,
          children: [
            {
              key: "PendingApproval",
              label: (
                <Link to="/app/writing-assistant/pending-approval">
                  Pending Approval
                </Link>
              ),
              count: pagesCount?.pendingApproval,
            },
            {
              key: "SentForApproval",
              label: (
                <Link to="/app/writing-assistant/sent-for-approval">
                  Sent for Approval
                </Link>
              ),
              count: pagesCount?.sentForApproval,
            },
            {
              key: "TeamApproved",
              label: (
                <Link to="/app/writing-assistant/team-approved">Approved</Link>
              ),
              count: pagesCount?.teamApproved,
            },
          ],
        },
        isAdminEnabled && {
          key: "Organisation",
          label: "Organisation",
          icon: <TeamOutlined />,
          children: [
            {
              key: "OrgSubmittedForApproval",
              label: (
                <Link to="/app/writing-assistant/org-submitted-for-approval">
                  Submitted for Approval
                </Link>
              ),
              count: pagesCount?.orgSubmittedForApproval,
            },
            {
              key: "OrgNeedRevision",
              label: (
                <Link to="/app/writing-assistant/org-need-revision">
                  Need Revision
                </Link>
              ),
              count: pagesCount?.orgNeedRevision,
            },
            {
              key: "OrgApproved",
              label: (
                <Link to="/app/writing-assistant/org-approved">Approved</Link>
              ),
              count: pagesCount?.orgApproved,
            },
          ],
        },
        isAdminEnabled && {
          key: "Admin",
          label: <Link to="/app/writing-assistant/admin">Admin</Link>,
          icon: <MdOutlineDashboard />,
        },
        {
          key: "Dashboard",
          label: <Link to="/app/writing-assistant/dashboard">Dashboard</Link>,
          icon: <FundOutlined />,
        },
      ].filter(Boolean),
    [pagesCount, isTeamsEnabled, isAdminEnabled]
  );

  return (
    <>
      <img
        src={AbgLogo}
        alt="abj-logo"
        style={{
          width: "75px",
          height: "auto",
          display: "block",
        }}
        className="mt-3 mx-4 mb-1"
      />
      <div
        className="main"
        ref={mainDivRef}
        key={renderKey}
        style={{ marginTop: "0px", paddingTop: "0px" }}
      >
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: "black",
              // fontFamily: "Roboto, sans-serif",
              wordSpacing: "10px",
            },
          }}
        >
          <div className="indexmenu">
            <MenuWithButton
              menuItems={menuItems}
              onButtonClick={onButtonClick}
              onMenuItemClick={onMenuItemClick}
              selectedKeys={
                selectedComponent === "EditJobDescription"
                  ? [lastPage]
                  : [selectedComponent === "" ? "MyTasks" : selectedComponent]
              }
            />
          </div>
          <div className="dashboard">
            <Suspense>
              <Routes>
                <Route
                  index
                  element={
                    <MyTask
                      onViewMore={onViewMore}
                      onEditClick={onEditClick}
                      setPagesCount={setPagesCount}
                      isTeamsEnabled={isTeamsEnabled} // Pass the isTeamsEnabled state to MyTask
                      // Pass the state setter to MyTask
                    />
                  }
                />
                <Route
                  path="/"
                  element={
                    <MyTask
                      onViewMore={onViewMore}
                      onEditClick={onEditClick}
                      setPagesCount={setPagesCount}
                      isTeamsEnabled={isTeamsEnabled} // Pass the isTeamsEnabled state to MyTask
                      // Pass the state setter to MyTask
                    />
                  }
                />
                <Route
                  path="/drafts"
                  element={
                    <Drafts
                      onEditClick={onEditClick}
                      setPagesCount={setPagesCount}
                    />
                  }
                />
                <Route
                  path="/approved"
                  element={
                    <Approved
                      setPagesCount={setPagesCount}
                      onEditClick={onEditClick}
                    />
                  }
                />
                <Route
                  path="/submitted-for-approval"
                  element={
                    <SubmittedForApproval
                      onEditClick={onEditClick}
                      setPagesCount={setPagesCount}
                    />
                  }
                />
                <Route
                  path="/need-revision"
                  element={
                    <NeedRevision
                      onEditClick={onEditClick}
                      setPagesCount={setPagesCount}
                    />
                  }
                />
                <Route
                  path="/withdrawn"
                  element={
                    <Withdrawn
                      onEditClick={onEditClick}
                      setPagesCount={setPagesCount}
                    />
                  }
                />
                <Route
                  path="/pending-approval"
                  element={
                    <PendingApproval
                      onEditClick={onEditClick}
                      setPagesCount={setPagesCount}
                    />
                  }
                />
                <Route
                  path="/sent-for-approval"
                  element={
                    <SentForApproval
                      onEditClick={onEditClick}
                      setPagesCount={setPagesCount}
                    />
                  }
                />
                <Route
                  path="/team-approved"
                  element={
                    <TeamApproved
                      onEditClick={onEditClick}
                      setPagesCount={setPagesCount}
                    />
                  }
                />
                <Route
                  path="/org-approved"
                  element={
                    <OrgApproved
                      onEditClick={onEditClick}
                      setPagesCount={setPagesCount}
                    />
                  }
                />
                <Route
                  path="/org-submitted-for-approval"
                  element={
                    <OrgSubmittedForApproval
                      onEditClick={onEditClick}
                      setPagesCount={setPagesCount}
                    />
                  }
                />
                <Route
                  path="/org-need-revision"
                  element={
                    <OrgNeedRevision
                      onEditClick={onEditClick}
                      setPagesCount={setPagesCount}
                    />
                  }
                />
                <Route
                  path="/admin"
                  element={<Admin onEditClick={onEditClick} />}
                />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route
                  path="/create-job-description"
                  element={
                    <CreateJobDescription onButtonClick={onButtonClick} />
                  }
                />
                <Route
                  path="/get-jd/:jdKey"
                  element={
                    <EditJobDescription
                      record={editRecord}
                      lastPage={location.pathname}
                    />
                  }
                />
              </Routes>
            </Suspense>
          </div>
          <FeedbackButton mainDivRef={mainDivRef} />
        </ConfigProvider>
      </div>
    </>
  );
};

export default WritingAssistant;
