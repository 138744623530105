import { message } from "antd";
import useDatacallswithtoken from "../../../../../Data Apis/apifunctions";
const useJDAPI = () => {
  const {
    useForGenerateJD,
    useForPositionList,
    useForPositionDetails,
    useForSaveJD,
    useForSubmitForApproval,
    useForDownloadWordFile,
    useForDownloadExcelFile,
    useForLoadJobDetails,
    useForApproveJD,
    useForReviseJD,
  } = useDatacallswithtoken();
  const GenerateJD = async (body) => {
    try {
      const res = await useForGenerateJD(JSON.stringify(body));
      if (res.status === 200) {
        return res; // Return the response data
      } else {
        throw new Error("Error generating job description");
      }
    } catch (err) {
      message.error("Error generating job description");
      console.error(err);
      throw err; // Re-throw error to handle it in onFinish
    }
  };
  const FetchPositionList = async (body) => {
    // Await the API call to ensure it completes before moving on
    const res = await useForPositionList(JSON.stringify(body));
    if (res.status === 200) {
      return res; // Return the response data
    } else {
      throw new Error("Error fetching position list");
    }
  };
  const FetchPositionDetails = async (body) => {
    const res = await useForPositionDetails(JSON.stringify(body));
    if (res.status === 200) {
      return res; // Return the response data
    } else {
      throw new Error("Error fetching position details");
    }
  };
  const SaveJD = async (body) => {
    const res = await useForSaveJD(body);
    if (res.status === 200) {
      return res; // Return the response data
    } else {
      throw new Error("Error saving job description");
    }
  };
  const SubmitForApproval = async (body) => {
    const res = await useForSubmitForApproval(body);
    if (res.status === 200) {
      return res; // Return the response data
    } else {
      throw new Error("Error submitting job description for approval");
    }
  };
  const DownloadWordFile = async (body) => {
    try {
      const res = await useForDownloadWordFile(body, {
        responseType: "blob", // Ensure the response is handled as a binary blob
      });

      if (res.status !== 200) {
        throw new Error("Error downloading Word file");
      }

      // Convert the response to a blob (binary large object)
      const blob = new Blob([res.data], {
        type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      });

      // Create a link element and use it to trigger the download
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "job_description.docx"); // Set file name
      document.body.appendChild(link);
      link.click();

      // Clean up the URL and remove the link
      window.URL.revokeObjectURL(url);
      link.remove();

      message.success("Word file downloaded successfully!");
    } catch (error) {
      console.error("Error downloading Word file:", error);
      message.error("Error downloading Word file");
    }
  };

  const DownloadExcelFile = async (body) => {
    try {
      const res = await useForDownloadExcelFile(body, {
        responseType: "blob", // Ensure the response is handled as a binary blob
      });

      if (res.status !== 200) {
        throw new Error("Error downloading Excel file");
      }

      // Convert the response to a blob (binary large object)
      const blob = new Blob([res.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      // Create a link element and use it to trigger the download
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "job_description.xlsx"); // Set file name
      document.body.appendChild(link);
      link.click();

      // Clean up the URL and remove the link
      window.URL.revokeObjectURL(url);
      link.remove();

      message.success("Excel file downloaded successfully!");
    } catch (error) {
      console.error("Error downloading Excel file:", error);
      message.error("Error downloading Excel file");
    }
  };
  const FetchJobDetails = async (body) => {
    // Await the API call to ensure it completes before moving on
    const res = await useForLoadJobDetails(JSON.stringify(body));
    if (res.status === 200) {
      return res; // Return the response data
    } else {
      throw new Error("Error fetching job details");
    }
  };

  const ApproveJD = async (body) => {
    const res = await useForApproveJD(body);
    if (res.status === 200) {
      return res; // Return the response data
    } else {
      throw new Error("Error approving job description");
    }
  };
  const ReviseJD = async (body) => {
    const res = await useForReviseJD(body);
    if (res.status === 200) {
      return res; // Return the response data
    } else {
      throw new Error("Error revising job description");
    }
  };
  return {
    GenerateJD,
    FetchPositionList,
    FetchPositionDetails,
    SaveJD,
    SubmitForApproval,
    DownloadWordFile,
    DownloadExcelFile,
    FetchJobDetails,
    ApproveJD,
    ReviseJD,
  };
};
export default useJDAPI;
