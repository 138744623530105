import Api from "./Api";
import { useAuth0 } from "@auth0/auth0-react";

const useDatacallswithtoken = () => {
  const { user, getAccessTokenSilently } = useAuth0();
  const userID = user?.sub;

  const getconfig = async () => {
    const headers = {
      accept: "application/json",
      Authorization: `Bearer ${await getAccessTokenSilently()}`,
      "Content-Type": "application/json",
    };
    return { headers: headers };
  };

  const useGetData = async (data) => {
    var temp_data = JSON.parse(JSON.stringify(data));
    temp_data.userId = userID;
    const config = await getconfig();
    const response = await Api.post("/processQuestion", temp_data, config);
    return response;
  };

  const useSavedbdetails = async (data) => {
    var temp_data = JSON.parse(JSON.stringify(data));
    temp_data.userId = userID;
    const config = await getconfig();
    const response = await Api.post("/saveDBDetails", temp_data, config);
    return response;
  };

  const useFetchschemas = async (data) => {
    const config = await getconfig();
    return await Api.post("/fetchSchemas", config, data);
  };

  const useGetDbTypes = async () => {
    const config = await getconfig();
    const response = await Api.get("/getDBTypes", config);
    return response["data"];
  };

  const useHomepage = async () => {
    const data = { userId: userID };
    const config = await getconfig();
    const response = await Api.post("/loadHomePage", data, config);
    return response["data"];
  };

  const useFetchSchemas = async (data) => {
    var temp_data = JSON.parse(JSON.stringify(data));
    temp_data.userId = userID;
    const config = await getconfig();
    const response = await Api.post("/fetchSchemas", temp_data, config);
    return response;
  };

  const useGiveFeedback = async (data) => {
    var temp_data = JSON.parse(JSON.stringify(data));
    temp_data.userId = userID;
    const config = await getconfig();
    const response = await Api.post("/processFeedback", temp_data, config);
    return response["data"];
  };

  const useSignup = async () => {
    const data = { userId: userID };
    return await Api.post("/signUp", data);
  };

  const useGetSummary = async (data) => {
    const config = await getconfig();
    const response = await Api.post("/getSummary", data, config);
    return response;
  };

  const useGettopdata = async (data) => {
    var temp_data = JSON.parse(JSON.stringify(data));
    temp_data.userId = userID;
    const config = await getconfig();
    const response = await Api.post("/topData", temp_data, config);
    return response.data;
  };

  const useSaveresponsetime = async (data) => {
    const config = await getconfig();
    const response = await Api.post("/saveResponseTime", data, config);
    return response.data;
  };

  const useForunstructured = async (data) => {
    var temp_data = JSON.parse(JSON.stringify(data));
    temp_data.userId = userID;
    const config = await getconfig();
    const response = await Api.post("/forunstructured", temp_data, config);
    return response.data;
  };

  const useForPdfSummary = async (data) => {
    const config = await getconfig();
    const response = await Api.post("/pdfSummary", data, config);
    return response.data;
  };

  const useForFileUpload = async (data) => {
    const config = await getconfig();
    config.headers["Content-Type"] = "multipart/form-data";
    console.log(data)
    const response = await Api.post(
      `/uploadDocument?user_id=${userID}`,
      data,
      config
    );
    // const response=await Api.post("/uploadDocument",data,config);
    return response.data;
  };

  // filsense advance api calls

  const useForFilesenseAdvAnalysisList = async () => {
    
    const data = { userId: userID };
    const config = await getconfig();
    const response = await Api.post("/filesense_adv/FS_listAnalysis", data, config);

    return response.data
  }

  const useForAnalysisTopicSummaries = async (analysisId) => {
    const data = { userId: userID, analysisId: analysisId };
    const config = await getconfig();
    const response = await Api.post("/filesense_adv/FS_getTopicSummaries", data, config);

    return response.data
  }

  const useForAnalysisFullDataByStep = async (analysisId, analysisTopicId, stageName) => {
    const data = { userId: userID, analysisId: analysisId, analysisTopicId: analysisTopicId, stageName: stageName };
    const config = await getconfig();
    const response = await Api.post("/filesense_adv/FS_getTopicFullData", data, config);

    return response.data
  }
  const useForUpdateFSQuestion = async (data) => {

    const apiData = {
      userId: userID,
      analysisId: data.analysisId,
      TopicId: data.TopicId,
      analysisStageId: data.analysisStageId,
      questionId: data.questionId,
      question: data.question
    };

    const config = await getconfig();
    const response = await Api.post("/filesense_adv/FS_updateFSQuestion", apiData, config);

    return response.data

  }
  const useForUpdateFSAnswer = async (data) => {

    const apiData = {
      userId: userID,
      analysisId: data.analysisId,
      TopicId: data.TopicId,
      analysisStageId: data.analysisStageId,
      questionId: data.questionId,
      question: data.question,
      output: data.output
    };

    const config = await getconfig();
    const response = await Api.post("/filesense_adv/FS_updateFSOutput", apiData, config);
    return response.data

  }

  const useForUpdateFSExample = async (data) => {
    console.log(data)
    const apiData = {
      userId: userID,
      analysisId: data.analysisId,
      TopicId: data.TopicId,
      analysisStageId: data.analysisStageId,
      exampleId: data.exampleId,
      example: data.example,
      sessionFlag: data.sessionFlag
    };

    const config = await getconfig();
    const response = await Api.post("/filesense_adv/FS_updateFSExample", apiData, config);
    return response.data

  }


  const useForUpdateFSPrompt = async (data) => {
    const apiData = {
      userId: userID,
      analysisId: data.analysisId,
      TopicId: data.TopicId,
      analysisStageId: data.analysisStageId,
      promptId: data.promptId,
      prompt: data.prompt,
      sessionprompt: data.sessionPrompt,
      sessionFlag: data.sessionFlag
    };

    const config = await getconfig();
    const response = await Api.post("/filesense_adv/FS_updateFSPrompt", apiData, config);
    return response.data
  }

  const useForRegenerateAIResponse = async (data) => {
    const apiData = {
      userId: userID,
      analysisStageId: data.analysisStageId,
      sessionFlag: data.sessionFlag
    };

    const config = await getconfig();
    const response = await Api.post("/filesense_adv/FS_regenerateOutput", apiData, config);
    return response.data
  }



 const useForFSFileUpload = async (data,analysisName,file_dict,email) => {
    const config = await getconfig();
    config.headers["Content-Type"] = "multipart/form-data";
    const encodedFileDict = encodeURIComponent(JSON.stringify(file_dict));


    const response = await Api.post(
      `/filesense_adv/FS_uploadDocuments?userId=${userID}&analysisName=${analysisName}&file_dict=${encodedFileDict}&emailId=${email}`,

      data,
      config
    );
    // const response=await Api.post("/uploadDocument",data,config);
    return response.data;
  };

  const useForFSPdfDownload = async (data) => {
    var temp_data = JSON.parse(JSON.stringify(data));
    temp_data.userId = userID;
    const config = await getconfig();
    config.responseType = "blob";
    const response = await Api.post("/filesense_adv/FS_downloadoutputPDF", temp_data, config);
    return response;
  };

  const useForFSGetFileList = async (data) => {
    // console.log(data)
    const apiData = {
      userId: userID,
      analysisId: data,
      
    };

    const config = await getconfig();
    const response = await Api.post("/filesense_adv/FS_getfileList", apiData, config);
    return response.data
  }



  const useForDataUpload = async (data) => {
    const config = await getconfig();
    config.headers["Content-Type"] = "multipart/form-data";
    const response = await Api.post(
      `/uploadDataFile?user_id=${userID}`,
      data,
      config
    );
    return response.data;
  };

  const useForColumnDataUpload = async (data) => {
    const config = await getconfig();
    var temp_data = JSON.parse(JSON.stringify(data));
    temp_data.user_id = userID;
    const response = await Api.post("/loadUserFile", temp_data, config);
    return response.data;
  };

  const useForWritingAssistance = async (data, textFormat) => {
    const config = await getconfig();
    config.headers["Content-Type"] = "multipart/form-data";
    const response = await Api.post(
      `/writingAssistatnt?format=${textFormat}`,
      data,
      config
    );
    return response.data;
  };

  const useForContentSearch = async (data, controller) => {
    let config = await getconfig();
    config.signal = controller.signal;
    var temp_data = JSON.parse(JSON.stringify(data));
    temp_data.userId = userID;
    let response = await Api.post("/contentSearch", temp_data, config);
    return response;
  };

  const useForReleventDocuments = async (data, page) => {
    var temp_data = JSON.parse(JSON.stringify(data));
    temp_data.userId = userID;
    const config = await getconfig();
    const response = await Api.post(
      `/getRelevantDocuments/${page}`,
      temp_data,
      config
    );
    return response.data;
  };

  const useForFeatureList = async () => {
    const data = { userId: userID };
    const config = await getconfig();
    const response = await Api.post("/getUserFeatures", data, config);
    return response.data;
  };

  const useForPdfFeedback = async (data) => {
    const config = await getconfig();
    const response = await Api.post("/pdfFeedback", data, config);
    return response.data;
  };

  const useForPdfResponseTime = async (data) => {
    const config = await getconfig();
    const response = await Api.post("/savePDFResponseTime", data, config);
    return response.data;
  };

  const useForResendEmailVerification = async () => {
    const data = { userId: userID };
    const config = await getconfig();
    const response = await Api.post("/resendVerificationEmail", data, config);
    return response.data;
  };

  const useForPdfDownload = async (data) => {
    var temp_data = JSON.parse(JSON.stringify(data));
    temp_data.userId = userID;
    const config = await getconfig();
    config.responseType = "blob";
    const response = await Api.post("/downloadPDF", temp_data, config);
    return response;
  };

  const useForLoadKx = async () => {
    const data = { userId: userID };
    const config = await getconfig();
    const response = await Api.post("/loadKXPage", data, config);
    return response.data;
  };

  const useForLoadForGroupsKx = async (group) => {
    const data = { userId: userID, groupName: group };

    const config = await getconfig();
    const response = await Api.post("/loadKXPage", data, config);
    return response.data;
  };

  const usePowerBiToken = async () => {
    // const data={userId:userID};
    const config = await getconfig();
    const response = await Api.get("/get_bi_access_token", config);
    return response.data;
  };

  const useForLatestDataPdfDownload = async () => {
    const config = await getconfig();
    config.responseType = "blob";
    const response = await Api.post("/downloadLatestDataPDF", {}, config);
    return response;
  };

  const useForUploadFileAsGroup = async (data, file, groupName) => {
    const config = await getconfig();
    config.headers["Content-Type"] = "multipart/form-data";
    config.headers["columns_data"] = JSON.stringify(data);
    const response = await Api.post(
      `/uploadFilesAsGroup/${groupName}/${userID}`,
      file,
      config
    );
    return response.data;
  };

  const useForReleventQuestionsKX = async (data) => {
    const config = await getconfig();
    const response = await Api.post("/questionsForContentSearch", data, config);
    return response.data;
  };

  const useForCompositSummary = async (data) => {
    const config = await getconfig();
    const response = await Api.post("/getCompositSummary", data, config);
    return response.data;
  };
  const useForGenerateJD = async (data) => {
    const config = await getconfig();
    const response = await Api.post(
      `${process.env.REACT_APP_AUTH0_URL}/writing_assistant/generate_job_description`,
      data,
      config
    );
    return response;
  };
  const useForPositionList = async (data) => {
    const config = await getconfig();
    const response = await Api.post(
      `${process.env.REACT_APP_AUTH0_URL}/writing_assistant/get_position_list`,
      data,
      config
    );
    return response;
  };
  const useForPositionDetails = async (data) => {
    const config = await getconfig();
    const response = await Api.post(
      `${process.env.REACT_APP_AUTH0_URL}/writing_assistant/get_position_details`,
      data,
      config
    );
    return response;
  };
  const useForSaveJD = async (data) => {
    const config = await getconfig();
    const response = await Api.post(
      `${process.env.REACT_APP_AUTH0_URL}/writing_assistant/save_job_details`,
      data,
      config
    );
    return response;
  };
  const useForSubmitForApproval = async (data) => {
    const config = await getconfig();
    const response = await Api.post(
      `${process.env.REACT_APP_AUTH0_URL}/writing_assistant/submit_for_approval`,
      data,
      config
    );
    return response;
  };
  const useForDownloadWordFile = async (data) => {
    const config = await getconfig();

    // Ensure response type is set to 'blob'
    const response = await Api.post(
      `${process.env.REACT_APP_AUTH0_URL}/writing_assistant/download_word_file`,
      data,
      {
        ...config,
        responseType: "blob", // Set responseType to 'blob' for binary data
      }
    );

    return response;
  };
  const useForDownloadExcelFile = async (data) => {
    const config = await getconfig();

    // Ensure response type is set to 'blob'
    const response = await Api.post(
      `${process.env.REACT_APP_AUTH0_URL}/writing_assistant/download_excel_file`,
      data,
      {
        ...config,
        responseType: "blob", // Set responseType to 'blob' for binary data
      }
    );

    return response;
  };
  const useForLoadJobDetails = async (data) => {
    const config = await getconfig();
    const response = await Api.post(
      `${process.env.REACT_APP_AUTH0_URL}/writing_assistant/load_job_details`,
      data,
      config
    );
    return response;
  };
  const useForApproveJD = async (data) => {
    const config = await getconfig();
    const response = await Api.post(
      `${process.env.REACT_APP_AUTH0_URL}/writing_assistant/approve_jd`,
      data,
      config
    );
    return response;
  };
  const useForReviseJD = async (data) => {
    const config = await getconfig();
    const response = await Api.post(
      `${process.env.REACT_APP_AUTH0_URL}/writing_assistant/revise_jd`,
      data,
      config
    );
    return response;
  };
  const useForLandingPageData = async (data) => {
    const config = await getconfig();
    const response = await Api.post(
      `${process.env.REACT_APP_AUTH0_URL}/writing_assistant/wa_landing_page`,
      data,
      config
    );
    return response;
  };
  const useForDraftsList = async (data) => {
    const config = await getconfig();
    const response = await Api.post(
      `${process.env.REACT_APP_AUTH0_URL}/writing_assistant/wa_drafts_list`,
      data,
      config
    );
    return response;
  };
  const useForApprovedList = async (data) => {
    const config = await getconfig();
    const response = await Api.post(
      `${process.env.REACT_APP_AUTH0_URL}/writing_assistant/wa_self_approved_list`,
      data,
      config
    );
    return response;
  };
  const useForSubmitForApprovalsList = async (data) => {
    const config = await getconfig();
    const response = await Api.post(
      `${process.env.REACT_APP_AUTH0_URL}/writing_assistant/wa_sub_app_list`,
      data,
      config
    );
    return response;
  };
  const useForNeedRevisionList = async (data) => {
    const config = await getconfig();
    const response = await Api.post(
      `${process.env.REACT_APP_AUTH0_URL}/writing_assistant/wa_need_rev_list`,
      data,
      config
    );
    return response;
  };
  const useForWithdrawnList = async (data) => {
    const config = await getconfig();
    const response = await Api.post(
      `${process.env.REACT_APP_AUTH0_URL}/writing_assistant/wa_withdrawn_list`,
      data,
      config
    );
    return response;
  };
  const useForPendingApprovalList = async (data) => {
    const config = await getconfig();
    const response = await Api.post(
      `${process.env.REACT_APP_AUTH0_URL}/writing_assistant/wa_team_pending_list`,
      data,
      config
    );
    return response;
  };
  const useForTeamApprovedList = async (data) => {
    const config = await getconfig();
    const response = await Api.post(
      `${process.env.REACT_APP_AUTH0_URL}/writing_assistant/wa_team_app_list`,
      data,
      config
    );
    return response;
  };
  const useForJWTToken = async (data) => {
    const config = await getconfig();
    const response = await Api.post(
      `${process.env.REACT_APP_AUTH0_URL}/writing_assistant/generate_jwt_token`,
      data,
      config
    );
    return response;
  };

  return {
    useSavedbdetails,
    useFetchschemas,
    useHomepage,
    useFetchSchemas,
    useGiveFeedback,
    useGetDbTypes,
    useSignup,
    useGetData,
    useGetSummary,
    useGettopdata,
    useForAnalysisFullDataByStep,
    useSaveresponsetime,
    useForunstructured,
    useForPdfSummary,
    useForFileUpload,
    useForAnalysisTopicSummaries,
    useForWritingAssistance,
    useForContentSearch,
    useForReleventDocuments,
    useForDataUpload,
    useForColumnDataUpload,
    useForFeatureList,
    useForPdfFeedback,
    useForPdfResponseTime,
    useForResendEmailVerification,
    useForPdfDownload,
    useForLoadKx,
    useForFilesenseAdvAnalysisList,
    useForUpdateFSPrompt,
    useForUpdateFSExample,
    useForUpdateFSAnswer,
    useForFSPdfDownload,
    useForFSFileUpload,
    useForFSGetFileList,
    useForRegenerateAIResponse,
    useForLoadForGroupsKx,
    usePowerBiToken,
    useForLatestDataPdfDownload,
    useForUploadFileAsGroup,
    useForUpdateFSQuestion,
    useForReleventQuestionsKX,
    useForCompositSummary,
    useForGenerateJD,
    useForPositionList,
    useForPositionDetails,
    useForSaveJD,
    useForSubmitForApproval,
    useForDownloadWordFile,
    useForDownloadExcelFile,
    useForLoadJobDetails,
    useForApproveJD,
    useForReviseJD,
    useForLandingPageData,
    useForApprovedList,
    useForDraftsList,
    useForSubmitForApprovalsList,
    useForNeedRevisionList,
    useForWithdrawnList,
    useForPendingApprovalList,
    useForTeamApprovedList,
    useForJWTToken,
  };
};

export default useDatacallswithtoken;
