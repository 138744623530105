const API_BASE_URL = `${process.env.REACT_APP_AUTH0_URL}`;
const TOKEN =
  "b501cc2bf6d764742c467e0f81dc014dd9b8d5ac9819c091f184aeada99ff5f8";

export const getCollabToken = async (user) => {
  console.log("this is the user ", user);
  try {
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        user_id: user?.email,
      }),
    };
    const res = await fetch(
      `${process.env.REACT_APP_AUTH0_URL}/writing_assistant/generate_jwt_token`,
      options
    );
    if (res.ok) {
      const data = await res.json();
      return data.token;
    }
  } catch (error) {
    console.log(error);
  }
};

export const getThreads = async (documentId) => {
  const jsonData = {
    function_name: "getThreads",
    document_id: documentId,
  };
  try {
    const response = await fetch(
      `${process.env.REACT_APP_AUTH0_URL}/writing_assistant/tiptap_api`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: TOKEN,
        },
        body: JSON.stringify(jsonData),
      }
    );

    if (!response.ok) {
      console.log("this is the response", response);
      throw new Error("Failed to retrieve data from server");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error in fecting threads:", error);
    return error;
  }
};

export const getDocument = async (documentId) => {
  const jsonData = {
    function_name: "getDocument",
    document_id: documentId,
  };
  try {
    const response = await fetch(
      `${API_BASE_URL}/writing_assistant/tiptap_api`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: TOKEN,
        },
        body: JSON.stringify(jsonData),
      }
    );

    if (!response.ok) {
      throw new Error("Failed to retrieve data from server");
    }

    const data = await response.arrayBuffer();
    console.log("this is the data", data);
    if (!(data instanceof ArrayBuffer)) {
      throw new Error("Retrieved data is not an ArrayBuffer");
    }
    return data;
  } catch (error) {
    console.error("Error in fecting threads:", error);
    return error;
  }
};
