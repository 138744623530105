import React, { useState, useEffect } from "react";

import { connect } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import "./custom.css";

// import LanguageDropdown from "../../components/Common/TopbarDropdown/LanguageDropdown";
import NotificationDropdown from "../../components/Common/TopbarDropdown/NotificationDropdown";
import Network from "../../components/Common/Network";
// import networkBagroundImage from "./ATL Pilot.svg";
import { Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";

//i18n
import { withTranslation } from "react-i18next";

//import images
// import logoSm from "../../assets/images/Genie.png";
// import logoDark from "../../assets/images/genieLight.png";
// import logoLight from "../../assets/images/genieDark.png";
import logoSmDark from "../../assets/images/AtlasSmLight.png";
import logoSmLight from "../../assets/images/AtlasSmDark.png";
import logoDark from "../../assets/images/AtlasLight.png";
import logoLight from "../../assets/images/AtlasDark.png";

// Redux Store
import {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
  changeStructuredQuestion,
  changeUnStructuredQuestion,
  changeSummaryQuestion,
  refreshQuestions,
  changeContentSearchQuestion,
  showNotfication,
  changeChatQuestions,
  changeIsSidebarOpen,
} from "../../store/actions";
import ProfileMenu from "../../components/Common/TopbarDropdown/ProfileMenu";
import AppsDropdown from "../../components/Common/TopbarDropdown/AppsDropdown";

// redux
import { useDispatch, useSelector } from "react-redux";
// import { set } from "lodash";
// import { use } from "i18next";

// actions
const Header = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const trailexpairedMessage = useSelector(
    (state) => state.PageData.trailExpiredMessage
  );
  const [search, setsearch] = useState(false);
  const structuredquestion = useSelector(
    (state) => state.PageData.structuredquestion
  );
  const unstructuredquestion = useSelector(
    (state) => state.PageData.unstructuredquestion
  );
  const contentSearchQuestion = useSelector(
    (state) => state.PageData.contentSearchQuestion
  );
  const isSideBarOpen = useSelector((state) => state.PageData.isSideBarOpen);
  const pathParts = location.pathname.split("/");
  const insightsIndex = pathParts.indexOf("insights");
  const chatQuestions = useSelector((state) => state.PageData.chatQuestions);
  const [question, setquestion] = useState("");
  const isSearchDisabled = useSelector(
    (state) => state.PageData.isSearchDisabled
  );
  const [searchDisabledMessage, setsearchDisabledMessage] = useState("");
  const dispatch = useDispatch();
  const [shouldShowSearch, setshouldShowSearch] = useState(true);
  const [showNetwork, setshowNetwork] = useState(false);
  const [originalQuestion, setoriginalQuestion] = useState("");
  const fileKey = useSelector((state) => state.PageData.fileKey);
  const show = useSelector((state) => state.PageData.show);
  let appDisplay = useSelector((state) => state.PageData.appDisplay);
  const notficationMessage = useSelector(
    (state) => state.PageData.notficationMessage
  );

  function keepAlphabetsSpacesNumbersAndQuestions(str) {
    // Keep only alphabetic characters (lowercase and uppercase), numbers, spaces, and question marks
    return str.replace(/[^a-zA-Z0-9 ?]/g, "");
  }

  function replaceSpacesWithPercent(inputString) {
    return inputString.replace(/ /g, "-");
  }

  // on search submit update redux state
  const OnSearchSubmit = (e) => {
    e.preventDefault();
    const pathName = replaceSpacesWithPercent(appDisplay);

    const updatedQuestion = keepAlphabetsSpacesNumbersAndQuestions(question);
    if (updatedQuestion) {
      if (location.pathname === "/app/home") {
        dispatch(changeStructuredQuestion(updatedQuestion));
      } else if (location.pathname === "/app/filesense") {
        if (isSearchDisabled) {
          setsearchDisabledMessage("Please Upload a File to Query");
        } else {
          dispatch(changeUnStructuredQuestion(updatedQuestion));
        }
      } else if (
        location.pathname === `/app/insights/${pathName.toLowerCase()}`
      ) {
        if (appDisplay === "Legal" || appDisplay === "Bluestar") {
          dispatch(changeChatQuestions([...chatQuestions, updatedQuestion]));
          dispatch(changeContentSearchQuestion(updatedQuestion));
          setshouldShowSearch(false);
        } else {
          dispatch(changeContentSearchQuestion(updatedQuestion));
        }
      } else if (location.pathname === "/app/insights-new") {
        dispatch(changeChatQuestions([...chatQuestions, updatedQuestion]));
        setshouldShowSearch(false);
      }
    }
  };
  useEffect(() => {
    setquestion(structuredquestion);
    setoriginalQuestion(structuredquestion);
  }, [structuredquestion]);

  useEffect(() => {
    // get last question from unstructured question array
    if (unstructuredquestion.length > 0) {
      setquestion(
        unstructuredquestion[unstructuredquestion.length - 1].question
      );
    } else {
      setquestion("");
      setoriginalQuestion("");
    }
  }, [unstructuredquestion]);

  useEffect(() => {
    setquestion(contentSearchQuestion);
    setoriginalQuestion(contentSearchQuestion);
  }, [contentSearchQuestion]);

  useEffect(() => {
    if (
      chatQuestions?.length === 0 &&
      location.pathname === `/app/insights/${appDisplay.toLowerCase()}` &&
      appDisplay !== "Sambalpur-KPI"
    ) {
      setshouldShowSearch(true);
      setquestion("");
      setoriginalQuestion("");
    }
  }, [chatQuestions]);

  useEffect(() => {
    dispatch(refreshQuestions());
    setquestion("");
    setoriginalQuestion("");
    if (location.pathname === "/app/filesense") {
      setsearchDisabledMessage("Please Upload a File to Query");
      setoriginalQuestion("");
      if (fileKey) {
        setshouldShowSearch(false);
      } else {
        setshouldShowSearch(true);
      }
    } else if (
      location.pathname === "/app/writing-assistant" ||
      location.pathname === "/app/connect-to-db" ||
      location.pathname === "/app/assistant" ||
      location.pathname === "/app/add-file-as-connection" ||
      location.pathname === "/app/contact-us" ||
      location.pathname === "/app/document-writer" ||
      location.pathname === "/app/writing-assistant/job-description"||
      location.pathname === "/app/analytics"
    ) {
      setshouldShowSearch(false);
    } else if (
      location.pathname === "/app/trail-expired" ||
      location.pathname === "/app/subscribe"
    ) {
      setshouldShowSearch(false);
    } else if (appDisplay === "Sambalpur-KPI") {
      setshouldShowSearch(false);
    } else {
      setshouldShowSearch(true);
      setsearchDisabledMessage("");
    }
  }, [location.pathname, dispatch, fileKey, appDisplay]);

  // const initialStructuredQuestion = useSelector(state => state.PageData.structuredquestion)
  // const initialUnstructuredQuestion = useSelector(state => state.PageData.unstructuredquestion)
  // const initialSummaryQuestion = useSelector(state => state.PageData.summaryquestions)
  // useEffect(() => {
  //   if (initialStructuredQuestion === undefined && initialUnstructuredQuestion.length === 0 && initialSummaryQuestion.length === 0) {
  //     setquestion('')
  //   }
  // }, [initialStructuredQuestion, initialUnstructuredQuestion, initialSummaryQuestion])

  useEffect(() => {
    if (trailexpairedMessage !== "") {
      navigate("/app/trail-expired");
      setsearchDisabledMessage("Trail Expired!");
    }
  }, [trailexpairedMessage, navigate]);

  function toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }

  function tToggle() {
    var body = document.body;
    dispatch(changeIsSidebarOpen(!isSideBarOpen));
    if (window.screen.width <= 998) {
      body.classList.toggle("sidebar-enable");
    } else {
      body.classList.toggle("vertical-collpsed");
      body.classList.toggle("sidebar-enable");
    }
  }

  return (
    <React.Fragment>
      {!(
        location.pathname === "/app/insights/legal" && appDisplay === "Legal"
      ) &&
        !(
          location.pathname === "/app/insights/blue-star" &&
          appDisplay === "Blue Star"
        ) && (
          <header id="page-topbar">
            <div className="navbar-header">
              <div className="d-flex">
                <div className="navbar-brand-box text-center">
                  <Link
                    to="/"
                    className="logo logo-dark"
                    onClick={() => {
                      setoriginalQuestion("");
                    }}
                  >
                    <span className="logo-sm">
                      <img src={logoSmLight} alt="logo-sm-dark" height="40" />
                    </span>
                    <span className="logo-lg">
                      <img src={logoDark} alt="logo-dark" height="45" />
                    </span>
                  </Link>

                  <Link
                    to="/"
                    onClick={() => {
                      setoriginalQuestion("");
                    }}
                    className="logo logo-light"
                  >
                    <span className="logo-sm">
                      <img src={logoSmDark} alt="logo-sm-light" height="40" />
                    </span>
                    <span className="logo-lg">
                      <img src={logoLight} alt="logo-light" height="45" />
                    </span>
                  </Link>
                </div>
                <button
                  type="button"
                  className="btn btn-sm px-3 font-size-24 header-item waves-effect"
                  id="vertical-menu-btn"
                  onClick={() => {
                    tToggle();
                  }}
                >
                  <i className="ri-menu-2-line align-middle"></i>
                </button>
              </div>

              {shouldShowSearch && (
                <form
                  onSubmit={(e) => OnSearchSubmit(e)}
                  className="app-search d-none d-lg-block"
                  style={{ width: "200%" }}
                >
                  <div className="position-relative">
                    <input
                      disabled={isSearchDisabled}
                      type="text"
                      className="form-control tour-step-4"
                      placeholder={
                        isSearchDisabled
                          ? searchDisabledMessage
                            ? searchDisabledMessage
                            : "Ask any business question"
                          : location.pathname === "/app/knowlegdeX"
                          ? "Search what you want to find"
                          : "Ask any business question"
                      }
                      onChange={(e) => {
                        setquestion(e.target.value);
                        setoriginalQuestion(e.target.value);
                      }}
                      value={originalQuestion}
                    />
                    <span className="ri-search-line"></span>
                    {question !== "" && question?.length !== 0 ? (
                      <button
                        onClick={() => {
                          setquestion("");
                          setoriginalQuestion("");
                        }}
                        className="close"
                        type="button"
                        style={{ top: "7px" }}
                      ></button>
                    ) : null}
                  </div>
                </form>
              )}
              <div className="d-flex">
                {shouldShowSearch && (
                  <div className="dropdown d-inline-block d-lg-none ms-2">
                    <button
                      onClick={() => {
                        setsearch(!search);
                      }}
                      type="button"
                      className="btn header-item noti-icon"
                      id="page-header-search-dropdown"
                    >
                      <i className="ri-search-line" />
                    </button>
                    <div
                      className={
                        search
                          ? "dropdown-menu dropdown-menu-lg dropdown-menu-end p-0 show"
                          : "dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
                      }
                      aria-labelledby="page-header-search-dropdown"
                    >
                      <form onSubmit={(e) => OnSearchSubmit(e)} className="p-3">
                        <div className="form-group m-0">
                          <div className="input-group">
                            <input
                              disabled={isSearchDisabled}
                              type="text"
                              className="form-control"
                              placeholder={
                                isSearchDisabled
                                  ? searchDisabledMessage
                                    ? searchDisabledMessage
                                    : "Ask any business question"
                                  : location.pathname === "/app/knowlegdeX"
                                  ? "Search what you want to find"
                                  : "Ask any business question"
                              }
                              onChange={(e) => {
                                setquestion(e.target.value);
                                setoriginalQuestion(e.target.value);
                              }}
                              value={originalQuestion}
                            />
                            {question !== "" && question?.length !== 0 ? (
                              <button
                                onClick={() => {
                                  setquestion("");
                                  setoriginalQuestion("");
                                }}
                                className="close"
                                type="button"
                                style={{ top: "7px" }}
                              ></button>
                            ) : null}
                            <div className="input-group-append">
                              <button className="btn btn-primary" type="submit">
                                <i className="ri-search-line" />
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                )}

                {/* <LanguageDropdown /> */}
                { location.pathname !== "/app/analytics" &&   <AppsDropdown /> }
               
                {/* button to show network */}
                <div className="dropdown d-none d-lg-inline-block ms-1">
                  {appDisplay === "Aluminium Market Outlook" &&
                    insightsIndex > 0 && (
                      <button
                        type="button"
                        onClick={() => {
                          setshowNetwork(!showNetwork);
                        }}
                        className="btn header-item noti-icon"
                        id="page-header-search-dropdown"
                      >
                        <i className="ri-global-line" />
                      </button>
                    )}
                </div>
                <Modal
                  // style={{
                  //   backgroundImage: `url(${networkBagroundImage})`,
                  // }}
                  size="xl"
                  className="special_modal modal-fullscreen"
                  isOpen={showNetwork}
                  toggle={() => setshowNetwork(!showNetwork)}
                  centered={true}
                >
                  <div
                    className="modal-content"
                    // style={{ backgroundColor: "#191919", color: "#191919", borderColor: "#191919" }}
                  >
                    {/* <ModalHeader
      toggle={() => setshowNetwork(!showNetwork)}>Network</ModalHeader> */}
                    <ModalBody>
                      <Network />
                    </ModalBody>
                  </div>
                </Modal>
                {/* end button to show network */}

                <div className="dropdown d-none d-lg-inline-block ms-1">
                  <button
                    type="button"
                    onClick={() => {
                      toggleFullscreen();
                    }}
                    className="btn header-item noti-icon"
                    data-toggle="fullscreen"
                  >
                    <i className="ri-fullscreen-line" />
                  </button>
                </div>

                {/* <NotificationDropdown /> */}
                <Modal
                  isOpen={show}
                  toggle={() => {
                    dispatch(showNotfication(!show));
                    navigate("/app/home");
                  }}
                >
                  <ModalHeader
                    toggle={() => {
                      dispatch(showNotfication(!show));
                      navigate("/app/home");
                    }}
                  >
                    Notification
                  </ModalHeader>
                  <ModalBody>
                    <p>{notficationMessage}</p>
                  </ModalBody>
                  <ModalFooter>
                    <button
                      className="btn btn-primary"
                      onClick={() => {
                        dispatch(showNotfication(!show));
                        navigate("/app/home");
                      }}
                    >
                      Ok
                    </button>
                  </ModalFooter>
                </Modal>

                <ProfileMenu />

                <div
                  className="dropdown d-inline-block"
                  onClick={() => {
                    props.showRightSidebarAction(!props.showRightSidebar);
                  }}
                >
                  <button
                    type="button"
                    className="btn header-item noti-icon right-bar-toggle waves-effect"
                  >
                    <i className="mdi mdi-cog"></i>
                  </button>
                </div>
              </div>
            </div>
          </header>
        )}
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { layoutType, showRightSidebar, leftMenu, leftSideBarType, question } =
    state.Layout;
  return { layoutType, showRightSidebar, leftMenu, leftSideBarType, question };
};

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
  changeStructuredQuestion,
  changeUnStructuredQuestion,
  changeSummaryQuestion,
})(withTranslation()(Header));
