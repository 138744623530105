import React from "react";

import { Form, Input, Select, Button, Collapse, Row, Col } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import JobDescription from "../Components/JD/JobDescription";
import EditJD from "../Components/JD/EditJD";
const { Option } = Select;
const { Panel } = Collapse;

const EditJobDescription = ({ record }) => {
  const title = "Edit Job Description";
  return <EditJD title={title} record={record} />;
};

export default EditJobDescription;
