import React, { useEffect, useState } from "react";
import {
  Tooltip,
  Avatar,
  Steps,
  Button,
  message,
  Skeleton,
  Menu,
  Dropdown,
} from "antd";
import {
  CheckOutlined,
  DownloadOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import { FiSend } from "react-icons/fi";
import Tablediv from "../../Components/Table/TableDiv";

import { useAuth0 } from "@auth0/auth0-react";
import ApprovalTooltip from "../../Components/ToolTip/ApprovalToolTip";
import "../../Components/ToolTip/ApprovalToolTip.css";
import useDatacallswithtoken from "../../../../Data Apis/apifunctions";

const { Step } = Steps;

const Approved = ({ onEditClick, setPagesCount }) => {
  const {
    useForApprovedList,
    useForDownloadWordFile,
    useForDownloadExcelFile,
  } = useDatacallswithtoken();
  const { user, getAccessTokenSilently } = useAuth0();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true); // Loading state
  const FetchApprovedList = async (body) => {
    const res = await useForApprovedList(JSON.stringify(body));
    if (res.status === 200) {
      return res; // Return the response data
    } else {
      throw new Error("Error fetching approverd list");
    }
  };
  const DownloadWordFile = async (body) => {
    const res = await useForDownloadWordFile(body);

    if (res.status !== 200) {
      throw new Error("Error downloading Word file");
    }

    // Convert the response to a blob (binary large object)
    const blob = new Blob([res.data], {
      type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    });

    // Create a link element and use it to trigger the download
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "job_description.docx"); // Set file name
    document.body.appendChild(link);
    link.click();

    // Clean up the URL and remove the link
    window.URL.revokeObjectURL(url);
    link.remove();

    message.success("Word file downloaded successfully!");
  };
  const DownloadExcelFile = async (body) => {
    const res = await useForDownloadExcelFile(body);

    if (res.status !== 200) {
      throw new Error("Error downloading Excel file");
    }

    // Convert the response to a blob (binary large object)
    const blob = new Blob([res.data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    // Create a link element and use it to trigger the download
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "job_description.xlsx"); // Set file name
    document.body.appendChild(link);
    link.click();

    // Clean up the URL and remove the link
    window.URL.revokeObjectURL(url);
    link.remove();

    message.success("Excel file downloaded successfully!");
  };
  const handleDownloadFile = async (jd_key, fileType) => {
    try {
      const body = {
        user_id: user?.sub,
        jd_key: jd_key,
      };

      if (fileType === "word") {
        await DownloadWordFile(body);
      } else if (fileType === "excel") {
        await DownloadExcelFile(body);
      }
    } catch (error) {
      console.error(`Error during ${fileType} file download:`, error);
      message.error(`Error downloading ${fileType} file`);
    }
  };
  // Color palette
  const colors = [
    "#08b6ae",
    "#3f47ca",
    "#f6850f",
    "#df3d82",
    "#7d84fa",
    "#70e068",
    "#147af2",
    "#e9c501",
    "#cc5e00",
    "#bbea2f",
  ];

  const getColorForApprover = (index) => {
    return colors[index % colors?.length];
  };

  const getInitials = (name) => {
    const [firstName, lastName] = name?.split(" ");
    return `${firstName[0]}${lastName ? lastName[0] : ""}`.toUpperCase();
  };

  // Utility function to create unique filter options based on data
  const createUniqueFilterOptions = (data, key) => {
    const uniqueValues = [...new Set(data.map((item) => item[key]))];
    return uniqueValues?.map((value) => ({
      text: value,
      value: value,
    }));
  };
  // Create unique approvers filter
  const uniqueApprovers = [
    ...new Set(data?.flatMap((item) => item?.approvers)),
  ].map((approver) => ({
    text: approver,
    value: approver,
  }));

  const columns = [
    {
      title: "Position ID",
      dataIndex: "positionId",
      sorter: (a, b) => a?.positionId?.localeCompare(b?.positionId),
      filters: createUniqueFilterOptions(data, "positionId"),
      onFilter: (value, record) => record?.positionId?.includes(value),
    },
    {
      title: "Position Title",
      dataIndex: "positionTitle",
      filters: createUniqueFilterOptions(data, "positionTitle"),
      onFilter: (value, record) => record?.positionTitle?.includes(value),
    },
    {
      title: "Department",
      dataIndex: "department",
      filters: createUniqueFilterOptions(data, "department"),
      onFilter: (value, record) => record?.department?.includes(value),
    },
    {
      title: "Location",
      dataIndex: "location",
      sorter: (a, b) => a?.location?.localeCompare(b?.location),
      filters: createUniqueFilterOptions(data, "location"),
      onFilter: (value, record) => record?.location?.includes(value),
    },
    {
      title: "Submitted Date",
      dataIndex: "submittedDate",
      sorter: (a, b) => new Date(a?.submittedDate) - new Date(b?.submittedDate),
    },
    {
      title: "Submitted By",
      dataIndex: "submittedBy",
      sorter: (a, b) => a?.submittedBy?.localeCompare(b?.submittedBy),
      filters: createUniqueFilterOptions(data, "submittedBy"),
      onFilter: (value, record) => record?.submittedBy?.includes(value),
    },
    {
      title: "Approvers",
      dataIndex: "approvers",
      filters: uniqueApprovers, // Use unique approvers for filtering
      onFilter: (value, record) => record?.approvers?.includes(value),
      sorter: (a, b) => a?.approvers?.length - b?.approvers?.length,
      render: (approvers, record) => (
        <Avatar.Group maxCount={3}>
          {approvers?.map((approver, index) => (
            <Tooltip
              key={approver}
              title={
                <ApprovalTooltip status_history={record?.status_history} />
              }
              overlayClassName="custom-approval-tooltip"
              placement="topRight"
            >
              <Avatar
                style={{
                  backgroundColor: getColorForApprover(index),
                  color: "#fff",
                  height: "30px",
                  width: "30px",
                }}
              >
                {getInitials(approver)}
              </Avatar>
            </Tooltip>
          ))}
        </Avatar.Group>
      ),
    },
    {
      title: "Actions",
      dataIndex: "actions",
      render: (_, record) => (
        <div style={{ display: "flex", whiteSpace: "nowrap" }}>
          <Tooltip title="View">
            <Button
              type="button"
              icon={<EyeOutlined />}
              style={{ margin: "0" }}
              onClick={(e) => {
                e.stopPropagation(); // Prevent click event from propagating to parent
                onEditClick(record);
              }}
            />
          </Tooltip>

          <Tooltip title="Share">
            <Button
              type="button"
              icon={<FiSend />}
              style={{ margin: "0" }}
              onClick={(e) => {
                e.stopPropagation(); // Prevent click event from propagating to parent
                console.log("Share clicked", record);
              }}
            />
          </Tooltip>

          {/* <Tooltip title="Download"> */}
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item
                  key="1"
                  onClick={(e) => {
                    e.domEvent.stopPropagation(); // Stop event from propagating to the row
                    handleDownloadFile(record?.key, "excel");
                  }}
                >
                  Download Excel
                </Menu.Item>
                <Menu.Item
                  key="2"
                  onClick={(e) => {
                    e.domEvent.stopPropagation(); // Stop event from propagating to the row
                    handleDownloadFile(record?.key, "word");
                  }}
                >
                  Download Word
                </Menu.Item>
              </Menu>
            }
            trigger={["hover"]}
            onClick={(e) => e.stopPropagation()} // Prevent click event from propagating to parent row
          >
            <Button
              type="button"
              icon={<DownloadOutlined />}
              style={{ padding: "4px", margin: "0" }}
            />
          </Dropdown>
          {/* </Tooltip> */}
        </div>
      ),
    },
  ];

  useEffect(() => {
    const fetchApprovedPageData = async () => {
      setLoading(true); // Start loading
      const jsonData = { userId: user?.sub };
      try {
        const res = await FetchApprovedList(jsonData);

        const fetchedData = res.data;

        setPagesCount({ approved: fetchedData.length });

        const formattedData = fetchedData.map((item) => {
          const approvers = item?.status_history.map((status) => status?.Name);
          const approvalStatus = item?.status_history.map(
            (status) => status?.action.toLowerCase() === "approved"
          ); // Correct handling for APPROVED status
          const approvalDates = item?.status_history.map((status) =>
            status?.approval_date
              ? new Date(status?.approval_date).toLocaleDateString()
              : ""
          );
          const status_history = item.status_history;
          return {
            key: item?.jd_key,
            positionId: item?.position_id,
            positionTitle: item?.process_title,
            department: item?.department,
            location: item?.position_location,
            submittedDate: item?.submitted_dt
              ? new Date(item?.submitted_dt).toISOString().split("T")[0]
              : null,
            approvers, // Use dynamic approvers from status_history
            approvalStatus, // Dynamic approval status
            approvalDates, // Dynamic approval dates
            submittedBy: item?.submited_by, // Example submitter, adjust as needed
            status_history,
          };
        });
        setData(formattedData);
      } catch (error) {
        console.error("Error during fetch:", error);
        message.error("Error fetching data");
      } finally {
        setLoading(false); // End loading
      }
    };
    fetchApprovedPageData();
  }, [getAccessTokenSilently, user]);

  // const menu = (
  //   <Menu>
  //     <Menu.Item key="1" onClick={handleDownloadExcel}>
  //       Download Excel
  //     </Menu.Item>
  //     <Menu.Item key="2" onClick={handleDownloadWord}>
  //       Download Word
  //     </Menu.Item>
  //   </Menu>
  // );

  return (
    <div className="approvedmain" style={{ flex: "1" }}>
      {loading ? (
        <Skeleton active /> // Display the skeleton when loading
      ) : (
        <Tablediv
          title={`Approved (${data.length})`}
          columns={columns}
          dataSource={data}
          rowSelectionEnabled={true}
          initialPageSize={10}
          onEditClick={onEditClick}
        />
      )}
    </div>
  );
};

export default Approved;
