import React from "react";
import { PlusOutlined } from "@ant-design/icons";
import { Button, Menu, Typography } from "antd";
import { useNavigate } from "react-router-dom";
import "./MenuWithButton.css";

const MenuWithButton = ({
  menuItems = [],
  onButtonClick = () => {},
  onMenuItemClick = () => {},
  buttonLabel = "Create Job Description",
  buttonIcon = <PlusOutlined />,
  buttonStyle = {},
  menuStyle = {},
  selectedKeys = [],
}) => {
  const navigate = useNavigate();

  const handleMenuClick = (e) => {
    onMenuItemClick(e);
    const clickedItem =
      menuItems
        .flatMap((item) => item.children || [item])
        .find((item) => item.key === e.key) || {};
    const route = clickedItem.route || "";

    if (route) {
      navigate(route);
    }
  };

  return (
    <div className="menu-div">
      <div className="menu">
        <Button
          type="primary"
          icon={buttonIcon}
          onClick={onButtonClick}
          style={{
            width: "100%",
            backgroundColor: "black",
            color: "white",
            marginBottom: "10px",
            borderRadius: "30px",
            border: "solid 1px black",
            ...buttonStyle,
          }}
        >
          {buttonLabel}
        </Button>

        <Menu
          onClick={handleMenuClick}
          selectedKeys={selectedKeys}
          style={{
            backgroundColor: "#F5F5F5",
            border: "none",
            ...menuStyle,
          }}
          defaultOpenKeys={menuItems.map((item) => item.key)}
          mode="inline"
          items={menuItems.map((item) => ({
            ...item,
            label: (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <span>{item.label}</span>
                {item.count !== undefined && (
                  <span className="menu-item-count">{item.count}</span>
                )}
              </div>
            ),
            children: item.children?.map((child) => ({
              ...child,
              label: (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <span>{child.label}</span>
                  {child.count !== undefined && (
                    <span className="menu-item-count">{child.count}</span>
                  )}
                </div>
              ),
            })),
          }))}
        />
      </div>
    </div>
  );
};

export default MenuWithButton;
