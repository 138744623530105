import { useCallback, useState } from "react";

import { useUser } from "../Hooks/useUser";
import { Button, Input } from "antd";

export const ThreadComposer = ({ threadId, provider }) => {
  const user = useUser();
  const [comment, setComment] = useState("");

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();

      if (!comment) {
        return;
      }

      if (provider) {
        provider.addComment(threadId, {
          content: comment,
          createdAt: Date.now(),
          updatedAt: Date.now(),
          data: { userName: user.name },
        });

        setComment("");
      }
    },
    [comment, provider]
  );

  return (
    <form>
      <Input.TextArea
        value={comment}
        onChange={(e) => setComment(e.currentTarget.value)}
        placeholder="Reply to thread …"
        rows={4} // Adjust the height of the textarea
        maxLength={256} // Limit the input to a maximum of 256 characters
        showCount
        style={{ margin: "0px 0px 10px 0px" }}
      />
      <div className="flex-row">
        <div
          className="button-group"
          style={{
            display: "flex",
            justifyContent: "flex-start",
          }}
        >
          <Button
            className="primary"
            disabled={!comment.length}
            onClick={handleSubmit}
          >
            Send
          </Button>
        </div>
      </div>
    </form>
  );
};
