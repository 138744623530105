import React from "react";
import { Tooltip, Avatar } from "antd";
import { CheckOutlined } from "@ant-design/icons";
import "./ApprovalToolTip.css"; // Assume we use external CSS for custom styling

const ApprovalTooltip = ({ status_history = [] }) => {
  // Ensure status_history is always an array
  if (!status_history.length) {
    return <div>No approval history available</div>; // Or return null if you want to show nothing
  }

  return (
    <div className="approval-tooltip" id="approvaltooltipmain">
      {status_history.map((status, index) => {
        const isApproved = status.action.toLowerCase() === "approved";
        const isLastApprover = index === status_history.length - 1; // Check if it's the last approver

        return (
          <div key={status.level} className="approver-section">
            <div className="approver-info">
              {isApproved ? (
                <span
                  className="approver-number"
                  style={{ border: "1px solid #32CD32" }}
                >
                  <CheckOutlined />
                </span>
              ) : (
                <span
                  className="approver-number"
                  style={{ border: "1px solid var(--Primary-3, #b3b3b3)" }}
                >
                  {status.level}
                </span>
              )}{" "}
              <span
                className="approver-name"
                style={{
                  color: isApproved ? "#32CD32" : "#fff", // Green for approved
                }}
              >
                {status.Name}
              </span>{" "}
              {/* Only add the line if it's not the last approver */}
              {!isLastApprover && (
                <span>
                  <hr
                    style={{
                      border: `1px solid ${isApproved ? "#32CD32" : "white"}`,
                      width: "34px",
                      marginLeft: "5px",
                    }}
                  />
                </span>
              )}
            </div>
            <div className="approver-status">
              {isApproved ? (
                <>
                  <span>Approved on {status.approval_date}</span>
                </>
              ) : (
                <span>Approval pending</span>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ApprovalTooltip;
