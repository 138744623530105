import React, { useEffect, useState } from 'react';
import { Form } from 'reactstrap';
import "../../style/style.css";
import Dropzone from 'react-dropzone';
import { useNotification } from './NotificationProvider';
import useDatacallswithtoken from '../../../../Data Apis/apifunctions';
import { useDispatch } from 'react-redux';
import { Dropdown, Flex, Menu, Table, Typography } from 'antd';
import { DeleteOutlined, DownOutlined, UploadOutlined } from '@ant-design/icons';
import ParameterForm from './ParameterForm';
import BlackBtn from './Steps/Elements/BlackBtn';
import { useAuth0 } from "@auth0/auth0-react";


const { Text, Title } = Typography;

const MultipleFileUpload = (props) => {
  const {user}=useAuth0()
  const dispatch = useDispatch();
  const notification = useNotification()
  const { useForFSFileUpload } = useDatacallswithtoken();
  const [selectedFiles, setselectedFiles] = useState([]);
  const [filestoupload, setFilestoupload] = useState([]);
  const [uploadLoading, setuploadLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [analysisName, setAnaLysisName] = useState("");
  const [allowGenerate, setAllowGenerate] = useState(false);
  const [errormessage, seterrormessage] = useState('');
  const { settimeTakenToUpload } = props;
  const [uploading, setUploading] = useState(false);
  const options = ['Antaike', 'CRU', 'Competition Data', 'Others'];



  const [timer, setTimer] = useState(0);

  const startTimer = () => {
    setTimer(0); // Reset timer
    setUploading(true);
  };

  const stopTimer = () => {
    setUploading(false);
  };

  useEffect(() => {
    if (errormessage.length > 1 && isError)
      notification.error({
        message: 'Error',
        description: errormessage,
        placement: 'topRight',
      })
  }, [errormessage])


  function handleAcceptedFiles(files, fileRejections) {
    setAllowGenerate(false);
    if (fileRejections.length > 0) {
      setIsError(true);
      seterrormessage(
        fileRejections[0].errors[0].code === 'file-too-large'
          ? `File size should be less than 10MB`
          : fileRejections[0].errors[0].code === 'file-too-small'
            ? `File size should be greater than 1KB`
            : fileRejections[0].errors[0].message
      );
      return;
    }
    const filesToProcess = files.reduce((acc, currentFile) => {
      // Check if the file is already in the filestoupload array based on the name
      const isDuplicate = filestoupload.some(
        (uploadedFile) => uploadedFile.name === currentFile.name
      );

      // Only add the file if it's not already in the array
      if (!isDuplicate) {
        acc.push(currentFile);
      }
      return acc;
    }, [...(filestoupload || [])]);
    // const filesToProcess = files
    console.log(files)
    // Set the files in the state
    setFilestoupload(filesToProcess);
    // console.log(filesToProcess)


    const filesArray = filesToProcess.map((file) => ({
      name: file.name,
      size: file.size,
      formattedSize: formatBytes(file.size),
      sources: undefined,
      preview: URL.createObjectURL(file),
    }));

    // Set the selected files array with the created file objects
    setselectedFiles(filesArray);
  }

  /**
   * Formats the size
   */
  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;

    if (minutes > 0) {
      return `${minutes}m ${remainingSeconds}s`;
    } else {
      return `${remainingSeconds}s`;
    }
  };
  const checkMissingFiles = () => {
    const missingFiles = [];
    requiredFiles.forEach((requiredFile) => {
      missingFiles.push(
        requiredFile.formats.length > 0
          ? `${requiredFile.name} (${requiredFile.formats.join(', ')})`
          : requiredFile.name
      );
    });

    return missingFiles;
  };

  const HandleFileSubmit = () => {
    const missingFiles = checkMissingFiles()
    
    if (missingFiles.length > 0) {
      const btn = <BlackBtn name={"Submit"} onBtnClick={() => {  GenerateAnalysis() }} />
      notification.warning({
        message: 'Required Files',
        description: (
          <div>
            <Text>I require the following files for generating the complete analysis:</Text>
            <ul>
              {missingFiles.map((file, index) => (
                <li key={index}>{file}</li>
              ))}
            </ul>
            <Text>I am configured to process only the above listed excel files, however you can add any pdfs reports.</Text>
          </div>
        ),
        btn,
        placement: 'topRight',
        style: {
          width: '550px', // Adjust the width as per your need
        },
      });

    } else {
      GenerateAnalysis();
    }
    console.log(missingFiles)



  };

  const GenerateAnalysis = async () => {
    const startTime = new Date().getTime();
    setuploadLoading(true);
    startTimer();
    setAllowGenerate(false);
    const formData = new FormData();
    const email=user.email

    for (const file of filestoupload) {
      formData.append('files', file);
    }
    const fileDict = selectedFiles.map((file) => ({
      filename: file.name,
      source: file.source
    }))
    console.log(JSON.stringify(fileDict), formData);

    await useForFSFileUpload(formData, analysisName, fileDict,email)
      .then((res) => {
        notification.success({
          message: 'I am currently analysing the files uploaded and will generate the complete analysis in 2 hours. I will mail once the analysis is complete.',
          // description: 'Your can view your analysis on the sidebar when generated',
          placement: 'topRight',
        });


        setselectedFiles([]);
        setFilestoupload([]);
        setuploadLoading(false);

        const duration = new Date().getTime() - startTime;

        const totalSeconds = Math.floor(duration / 1000);
        // const secondsOnly = totalSeconds % 60;
        settimeTakenToUpload(totalSeconds);
      })
      .catch((err) => {
        console.error(err);
        setuploadLoading(false);
        setIsError(true);
        setAllowGenerate(true);
        seterrormessage('Something went wrong. Please try again later.');
      })
      .finally(() => {
        stopTimer();
      });
  }
  // console.log(filestoupload)
  useEffect(() => {
    let interval;

    if (uploading) {
      interval = setInterval(() => {
        setTimer((prev) => prev + 1);
      }, 1000); // Increment every second
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [uploading]);

  const handleMenuClick = (fileIndex, selectedValue) => {
    const updatedFiles = selectedFiles.map((file, i) =>
      i === fileIndex ? { ...file, source: selectedValue } : file
    )

    setselectedFiles(updatedFiles);

    const allSourcesSelected = updatedFiles.every(file => file.source);


    setAllowGenerate(allSourcesSelected);

    console.log(allSourcesSelected)

  };

  const columns = [
    {
      title: 'Filename',
      dataIndex: 'name',
      key: 'name',
      render: (text) => <span>{text}</span>,
    },
    {
      title: 'Selected sources',
      dataIndex: 'source',
      key: 'source',
      width: 200,
      render: (text, record, index) => {
        const menu = (
          <Menu onClick={(e) => handleMenuClick(index, e.key)}>
            {options.map((option) => (
              <Menu.Item key={option}>{option}</Menu.Item>
            ))}
          </Menu>
        );
        return (
          <Dropdown overlay={menu}>
            <a className='ant-dropdown-link' onClick={(e) => e.preventDefault()}>
              {text ? text : 'Select Sources'} <DownOutlined />
            </a>
          </Dropdown>
        );
      },
    },
    {
      title: 'Action',
      key: 'action',
      width: 60,
      render: (text, record) => (
        <DeleteOutlined

          onClick={() => deleteFileFromSelectedFiles(record)}
          disabled={uploadLoading}
        />


      ),
    },
  ];

  const deleteFileFromSelectedFiles = (record) => {
    const updatedFiles = selectedFiles.filter((file) => file.name !== record.name)

    setselectedFiles(updatedFiles)

    setFilestoupload((current) =>
      current.filter((file) => file.name !== record.name)
    );

    const allSourcesSelected = updatedFiles.length == 0 ? false : updatedFiles.every(file => file.source);
    setAllowGenerate(allSourcesSelected);
    console.log(allSourcesSelected)
  }


  // console.log(selectedFiles)

  return (
    <>
      <Flex vertical gap={30}>
        <Form className='dropzone' style={{ minHeight: '160px' }}>
          <Dropzone
            // decrease the height of dropzone
            // accept only pdf and Excel files
            accept='.pdf, .txt, .doc, .docx, .ppt, .pptx'
            minSize={1000}
            maxSize={30000000}
            onDrop={(acceptedFiles, fileRejections) => {
              handleAcceptedFiles(acceptedFiles, fileRejections);
            }}
          >
            {({ getRootProps, getInputProps }) => (
              <div style={{ textAlign: 'center' }}>
                <div className='dz-message needsclick' {...getRootProps()}>
                  <input {...getInputProps()} />
                  <div className='mb-3'>
                    {/* <i className='display-4 text-muted mdi mdi-cloud-upload-outline'></i> */}
                    <UploadOutlined style={{ fontSize: "50px" }} />
                  </div>
                  <Flex vertical gap={5}>

                    <Text strong='true'> Drag & drop your files here or Browse</Text>
                    <Text className="font-col-999">You can select multiple files </Text>
                  </Flex>
                </div>
              </div>
            )}
          </Dropzone>
          {uploadLoading ? (
            <div className='text-center mt-4'>
              <button
                style={{ width: '11%', minWidth: '120px' }}
                type='button'
                className='btn btn-primary '
                disabled
              >
                <span
                  style={{ marginRight: '1em' }}
                  className='spinner-border spinner-border-sm mr-2'
                  role='status'
                  aria-hidden='true'
                ></span>
                {formatTime(timer)}
              </button>
              {uploading && <p> Uploading...</p>}
            </div>
          ) : null}

        </Form>
        {selectedFiles && selectedFiles.length > 0 &&
          <Flex vertical gap={5}>
            <Flex justify='space-between'>
              <Title className='font-col-666' level={5}>Files for Analysis</Title>
              <Text className='font-col-999'>Note: Select source each file to generate analysis</Text>
            </Flex>

            <Table
              dataSource={selectedFiles}
              columns={columns}
              rowKey='name'
              pagination={selectedFiles.length > 5 ? { pageSize: 5 } : false}
            />

          </Flex>
        }
        <Flex style={{ width: "100%" }} >
          <ParameterForm setAnaLysisName={setAnaLysisName} analysisName={analysisName} allowGenerate={allowGenerate} HandleFileSubmit={HandleFileSubmit} />
        </Flex>
      </Flex>

      {/* <div className='text-center mt-4'>
        <Button
          // type='button'
          // size='small'
          // className='btn btn-primary '
          onClick={() => HandleFileSubmit()}
          disabled={selectedFiles.length === 0 || uploadLoading}
        >
          Upload document
        </Button>
      </div> */}
    </>


  );
};

export default MultipleFileUpload;


const requiredFiles = [
  { name: "CRU Aluminium Market Outlook", formats: ["Excel", "PDF"] },
  { name: "CRU Aluminium Monitor", formats: ["Excel", "PDF"] },
  { name: "CRU Global Economic Outlook", formats: ["Excel", "PDF"] },
  { name: "Antaike Reports", formats: [] },
  { name: "Competition Data", formats: ["Excel"] }
];