import React, { useState, useEffect, useRef } from 'react';

const CommentPopup = ({ isOpen, onClose, onSubmit, position }) => {
  const [comment, setComment] = useState('');
  const inputRef = useRef(null);
  const popupRef = useRef(null);

  useEffect(() => {
    if (isOpen && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isOpen]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(comment);
    setComment('');
    onClose();
  };

  return (
    <div
      ref={popupRef}
      style={{
        position: 'absolute',
        left: `${position.x - 350}px`,
        top: `${position.y - 280}px`,
        zIndex: 10000,
        overflow: 'visible',
      }}
    >
      <form
        onSubmit={handleSubmit}
        className='comment-popup d-flex flex-column py-3'
      >
        <input
          ref={inputRef}
          type='text'
          value={comment}
          className='form-control mb-2 pb-5'
          onChange={(e) => setComment(e.target.value)}
          placeholder='Add your comment...'
        />
        <div>
          <button className='btn btn-sm btn-primary' type='submit'>
            Submit
          </button>
          <button
            className='btn btn-sm btn-primary'
            type='button'
            onClick={onClose}
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
};

export default CommentPopup;
