import React, { useState } from "react";
import { Navigate, Route, useLocation } from "react-router-dom";
// import { useProfile } from "../Hooks/UserHooks";
import { useAuth0 } from "@auth0/auth0-react";
const AuthProtected = (props) => {
  const {
    isAuthenticated,
    loginWithRedirect,
    isLoading,
    user,
    error,
    getIdTokenClaims,
    getAccessTokenSilently,
  } = useAuth0();
  let userObject = user;
  const [foruser, setforuser] = useState(false);
  const location = useLocation();
  const forToken = (props) => {
    // wait for the promise to resolve and assaign result to token
    getAccessTokenSilently().then((res) => {
      getIdTokenClaims().then((res) => {
        setforuser(res);
      });
    });

    if (!foruser) {
      return <>Loading...</>;
    }
    if (foruser.email_verified) {
      return <>{props.children}</>;
    } else {
      return <Navigate to="/verify-email" />;
    }
  };

  if (isLoading) {
    return <>Loading...</>;
  }
  if (error) {
    return <>Error...{error.message}</>;
  }
  const initialUrl = sessionStorage.getItem("initialUrl");
  if (!isAuthenticated && userObject === undefined) {
    <Navigate to={loginWithRedirect()} />;
  } else if (isAuthenticated && userObject?.email_verified) {
    // Redirect to initial URL if present
    if (
      initialUrl &&
      initialUrl !== location.pathname &&
      !initialUrl.includes("_used")
    ) {
      const usedInitialUrl = `${initialUrl}_used`; // Append "_used" to the initial URL
      sessionStorage.setItem("initialUrl", usedInitialUrl); // Update the session storage with the modified value
      return <Navigate to={initialUrl} />;
    }
    return <>{props.children}</>;
  } else if (isAuthenticated && !userObject?.email_verified) {
    return <>{forToken(props)}</>;
  }
};

const AccessRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        return (
          <>
            {" "}
            <Component {...props} />{" "}
          </>
        );
      }}
    />
  );
};

export { AuthProtected, AccessRoute };
