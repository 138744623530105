import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
    Dropdown as DropdownStrap,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
} from 'reactstrap';
import { changeCurrentApp, changeChatQuestions, appDispayHandler, loginSuccess } from '../../../../../store/actions';
import { DownOutlined } from '@ant-design/icons';
import { Button, Dropdown, Space, DatePicker, ConfigProvider, Input } from 'antd';
import "./style/style.css"




const { RangePicker } = DatePicker;

const Header = ({ files,setSorter,setFiles,sorter,onSearchChange,handleSortBySimilarity,handleSortByDate, onSourceChange, sources, onSorterChange, onRangePickerChange }) => {
    const dispatch = useDispatch();
    const appDisplay = useSelector((state) => state.PageData.appDisplay);
    const groups = useSelector((state) => state.PageData.groups);
    const currentApp = useSelector((state) => state.PageData.currentApp);
    const [btnsuccess1, setBtnsuccess1] = useState(false);
    const [search, setSearch] = useState('');

    const items = sources?.map((item, i) => {
        const val = {
            label: <a onClick={() => { console.log("selected", item); onSourceChange(item) }}>{item}</a>,
            key: i,
        }
        return val;
    })






    const items2 = [
        {
            label: <a onClick={(e) => handleSortBySimilarity(e)}>Relevance</a>,
            key: '0',
        },
        {
            label: <a onClick={(e) => handleSortByDate(e)}>Date</a>,
            key: '1',
        },
        // {
        //     type: 'divider',
        // },

    ];
    useEffect(() => {

        if (sources?.length > 1) {
            items2.push({
                label: <a onClick={() => onSorterChange("source")}>Sort by source</a>,
                key: '3',
            });
        }
    })



    // console.log(onSearchChange);
    return (
        <div style={{ padding: "1em 0em .3em 0em", borderBottom: "1px solid #ccc", background: "white" }}>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <form
                    onSubmit={(e) => { e.preventDefault(); onSearchChange(search); setSearch(''); }}
                    autoComplete="off"
                    style={{ height: '48px', display: 'flex', alignItems: 'center', backgroundColor: 'transparent', border: '1px solid #cccccc', borderRadius: '30px', width: '65%', maxWidth: '1000px', }}
                >
                    <i style={{ padding: '10px', fontSize: '20px', color: '#aaa', cursor: 'pointer', }} onClick={(e) => { }} className="bx bx-search" />
                    <input
                        className='input-placeholder'
                        style={{ width: '100%', height: '100%', border: 'none', borderRadius: '25px', color: '#666', backgroundColor: 'transparent', fontSize: '14px', }}
                        type="text" placeholder={'Ask any business question...'}
                        name="search" value={search} onChange={(e) => setSearch(e.target.value)} />
                    {search && (
                        <i style={{ padding: '15px', fontSize: '20px', color: '#aaa', cursor: 'pointer', }}
                            onClick={() => setSearch('')} className="bx bx-x" />
                    )}
                </form>
                <DropdownStrap
                    isOpen={btnsuccess1}
                    toggle={() => setBtnsuccess1(!btnsuccess1)}
                    style={{ position: 'absolute', right: '21px', top: '19px' }} drop="start">
                    <DropdownToggle
                        style={{
                            backgroundColor: btnsuccess1 ? '#f4f4f4' : '',
                            fontSize: appDisplay === 'ATL Marketing Intelligence' ? '0.80rem' : '1rem',
                            whiteSpace: 'nowrap',
                        }}
                        tag="a" className="btn">
                        {appDisplay} <i className="mdi mdi-chevron-down" />
                    </DropdownToggle>
                    <DropdownMenu>
                        {groups.map((item, i) => (
                            <Link
                                key={i}
                                onClick={() => {
                                    dispatch(appDispayHandler(item.group_name));
                                    dispatch(changeCurrentApp(item));
                                    setBtnsuccess1(!btnsuccess1);
                                    if (currentApp !== item) { dispatch(changeChatQuestions('')); }
                                }}
                                to={item?.group_name ? `/app/insights/${item?.group_name?.toLowerCase()?.replaceAll(' ', '-')}` : '/'}>
                                <DropdownItem style={{ fontSize: '.8rem !important' }}>
                                    {item?.group_name}
                                </DropdownItem>
                            </Link>
                        ))}
                    </DropdownMenu>
                </DropdownStrap>
            </div>
            {sources &&
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
                    <div style={{ width: '65%', maxWidth: '1000px', padding: "0px 5px", justifyContent: "space-between", display: "flex", alignItems: "center" }}>
                        {/* <div style={{ display: "flex", justifyContent:'space-around', alignItems: "center" }}> */}
                            {items && items.length > 0 && <Dropdown menu={{ items }} trigger={['click']}>
                                <a onClick={(e) => { e.preventDefault(); console.log(e) }} style={{ fontSize: '14px', cursor: "pointer !important" }}>
                                    <Button type='text'>
                                        Source<DownOutlined />
                                    </Button>
                                </a>
                            </Dropdown>}
                            <ConfigProvider theme={{ token: { colorPrimary: 'black', }, }}>
                                <RangePicker variant="borderless" onChange={(dates, dateStrings) => onRangePickerChange(dates, dateStrings)} />
                            </ConfigProvider>
                        {/* </div> */}
                        <div style={{ display: "flex", gap: "15px" }}>
                            {/* <div>dive deep</div> */}
                            <Dropdown
                                style={{ fontSize: '14px', cursor: "pointer" }}
                                menu={{ items: items2 }}  // Ensure this structure is correct
                                trigger={['click']}
                            >
                                <a onClick={(e) => { e.preventDefault(); console.log(e) }}>
                                    <Button type='text'>
                                        {sorter !== null ? sorter : "Sort by" }
                                        {sorter !== null && <i onClick={(e) => {return setSorter(null),setFiles(files)}} className='fa fa-times'></i>}
                                        <DownOutlined />
                                    </Button>
                                </a>
                            </Dropdown>
                        </div>
                    </div>
                </div>}
        </div>
    );
};

export default Header;
