export const parseJobDescriptionHTML = (
  htmlContent,
  generatedJobDescription
) => {
  // Create a DOM parser
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlContent, "text/html");

  // Helper function to get text content of an element by heading text
  const getTextContentAfterHeading = (headingText) => {
    const headings = doc?.getElementsByTagName("h3");
    for (let h of headings) {
      if (h?.textContent?.trim() === headingText) {
        const nextElement = h?.nextElementSibling;
        return nextElement ? nextElement?.textContent?.trim() : "";
      }
    }
    return "";
  };

  // Helper function to parse table data
  const parseTable = (headingText) => {
    const headings = doc?.getElementsByTagName("h3");
    for (let h of headings) {
      if (h?.textContent?.trim() === headingText) {
        const table = h?.nextElementSibling;
        if (table && table?.tagName === "TABLE") {
          const rows = table?.querySelectorAll("tr");
          return Array?.from(rows)?.map((row) => {
            const cells = row?.querySelectorAll("td");
            return Array?.from(cells)?.map((cell) => cell?.textContent?.trim());
          });
        }
      }
    }
    return [];
  };

  // Generator function for sequential ID mapping
  function* sequentialIdGenerator(arr, idField) {
    for (const item of arr) {
      yield item[idField]; // Yield the specified idField (jd_kra_id or jd_relationship_id)
    }
  }

  // Parse job purpose
  const jobPurpose = getTextContentAfterHeading("Job Purpose");

  // Parse job context
  const jobContext = getTextContentAfterHeading(
    "Job Context & Major Challenges"
  );

  // Create a generator for jd_kra_id
  const kraIdGenerator = sequentialIdGenerator(
    generatedJobDescription?.kra || [],
    "jd_kra_id"
  );

  // Parse KRAs (Key Result Areas) and map ids sequentially using the generator
  const kraTable = parseTable("Key Result Areas/Accountabilities");
  const kra = kraTable?.map(([kra, action]) => ({
    kra,
    action,
    jd_kra_id: String(kraIdGenerator.next().value) || "", // Use generator to get jd_kra_id
  }));

  // Parse reportees
  const reporteesTable = parseTable("Job Purpose of Direct Reports");
  const reportees = reporteesTable?.map(
    ([position_title, job_purpose, position_num]) => ({
      position_title,
      job_purpose,
      position_num,
    })
  );

  // Parse dimensions
  const dimensionsText = getTextContentAfterHeading("Dimensions");
  const dimensions = dimensionsText?.split("\n")?.filter(Boolean);
  const Jd_dimensions = {
    business_revenue: dimensions[0] || "",
    business_number: dimensions[1] || "",
    unit_number: dimensions[2] || "",
    function_number: dimensions[3] || "",
    department_number: dimensions[4] || "",
    others: dimensions?.slice(5)?.join(", "),
  };

  // Create a generator for jd_relationship_id
  const relationshipIdGenerator = sequentialIdGenerator(
    generatedJobDescription?.relationships || [],
    "jd_relationship_id"
  );

  // Parse relationships and map ids sequentially using the generator
  const relationshipTable = parseTable("Relationships");
  const relationships = relationshipTable?.map(([type, frequency]) => ({
    type,
    frequency,
    jd_relationship_id: String(relationshipIdGenerator.next().value) || "", // Use generator to get jd_relationship_id
  }));

  return {
    job_purpose: jobPurpose,
    job_context: jobContext,
    kra,
    Jd_reportees: reportees,
    Jd_dimensions: Jd_dimensions,
    Jd_relationships: relationships,
  };
};
