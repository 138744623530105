import React, { useEffect, useState } from 'react';
import { Collapse, Skeleton, Tooltip } from 'antd';
import { Flex, Input } from 'antd';
// import { stepData } from '../../data';
import Markdown from 'react-markdown';
import AccordionItem from './AccordionItem';
import { useNotification } from '../NotificationProvider';
import "../../../style/style.css";
import useDatacallswithtoken from '../../../../../Data Apis/apifunctions';
import GradientBtn from './Elements/GradientBtn';
const { TextArea } = Input;


const Step2 = ({ step2Data, analysisId, saveChanges }) => {
    console.log(step2Data, analysisId)
    const { useForRegenerateAIResponse } = useDatacallswithtoken()
    const [stepData, setStepData] = useState(step2Data);
    const [promptsSaved, setPromptsSaved] = useState({});
    const [sessionFlag, setSessionFlag] = useState(false);
    const notification = useNotification();
    const [loadingRegenerate, setLoadingRegenerate] = useState(false)

    const [aiGeneratedRed, setAiGeneratedRes] = useState(stepData?.topic_stage_data[0]?.output)


    useEffect(() => {
        saveChanges(promptsSaved)
    }, [promptsSaved])




    const handleAiTextArea = (e) => {
        console.log(e.target.value)
        setAiGeneratedRes(e.target.value)
    }

    const handeRegenerate = () => {
        if (!Object.values(promptsSaved).every(value => value === true)) {
            notification.warning({
                message: 'Save all the prompts to regenerate response',
                // description: '',
                placement: 'topRight',
            })

        }
        else {

            const data = {
                analysisStageId: stepData?.topic_stage_data[0]?.analysis_stages_id,
                sessionFlag: sessionFlag
            }
            RegenateAiResponse(data)

        }

    }

    const RegenateAiResponse = (data) => {
        setLoadingRegenerate(true)
        useForRegenerateAIResponse(data).then((res) => {
            setLoadingRegenerate(false)
            console.log(res)
            setAiGeneratedRes(res.output)
            notification.success({
                message: 'Answer is successfully regenerated',
                // description: '',
                placement: 'topRight',
            });
        }).catch(err => {
            setLoadingRegenerate(false)
            notification.error({
                message: 'Something went wrong',
                description: err.message,
                placement: 'topRight',
            })

        })

    }



    console.log(stepData?.topic_stage_data[0])
    return (
        <Flex className='w100-h100' vertical gap={20} align='start'>

            {stepData?.topic_stage_data[0]?.prompt &&
                <AccordionItem
                    question={"System Prompt"}
                    prompt={stepData?.topic_stage_data[0]?.prompt}
                    topicObj={stepData?.topic_stage_data[0]}
                    analysisId={analysisId}
                    setPromptsSaved={setPromptsSaved}



                />
            }

            <AccordionItem
                question={"Session Prompt"}
                sessionPrompt={stepData?.topic_stage_data[0]?.session_prompt}
                topicObj={stepData?.topic_stage_data[0]}
                analysisId={analysisId}
                setPromptsSaved={setPromptsSaved}
                setSessionFlag={setSessionFlag}
            />
            {stepData?.topic_stage_data[0].example &&
                <AccordionItem
                    question={"Examples"}
                    example={stepData?.topic_stage_data[0]?.prompt}
                    setPromptsSaved={setPromptsSaved}
                />
            }

            {/* <TextArea
                // className='editable-text-area'
                autoSize
                allowClear
                // style={{padding:"5px 10px"}}
                value={aiGeneratedRed}
                // disabled={!isEditing}
                onChange={handleAiTextArea}
            /> */}



            <Flex style={{ alignSelf: "center" }}>


                <GradientBtn onBtnClick={() => handeRegenerate()} name={"Regenerate"} disable={false} />
            </Flex>


            <Flex className='p20 br-ra15-sld1-e6e6 w100 ' vertical >

                {loadingRegenerate ? <Skeleton title={false} paragraph={{ rows: 2 }} /> : aiGeneratedRed && <Markdown>{aiGeneratedRed}</Markdown>}
                {/* {aiGeneratedRed} */}
            </Flex>



        </Flex>
    );
};

export default Step2;
