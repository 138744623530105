import { DownloadOutlined, SaveOutlined } from "@ant-design/icons";
import { FloatButton } from "antd";
import React from "react";

const FloatButtons = () => {
  return (
    <div>
      {" "}
      <FloatButton
        icon={<SaveOutlined />}
        description="Save"
        shape="square"
        style={{
          insetInlineEnd: 24,
          color: "white",
          backgroundColor: "black",
        }}
      />
      <FloatButton
        icon={<DownloadOutlined />}
        description="Submit For Approval"
        shape="square"
        style={{
          insetInlineEnd: 94,
          color: "white",
          backgroundColor: "black",
        }}
      />
      <FloatButton
        icon={<DownloadOutlined />}
        description="Download"
        shape="square"
        style={{
          insetInlineEnd: 164,
          color: "white",
          backgroundColor: "black",
        }}
      />
    </div>
  );
};
export default FloatButtons;
