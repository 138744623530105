import React, { useEffect, useState , useMemo, useCallback} from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { ResponsiveBar } from '@nivo/bar'
import UserPanel from "./components/userpanel/UserPanel"
import Notifications from './components/Notifications'
import OrderStatus from './components/OrderStatus'
import { Card, Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Row, Spinner } from 'reactstrap'
import QuestionCard from './components/QuestionsCard';
import {changeFeatureList,changeTrailExpiredMessage,} from '../../store/actions';
import useDatacallswithtoken from '../../Data Apis/apifunctions';
import './index.css'

const customColors = [
  "#163771", "#0FB5AE", "#147AF3", "#7326D3", "#E8C600", 
  "#CB5D00", "#008F5D", "#BCE931"
];

const AdminDashboard = () => { 
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { getAccessTokenSilently, user } = useAuth0()
  const { useForFeatureList } = useDatacallswithtoken();
  const featureList = useSelector((state) => state.PageData.featureList);
  const [dashBoardWeeklyQuestions, setDashBoardWeeklyQuestions] = useState([])
  const [sampleQuestions, setSampleQuestions] = useState([])
  const [projectsData, setProjectsData] = useState([])
  const [topUsersData, setTopUsersData] = useState([])
  const [questionByGroup, setQuestionByGroup] = useState([])
  const [chartData, setChartData] = useState([])
  const [totalSize, setTotalSize] = useState(null)
  const [totalSizeByGroup, setTotalSizeByGroup] = useState([])
  const [isPageLoading, setIsPageLoading] = useState(false)
  const [dashboardRows, setDashboardRows] = useState(null)
  const [rowsChartData, setRowsChartData] = useState([])
  const [toggleDropdown, settoggleDropdown] = useState(false)
  const [selectedOrg, setSelectedOrg] = useState(null);
  const [stackbarData, setstackbarData] = useState([])
  const [stackBarKeys, setstackBarKeys] = useState([])
    

    const GetFeatureList = () => {
    useForFeatureList().then((res) => {
      if (typeof res === 'object') {
        dispatch(changeFeatureList(res));
      } else {
        dispatch(changeTrailExpiredMessage(res));
      }
    });
  };

    useEffect(() => {
    if (featureList?.length === 0) {
      GetFeatureList();
    }
    // if "FS" not in featureList, then navigate to subscribe
    else if (!featureList.find((item) => item.code === 'AD')) {
      navigate('/app/subscribe', { replace: true });
    }
  }, [featureList]);

const filterDataByOrg = useCallback((data) => {
    if (!selectedOrg) return data;
    return data.filter(item => item.org_name === selectedOrg);
  }, [selectedOrg]);

  const getShortName = useCallback((orgName) => {
    switch(orgName){
      case "Hindalco": return "HIL";
      case "Blue Star India": return "BSI";
      case "Apollo Tyres": return "ATL";
      default: return "ACT";
    }
  }, []);



const transformDataForStackedBar = useCallback((data) => {
  if (!data || !data.length) {
    console.error("Invalid data structure:", data);
    return { transformedData: [], groups: [] };
  }

  const allWeeks = new Set();
  const groupSet = new Set();

   const allOriginalDates = new Set();
  data.forEach(org => {
    org.groups.forEach(group => {
      group.weekly_questions.forEach(week => {
        allOriginalDates.add(week.week_ending_date);
      });
    });
  });

  // First pass: collect weeks and groups
  data.forEach(org => {
    org.groups.forEach(group => {
      const groupKey = data.length > 1 ? `${getShortName(org.org_name)}-${group.group_name}` : `${group.group_name}`;
      groupSet.add(groupKey);
      group.weekly_questions.forEach(week => {
        allWeeks.add(week.week_ending_date);
      });
    });
  });

  const sortedWeeks = Array.from(allWeeks).sort((a, b) => new Date(a) - new Date(b));
  const groups = Array.from(groupSet);

  const transformedData = sortedWeeks.map(week => {
    const weekData = { week: week };
    const groupValues = {};

    groups.forEach(groupKey => {
      groupValues[groupKey] = 0;
    });


    data.forEach(org => {
      org.groups.forEach(group => {
        const groupKey = data.length > 1 ? `${getShortName(org.org_name)}-${group.group_name}` : `${group.group_name}`;
        const weekQuestion = group.weekly_questions.find(w => w.week_ending_date === week);
        if (weekQuestion) {
          groupValues[groupKey] = parseInt(weekQuestion.question_count) || 0;
        }
      });
    });

    // Sort groups for this specific week
    const sortedGroups = Object.entries(groupValues)
      .sort(([, a], [, b]) => b - a)
      .map(([key]) => key);

    // Assign values to weekData in sorted order
    sortedGroups.forEach(group => {
      weekData[group] = groupValues[group];
    });

    return weekData;
  });

  // Calculate overall group order for legend
  const groupTotals = groups.reduce((acc, group) => {
    acc[group] = transformedData.reduce((sum, week) => sum + (week[group] || 0), 0);
    return acc;
  }, {});

  const sortedGroups = groups.sort((a, b) => groupTotals[b] - groupTotals[a]);

  return { transformedData, groups: sortedGroups };

},[]);


  const getTextColor = useCallback((backgroundColor) => {
  const hex = backgroundColor.replace('#', '');
  const r = parseInt(hex.substr(0, 2), 16);
  const g = parseInt(hex.substr(2, 2), 16);
  const b = parseInt(hex.substr(4, 2), 16);
  const brightness = (r * 299 + g * 587 + b * 114) / 1000;
  return brightness > 128 ? '#333333' : '#ffffff';
},[])

const  transformData = useCallback((rawData) => {
  let result;
  if (rawData.length > 1) {
    result = rawData.flatMap(org => 
      org.groups.map(group => {
        const questions = group.users.reduce((acc, user) => acc + user.question_count, 0);
        return {
          group: `${getShortName(org.org_name)}-${group.group_name}`,
          questions: questions
        };
      })
    );
  } else {
    result = rawData.flatMap(org => 
      org.groups.map(group => ({
        group: `${group.group_name}`,
        questions: group.users.reduce((acc, user) => acc + user.question_count, 0)
      }))
    );
  }
  
  // Sort the result array in descending order based on the number of questions
  return result.sort((a, b) =>  a.questions - b.questions );
},[getShortName])

  const getKeys = useCallback((data) => {
    const allKeys = new Set();
    data.forEach(item => {
      Object.keys(item).forEach(key => {
        if (key !== 'group') allKeys.add(key);
      });
    });
    return Array.from(allKeys);
  },[]);

const cumulativeData = stackbarData.map((weekData, index, array) => {
    const total = stackBarKeys.reduce((sum, key) => {
      return sum + array.slice(0, index + 1).reduce((weekSum, week) => weekSum + (week[key] || 0), 0);
    }, 0);
    return {
      week: weekData.week,
      total: total
    };
  });


  const commonProps = {
    margin: { top: 20, right: 50, bottom: 40, left: 60 },
    padding: 0.2,
    labelSkipWidth: 16,
    labelSkipHeight: 16,
    labelTextColor: { from: 'color', modifiers: [['darker', 1.6]] },
    axisBottom: {
      tickSize: 5,
      tickPadding: 5,
      tickRotation: -45,
      legend: 'Number',
      legendPosition: 'middle',
      legendOffset: 36,
    },
  };

  const fetchData = useCallback(async (endpoint, setter) => {
    try {
      const options = {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${await getAccessTokenSilently()}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({userId: user?.sub})
      };
      const response = await fetch(`${process.env.REACT_APP_AUTH0_URL}/${endpoint}`, options);
      const data = await response.json();
      setter(data);
    } catch (error) {
      console.error(`Error fetching data from ${endpoint}:`, error);
    }
  },[getAccessTokenSilently, user]);

  useEffect(() => {
    const fetchAllData = async () => {
      setIsPageLoading(true);
      try {
        await Promise.all([
          fetchData('getDashboardRows', (data) => {
            setDashboardRows(data);
            setRowsChartData(()=>{
              let result
              if(data.org_row_counts.length > 1){
                result = data.org_row_counts.flatMap(org => 
                  org.groups.map(group => ({
                    group: `${getShortName(org.org_name)}-${group.groupName}`,
                    count: group.value
                  }))
              );
              }else{
                result =  data.org_row_counts.flatMap(org => 
                  org.groups.map(group => ({
                    group: `${group.groupName}`,
                    count: group.value
                  }))
              );
              }
              return result.sort((a, b) =>  a.count - b.count );
              
            })
          }),
          fetchData('getDashboardClient', (data) => {
            setProjectsData(data.data.organisation);
            setQuestionByGroup(transformData(data.data.organisation));
            const chartData = data.data.organisation.flatMap(org => {
              let result
              if(data.data.organisation.length > 1){
                result = org.groups.map(group => ({
                group: `${getShortName(org.org_name)}-${group.group_name}`,
                users: group.user_count
              }))
              }else{
                result = org.groups.map(group => ({
                  group: `${group.group_name}`,
                  users: group.user_count
                }))
              }
              return result.sort((a, b) =>  a.users - b.users );
            }
            )
            setChartData(chartData);
          }),
          fetchData('getDocumentSize', (data) => {
            setTotalSizeByGroup(()=> {
              let result
              if(data?.org_folder_sizes?.length > 1){
                  result =  data?.org_folder_sizes?.flatMap(org => 
                  org.groups.map(group => ({
                      group: `${getShortName(org.org_name)}-${group.groupName}`,
                      size: group.value.toFixed(2),
                  }))
              );
              }else{
                 result =  data?.org_folder_sizes.flatMap(org => 
                  org.groups.map(group => ({
                      group: `${group.groupName}`,
                      size: group.value.toFixed(2),
                  }))
              );
              }
               return result.sort((a, b) =>  a.size - b.size );
            })
            setTotalSize(data);
          }),
          fetchData('getDashboardWeeklyQuestions', (data) => {
              setDashBoardWeeklyQuestions(data.data);
              const {transformedData,groups} = transformDataForStackedBar(data.data)
              setstackbarData(transformedData)
              setstackBarKeys(groups)
            }),
          fetchData('getDashboardTopUsers', (data)=> {
            setTopUsersData(data)
          }),
          fetchData('getDashboardQuestions', (data) => {
            setSampleQuestions(data.data)
      })
        ]);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsPageLoading(false);
      }
    };

    fetchAllData();
  }, []);

   useEffect(() => {
      const processData = () => {
        const filteredData = filterDataByOrg(projectsData);
        setQuestionByGroup(transformData(filteredData));

        // Process chart data (users)
        const processUsersData = (data) => {
          return data.flatMap(org => {
            const prefix = data.length > 1 ? `${getShortName(org.org_name)}-` : '';
            return org.groups.map(group => ({
              group: `${prefix}${group.group_name}`,
              users: group.user_count
            }));
          }).sort((a, b) => a.users - b.users);
        };
        setChartData(processUsersData(filteredData));

        // Process weekly questions data
        if (dashBoardWeeklyQuestions.length > 0) {
          const filteredWeeklyQuestions = filterDataByOrg(dashBoardWeeklyQuestions);
          const { transformedData, groups } = transformDataForStackedBar(filteredWeeklyQuestions);
          setstackbarData(transformedData);
          setstackBarKeys(groups);
        }

        // Process total size data
        const processSizeData = (data) => {
          return data.flatMap(org => {
            const prefix = data.length > 1 ? `${getShortName(org.org_name)}-` : '';
            return org.groups.map(group => ({
              group: `${prefix}${group.groupName}`,
              size: parseFloat(group.value.toFixed(2))
            }));
          }).sort((a, b) => a.size - b.size);
        };
        if (totalSize !== null) {
          const filteredFileSizeData = filterDataByOrg(totalSize.org_folder_sizes);
          setTotalSizeByGroup(processSizeData(filteredFileSizeData));
        }

        // Process rows chart data
        const processRowsData = (data) => {
          return data.flatMap(org => {
            const prefix = data.length > 1 ? `${getShortName(org.org_name)}-` : '';
            return org.groups.map(group => ({
              group: `${prefix}${group.groupName}`,
              count: group.value
            }));
          }).sort((a, b) => a.count - b.count);
        };
        if (dashboardRows !== null) {
          const filteredRowsData = filterDataByOrg(dashboardRows.org_row_counts);
          setRowsChartData(processRowsData(filteredRowsData));
        }
      };
    processData();
  }, [selectedOrg, projectsData]);



const renderResponsiveBar = useMemo(() => (data, keys, indexBy, additionalProps = {}) => {
  
  const isStacked = keys.length > 1;

   const formatValue = (value) => {
    if(keys[0] === "size"){
      return value.toFixed(1)
    }
    if (value >= 1000) {
      return `${(value / 1000).toFixed(1)}k`;
    }
    return value;
  };
const formatAxisLabel = (value) => {
    // Split the value by spaces
    const splitedStringArray = value.split(' ');
    
    // Process each word or hyphenated phrase
    return splitedStringArray.flatMap(part => {
      if (part.includes('-')) {
        // If the part contains a hyphen, split it further
        return part.split('-');
      }
      // If no hyphen, return the part as is
      return part
    });
  };

  const mergedProps = {
    ...commonProps,
    ...additionalProps,
    axisLeft:{
    tickPadding:5,
    tickSize:5,
    format: formatAxisLabel,
    renderTick: ({ value, x, y, tickWidth }) => {
          const parts = formatAxisLabel(value);
          return (
            <g transform={`translate(${x},${y})`}>
          {parts.map((part, i) => (
            <text
              key={i}
              textAnchor="end"
              dominantBaseline="middle"
              style={{ 
                fill: 'currentColor',
                fontSize: filterDataByOrg(projectsData).length > 1 ? "0.6rem" : '0.6rem',
                fontWeight:'bold'
              }}
              x={-tickWidth -1000}  // Moved text slightly to the left
              y={i * 12 - (parts.length - 1) * 6}
            >
              {part}&nbsp;&nbsp;
            </text>
          ))}
        </g>
          );
        },
},
    axisBottom: {
      tickValues: 2,
      format: value => {
        if (value >= 1000) {
          return `${(value / 1000).toFixed(0)}k`;
        }
        return value.toFixed(0);
      },
      legend: additionalProps.axisBottom?.legend || 'Number',
      legendPosition: 'middle',
      legendOffset: 36,
      ...additionalProps.axisBottom
    },
    labelTextColor: '#ffffff',
    enableLabel: false,
    enableTotals: true,
     valueFormat: formatValue,
    labelOffset: 0,
    tooltip: ({ id, value, color, indexValue }) => (
      <p className='p-1' style={{ 
        color: '#000', 
        backgroundColor: '#fff', 
        borderRadius: '4px',
        boxShadow: '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)', 
        textTransform: "capitalize", 
      }}>
        {indexValue}: {formatValue(value)}
      </p>
    ),
    legends: isStacked ? [
      {
        dataFrom: 'keys',
        anchor: 'bottom-right',
        direction: 'column',
        justify: false,
        translateX: 120,
        translateY: 0,
        itemsSpacing: 2,
        itemWidth: 100,
        itemHeight: 20,
        itemDirection: 'left-to-right',
        itemOpacity: 0.85,
        symbolSize: 20,
        effects: [
          {
            on: 'hover',
            style: {
              itemOpacity: 1
            }
          }
        ]
      }
    ] : []
  };

  // Check if data is empty
  if (!data || data.length === 0) {
    return (
      <div style={{ 
        height: '400px', 
        width: '100%', 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center',
        backgroundColor: '#f5f5f5',
        border: '1px solid #ddd',
        borderRadius: '4px'
      }}>
        <p style={{ 
          fontSize: '18px', 
          color: '#666',
          textAlign: 'center',
          textTransform:'capitalize'
        }}>
          {selectedOrg 
            ? `No data available for ${selectedOrg}`
            : 'No data available'}
        </p>
      </div>
    );
  }

  return (
    <div style={{ height: '400px', width: '95%' }}>
      <ResponsiveBar
        data={data}
        keys={keys}
         padding={0.1} // Adjust this value to increase/decrease space between groups
        indexBy={indexBy}
        layout="horizontal"
        colors={isStacked 
          ? ({ id }) => customColors[keys.indexOf(id) % customColors.length]
          : ({ index }) => customColors[0]
        }
        colorBy={isStacked ? "id" : "index"}
        labelTextColor={({ color }) => getTextColor(color)}
        label={d => `${d.value}`}
        enableLabel={true}
        axisLeft={null}
        {...mergedProps}
      />
    </div>
  );
}, [filterDataByOrg, projectsData]);


  if (isPageLoading) {
    return (
      <div className='page-content'> 
        <Spinner style={{position: "absolute",top: "50%",left: "50%",}} animation="border" variant="primary" />
      </div>
    );
  }

  return (
    <div className='page-content'>
      {projectsData.length > 1 && <div className='mb-3 d-flex justify-content-start align-items-center'>
              <Dropdown isOpen={toggleDropdown} toggle={() => settoggleDropdown(!toggleDropdown)}>
                <DropdownToggle
                  style={{
                    fontSize: "1.2em",
                    textTransform:'capitalize', 
                    // backgroundColor: '#163771',
                    color: 'white',
                    transition: 'background-color 0.3s ease',
                    position: 'relative', 
                  }}
                  tag="button"
                  className="btn btn-primary"
                >
                  {selectedOrg ? selectedOrg : "Show organisations"} <i className="mdi mdi-chevron-down" />
                </DropdownToggle>
                <DropdownMenu>
                  {projectsData.map((item, i) => (
                    <DropdownItem 
                      style={{textTransform:'capitalize'}} 
                      key={i}
                      onClick={() => setSelectedOrg(item.org_name)}
                    >
                      {item.org_name}
                    </DropdownItem>
                  ))}
                  <DropdownItem onClick={()=> setSelectedOrg(null)}> Show all </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </div>
       }
      <UserPanel data={filterDataByOrg(projectsData)} TotalSize={filterDataByOrg(totalSize?.org_folder_sizes)} maxSize={totalSize?.total_size} rowsData={filterDataByOrg(dashboardRows?.org_row_counts)} maxRows={dashboardRows?.total_size} />
      <Row>
        <Col lg={6} xl={3}>
          <Card body>
            <h6>Rows by project</h6>
            {renderResponsiveBar(rowsChartData, ['count'], 'group', { axisBottom: { legend: 'Number of rows' } })}
          </Card>
        </Col>
        <Col lg={6} xl={3}>
          <Card body>
            <h6>File size by project</h6>
            {renderResponsiveBar(totalSizeByGroup, ['size'], 'group', {
                label: d => `${d.value} GB`,
                axisBottom: { legend: 'Size (GB)' }
              })}
          </Card>
        </Col>
        <Col lg={6} xl={3}>
          <Card body>
            <h6>Questions by project</h6>
            {renderResponsiveBar(questionByGroup, getKeys(questionByGroup), 'group', { axisBottom: { legend: 'Number of questions' } })}
          </Card>
        </Col>
        <Col lg={6} xl={3}>
          <Card body>
            <h6>Users by project</h6>
            {renderResponsiveBar(chartData, ['users'], 'group', { axisBottom: { legend: 'Number of users' } })}
          </Card>
        </Col>
      </Row>
      <Card body>
        <h5>Weekly Questions</h5>
        {projectsData.length > 1 && selectedOrg === null ? <div style={{ height: '500px', width: '100%' }}>
        <ResponsiveBar
          data={stackbarData}
          keys={stackBarKeys}
          indexBy="week"
          colors={(bar) => {
            // Get all the keys (stacks) for this specific bar
            const keysForThisBar = Object.keys(bar.data).filter(key => key !== 'week' && bar.data[key] > 0);
            // Find the index of this bar's key within the keys for this specific bar
            const indexWithinBar = keysForThisBar.indexOf(bar.id);
            // Use this index to cycle through the customColors array
            return customColors[indexWithinBar % customColors.length];
          }}
          label={d => d.value > 0 ? `${d.value}` : ''}
          enableLabel={true}
          labelTextColor={"#ffffff"}
          margin={{ top: 50, right: 10, bottom: 100, left: 60 }}
          padding={0.2}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'Week Ending Date',
            legendPosition: 'middle',
            legendOffset: 80,
            format: (value) => value.split('T')[0],
            tickValues: stackbarData.filter((_, index) => index % 3 === 0).map(d => d.week),
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'Total Questions',
            legendPosition: 'middle',
            legendOffset: -50,
            tickValues:4
          }}
          labelSkipWidth={12}
          labelSkipHeight={12}
          animate={false}
          tooltip={({ id, value, color, indexValue }) => (
            <p className='p-1' style={{ color:'#000', backgroundColor:'#fff', borderRadius: '4px',
              boxShadow: '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)', textTransform: "capitalize", }}>
              <span style={{ textTransform: 'capitalize' }}>{id}-{value}</span>
            </p>
          )}
        />
        </div> : <div style={{ height: '500px', width: '100%' }}>
      <ResponsiveBar
        data={cumulativeData}
        keys={['total']}
        indexBy="week"
        colors={customColors[0]} // Using the first color from customColors
        label={d => `${d.value}`}
        labelTextColor={"#ffffff"}
        margin={{ top: 50, right: 10, bottom: 100, left: 60 }}
        padding={0.2}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: 'Week Ending Date',
          legendPosition: 'middle',
          legendOffset: 80,
          format: (value) => value.split('T')[0],
          tickValues: cumulativeData.filter((_, index) => index % 3 === 0).map(d => d.week),
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: 'Cumulative Total Questions',
          legendPosition: 'middle',
          legendOffset: -50
        }}
        labelSkipWidth={12}
        labelSkipHeight={12}
        animate={false}
        enableLabel={false}
        enableTotals={true}
        tooltip={({ id, value, indexValue }) => (
          <p className='p-1' style={{ 
            color: '#000', 
            backgroundColor: '#fff', 
            borderRadius: '4px',
            boxShadow: '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)', 
            textTransform: "capitalize" 
          }}>
            <span>{indexValue.split('T')[0]}: {value}</span>
          </p>
        )}
      />
    </div>
 }
        
      </Card>

      {projectsData.length > 1 && selectedOrg === null && <Row className="align-items-start">
        <OrderStatus data={filterDataByOrg(projectsData)} />
        <Notifications data={filterDataByOrg(topUsersData)} />
        <QuestionCard data={filterDataByOrg(sampleQuestions)} />
      </Row> }
      
    </div>
  )
}

export default React.memo(AdminDashboard)