import { useCallback, useEffect, useRef } from "react";

export const ThreadCard = ({
  id,
  active,
  open,
  children,
  onClick,
  onClickOutside,
}) => {
  const cardRef = useRef();

  // Handle click inside the card
  const handleClick = useCallback(
    (event) => {
      // Prevent triggering onClickOutside when clicking inside the card
      event.stopPropagation();
      if (onClick) {
        onClick(id);
      }
    },
    [id, onClick]
  );

  // Handle click outside the card
  const handleClickOutside = useCallback(
    (event) => {
      if (
        onClickOutside &&
        cardRef.current &&
        !cardRef.current.contains(event.target)
      ) {
        onClickOutside();
      }
    },
    [onClickOutside]
  );

  useEffect(() => {
    if (active) {
      document.addEventListener("click", handleClickOutside);
    }
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [active, handleClickOutside]);

  return (
    <div
      ref={cardRef}
      className={`thread${open ? " is-open" : ""}${active ? " is-active" : ""}`}
      style={{
        border: "1px solid gray",
      }}
      onClick={handleClick}
    >
      {children}
    </div>
  );
};
