import React, { useEffect, useState, useRef } from 'react';

import {
  Container,
  Card,
  CardBody,
  Spinner,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import SearchBar from './Components/searchBar';
import DefaultRender from './Components/Defaultrender';
// import SingleAnswer from "./Components/singleAnswer";
import SingleAnswer from './Components/singleAnswer';
import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch, useSelector } from 'react-redux';
import {
  changeChatQuestions,
  changeFeatureList,
  changeTrailExpiredMessage,
} from '../../store/actions';
import useDatacallswithtoken from '../../Data Apis/apifunctions';
import { useNavigate } from 'react-router';
import { changeWebSocket } from '../../store/actions';
import amplitude from 'amplitude-js';
// import { useAuth0 } from "@auth0/auth0-react";

const ChatWithData = () => {
  const amplitudeInstance = amplitude.getInstance();
  amplitudeInstance.init('f2296121e8511fb05dac6bec189457a');

  amplitudeInstance.logEvent('Insights', {
    page_name: window.location.pathname,
  });
  const currentApp = useSelector((state) => state.PageData.currentApp);
  const { useForFeatureList } = useDatacallswithtoken();
  const ref = useRef(null);
  const forDivChange = useRef(null);
  document.title = 'Chat with data | Actalyst AI Platform';
  const questions = useSelector((state) => state.PageData.chatQuestions);
  const ws = useSelector((state) => state.PageData.websocket);
  const [questionAndAnswer, setQuestionAndAnswer] = useState([]);
  const wsURL = process.env.REACT_APP_AUTH0_WS;
  const { getAccessTokenSilently, user } = useAuth0();
  const [isPageLoading, setIsPageLoading] = useState(true);
  // const [ws, setWs] = useState(null);
  const [newAnswer, setNewAnswer] = useState([]);
  const [isChatError, setIsChatError] = useState(false);
  const dispatch = useDispatch();
  const setWs = (ws) => {
    dispatch(changeWebSocket(ws));
  };
  const featureList = useSelector((state) => state.PageData.featureList);
  const navigate = useNavigate();
  // const { user,logout } = useAuth0();
  // amplitudeInstance.setUserId(user.email);

  const GetFeatureList = () => {
    useForFeatureList().then((res) => {
      if (typeof res === 'object') {
        dispatch(changeFeatureList(res));
      } else {
        dispatch(changeTrailExpiredMessage(res));
      }
    });
  };
  // useEffect(() => {
  //   ReactGA.send({
  //     hitType: 'pageview',
  //     page: 'window.location.pathname',
  //   });
  // }, []);
  useEffect(() => {
    if (featureList?.length === 0) {
      GetFeatureList();
    } else if (!featureList.find((item) => item.code === 'KC')) {
      navigate('/app/subscribe', { replace: true });
    }
  }, [featureList]);
  useEffect(() => {
    if (
      !isChatError &&
      featureList.find((item) => item.code === 'KC') &&
      ws === null
    ) {
      getAccessTokenSilently().then((token) => {
        const ws = new WebSocket(
          wsURL +
            '/kx_chat' +
            '/' +
            user?.sub +
            '/' +
            currentApp?.folder +
            '?token=' +
            token
        );
        setWs(ws);
        ws.onopen = () => {
          // console.log("connected");
          setIsPageLoading(false);
        };
        ws.onmessage = (evt) => {
          const message = evt.data;
          let answer = JSON.parse(message);
          if (!answer.error) setNewAnswer((q) => [...q, answer.response]);
          else
            setNewAnswer((q) => [
              ...q,
              'Sorry, Someting went wront try again later.',
            ]);
        };
        ws.onclose = () => {
          // console.log("disconnected");
          setIsChatError(true);
          setWs(null);
        };
      });
    } else if (ws !== null && !isChatError) {
      setIsPageLoading(false);
      ws.onmessage = (evt) => {
        const message = evt.data;
        let answer = JSON.parse(message);
        if (!answer.error) {
          setNewAnswer((q) => [...q, answer.response]);
        } else {
          setNewAnswer((q) => [
            ...q,
            'Sorry, Someting went wront try again later.',
          ]);
        }
      };
      ws.onclose = () => {
        // console.log("disconnected");
        setIsChatError(true);
        setWs(null);
      };
    } else if (isChatError) {
      setIsPageLoading(true);
    } else if (ws !== null) {
      setIsPageLoading(false);
    } else if (ws === null && !isChatError) {
      setIsPageLoading(true);
    }
  }, [getAccessTokenSilently, wsURL, isChatError, featureList, currentApp, ws]);

  useEffect(() => {
    if (ws !== null) {
      // ws?.close()
      setWs(null);
      setIsPageLoading(true);
      dispatch(changeChatQuestions([]));
    }
  }, [currentApp]);

  useEffect(() => {
    if (questions.length > 0) {
      setQuestionAndAnswer((q) => [
        ...q,
        {
          question: questions[questions.length - 1],
          answer: null,
          id: questions.length - 1,
        },
      ]);
      ws.send(questions[questions.length - 1]);
    } else if (questions.length === 0) {
      setQuestionAndAnswer([]);
    }
  }, [questions]);

  useEffect(() => {
    if (newAnswer.length > 0) {
      setQuestionAndAnswer((q) =>
        q.map((item, index) => {
          if (index === q.length - 1) {
            return {
              ...item,
              answer: newAnswer[newAnswer.length - 1],
            };
          } else {
            return item;
          }
        })
      );
    }
  }, [newAnswer]);

  useEffect(() => {
    if (ref.current) {
      ref.current.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
        inline: 'nearest',
      });
    }
  }, [questionAndAnswer]);

  return (
    <>
      <div
        className='page-content'
        style={{
          paddingLeft: '0px',
          paddingRight: '0px',
        }}
      >
        <Container fluid={true}>
          <Modal
            isOpen={isChatError}
            toggle={() => {
              setIsChatError(false);
              dispatch(changeChatQuestions([]));
            }}
          >
            <ModalHeader
              toggle={() => {
                setIsChatError(!isChatError);
                dispatch(changeChatQuestions([]));
              }}
            >
              Error
            </ModalHeader>
            <ModalBody>
              Sorry, Something went wrong. Please try again later.
            </ModalBody>
            <ModalFooter>
              <button
                className='btn btn-primary'
                onClick={() => {
                  setIsChatError(!isChatError);
                  dispatch(changeChatQuestions([]));
                }}
              >
                OK
              </button>
            </ModalFooter>
          </Modal>
          {isPageLoading ? (
            <Spinner
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                // transform: "translate(-50%, -50%)",
              }}
              animation='border'
              variant='primary'
            />
          ) : (
            <Card
              style={{
                backgroundColor: 'white',
              }}
            >
              <CardBody>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    height: '70vh',
                    maxHeight: '70vh',
                    justifyContent: 'space-between',
                  }}
                >
                  <div
                    ref={forDivChange}
                    style={{
                      overflowY: 'auto',
                      width: '100%',
                      height: '100%',
                      overflowX: 'hidden',
                    }}
                  >
                    {questionAndAnswer.length > 0 ? (
                      <>
                        {questionAndAnswer?.map((item, index) => {
                          return (
                            <SingleAnswer
                              reff={ref}
                              forDivChange={forDivChange}
                              key={item.id}
                              item={item.question}
                              answer={item.answer}
                            />
                          );
                        })}
                      </>
                    ) : (
                      <DefaultRender />
                    )}
                    <div ref={ref} />
                  </div>
                  <SearchBar
                    isLoading={
                      questionAndAnswer.length > 0
                        ? questionAndAnswer[questionAndAnswer.length - 1]
                            ?.answer
                          ? false
                          : true
                        : false
                    }
                  />
                </div>
              </CardBody>
            </Card>
          )}
        </Container>
      </div>
    </>
  );
};

export default ChatWithData;
