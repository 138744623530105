import React, { useState } from "react";
import { Button, Modal, Form, Input, message } from "antd";
import { MdFeedback } from "react-icons/md";
import { useScreenshot } from "use-react-screenshot";
import useJDAPI from "./api";
import { useAuth0 } from "@auth0/auth0-react";
import { useLocation } from "react-router-dom";

// Define the JD fields globally

const FeedbackButton = ({ mainDivRef }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [form] = Form.useForm();
  const { SubmitFeedback } = useJDAPI();
  const [image, takeScreenshot] = useScreenshot();
  const [fileList, setFileList] = useState([]);
  const { user } = useAuth0();
  const location = useLocation();
  const [isScreenshotTaken, setIsScreenshotTaken] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formValues, setFormValues] = useState({});
  const [isAnyFieldFilled, setIsAnyFieldFilled] = useState(false);
  const path = location.pathname.split("/writing-assistant/")[1];
  let pageName = path ? path.split("/")[0] : "home/my-tasks";

  const extractPageNameAndItem = () => {
    let item = "";
    if (pageName === "get-jd" || pageName == "create-job-description") {
      item = path.split("/")[1] || "";
    }
    return { pageName, item };
  };

  function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  const showModal = async () => {
    setIsVisible(true);
    await sleep(500);
    setLoading(true);
    screenshotHandler();
  };

  const screenshotHandler = () => {
    setTimeout(async () => {
      try {
        const capturedImage = await takeScreenshot(mainDivRef.current);
        if (capturedImage) {
          // console.log("Screenshot captured!", capturedImage);
          setIsScreenshotTaken(true); // Screenshot is successfully taken
          // download(capturedImage, { name: 'lorem-ipsum', extension: 'png' });
        } else {
          console.log("Failed to capture screenshot.");
        }
      } catch (error) {
        console.error("Error taking screenshot:", error);
      } finally {
        setLoading(false);
      }
    }, 0);
  };

  const handleOk = async () => {
    try {
      const formData = await form.validateFields();
      const { pageName, item } = extractPageNameAndItem();

      let issueDescription = formData.comments || "";

      if (pageName === "get-jd" || pageName == "create-job-description") {
        const jdSections = jdFields.map((field) => ({
          label: field.label,
          value: formData[field.name],
        }));

        // Concatenate only non-empty fields
        issueDescription = jdSections
          .filter((section) => section.value?.trim()) // Filter out empty fields
          .map(
            (section, index) =>
              `${index + 1}) ${section.label}: ${section.value}`
          )
          .join("\n");
      }

      await sendFeedback(
        { ...formData, comments: issueDescription },
        image,
        fileList,
        pageName,
        item
      );
      form.resetFields();
      setFileList([]);
      setIsVisible(false);
    } catch (error) {
      message.error("Failed to submit feedback.");
    }
    clearAllFields();
  };

  const handleCancel = () => {
    setIsVisible(false);
  };

  const convertBase64ToBlob = (base64, mimeType) => {
    const byteString = atob(base64.split(",")[1]);
    const byteNumbers = new Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
      byteNumbers[i] = byteString.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: mimeType });
  };

  const sendFeedback = async (
    formValues,
    screenshot,
    fileList,
    pageName,
    item
  ) => {
    try {
      const screenshotBlob = convertBase64ToBlob(screenshot, "image/png");
      const files = [
        new File([screenshotBlob], "screenshot.png", { type: "image/png" }),
        ...fileList.map((file) => file.originFileObj),
      ];

      const body = new FormData();

      files.forEach((file) => {
        body.append("file", file, file.name);
      });
      body.append("user_id", user?.sub);
      body.append("page_name", pageName);
      body.append("group", formValues.group || "");
      body.append("item", item || "");
      body.append("comments", formValues.comments);
      body.append("steps", formValues.steps || "");

      const res = await SubmitFeedback(body);
      message.success("Feedback submitted successfully!");
    } catch (error) {
      console.error("Failed to send feedback:", error);
      message.error("Error submitting feedback");
    }
  };

  // Handle form value changes
  const handleFormChange = (_, allValues) => {
    setFormValues(allValues);
    checkIfAnyFieldIsFilled(allValues);
  };

  // Check if any of the JD fields have a value
  const checkIfAnyFieldIsFilled = (values) => {
    const isFilled =
      jdFields.some((field) => values[field.name]?.trim()) ||
      otherFields.some((field) => values[field.name]?.trim());
    setIsAnyFieldFilled(isFilled);
  };

  const clearAllFields = () => {
    const emptyValues = {};

    [...jdFields, ...otherFields].forEach((field) => {
      emptyValues[field.name] = ""; // Set all field values to empty strings
    });

    form.setFieldsValue(emptyValues); // Update the form fields with empty values
    setFormValues(emptyValues); // Reset the state values as well
    setIsAnyFieldFilled(false);
  };

  return (
    <>
      <Button
        type="primary"
        shape="default"
        icon={<MdFeedback />}
        size="large"
        onClick={showModal}
        style={{
          position: "fixed",
          left: 90,
          bottom: 24,
          zIndex: 1000,
        }}
      >
        Feedback
      </Button>

      <Modal
        title="Feedback"
        open={isVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={handleOk}
            disabled={!isScreenshotTaken || !isAnyFieldFilled}
          >
            Submit
          </Button>,
        ]}
      >
        <Form form={form} layout="vertical" onValuesChange={handleFormChange}>
          {pageName === "get-jd" || pageName == "create-job-description"
            ? jdFields.map((field, index) => (
                <Form.Item label={field.label} name={field.name} key={index}>
                  <Input.TextArea rows={1} placeholder={field.placeholder} />
                </Form.Item>
              ))
            : otherFields.map((field, index) => (
                <Form.Item
                  label={field.label}
                  name={field.name}
                  key={index}
                  rules={
                    field.required
                      ? [
                          {
                            required: true,
                            message: `Please input the ${field.label.toLowerCase()}!`,
                          },
                        ]
                      : []
                  }
                >
                  <Input.TextArea rows={1} placeholder={field.placeholder} />
                </Form.Item>
              ))}
        </Form>
      </Modal>
    </>
  );
};

export default FeedbackButton;

const jdFields = [
  {
    label: "Job Purpose",
    name: "jobPurpose",
    placeholder: "Describe Job Purpose",
  },
  {
    label: "Dimensions",
    name: "dimensions",
    placeholder: "Describe Dimensions",
  },
  {
    label: "Job Context & Major Challenges",
    name: "jobContext",
    placeholder: "Describe Job Context",
  },
  {
    label: "Key Result Areas/Accountabilities",
    name: "kra",
    placeholder: "Describe Key Result Areas/Accountabilities",
  },
  {
    label: "Job Purpose of Direct Reports",
    name: "reportsTo",
    placeholder: "Describe Job Purpose of Direct Reports",
  },
  {
    label: "Relationships",
    name: "relationships",
    placeholder: "Describe Relationships",
  },
  {
    label: "Organization Relationships",
    name: "orgRelationships",
    placeholder: "Describe Organization Relationships",
  },
];

const otherFields = [
  {
    label: "Issue Description",
    name: "comments",
    placeholder: "Describe the issue",
    required: true,
  },
  {
    label: "Steps to Reproduce",
    name: "steps",
    placeholder: "Detailed steps to reproduce the issue",
  },
];
