import React from "react";
import { ArrowRightOutlined } from "@ant-design/icons";
import { Badge, Card, Layout, Typography } from "antd";
import "./Boxes.css";
import { useAuth0 } from "@auth0/auth0-react";

const Boxes = ({
  dashboardGreeting = "Good Morning",
  taskSummary = { totalTasks: 0, pendingApproval: 0, needRevision: 0 },
  cards = { pendingApproval: [], needRevision: [] },
  onViewMore,
  onCardClick, // New prop for handling card click
}) => {
  const { user } = useAuth0();

  const userName = user?.email?.split("@")[0] || "User";

  const handleViewMoreClick = (section) => {
    if (onViewMore) onViewMore(section);
  };

  const handleCardClick = (card) => {
    if (onCardClick) onCardClick(card); // Call onCardClick when a card is clicked
  };

  const timeAgo = (dateString) => {
    const now = new Date();
    const createdDate = new Date(dateString);
    const diffInMs = now - createdDate;
    const diffInDays = Math?.floor(diffInMs / (1000 * 60 * 60 * 24));

    if (diffInDays < 1) {
      const diffInHours = Math?.floor(diffInMs / (1000 * 60 * 60));
      return diffInHours <= 1
        ? `${diffInHours} hour ago`
        : `${diffInHours} hours ago`;
    } else if (diffInDays < 7) {
      return diffInDays === 1
        ? `${diffInDays} day ago`
        : `${diffInDays} days ago`;
    } else if (diffInDays < 30) {
      const diffInWeeks = Math?.floor(diffInDays / 7);
      return diffInWeeks === 1
        ? `${diffInWeeks} week ago`
        : `${diffInWeeks} weeks ago`;
    } else if (diffInDays < 365) {
      const diffInMonths = Math?.floor(diffInDays / 30);
      return diffInMonths === 1
        ? `${diffInMonths} month ago`
        : `${diffInMonths} months ago`;
    } else {
      const diffInYears = Math?.floor(diffInDays / 365);
      return diffInYears === 1
        ? `${diffInYears} year ago`
        : `${diffInYears} years ago`;
    }
  };

  const renderCard = (card, index) => (
    <div key={index} style={{ cursor: "pointer" }}>
      <Badge.Ribbon
        text={timeAgo(card?.created_at)}
        style={{ padding: "3px 4px 3px 4px" }}
        className="ribbon"
      >
        <Card
          onClick={() => handleCardClick(card)}
          className="card"
          size="small"
          color="black"
          style={{
            lineHeight: "0px",

            borderRadius: "9px",
          }}
        >
          <div style={{ marginBottom: "5px" }}>
            <Typography
              className="typography"
              style={{
                color: "#707070",
                fontSize: "11px",
                fontStyle: "normal",
                fontWeight: 400,
                // lineHeight: "100%",
                // margin: "0px 0px 0px 0px",
                whiteSpace: "normal",
                wordWrap: "break-word",
                wordBreak: "break-all",
                hyphens: "auto",
                overflow: "hidden",
              }}
            >
              Submitted by
            </Typography>
            &nbsp;
            <Typography
              className="typography"
              style={{
                color: "#1A1A1A",
                fontSize: "12px",
                fontStyle: "normal",
                fontWeight: 400,
                // lineHeight: "100%",
                margin: "0px 0px 30px 0px",
                whiteSpace: "normal",
                wordWrap: "break-word",
                wordBreak: "break-all",
                hyphens: "auto",
                overflow: "hidden",
              }}
            >
              {card?.submited_by}
            </Typography>
          </div>

          <div>
            <Typography
              className="typography"
              style={{
                color: "#1A1A1A",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "24px",
                margin: "0px 0px 3px 0px",
                whiteSpace: "normal",
                wordWrap: "break-word",
                wordBreak: "break-all",
                hyphens: "auto",
                overflow: "hidden",
              }}
            >
              Position ID: {card?.position_id}
            </Typography>
            <div
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              <Typography
                className="typography"
                style={{
                  color: "#1A1A1A",
                  fontSize: "13px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "16px",
                  padding: "0px 0px 10px 0px",

                  whiteSpace: "normal",
                  wordWrap: "break-word",
                  wordBreak: "break-all",
                  hyphens: "auto",
                  overflow: "hidden",
                }}
              >
                {card?.process_title}
              </Typography>
              <Typography
                className="typography"
                style={{
                  color: "#1A1A1A",
                  fontSize: "11px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "15px", // 120%
                  whiteSpace: "normal",
                  wordWrap: "break-word",
                  wordBreak: "break-all",
                  hyphens: "auto",
                  overflow: "hidden",
                }}
              >
                Department: {card?.department}
              </Typography>
            </div>
          </div>
        </Card>
      </Badge.Ribbon>
    </div>
  );

  const renderCardsSection = (sectionCards, sectionKey) => {
    const firstFourCards = sectionCards?.slice(0, 3);
    const remainingCards = sectionCards?.slice(3, 5);
    const shouldShowViewMore = sectionCards?.length > 5;

    return (
      <>
        <div
          className="box1"
          style={{
            borderRadius: "12px",
            height: remainingCards?.length > 0 ? "auto" : "max-content",
            overflow: remainingCards?.length > 0 ? "visible" : "hidden",
          }}
        >
          {firstFourCards?.map(renderCard)}
        </div>

        <div
          className="box2"
          style={{
            borderRadius: "12px",
            height: remainingCards?.length > 0 ? "auto" : "0px",
            backgroundColor: remainingCards?.length > 0 ? "" : "transparent",
          }}
        >
          {remainingCards?.length > 0 && (
            <>
              {remainingCards?.map(renderCard)}
              {shouldShowViewMore && (
                <Card
                  className="view-more-card card"
                  size="small"
                  color="black"
                  style={{
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "10px",
                    cursor: "pointer",
                    borderRadius: "8px",
                    border: "1px dashed #8C8C8C",
                    background: "transparent",
                    boxShadow: "0px 2px 0px 0px rgba(0, 0, 0, 0.02)",
                    whiteSpace: "normal",
                    wordWrap: "break-word",
                    wordBreak: "break-all",
                    hyphens: "auto",
                    overflow: "hidden",
                  }}
                  onClick={() => handleViewMoreClick(sectionKey)}
                >
                  <Typography
                    className="typography"
                    style={{
                      display: "flex",
                      height: "83px",
                      padding: "0px 8px",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "8px",
                      alignSelf: "stretch",
                      whiteSpace: "normal",
                      wordWrap: "break-word",
                      wordBreak: "break-all",
                      hyphens: "auto",
                      overflow: "hidden",
                    }}
                  >
                    <Typography
                      style={{
                        color: "#1F1F1F",
                        textAlign: "center",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: 400,
                        lineHeight: "22px",
                        whiteSpace: "normal",
                        wordWrap: "break-word",
                        wordBreak: "break-all",
                        hyphens: "auto",
                        overflow: "hidden",
                      }}
                    >
                      View More
                    </Typography>{" "}
                    <ArrowRightOutlined />
                  </Typography>
                </Card>
              )}{" "}
            </>
          )}
        </div>
      </>
    );
  };

  return (
    <div className="boxmain">
      <div className="boxdashboard">
        <h2 className="gradient-text ">
          {dashboardGreeting},{" "}
          <span style={{ textTransform: "capitalize" }}> {userName}.</span>
        </h2>
        <Typography
          className="typography"
          style={{
            color: " #1A1A1A",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "120%",
          }}
        >
          Your Tasks ({taskSummary?.totalTasks})
        </Typography>

        <div className="sections" style={{ marginTop: "37px" }}>
          <div
            className="pendingApproval"
            style={{ flex: "1", flexDirection: "column" }}
          >
            <div>
              <Typography
                className="typography"
                style={{
                  color: "#1A1A1A",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: "400",
                  lineHeight: "120%",
                }}
              >
                Pending Approval ({taskSummary?.pendingApproval})
              </Typography>
              <hr className="rounded" style={{ margin: "10px 0px 15px 0px" }} />
            </div>
            {cards?.pendingApproval?.length > 0 && (
              <div className="boxes">
                {renderCardsSection(cards?.pendingApproval, "pendingApproval")}
              </div>
            )}
          </div>

          <div
            className="needRevision"
            style={{ flex: "1", flexDirection: "column" }}
          >
            <div>
              <Typography
                className="typography"
                style={{
                  color: "#1A1A1A",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: "400",
                  lineHeight: "120%",
                }}
              >
                Need Revision ({taskSummary?.needRevision})
              </Typography>
              <hr className="rounded" style={{ margin: "10px 0px 15px 0px" }} />
            </div>
            {cards?.needRevision?.length > 0 && (
              <div className="boxes">
                {renderCardsSection(cards?.needRevision, "needRevision")}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Boxes;
