import React from "react";
import {
  Form,
  Input,
  Select,
  Button,
  Row,
  Col,
  Collapse,
  Typography,
} from "antd";

const { Option } = Select;
const { Panel } = Collapse;

const JobDescriptionForm = ({
  positionList,
  positionDetails,
  onSubmit,
  isPositionIdSelected,
  handlePositionIdChange,
  selectedQualifications,
  setSelectedQualifications,
  selectedSpecializations,
  setSelectedSpecializations,
  selectedIndustryPreferences,
  setSelectedIndustryPreferences,
  selectedExperience,
  setSelectedExperience,
  internalRelationship,
  setInternalRelationship,
  externalRelationship,
  setExternalRelationship,
  selectedSkills,
  setSelectedSkills,
  submitting,
  generatedJobDescription,
  title,
  mode = "create",
  initialValues = {},
  jdHistory, // Pass the jd_history array from the parent
  onViewStatusClick, // Function to handle button click (toggle approval history)
  showApprovalHistory,
}) => {
  const handleFinish = (values) => {
    if (mode === "create" && onSubmit) {
      onSubmit(values);
    }
  };

  return (
    <Form
      layout="vertical"
      style={{
        display: "flex",
        flexDirection: "column",
        flex: "1",
        padding: "20px 30px",
        borderRadius: "7px",
        border: "1px solid #E6E6E6",
      }}
      onFinish={handleFinish}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center", // Vertically center the items
          justifyContent: "space-between", // Optional: add space between the elements if needed
          margin: "0px 0px 20px 0px",
        }}
      >
        <Typography
          style={{
            color: "#1A1A1A",
            fontFamily: "Roboto, sans-serif",
            fontSize: "28px",
            fontWeight: 400,
            lineHeight: "100%",
            margin: "0 25px 0 0", // Add right margin if needed
          }}
        >
          {title}
        </Typography>
        {/* Conditionally render the View Status button if jdHistory is not empty */}
        {jdHistory && (
          <Button
            onClick={onViewStatusClick}
            style={{
              color: "white",
              backgroundColor: jdHistory?.length > 0 ? "black" : "#b3b3b3", // Black when enabled, gray when disabled
              cursor: jdHistory?.length > 0 ? "pointer" : "not-allowed", // Change cursor to indicate disabled state
              borderColor: jdHistory?.length > 0 ? "black" : "#b3b3b3", // Adjust the border color when disabled
            }}
            disabled={!jdHistory || jdHistory.length === 0} // Disable if there is no history
          >
            {showApprovalHistory ? "Hide Status" : "View Status"}
          </Button>
        )}
      </div>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item label="Position ID" tooltip="This is the Position ID">
            <Select
              defaultValue={
                mode === "edit" ? initialValues.positionId : "select"
              }
              onChange={handlePositionIdChange}
              disabled={mode === "edit"}
            >
              {mode === "edit" ? (
                <Option value={initialValues.positionId}>
                  {initialValues.positionId}
                </Option>
              ) : (
                <>
                  <Option value="select">Select Position ID</Option>
                  {positionList?.map((position) => (
                    <Option
                      key={position?.position_id}
                      value={position?.position_id}
                    >
                      {position?.position_id} - {position?.process_title}
                    </Option>
                  ))}
                </>
              )}
            </Select>
          </Form.Item>
        </Col>
        {isPositionIdSelected && positionDetails && (
          <Col span={12}>
            <Form.Item label="Job Title" tooltip="This is the Job Title">
              <Input
                placeholder="Job Title"
                disabled
                value={positionDetails?.process_title}
              />
            </Form.Item>
          </Col>
        )}
      </Row>

      {isPositionIdSelected && positionDetails && (
        <>
          {/* ... [Rest of the fields remain the same, with optional fields editable] */}
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Job Function"
                tooltip="This is the Job Function"
              >
                <Input
                  placeholder="Job Function"
                  disabled
                  value={positionDetails?.function}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Department" tooltip="This is the Department">
                <Input
                  placeholder="Department"
                  disabled
                  value={positionDetails?.department}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item label="Location" tooltip="This is the Location">
                <Input
                  placeholder="Location"
                  disabled
                  value={positionDetails?.location}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Manager's Name"
                tooltip="This is the Manager's Name"
              >
                <Input
                  placeholder="Manager's Name"
                  disabled
                  value={positionDetails?.reports_to_position_title}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Manager's ID"
                tooltip="This is the Manager's ID"
              >
                <Input
                  placeholder="Manager's ID"
                  disabled
                  value={positionDetails?.reports_to_position_num}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Manager's Designation"
                tooltip="This is the Manager's Designation"
              >
                <Input
                  placeholder="Manager's Designation"
                  disabled
                  value={positionDetails?.reports_to_position_designation}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item label="Business" tooltip="This is the Business">
                <Input
                  placeholder="Business"
                  disabled
                  value={positionDetails?.business}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Business Unit"
                tooltip="This is the Business Unit"
              >
                <Input
                  placeholder="Business Unit"
                  disabled
                  value={positionDetails?.business_unit}
                />
              </Form.Item>
            </Col>
          </Row>

          <Collapse>
            <Panel header="Optional Fields" key="1">
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    label="Qualification"
                    tooltip="This is the Qualification"
                  >
                    <Select
                      mode="multiple"
                      placeholder="Select Qualification"
                      value={selectedQualifications}
                      onChange={(value) => setSelectedQualifications(value)}
                    >
                      {positionDetails?.qualifications?.map((qualification) => (
                        <Select.Option
                          key={qualification?.qualification_id}
                          value={qualification?.qualification}
                        >
                          {qualification?.qualification}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Specialization"
                    tooltip="This is the Specialization"
                  >
                    <Select
                      mode="multiple"
                      placeholder="Select Specialization"
                      value={selectedSpecializations}
                      onChange={(value) => setSelectedSpecializations(value)}
                    >
                      {positionDetails?.specializations?.map(
                        (specialization) => (
                          <Select.Option
                            key={specialization?.specialization_id}
                            value={specialization?.specialization}
                          >
                            {specialization?.specialization}
                          </Select.Option>
                        )
                      )}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    label="Industry Preference"
                    tooltip="This is the Industry Preference"
                  >
                    <Select
                      mode="multiple"
                      placeholder="Select Industry Preferences"
                      value={selectedIndustryPreferences}
                      onChange={(value) =>
                        setSelectedIndustryPreferences(value)
                      }
                    >
                      {positionDetails?.industries?.map((industry) => (
                        <Select.Option
                          key={industry?.industry_preference_id}
                          value={industry?.industry_preference}
                        >
                          {industry?.industry_preference}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Experience (years)"
                    tooltip="This is the Experience"
                  >
                    <Select
                      placeholder="Select Experience"
                      value={selectedExperience}
                      onChange={(value) => setSelectedExperience(value)}
                    >
                      {positionDetails?.experiences?.map((experience) => (
                        <Select.Option
                          key={experience?.experience_id}
                          value={experience?.experience}
                        >
                          {experience?.experience}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    label="Internal Relationship"
                    tooltip="This is the Internal Relationship"
                  >
                    <Input
                      placeholder="Enter internal relationship"
                      value={internalRelationship}
                      onChange={(e) => setInternalRelationship(e.target.value)}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="External Relationship"
                    tooltip="This is the External Relationship"
                  >
                    <Input
                      placeholder="Enter external relationship"
                      value={externalRelationship}
                      onChange={(e) => setExternalRelationship(e.target.value)}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item label="Skill Set" tooltip="This is the Skill Set">
                    <Select
                      mode="tags"
                      placeholder="Select or type skills"
                      value={selectedSkills}
                      onChange={(value) => setSelectedSkills(value)}
                    >
                      {positionDetails?.skills?.map((skill) => (
                        <Select.Option
                          key={skill?.skillset_id}
                          value={skill?.skill}
                        >
                          {skill?.skill}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Panel>
          </Collapse>
          {/* Conditional rendering of the submit button */}
          {mode === "create" && (
            <Row
              justifyContent="center"
              style={{ marginTop: "20px", flex: "1" }}
            >
              <Col style={{ flex: "1", justifyContent: "center" }}>
                <center>
                  {" "}
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={submitting}
                    disabled={
                      generatedJobDescription &&
                      Object.keys(generatedJobDescription)?.length > 0
                    }
                    style={{
                      width: "60%",
                      color: "white",
                      background: "black",
                    }}
                  >
                    Generate Job Description
                  </Button>
                </center>
              </Col>
            </Row>
          )}
        </>
      )}
    </Form>
  );
};

export default JobDescriptionForm;
