import { message } from "antd";

import { TiptapCollabProvider } from "@hocuspocus/provider";
import * as Y from "yjs";
import { generateTableFromJson } from "../../../Utils/Utils";
import { parseJobDescriptionHTML } from "./parseJobDescriptionHTML";
import { getCollabToken, getDocument, getThreads } from "../../TipTap/Api/api";

export const useJobDescriptionHandlers = ({
  user,
  getAccessTokenSilently,
  positionDetails,
  selectedSkills,
  selectedQualifications,
  selectedIndustryPreferences,
  selectedSpecializations,
  internalRelationship,
  externalRelationship,
  selectedExperience,
  setSubmitting,
  setContent,
  setDoc,
  setProvider,
  setCurrentDocumentThreads,
  setGeneratedJobDescription,
  navigate,
  generatedJobDescription,
  positionList,
  FetchPositionDetails,
  FetchJobDetails,
  GenerateJD,
  SaveJD,
  SubmitForApproval,
  DownloadWordFile,
  DownloadExcelFile,
  ApproveJD,
  ReviseJD,
  record,
  setPositionDetails, // Function to update positionDetails state
  setJobDetails, // Function to update jobDetails state
  setLoading, //
}) => {
  const onFinish = async () => {
    setSubmitting(true);
    try {
      // Format skills with id and original value
      const formattedSkills = selectedSkills?.map((skill) => {
        const skillData = positionDetails?.skills?.find(
          (item) => item?.skill === skill
        );
        return {
          skill: String(skillData?.skillset_id) || "",
          value: skill,
        };
      });

      // Format qualifications with id and original value
      const formattedQualifications = selectedQualifications?.map(
        (qualification) => {
          const qualificationData = positionDetails?.qualifications?.find(
            (item) => item?.qualification === qualification
          );
          return {
            qualification: String(qualificationData?.qualification_id) || "",
            value: qualification,
          };
        }
      );

      // Format industry preferences with id and original value
      const formattedIndustryPreferences = selectedIndustryPreferences?.map(
        (preference) => {
          const preferenceData = positionDetails?.industries?.find(
            (item) => item?.industry_preference === preference
          );
          return {
            preference: String(preferenceData?.industry_preference_id) || "",
            value: preference,
          };
        }
      );

      // Format specializations with id and original value
      const formattedSpecializations = selectedSpecializations?.map(
        (specialization) => {
          const specializationData = positionDetails?.specializations?.find(
            (item) => item?.specialization === specialization
          );
          return {
            specialization: String(specializationData?.specialization_id) || "",
            value: specialization,
          };
        }
      );

      const body = {
        user_id: user?.sub,
        position_num: String(positionDetails?.position_id || ""),
        position_title: positionDetails?.designation || "",
        business_unit: positionDetails?.business_unit || "",
        business: positionDetails?.business || "",
        function: positionDetails?.function || "",
        location: positionDetails?.location || "",
        department: positionDetails?.department || "",
        reports_to_position_num: String(
          positionDetails?.reports_to_position_num || ""
        ),
        reports_to_position_title:
          positionDetails?.reports_to_position_title || "",
        reports_to_position_function:
          positionDetails?.reports_to_position_designation || "",
        reports_to_position_department: positionDetails?.department || "",
        experience: selectedExperience,

        // Pass formatted skills
        Skills: formattedSkills,

        // Pass formatted qualifications
        qualifications: formattedQualifications,

        // Pass formatted specializations
        specializations: formattedSpecializations,

        // Pass formatted industry preferences
        ind_preferences: formattedIndustryPreferences,

        relationship_internal: internalRelationship || "",
        relationship_external: externalRelationship || "",
      };

      const res = await GenerateJD(body);
      const data = res.data;

      if (res.status === 200) {
        setContent(() => {
          let sectionNumber = 1;
          return ` ${
            data?.job_purpose
              ? `<div>
                  <h3>${sectionNumber++}) Job Purpose</h3>
                  <p>${data?.job_purpose}</p>
                </div>`
              : ""
          }
          ${
            data?.dimensions && data?.dimensions.length > 0
              ? `<div>
                  <h3>${sectionNumber++}) Dimensions</h3>
                  <p>${data?.dimensions}</p>
                </div>`
              : ""
          }
          ${
            data?.job_context
              ? `<div>
                  <h3>${sectionNumber++}) Job Context & Major Challenges</h3>
                  <p>${data?.job_context}</p>
                </div>`
              : ""
          }
          ${
            data?.kra && data?.kra.length > 0
              ? `<div style="flex: 1; width: 100%;">
                  <h3>${sectionNumber++}) Key Result Areas/Accountabilities</h3>
                  ${generateTableFromJson(data?.kra)}
                </div>`
              : ""
          }
          ${
            data?.reportees && data?.reportees.length > 0
              ? `<div style="flex: 1; width: 100%;">
                  <h3>${sectionNumber++}) Job Purpose of Direct Reports</h3>
                  <p>${data?.reportees}</p>
                </div>`
              : ""
          }
          ${
            data?.relationships && data?.relationships.length > 0
              ? `<div style="flex: 1; width: 100%;">
                  <h3>${sectionNumber++}) Relationships</h3>
                  ${generateTableFromJson(data?.relationships)}
                </div>`
              : ""
          }
          ${
            data?.organization_relationships
              ? `<div style="flex: 1; width: 100%;">
                  <h3>${sectionNumber++}) Organization Relationships</h3>
                </div>`
              : ""
          }
        </div>`;
        });

        const newYdoc = new Y.Doc();
        const newProvider = new TiptapCollabProvider({
          appId: "x9l4z8kr",
          name: `${data?.jd_key}`,
          document: newYdoc,
          token: await getCollabToken(user),
        });

        setDoc(newYdoc);
        setProvider(newProvider);
        setCurrentDocumentThreads([]);

        message.success("Job Description generated successfully!");
        setGeneratedJobDescription({
          ...data,
          positionDetails,
        });
      } else {
        message.error("Error generating job description");
      }
    } catch (error) {
      message.error("Error generating job description");
    } finally {
      setSubmitting(false);
    }
  };

  const handleSaveJD = async (updatedJobDescription) => {
    try {
      const parsedData = parseJobDescriptionHTML(
        updatedJobDescription,
        generatedJobDescription
      );

      // Format skills with id and original value
      const formattedSkills = selectedSkills?.map((skill) => {
        const skillData = positionDetails?.skills?.find(
          (item) => item?.skill === skill
        );
        return {
          skill: String(skillData?.skillset_id) || "",
          value: skill,
        };
      });

      // Format qualifications with id and original value
      const formattedQualifications = selectedQualifications?.map(
        (qualification) => {
          const qualificationData = positionDetails?.qualifications?.find(
            (item) => item?.qualification === qualification
          );
          return {
            qualification: String(qualificationData?.qualification_id) || "",
            value: qualification,
          };
        }
      );

      // Format industry preferences with id and original value
      const formattedIndustryPreferences = selectedIndustryPreferences?.map(
        (preference) => {
          const preferenceData = positionDetails?.industries?.find(
            (item) => item?.industry_preference === preference
          );
          return {
            preference: String(preferenceData?.industry_preference_id) || "",
            value: preference,
          };
        }
      );

      // Format specializations with id and original value
      const formattedSpecializations = selectedSpecializations?.map(
        (specialization) => {
          const specializationData = positionDetails?.specializations?.find(
            (item) => item?.specialization === specialization
          );
          return {
            specialization: String(specializationData?.specialization_id) || "",
            value: specialization,
          };
        }
      );

      // Filter the parsed KRAs and relationships to exclude those with empty IDs
      const filteredKra = parsedData?.kra?.filter(
        (kraItem) =>
          kraItem.jd_kra_id && kraItem.jd_kra_id.trim() !== "undefined"
      );

      const filteredRelationships = parsedData?.Jd_relationships?.filter(
        (rel) =>
          rel.jd_relationship_id &&
          rel.jd_relationship_id.trim() !== "undefined"
      );

      const jdKey = generatedJobDescription?.jd_key || record?.key;
      console.log(generatedJobDescription);
      const body = {
        user_id: user?.sub,
        jd_key: jdKey || "",
        tip_tap_id: jdKey || "",
        experience: selectedExperience,
        last_updated_dt: new Date().toISOString(),

        // Pass formatted skills
        Skills: formattedSkills,

        // Pass formatted qualifications
        qualifications: formattedQualifications,

        // Pass formatted specializations
        specializations: formattedSpecializations,

        // Pass formatted industry preferences
        ind_preferences: formattedIndustryPreferences,

        relationship_internal: internalRelationship || "",
        relationship_external: externalRelationship || "",
        job_purpose: parsedData?.job_purpose,
        job_context: parsedData?.job_context,
        kra: filteredKra,
        Jd_reportees: parsedData?.Jd_reportees,
        Jd_dimensions: parsedData?.Jd_dimensions,
        Jd_relationships: filteredRelationships,
      };
      const res = await SaveJD(body);

      message.success("Job Description Saved successfully!");
    } catch (error) {
      console.error("Error during generation:", error);
      message.error("Error saving job description");
    }
  };

  const handleSubmitForApproval = async (comment) => {
    try {
      const jdKey = generatedJobDescription?.jd_key;
      const body = {
        jd_key: jdKey || "",
        user_id: user?.sub,
        position_num: String(positionDetails?.position_id || ""),
        reports_position_num: String(
          positionDetails?.reports_to_position_num || ""
        ),
        comments: comment,
      };

      const res = await SubmitForApproval(body);

      message.success("Submitted for approval successfully!");
      setTimeout(() => {
        navigate("/app/writing-assistant/my-task");
      }, 300);
    } catch (error) {
      message.error("Error submitting job description for approval");
    }
  };

  const handleDownloadFile = async (content, fileType) => {
    try {
      const jdKey = generatedJobDescription?.jd_key;

      const body = {
        user_id: user?.sub,
        jd_key: jdKey || "",
      };

      if (fileType === "word") {
        await DownloadWordFile(body);
      } else if (fileType === "excel") {
        await DownloadExcelFile(body);
      }
    } catch (error) {
      console.error(`Error during ${fileType} file download:`, error);
      message.error(`Error downloading ${fileType} file`);
    }
  };
  const handleApproveJD = async (comment) => {
    try {
      const jdKey = generatedJobDescription?.jd_key;
      const body = {
        jd_key: jdKey || "",
        user_id: user?.sub,
        position_num: String(positionDetails?.position_id || ""),
        reports_position_num: String(
          positionDetails?.reports_to_position_num || ""
        ),
        comments: comment,
      };
      const res = await ApproveJD(body);
      message.success("Job description approved successfully!");
      setTimeout(() => {
        navigate("/app/writing-assistant/my-task");
      }, 300);
    } catch (error) {
      message.error("Error approving job description");
    }
  };

  const handleReviseJD = async (comment) => {
    try {
      const jdKey = generatedJobDescription?.jd_key;
      const body = {
        jd_key: jdKey || "",
        user_id: user?.sub,
        position_num: String(positionDetails?.position_id || ""),
        reports_position_num: String(
          positionDetails?.reports_to_position_num || ""
        ),
        comments: comment,
      };
      const res = await ReviseJD(body);
      message.success("Job description revision submitted successfully!");
      setTimeout(() => {
        navigate("/app/writing-assistant/my-task");
      }, 300);
    } catch (error) {
      message.error("Error revising job description");
    }
  };

  return {
    onFinish,
    handleSaveJD,
    handleSubmitForApproval,
    handleDownloadFile,
    handleApproveJD,
    handleReviseJD,
  };
};
