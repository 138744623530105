import React, { useState } from 'react';
import { Flex, Typography, Input } from 'antd';
import { CheckOutlined, EditOutlined, LoadingOutlined, RightOutlined } from '@ant-design/icons';
import "../../style/style.css";
import useDatacallswithtoken from '../../../../Data Apis/apifunctions';
import { useNotification } from './NotificationProvider';
const { Text } = Typography;
const { TextArea } = Input

const SingleSumary = ({ summaryItem, onEditClicked }) => {
    const { useForFSUpdateSingleTopicSummay } = useDatacallswithtoken();
    const notification = useNotification();

    const [summary, setSummary] = useState(summaryItem?.summary ? summaryItem?.summary : "");
    const [unSavedSummary, setUnSavedSummary] = useState(summaryItem?.summary);
    const [analysisTopicId, setAnalysisTopicId] = useState(summaryItem?.analysis_topic_id);
    const [loading, setLoading] = useState(false)


    const [editing, setEditing] = useState(false);

    // console.log(summaryItem)
    const handleSaveSummary = () => {

        setEditing(false);
        if (unSavedSummary !== summary) {
            setLoading(true)
            SaveSummary();
        }




    }

    const SaveSummary = () => {
        useForFSUpdateSingleTopicSummay(summary, analysisTopicId).then((res) => {
            setUnSavedSummary(summary);
            setLoading(false)
            notification.success({
                message: `${summaryItem?.topic_name} summary is been saved`,
                placement: 'topRight',
            });

        }).catch((err) => {
            setLoading(false)
            notification.error({
                message: "Error",
                description: err.message,
                placement: 'topRight',
            });
        })
    }


    // console.log(summaryItem)
    return (
        <Flex className='p20 border-radius15' gap={10} align='start' style={{ background: "#f5f5f5" }} >
            {
                summaryItem?.topic_name
                && <Text className='font-col-666' strong='true' style={{ width: "100px" }} >{summaryItem?.topic_name}</Text>
            }
            <TextArea
                className='editable-text-area'
                variant='borderless'
                autoSize
                value={summary}
                onChange={(e) => setSummary(e.target.value)}
                disabled={!editing}
            />
            <Flex gap={15} style={{ color: "#b3b3b3" }}>
                {/* {loading ? <LoadingOutlined /> : editing ? <CheckOutlined onClick={() => handleSaveSummary()} /> : <EditOutlined onClick={() => setEditing(true)} />} */}
                <RightOutlined onClick={() => onEditClicked()} />
            </Flex>
        </Flex>
    )
}

export default SingleSumary;
