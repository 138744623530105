import React, { useState } from 'react';
import { Flex, Typography, Upload, message, Form, Input, Button, Radio, Select } from 'antd';
import { InboxOutlined, UploadOutlined } from '@ant-design/icons';

import "../../style/style.css";

import MultipleFileUpload from './MultipleFileUpload';
import SimpleBar from 'simplebar-react';
const { Title, Text } = Typography;


const { Dragger } = Upload;

const CreateNewAnalysis = () => {
    const [timeTakenToUpload, settimeTakenToUpload] = useState(0);
    // const [buckName, setbuckName] = useState('');
    // const [s3Locations, sets3Locations] = useState([]);
    

    return (
        <Flex className='br-ra15-sld1-e6e6 w100-h100 p20' vertical gap={20}>
            <SimpleBar style={{ height: "100%", maxHeight: "100%" }}>
                <Flex vertical >
                    <Title className='font-col-666 margin0' level={3}>Upload Files</Title>
                    <Text className="font-col-999" style={{ marginBottom: "20px" }} >Add one or more PDF, PPT, Word, or Excel files to get analysis on them.</Text>

                    <MultipleFileUpload settimeTakenToUpload={settimeTakenToUpload} />
                </Flex>
            </SimpleBar>

        </Flex>
    );
}

export default CreateNewAnalysis;
