import React, { useEffect, useState, useRef } from 'react';
import ReactGA from 'react-ga4';
import {
  Container,
  Spinner,
  Card,
  CardBody,
  Row,
  Col,
  Button,
} from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
// import MainContent from "./Components/kx-components/mainContent";
import MainContent from './Components/kx-components/mainStyleCopy';
import Defaultrender from './Components/kx-components/Defaultrender';
import {
  changeSearchDisabled,
  changeFeatureList,
  changeTrailExpiredMessage,
  changeAppsStructured,
} from '../../store/actions';
import { Navigate } from 'react-router-dom';
import useDatacallswithtoken from '../../Data Apis/apifunctions';
import Chatrender from './Components/stru-components/Chatrender';
import SearchBar from './Components/searchBar';
import { changeChatQuestions } from '../../store/actions';
import amplitude from 'amplitude-js';
const ContentSearch = () => {
  document.title = 'Actalyst AI Platform';
  const amplitudeInstance = amplitude.getInstance();

  amplitudeInstance.logEvent('Adv-Intelligence', {
    page_name: 'Adv-Intelligence',
  });
  const dispatch = useDispatch();
  const ref = useRef(null);
  const { useForFeatureList, useForLoadKx, useForCompositSummary } =
    useDatacallswithtoken();
  const [singleAnswerColapse, setSingleAnswerColapse] = useState([]);
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  // const [isSocketError, setIsSocketError] = useState(false)
  // const [userGroups, setUserGroups] = useState([])
  const [columnsToCancle, setColumnsToCancle] = useState([]);
  const featureList = useSelector((state) => state.PageData.featureList);
  const apps = useSelector((state) => state.PageData.apps);
  const currentApp = useSelector((state) => state.PageData.currentApp);
  // const contentSearchQuestion = useSelector(state => state.PageData.contentSearchQuestion)
  const contentSearchQuestions = useSelector(
    (state) => state.PageData.chatQuestions
  );
  const [news, setNews] = useState();
  const [insights, setInsights] = useState();
  const [isPageExist, setIsPageExist] = useState(true);
  const [releventQuestions, setReleventQuestions] = useState([]);
  const [questionsToRender, setQuestionsToRender] = useState([]);
  const GetFeatureList = () => {
    useForFeatureList().then((res) => {
      if (typeof res === 'object') {
        dispatch(changeFeatureList(res));
      } else {
        dispatch(changeTrailExpiredMessage(res));
      }
    });
  };
  useEffect(() => {
    dispatch(changeSearchDisabled(false));
  }, []);
  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: 'window.location.pathname',
    });
  }, []);
  useEffect(() => {
    if (featureList?.length === 0) {
      GetFeatureList();
    }
    // if "IN" not in featureList, then navigate to subscribe
    else if (!featureList.find((item) => item.code === 'NN')) {
      // navigate("/app/subscribe", { replace: true });
      setIsPageExist(false);
    }
  }, [featureList]);

  const GetLoadKx = () => {
    useForLoadKx()
      .then((res) => {
        // setUserGroups(res?.groups)
        setNews(res?.news);
        // console.log(res?.dataSrc_list)
        // dispatch(changeApps(res?.dataSrc_list))
        // console.log(res?.user_schemas)
        dispatch(changeAppsStructured(res?.user_schemas));
        setInsights(res?.insights);
        setIsPageLoading(false);
      })
      .catch((err) => {
        setIsPageLoading(false);
        setIsError(true);
      });
  };
  useEffect(() => {
    GetLoadKx();
  }, []);

  useEffect(() => {
    let dumObj = releventQuestions.map((item) => {
      return item?.question;
    });
    setQuestionsToRender(
      dumObj.sort(() => Math.random() - Math.random()).slice(0, 5)
    );
  }, [releventQuestions]);

  useEffect(() => {
    setIsSocketError(false);
  }, [currentApp]);

  useEffect(() => {
    if (contentSearchQuestions.length > 0) {
      setSingleAnswerColapse((q) => {
        let q1 = q.map((item) => {
          return { ...item, isColapse: true };
        });
        return [
          ...q1,
          {
            question: contentSearchQuestions[contentSearchQuestions.length - 1],
            isColapse: true,
            isSocketError: false,
            unstructuredSummary: '',
            unstructuredSummaryLoading: true,
            structuredSummary: '',
            structuredSummaryLoading: true,
            compositSummary: '',
            compositSummaryLoading: true,
          },
        ];
      });
    } else {
      setSingleAnswerColapse([]);
      setReleventQuestions([]);
    }
    if (ref.current) {
      ref.current.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
        inline: 'nearest',
      });
    }
  }, [contentSearchQuestions]);

  const setIsSocketError = (question) => {
    setSingleAnswerColapse((q) => {
      return q.map((item) => {
        if (item.question === question) {
          return { ...item, isSocketError: true };
        } else {
          return item;
        }
      });
    });
  };

  const setUnstructuredSummary = (question, summary) => {
    setSingleAnswerColapse((q) => {
      return q.map((item) => {
        if (item.question === question) {
          return {
            ...item,
            unstructuredSummary: summary,
            unstructuredSummaryLoading: false,
          };
        } else {
          return item;
        }
      });
    });
  };

  const setStructuredSummary = (question, summary) => {
    setSingleAnswerColapse((q) => {
      return q.map((item) => {
        if (item.question === question) {
          return {
            ...item,
            structuredSummary: summary,
            structuredSummaryLoading: false,
          };
        } else {
          return item;
        }
      });
    });
  };

  const setCompositSummary = (question, summary) => {
    setSingleAnswerColapse((q) => {
      return q.map((item) => {
        if (item.question === question) {
          return {
            ...item,
            compositSummary: summary,
            compositSummaryLoading: false,
          };
        } else {
          return item;
        }
      });
    });
  };

  const GetCompositSummary = (
    question,
    structurdSummary,
    unStructuredSummary
  ) => {
    const data = {
      question: question,
      structuredSummary: structurdSummary,
      unstructuredSummary: unStructuredSummary,
    };
    useForCompositSummary(data).then((res) => {
      setCompositSummary(question, res?.summary);
    });
  };

  useEffect(() => {
    singleAnswerColapse.map((item) => {
      if (
        item.structuredSummary &&
        item.unstructuredSummary &&
        item.compositSummaryLoading
      ) {
        GetCompositSummary(
          item.question,
          item.structuredSummary,
          item.unstructuredSummary
        );
      }
    });
  }, [singleAnswerColapse]);

  return (
    <>
      {isPageExist ? (
        <>
          {apps?.length > 0 ? (
            <div
              className='page-content'
              style={{
                paddingBottom: '0px',
              }}
            >
              <Container fluid={true}>
                {isPageLoading ? (
                  <Spinner
                    style={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      // transform: "translate(-50%, -50%)",
                    }}
                    animation='border'
                    variant='primary'
                  />
                ) : (
                  <>
                    {isError ? (
                      <div className='text-center d-flex justify-content-center flex-direction-column'>
                        <div>
                          <img
                            src='https://cdn.dribbble.com/users/1059583/screenshots/4171367/coding-freak.gif'
                            alt='server down'
                          />
                          <p>
                            <h3>Server is down or not responding! ...</h3>
                            <p>please try again later</p>
                          </p>
                        </div>
                      </div>
                    ) : (
                      <>
                        {contentSearchQuestions?.length > 0 ? (
                          <>
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                // height: "63vh",
                                minHeight: '63vh',
                                justifyContent: 'space-between',
                              }}
                            >
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  overflowY: 'auto',
                                  width: '100%',
                                  height: '100%',
                                  overflowX: 'hidden',
                                  paddingBottom: '50px',
                                  // alignItems: "center",
                                }}
                              >
                                {contentSearchQuestions?.map((item, index) => {
                                  return (
                                    <Card key={index}>
                                      <CardBody>
                                        <div
                                          style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            width: '100%',
                                          }}
                                        >
                                          <div>
                                            <div
                                              style={{
                                                fontWeight: 'bold',
                                                fontSize: '18px',
                                              }}
                                            >
                                              {item}
                                            </div>
                                            <div
                                              style={{
                                                fontSize: '14px',
                                                paddingLeft: '10px',
                                                minHeight: '20px',
                                              }}
                                            >
                                              {singleAnswerColapse?.find(
                                                (q) => q.question === item
                                              )?.isSocketError ? (
                                                <>
                                                  {singleAnswerColapse?.find(
                                                    (q) => q.question === item
                                                  )
                                                    ?.unstructuredSummaryLoading ? (
                                                    <Spinner
                                                      style={{
                                                        position: 'absolute',
                                                        // top: "50%",
                                                        left: '50%',
                                                      }}
                                                      animation='border'
                                                      variant='primary'
                                                    />
                                                  ) : (
                                                    <div
                                                      style={{
                                                        whiteSpace: 'pre-wrap',
                                                      }}
                                                    >
                                                      {
                                                        singleAnswerColapse?.find(
                                                          (q) =>
                                                            q.question === item
                                                        )?.unstructuredSummary
                                                      }
                                                    </div>
                                                  )}
                                                </>
                                              ) : (
                                                <>
                                                  {singleAnswerColapse?.find(
                                                    (q) => q.question === item
                                                  )?.compositSummaryLoading ? (
                                                    <>
                                                      {singleAnswerColapse?.find(
                                                        (q) =>
                                                          q.question === item
                                                      )
                                                        ?.unstructuredSummaryLoading ? (
                                                        <Spinner
                                                          style={{
                                                            position:
                                                              'absolute',
                                                            // top: "50%",
                                                            left: '50%',
                                                          }}
                                                          animation='border'
                                                          variant='primary'
                                                        />
                                                      ) : (
                                                        <div
                                                          style={{
                                                            whiteSpace:
                                                              'pre-wrap',
                                                          }}
                                                        >
                                                          {
                                                            singleAnswerColapse?.find(
                                                              (q) =>
                                                                q.question ===
                                                                item
                                                            )
                                                              ?.unstructuredSummary
                                                          }
                                                        </div>
                                                      )}
                                                    </>
                                                  ) : (
                                                    <div
                                                      style={{
                                                        whiteSpace: 'pre-wrap',
                                                      }}
                                                    >
                                                      {
                                                        singleAnswerColapse?.find(
                                                          (q) =>
                                                            q.question === item
                                                        )?.compositSummary
                                                      }
                                                    </div>
                                                  )}
                                                </>
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                        {singleAnswerColapse?.find(
                                          (q) => q.question === item
                                        )?.unstructuredSummaryLoading ? null : (
                                          <div
                                            style={{
                                              paddingTop: '10px',
                                              paddingLeft: '10px',
                                              border: 'none',
                                            }}
                                            type='button'
                                            className='btn btn-link btn-sm'
                                            onClick={() => {
                                              setSingleAnswerColapse((q) => {
                                                return q.map((q) => {
                                                  if (q.question === item) {
                                                    return {
                                                      ...q,
                                                      isColapse: !q.isColapse,
                                                    };
                                                  } else {
                                                    return q;
                                                  }
                                                });
                                              });
                                            }}
                                          >
                                            <i
                                              className='fab fa-wpexplorer'
                                              style={{
                                                fontSize: '24px',
                                              }}
                                            />
                                          </div>
                                        )}
                                        <Card
                                          key={item}
                                          style={{
                                            display: singleAnswerColapse?.find(
                                              (q) => q.question === item
                                            )?.isColapse
                                              ? 'none'
                                              : 'block',
                                            backgroundColor: '#f8f8f8',
                                            // border: "1px solid #f8f8f8",
                                          }}
                                        >
                                          <CardBody>
                                            {currentApp?.folder ? (
                                              <MainContent
                                                setReleventQuestions={
                                                  setReleventQuestions
                                                }
                                                setUnstructuredSummary={
                                                  setUnstructuredSummary
                                                }
                                                reff={ref}
                                                question={item}
                                                userGroup={currentApp.folder}
                                                setColumnsToCancle={
                                                  setColumnsToCancle
                                                }
                                              />
                                            ) : null}
                                            {singleAnswerColapse?.find(
                                              (q) => q.question === item
                                            )?.isSocketError ? null : (
                                              <div
                                                style={{
                                                  paddingTop: '10px',
                                                }}
                                              >
                                                {currentApp?.schema_id ? (
                                                  <Chatrender
                                                    setStructuredSummary={
                                                      setStructuredSummary
                                                    }
                                                    setIsSocketError={
                                                      setIsSocketError
                                                    }
                                                    question={item}
                                                    schemaid={
                                                      currentApp?.schema_id
                                                    }
                                                  />
                                                ) : null}
                                              </div>
                                            )}
                                          </CardBody>
                                        </Card>
                                      </CardBody>
                                    </Card>
                                  );
                                })}

                                {questionsToRender.length > 0 ? (
                                  <Row>
                                    <Col lg={12}>
                                      <Card>
                                        <CardBody>
                                          <div
                                            style={{
                                              fontSize: '0.8rem',
                                              fontWeight: 'bold',
                                              marginBottom: '3px',
                                            }}
                                          >
                                            Suggested Follow-ups
                                          </div>
                                          <div
                                            style={{
                                              display: 'flex',
                                              justifyContent: 'space-between',
                                            }}
                                          >
                                            {questionsToRender.map(
                                              (item, index) => {
                                                return (
                                                  <Button
                                                    key={index}
                                                    className='btn-sm'
                                                    color='white'
                                                    style={{
                                                      border:
                                                        '1px solid #dee0e0',
                                                      marginRight: '2px',
                                                    }}
                                                    onClick={() => {
                                                      // setNewColumns(q => [...q, { name: item, controller: new AbortController() }]);
                                                      dispatch(
                                                        changeChatQuestions([
                                                          ...contentSearchQuestions,
                                                          item,
                                                        ])
                                                      );
                                                    }}
                                                    onMouseOver={(e) => {
                                                      e.target.style.backgroundColor =
                                                        '#dee0e0';
                                                    }}
                                                    onMouseOut={(e) => {
                                                      e.target.style.backgroundColor =
                                                        'white';
                                                    }}
                                                  >
                                                    {item}
                                                  </Button>
                                                );
                                              }
                                            )}
                                          </div>
                                        </CardBody>
                                      </Card>
                                    </Col>
                                  </Row>
                                ) : null}
                              </div>
                              <div ref={ref} />
                              <div
                                style={{
                                  display: 'flex',
                                  position: 'fixed',
                                  flexDirection: 'column',
                                  bottom: '10px',
                                  // top: "85vh",
                                  zIndex: '100',
                                  width: '100%',
                                  justifyContent: 'space-between',
                                  alignItems: 'center',
                                }}
                              >
                                <SearchBar isLoading={false} />
                              </div>
                            </div>
                          </>
                        ) : (
                          <Defaultrender insights={insights} news={news} />
                        )}
                      </>
                    )}
                  </>
                )}
              </Container>
            </div>
          ) : (
            <Spinner
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                // transform: "translate(-50%, -50%)",
              }}
              animation='border'
              variant='primary'
            />
          )}
        </>
      ) : (
        <Navigate to='/app/subscribe' replace={true} />
      )}
    </>
  );
};

export default ContentSearch;
