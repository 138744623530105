import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import SidebarData from "./SidebarData";
import { useSelector, useDispatch } from "react-redux";
import {

  changeFeatureList,
  changeTrailExpiredMessage,
 
} from "../../store/actions";
import useDatacallswithtoken from "../../Data Apis/apifunctions";
const HomeRedirect = () => {
  const navigate = useNavigate();
  const featureList = useSelector((state) => state.PageData.featureList);
  const trailexpairedMessage = useSelector(
    (state) => state.PageData.trailExpiredMessage
  );
  const { useForFeatureList } = useDatacallswithtoken();
  const dispatch = useDispatch();
  const featureListArr = featureList ? Object.keys(featureList) : [];

  const GetFeatureList = () => {
    useForFeatureList()
      .then((res) => {
        if (typeof res === "object") {
          dispatch(changeFeatureList(res));
        } else {
          dispatch(changeTrailExpiredMessage(res));
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch(changeTrailExpiredMessage(err?.response?.data));
      });
  };
  useEffect(() => {
    if (featureList?.length === 0) {
      console.log("feature", featureList);
      GetFeatureList();
    }
  }, []);

  useEffect(() => {
    if (!featureListArr || featureListArr.length == 0) return;
    // console.log(featureList, SidebarData)
    for (let i = 0; i < SidebarData.SidebarData.length; i++) {
      const feature = SidebarData.SidebarData[i];

      const sidebarItem = featureListArr.find((item) => item === feature.code);
      // console.log(feature, sidebarItem)
      if (sidebarItem && SidebarData.SidebarData[i].url) {
        console.log(SidebarData, "sidebar");
        navigate(SidebarData.SidebarData[i].url, { replace: true });
        return; // Stop after the first navigation
      }
    }
  }, [featureListArr]);
  // useEffect(() => {
  //     if (trailexpairedMessage !== "") {
  //         navigate("/app/trail-expired");
  //         // setsearchDisabledMessage("Trail Expired!");
  //     }
  // }, [trailexpairedMessage, navigate]);

  return null;
};

export default HomeRedirect;
