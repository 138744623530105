import React, { useEffect, useState } from 'react';
import { Collapse, Input, notification } from 'antd';
import { CheckOutlined, EditOutlined, Loading3QuartersOutlined, LoadingOutlined } from '@ant-design/icons';
import { Flex } from 'antd'; // Assuming Flex is correctly imported from your custom components;
import "../../../style/style.css";
import useDatacallswithtoken from '../../../../../Data Apis/apifunctions';

const { TextArea } = Input;
const { Panel } = Collapse;

const AccordionItem = ({ topicObj, analysesId, setPromptsSaved, setSessionFlag,
    question, prompt: initialPrompt, sessionPrompt: initialSessionPrompt, example: initialExample }) => {
    const { useForUpdateFSPrompt, useForUpdateFSExample } = useDatacallswithtoken()
    const [isEditing, setIsEditing] = useState(false);
    const [saveResponseLoading, setSaveResponseLoading] = useState(false)


    const [prompt, setPrompt] = useState(initialPrompt);
    const [example, setExample] = useState(initialExample);
    const [sessionPrompt, setSessionPrompt] = useState(initialSessionPrompt);

    const [currentAnswer, setCurrentAnswer] = useState();
    // console.log(question)
    useEffect(() => {
        if (question == "System Prompt") { setCurrentAnswer(prompt) }
        else if (question == "Session Prompt") { setCurrentAnswer(sessionPrompt) }
        else if (question == "Examples") { setCurrentAnswer(example) }

    }, [question])

    // console.log(topicObj)

    const handleSave = (e) => {
        e.stopPropagation();
        setIsEditing(false);
        setPromptsSaved(prev => ({ ...prev, [question]: true }));
        if (question == "Session Prompt") setSessionFlag(true)
        // console.log(topicObj)
        const data = {
            analysisId: analysesId,
            TopicId: topicObj?.analysis_topic_id,
            analysisStageId: topicObj?.analysis_stages_id,
            promptId: topicObj?.prompt_id,
            prompt: question == "System Prompt" ? currentAnswer : null,
            example: question == "Example" ? currentAnswer : null,
            exampleId: topicObj.example_id,
            sessionPrompt: question == "Session Prompt" ? currentAnswer : null,
            sessionPromptId: topicObj.session_prompt_id,
            sessionFlag: question == "Session Prompt"
        };
        if (question == "System Prompt" || question == "Session Prompt") {
            SavePrompt(data);
        }
        else if (question == "Examples") {
            SaveExample(data);
        }
        console.log(question)

    };

    const SavePrompt = (data) => {
        setSaveResponseLoading(true)
        useForUpdateFSPrompt(data).then(res => {
            setSaveResponseLoading(false)
            console.log(res)

            notification.success({
                message: 'Saved',
                description: `${question} has been saved`,
                placement: 'topRight',
            });
        }).catch(err => {
            setSaveResponseLoading(false)
                notification.error({
                    message: 'Error',
                    description: err.message,
                    placement: 'topRight',
                });
            console.log(err.message)
        })

    }

    const SaveExample = (data) => {

        setSaveResponseLoading(true)
        useForUpdateFSExample(data).then(res => {
            setSaveResponseLoading(false)
            console.log(res)

            notification.success({
                message: 'Saved',
                description: `${question} has been saved`,
                placement: 'topRight',
            });
        }).catch(err => {
            setSaveResponseLoading(false)
            notification.error({
                message: 'Error',
                description: err.message,
                placement: 'topRight',
            });
            console.log(err.message)
        })

    }

    const handleEdit = (e) => {
        e.stopPropagation();

        setIsEditing(true);
    };

    const handleChange = (e) => {
        setCurrentAnswer(e.target.value);
        setPromptsSaved(prev => ({ ...prev, [question]: false }))
    };

    return (
        <>
            {question &&
                <Collapse accordion style={{ width: "100%" }} defaultActiveKey={['1']}>
                    <Panel
                        header={(
                            <Flex justify='space-between'>
                                {question}
                                {saveResponseLoading ? <LoadingOutlined/>:
                                isEditing ? (
                                    <CheckOutlined
                                        className='cursor-pointer'
                                        style={{ color: '#b3b3b3' }}
                                        onClick={handleSave}
                                    />
                                ) : (
                                    <EditOutlined
                                        className='cursor-pointer'
                                        style={{ color: '#b3b3b3' }}
                                        onClick={handleEdit}
                                    />
                                )}
                            </Flex>
                        )}
                    >
                        <TextArea
                            className='editable-text-area'
                            autoSize
                            allowClear
                            variant='borderless'
                            value={currentAnswer}
                            disabled={!isEditing}
                            onChange={handleChange}
                        />
                    </Panel>
                </Collapse>
            }
        </>
    );
};

export default AccordionItem;
