import React, { useEffect, useState, useRef } from 'react';

import {
  Container,
  Card,
  CardBody,
  Spinner,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  CardTitle,
  Button,
} from 'reactstrap';
import SearchBar from './Components/searchBar';
import DefaultRender from './Components/Defaultrender';
import SingleAnswer from './Components/singleAnswer';
import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch, useSelector } from 'react-redux';
import {
  changeChatQuestionsUnstructured,
  changeFileName,
  refreshQuestions,
  changeFileKey,
} from '../../../../store/actions';
import { changeWebSocketUnstructured } from '../../../../store/actions';
import NewChatLayout from './Components/NewChatLayout';
import useDatacallswithtoken from '../../../../Data Apis/apifunctions';
import AdobePdfViewer from '../../../../components/Common/AdobePdfRender/FullWindow';
import { CloseOutlined, FilePdfOutlined, PlusOutlined, FileOutlined } from '@ant-design/icons';
import { ConfigProvider, Flex, Spin, Tag, Tooltip } from 'antd';
import RenderPdf from '../../../New Content Search/Components/kx-components/renderPdf';

import './index.css';
import axios from 'axios';

const languageOptions = [
  { key: 'Hindi', text: 'Hindi', value: 'hi' },
  { key: 'English', text: 'English', value: 'en' },
  { key: 'Odia', text: 'Odia', value: 'or' },
  { key: 'Bengali', text: 'Bengali', value: 'bn' },
  { key: 'Marathi', text: 'Marathi', value: 'mr' },
  { key: 'Telugu', text: 'Telugu', value: 'te' },
  { key: 'Tamil', text: 'Tamil', value: 'ta' },
  { key: 'Malayalam', text: 'Malayalam', value: 'ml' },
  { key: 'Gujarati', text: 'Gujarati', value: 'gu' },
];

const ChatWithData = (props) => {
  const { buckName, settimeTakenToUpload } = props;
  const ref = useRef(null);
  const forDivChange = useRef(null);
  const { useForPdfDownload, useForReleventQuestionsKX } =
    useDatacallswithtoken();
  const questions = useSelector(
    (state) => state.PageData.chatQuestionsUnstructured
  );
  const fileKey = useSelector((state) => state.PageData.fileKey);
  const s3Locations = useSelector(
    (state) => state.PageData.fileSenseS3Location
  );
  const ws = useSelector((state) => state.PageData.websocketUnstructured);
  const currentgroup = useSelector((state) => state.PageData.currentApp.folder);
  const filename = useSelector((state) => state.PageData.filename);
  const fileSenseBucketName = useSelector(
    (state) => state.PageData.fileSenseBucketName
  );
  const [questionAndAnswer, setQuestionAndAnswer] = useState([]);
  const wsURL = process.env.REACT_APP_AUTH0_WS;
  const { getAccessTokenSilently, user } = useAuth0();
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [newAnswer, setNewAnswer] = useState(null);
  const [isChatError, setIsChatError] = useState(false);
  const [isStreaming, setIsStreaming] = useState(false);
  const expectedPattern = /^Should I create a .* page summary for you\?$/;
  const dispatch = useDispatch();
  const [chatQuestions, setChatQuestions] = useState([]);
  const [showPop, setShowPop] = useState([false]);
  const [longType, setLongType] = useState(false);
  const [translatedAnswers, setTranslatedAnswers] = useState({});
  const [regeneratedAnswers, setRegeneratedAnswers] = useState({});
  const [disableSerachBar, setdisableSerachBar] = useState(false);
  const [selectedTagIndex, setSelectedTagIndex] = useState(null);
  const [longTypeAnswers, setLongTypeAnswers] = useState({});
  const [popupInteractions, setPopupInteractions] = useState({});
  const regeneratedAnswersRef = useRef({});
  const currentRegeneratingIndexRef = useRef(null);
  const wsRef = useRef(null);
  const [renderPdfState, setrenderPdfState] = useState({
    loading: false,
    error: null,
    pdfBlob: null,
    renderPdf: false,
  });
  const [suggestedQuestions, setSuggestedQuestions] = useState([]);

  // Refs to keep track of latest state values
  const chatQuestionsRef = useRef(chatQuestions);
  const questionAndAnswerRef = useRef(questionAndAnswer);
  const questionsRef = useRef(questions);

  const handleMouseEnter = (e) => {
    e.currentTarget.querySelector('.text').style.display = 'block';
  };

  const handleMouseLeave = (e) => {
    e.currentTarget.querySelector('.text').style.display = 'none';
  };

  const translateAnswer = async (index, text, targetLanguage) => {
    try {
      const response = await axios.post(
        'https://translation.googleapis.com/language/translate/v2',
        {},
        {
          params: {
            q: text,
            target: targetLanguage,
            key: 'AIzaSyBU5F265RjIc3wHXisnPNkSmcSfoaW4cWo',
          },
        }
      );
      const translatedText = response.data.translations[0].translatedText;
      return translatedText;
    } catch (error) {
      console.error('Error translating response:', error);
      return `Error translating: ${error.message}`;
    }
  };

  const regenerateAnswer = async (index) => {
    const question = questionAndAnswer[index].question;

    // Set streaming to true
    setIsStreaming(true);

    // Prepare a new slot for the regenerated answer and set it to null to show loading state
    const updatedAnswers = {
      ...regeneratedAnswersRef.current,
      [index]: [...(regeneratedAnswersRef.current[index] || []), null],
    };
    regeneratedAnswersRef.current = updatedAnswers;
    setRegeneratedAnswers(updatedAnswers);

    // Set the current regenerating index
    currentRegeneratingIndexRef.current = index;

    // Send the question again through WebSocket
    if (ws && ws.readyState === WebSocket.OPEN) {
      ws.send(
        JSON.stringify({
          question: question,
          to_email: user?.email,
          long_type: 'No',
          s3_location: s3Locations,
        })
      );
    } else {
      console.error('WebSocket is not open');
      setIsStreaming(false);
      currentRegeneratingIndexRef.current = null;
    }
  };

  const handleStartNewChat = () => {
    // Reset all relevant states
    setSuggestedQuestions([]);
    setQuestionAndAnswer([]);
    setIsStreaming(false);
    setdisableSerachBar(false);
    setRegeneratedAnswers({});
    setTranslatedAnswers({});
    setLongTypeAnswers({});
    setPopupInteractions({});
    setSelectedTagIndex(null);
    setShowPop([]);
    setNewAnswer(null);
    setIsChatError(false);
    setLongType(false);

    // Reset Redux state
    dispatch(changeChatQuestionsUnstructured([]));
    dispatch(refreshQuestions());

    // Reset refs
    chatQuestionsRef.current = [];
    questionAndAnswerRef.current = [];
    questionsRef.current = [];
    regeneratedAnswersRef.current = {};
    currentRegeneratingIndexRef.current = null;

    // Reset PDF viewer state
    setrenderPdfState({
      loading: false,
      error: null,
      pdfBlob: null,
      renderPdf: false,
    });
  };

  const handleTagClick = (index) => {
    if (selectedTagIndex === index) {
      // If the same tag is clicked again, close the PDF and reset the selection
      setrenderPdfState((prev) => ({
        ...prev,
        renderPdf: false,
        loading: false,
      }));
      setSelectedTagIndex(null);
    } else {
      // If a different tag is clicked, update the selection and render the PDF
      setSelectedTagIndex(index);
      HandleRenderPdf();
    }
  };

  useEffect(() => {
    chatQuestionsRef.current = chatQuestions;
    questionAndAnswerRef.current = questionAndAnswer;
    questionsRef.current = questions;
    if (s3Locations === 'Error while uploading to S3') {
      setIsChatError(true);
    }
  }, [chatQuestions, questionAndAnswer, questions]);

  const setWs = (ws) => {
    dispatch(changeWebSocketUnstructured(ws));
  };

  useEffect(() => {
    if (!isChatError && ws === null) {
      getAccessTokenSilently().then((token) => {
        const newWs = new WebSocket(
          `${wsURL}/forunstructuredChat/${user?.sub}/${fileKey}?token=${token}`
        );
        setWs(newWs);
        wsRef.current = newWs;
        ws.onopen = () => {
          setIsPageLoading(false);
        };
        ws.onclose = () => {
          setIsChatError(true);
          setWs(null);
          setIsStreaming(false);
        };
        ws.onmessage = handleMessage;
      });
    }
    return () => {
      setSuggestedQuestions([]);
      setQuestionAndAnswer([]);
      setIsStreaming(false);
      setdisableSerachBar(false);
      setRegeneratedAnswers({});
      setTranslatedAnswers({});
      setLongTypeAnswers({});
      setPopupInteractions({});
      setSelectedTagIndex(null);
      setShowPop([]);
      setNewAnswer(null);
      setIsChatError(false);
      setLongType(false);

      // Reset Redux state
      dispatch(changeChatQuestionsUnstructured([]));
      dispatch(refreshQuestions());

      // Reset refs
      chatQuestionsRef.current = [];
      questionAndAnswerRef.current = [];
      questionsRef.current = [];
      regeneratedAnswersRef.current = {};
      currentRegeneratingIndexRef.current = null;

      // Reset PDF viewer state
      setrenderPdfState({
        loading: false,
        error: null,
        pdfBlob: null,
        renderPdf: false,
      });
    };
  }, [getAccessTokenSilently, wsURL, isChatError, user, fileKey]);

  useEffect(() => {
    if (ws) {
      ws.onmessage = handleMessage;
    }
  }, [ws]);

  const handleMessage = (evt) => {
    const message = evt.data;
    let processedMessage = message;

    try {
      const jsonMessage = JSON.parse(message);
      if (jsonMessage.status === 'streaming_started') {
        return;
      }
      if (
        jsonMessage.status === 'streaming_stopped' ||
        jsonMessage.status === 'streaming_finished'
      ) {
        setIsStreaming((prev) => {
          const question =
            questionAndAnswerRef?.current[
              questionAndAnswerRef?.current.length - 1
            ]?.question;
          const answer =
            questionAndAnswerRef?.current[
              questionAndAnswerRef?.current.length - 1
            ]?.answer;
          HandleReleventQuestions(question, answer);
          return false;
        });
        setdisableSerachBar(false);
        currentRegeneratingIndexRef.current = null;
        return;
      }
      processedMessage = jsonMessage.response || message;
    } catch (error) {
      // If it's not JSON, use the message as is
    }

    // Check if we're currently regenerating an answer
    if (currentRegeneratingIndexRef.current !== null) {
      const updatedAnswers = {
        ...regeneratedAnswersRef.current,
        [currentRegeneratingIndexRef.current]: [
          ...(regeneratedAnswersRef.current[
            currentRegeneratingIndexRef.current
          ] || []),
        ],
      };
      const currentAnswers =
        updatedAnswers[currentRegeneratingIndexRef.current];
      const lastIndex = currentAnswers.length - 1;

      if (currentAnswers[lastIndex] === null) {
        currentAnswers[lastIndex] = processedMessage;
      } else {
        currentAnswers[lastIndex] += processedMessage;
      }

      regeneratedAnswersRef.current = updatedAnswers;
      setRegeneratedAnswers(updatedAnswers);
    } else {
      if (expectedPattern.test(processedMessage)) {
        setdisableSerachBar(true);
        setShowPop((prev) => {
          const newArray = [...prev];
          newArray[chatQuestionsRef.current.length - 1] = true;
          return newArray;
        });
      }
      // Handle regular streaming response
      setQuestionAndAnswer((prevQA) => {
        const updatedQA = [...prevQA];
        const lastIndex = updatedQA.length - 1;
        if (lastIndex >= 0) {
          updatedQA[lastIndex] = {
            ...updatedQA[lastIndex],
            answer: (updatedQA[lastIndex].answer || '') + processedMessage,
          };
        }
        return updatedQA;
      });
    }
  };

  const stopStreaming = () => {
    console.log(isStreaming, ws.send);
    ws.send(JSON.stringify({ action: 'stop_streaming' }));
  };

  const HandleReleventQuestions = (question, data, fileName) => {
    useForReleventQuestionsKX({ question: question, data: data }).then(
      (res) => {
        const questions = Object.values(res);
        setSuggestedQuestions(questions);
        // Handle the response here
      }
    );
  };

  const HandleRenderPdf = () => {
    setrenderPdfState((prevState) => ({
      ...prevState,
      loading: true,
      renderPdf: true,
    }));
    const dataConfig = {
      filekey: s3Locations,
      bucketName: fileSenseBucketName,
    };
    useForPdfDownload(dataConfig)
      .then((res) => {
        setrenderPdfState((prevState) => ({
          ...prevState,
          pdfBlob: res.data,
          loading: false,
          renderPdf: true,
        }));
      })
      .catch((err) => {
        setrenderPdfState((prevState) => ({
          ...prevState,
          loading: false,
          error: err,
        }));
      });
  };

  // useEffect(() => {
  //   ReactGA.send({
  //     hitType: 'pageview',
  //     page: 'window.location.pathname',
  //   });
  // }, []);

  useEffect(() => {
    if (questions.length > 0) {
      setQuestionAndAnswer((prevQA) => {
        return [
          ...prevQA,
          { question: questions[questions.length - 1], answer: null },
        ];
      });
      setShowPop((prev) => [...prev, false]); // This adds a new false value for the new question
      if (ws) {
        ws.send(
          JSON.stringify({
            question: questions[questions.length - 1],
            to_email: user?.email,
            long_type: longType ? 'Yes' : 'No',
            s3_location: s3Locations,
          })
        );
        setLongTypeAnswers((prev) => ({
          ...prev,
          [questions.length - 1]: longType,
        }));
        if (longType) {
          setIsStreaming(false);
        } else {
          setIsStreaming(true);
        }
      }
      setLongType(false);
    } else if (questions.length === 0) {
      setQuestionAndAnswer([]);
      setShowPop([]); // Reset showPop when there are no questions
    }
  }, [questions, ws, user]);

  const summarize = () => {
    setLongType(true);
    const newQuestions = [...questions, questions[questions.length - 1]];
    dispatch(changeChatQuestionsUnstructured(newQuestions));
    setChatQuestions(newQuestions);
    setShowPop((prev) => [...prev, false]); // Add a new false value for the new question
  };

  useEffect(() => {
    if (newAnswer) {
      setQuestionAndAnswer((prevQA) =>
        prevQA.map((item, index) =>
          index === prevQA.length - 1 ? { ...item, answer: newAnswer } : item
        )
      );
    }
  }, [newAnswer]);

  useEffect(() => {
    if (ref?.current) {
      setTimeout(() => {
        ref.current.scrollIntoView({
          behavior: 'smooth',
          block: 'end',
          inline: 'nearest',
        });
      }, 1000);
    }
  }, [questionAndAnswer]);

  return (
    <>
      <div style={{ paddingBottom: '6px' }}>
        <Modal
          isOpen={isChatError}
          toggle={() => {
            setIsChatError(false);
            dispatch(changeChatQuestionsUnstructured([]));
          }}
        >
          <ModalHeader
            toggle={() => {
              setIsChatError(!isChatError);
              dispatch(changeChatQuestionsUnstructured([]));
            }}
          >
            Error
          </ModalHeader>
          <ModalBody>
            Sorry, Something went wrong. Please try again later.
          </ModalBody>
          <ModalFooter>
            <button
              className='btn btn-primary'
              onClick={() => {
                setIsChatError(!isChatError);
                dispatch(changeChatQuestionsUnstructured([]));
                dispatch(changeFileKey(''));
                dispatch(changeFileName(''));
                ws.close();
              }}
            >
              OK
            </button>
          </ModalFooter>
        </Modal>
        {isPageLoading ? (
          <Spinner
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              // transform: "translate(-50%, -50%)",
            }}
            animation='border'
            variant='primary'
          />
        ) : (
          <CardBody
            className='d-flex justify-content-start align-items-start pt-0'
            style={{ height: '91vh', maxHeight: '91vh' }}
          >
            <div
              style={{ width: '50%' }}
              className='d-flex flex-column justify-content-between align-items-start h-100'
            >
              {questionAndAnswer.length > 0 ? (
                <div
                  className='hidden-scrollbar w-100'
                  style={{ overflowY: 'auto' }}
                >
                  {questionAndAnswer.map((item, index) => {
                    return (
                      <NewChatLayout
                        chatQuestions={chatQuestionsRef}
                        summarize={summarize}
                        showPop={showPop[index]} // Pass the specific showPop value for this index
                        setshowPop={setShowPop}
                        reff={ref}
                        forDivChange={forDivChange}
                        key={index}
                        item={item.question}
                        answer={item.answer}
                        setdisableSerachBar={setdisableSerachBar}
                        setQuestionAndAnswer={setQuestionAndAnswer}
                        index={index} // Pass the index to SingleAnswer
                        handleRenderPdf={HandleRenderPdf}
                        isSelected={selectedTagIndex === index}
                        onTagClick={() => handleTagClick(index)}
                        setIsStreaming={setIsStreaming}
                        translatedAnswer={translatedAnswers[index]}
                        onTranslate={(targetLanguage, currentAnswer) =>
                          translateAnswer(index, currentAnswer, targetLanguage)
                        }
                        regeneratedAnswers={regeneratedAnswers[index] || []}
                        onRegenerate={() => regenerateAnswer(index)}
                        isLastQuestion={index === questionAndAnswer.length - 1}
                        languageOptions={languageOptions}
                        hasInteractedWithPopup={popupInteractions[index]}
                        setPopupInteractions={setPopupInteractions}
                        isLongTypeAnswer={longTypeAnswers[index]}
                        isLastAnswer={index === questionAndAnswer.length - 1}
                      />
                    );
                  })}
                </div>
              ) : (
                <DefaultRender />
              )}
              <div
                style={{
                  padding: '5px 20px 0px 30px',
                  borderTop: '1px solid #e6e6e6',
                  width: '100%',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    gap: '10px',
                    alignItems: 'flex-end',
                  }}
                >
                  <div
                    className='new-chat-btn-container'
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    onClick={() => {
                      if (!isStreaming) {
                        handleStartNewChat();
                      }
                    }}
                    style={{ background: 'black' }}
                  >
                    <PlusOutlined className='plus-icon' />
                    <span
                      style={{
                        lineHeight: '15px',
                        display: 'none',
                        minWidth: '80px',
                      }}
                      className='text'
                    >
                      New Chat
                    </span>
                  </div>
                  <SearchBar
                    disableSerachBar={disableSerachBar}
                    setNewAnswer={setNewAnswer}
                    setchatQuestions={setChatQuestions}
                    stopStreaming={stopStreaming}
                    isLoading={isStreaming}
                    suggestedQuestions={suggestedQuestions}
                  />
                </div>

                <div
                  style={{
                    textAlign: 'center',
                    color: '#666666',
                    fontSize: '12px',
                    paddingTop: '6px',
                  }}
                >
                  AI can make mistakes. Please double check responses
                </div>
                {/* <p className='text-center' style={{fontSize: ".75rem",color: "#666666"}}>Crafted by Actalyst.</p> */}
              </div>
            </div>
            <div
              style={{
                background: '#E6E6E6',
                // background: "linear-gradient(180deg, rgba(230, 230, 230, 0.00) -7.51%, #E6E6E6 33.53%)",
                width: '1px',
                height: '150%',
                zIndex: '2',
              }}
            ></div>
            <div
              style={{ width: '50%' }}
              className='d-flex justify-content-center align-items-center h-100 '
            >
              {renderPdfState.renderPdf ? (
                renderPdfState.loading ? (
                  <ConfigProvider theme={{ token: { colorPrimary: 'black' } }}>
                    <Spin />
                  </ConfigProvider>
                ) : (
                  <div
                    style={{
                      height: '100%',
                      width: '100%',
                      paddingLeft: '2em',
                      border: 'none',
                    }}
                  >
                    <CloseOutlined
                      onClick={() => {
                        setrenderPdfState((prev) => ({
                          ...prev,
                          renderPdf: false,
                          loading: false,
                        }));
                        setSelectedTagIndex(null);
                      }}
                      style={{
                        fontSize: '10px',
                        color: 'black',
                        position: 'absolute',
                        background: 'white',
                        right: '10px',
                        margin: '7px',
                        padding: '5px',
                        borderRadius: '3px',
                      }}
                    />
                    <RenderPdf pdfByteArray={renderPdfState.pdfBlob} />
                  </div>
                )
              ) : (
                <CardBody
                  style={{ alignSelf: 'flex-start' }}
                  className='mb-4 px-3 pt-4 w-100'
                >
                  <CardTitle className='mb-4'>File Uploaded</CardTitle>
                  <div
                    className='d-flex flex-column gap-3 justify-content-start align-items-start'
                  // style={{
                  //   justifyContent: 'space-between',
                  //   alignItems: 'center',
                  //   gap: '10px',
                  // }}
                  >


                    <Flex gap={10} align='center' wrap>

                      <div>Uploaded File(s) :</div> {filename.map((file, index) => {
                        return <Tooltip key={index} placement='bottomLeft' title={file}>

                          <Tag key={index} icon={
                            file.endsWith('.pdf') ? (
                              <FilePdfOutlined />
                            ) : (
                              <FileOutlined />
                            )
                          } style={{
                            marginBottom: '5px',
                            padding: '5px 10px',
                            fontSize: '.8rem',
                            cursor: 'pointer',
                            borderRadius: '5px',
                            background: 'white',
                            color: 'black',
                          }}>{file.length > 30 ? file.slice(0, 30) + ".." : file}</Tag>
                        </Tooltip>
                      })}
                    </Flex>
                    {/* <br /> */}
                    {/* Your file was uploaded in {formatTime(timeTakenToUpload)} */}
                    <Button
                      color=''
                      className='bg-black text-white'
                      onClick={() => {
                        dispatch(changeFileName(''));
                        dispatch(refreshQuestions());
                        dispatch(changeFileKey(''));
                        dispatch(changeChatQuestionsUnstructured([]));
                        dispatch(changeWebSocketUnstructured(null));
                        ws.close();
                        settimeTakenToUpload(0);
                      }}
                    >
                      Change File
                    </Button>
                  </div>
                </CardBody>
              )}
            </div>
          </CardBody>
        )}
      </div>
    </>
  );
};

export default ChatWithData;
