import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { useLocation } from 'react-router-dom';
// import useDatacallswithtoken from '../../Data Apis/apifunctions';
import useDatacallswithtoken from '../../../../Data Apis/apifunctions';
import { useNavigate } from 'react-router-dom';
import {
   
    changeFeatureList,
    filesenseAppDisplayHandler,
    changeTrailExpiredMessage
   

} from '../../../../store/actions';
import { Spin } from 'antd';




const Header = ({ loading, setLoading }) => {

    const dispatch = useDispatch();
    const Navigate = useNavigate();
    const { useForFeatureList } = useDatacallswithtoken();
    const filesenseAppDisplay = useSelector((state) => state.PageData.appDisplay);
    const location = useLocation();
    const featureList = useSelector((state) => state.PageData.featureList);
    const [btnsuccess1, setBtnsuccess1] = useState(false);
    const [currentFeature, setCurrentFeature] = useState("FS");
    const [filteredGroups, setFilteredGroups] = useState([]);
    const [selectedApp, setSelectedApp] = useState(null)

   console.log(filesenseAppDisplay)

    const GetFeatureList = () => {
        useForFeatureList().then(res => {
            setLoading(false)
            if (typeof res === 'object') {
                dispatch(changeFeatureList(res))
            }
            else {
                dispatch(changeTrailExpiredMessage(res))
            }
        }).catch(err => {
            console.log(err)
            setLoading(false)
            dispatch(changeTrailExpiredMessage(err?.response?.data))
        })


    }

    useEffect(() => {
        navigator(featureList)
    }, [featureList])

   

    const navigator = () => {
        if (!featureList || Object.keys(featureList).length == 0) {
            GetFeatureList();
            // console.log(featureList)

        }
        else if (featureList && Object.keys(featureList).length > 0) {
            setLoading(false)
            console.log(featureList)
            setFilteredGroups(featureList[currentFeature])
            const pathArr = location.pathname.split("/")
            console.log(pathArr)

            if (pathArr.length > 3 && pathArr[3] == "advance" || pathArr[3] == "default") {
                console.log(pathArr[3], featureList[currentFeature])
                const feature = featureList[currentFeature].find(item => { console.log(item?.group_name); return item?.group_name.toLowerCase() == pathArr[3] })
                // Navigate(feature?.group_name?.toLowerCase()?.replaceAll(' ', '-'));
                dispatch(filesenseAppDisplayHandler(feature.group_name))
                setSelectedApp(feature);
                return;
            }
            else {

                const tempAppDisplayFilesense = featureList[currentFeature].find(item => item.isDefault != null);
                if (tempAppDisplayFilesense) {
                    setSelectedApp(tempAppDisplayFilesense);
                    dispatch(filesenseAppDisplayHandler(tempAppDisplayFilesense?.group_name))
                    Navigate(tempAppDisplayFilesense?.group_name?.toLowerCase()?.replaceAll(' ', '-'))
                }
                else {
                    setSelectedApp(featureList[currentFeature][0]);
                    dispatch(filesenseAppDisplayHandler(featureList[currentFeature][0]?.group_name))
                    Navigate(featureList[currentFeature][0]?.group_name?.toLowerCase()?.replaceAll(' ', '-'))
                }

            }
        }

    }


    // console.log(filteredGroups,featureList)
   

    return (
        <div style={{ textAlign: 'right', display: 'flex', justifyContent: "flex-end", padding: "0px 20px" }}>
            {!loading &&
                <Dropdown
                    isOpen={btnsuccess1}
                    toggle={() => setBtnsuccess1(!btnsuccess1)}
                >
                    <DropdownToggle
                        drop={'down-centered'}
                        style={{
                            backgroundColor: btnsuccess1 ? '#f4f4f4' : '',
                            fontSize: '1rem',
                        }}
                        tag='button'
                        className='btn'
                    >
                        {selectedApp?.group_name} <i className='mdi mdi-chevron-down' />
                    </DropdownToggle>
                    <DropdownMenu>
                        {filteredGroups && filteredGroups.map((item, i) => {
                            return (
                                <span
                                    key={i}
                                    onClick={() => {
                                        console.log(item?.group_name)
                                        setSelectedApp(item)
                                        Navigate(item?.group_name?.toLowerCase()?.replaceAll(' ', '-'))
                                    }}
                                >
                                    <DropdownItem>{item?.group_name}</DropdownItem>
                                </span>
                            );
                        })}
                    </DropdownMenu>
                </Dropdown>
            }
        </div>
    )
}


export default Header

