import React from 'react'
import { Button } from 'antd'
import aiMagicIcon from "../../../../../../assets/images/filesense/ai-magic.svg"
import "../../../../style/style.css"


const GradientBtn = ({ onBtnClick, name, disable }) => {
    return (
        <Button 
        disabled={disable} 
        style={{
            background:  "linear-gradient(135deg, #6253e1, #04befe)",
            opacity: disable ? 0.5 : 1,
            border: "none",
            borderRadius: '8px',
            padding: '12px 24px',
            color: "white"

        }}
            onClick={() => onBtnClick()} >
            <img src={aiMagicIcon} alt="AI Magic" style={{ marginRight: '8px' }} />
            {name}
        </Button>
    )
}

export default GradientBtn
