import { Button } from "antd";
import { useCallback, useState } from "react";

export const CommentCard = ({
  name,
  createdAt,
  content,
  onEdit,
  onDelete,
  showActions = false,
  user,
  createdUser,
}) => {
  const [isComposing, setIsComposing] = useState(false);
  const [composeValue, setComposeValue] = useState(content);

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();

      if (onEdit) {
        setIsComposing(false);

        onEdit(composeValue);
      }
    },
    [composeValue, onEdit]
  );

  return (
    <div className="comment">
      <div className="label-group">
        <label>{name}</label>
        <label>{new Date(createdAt).toLocaleTimeString()}</label>
      </div>

      {!isComposing ? (
        <div className="comment-content">
          <p>{content}</p>
          {showActions ? (
            <div
              className="button-group"
              style={{ display: "flex", justifyContent: "flex-start" }}
            >
              {user === createdUser && (
                <Button
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();

                    setIsComposing(true);
                  }}
                >
                  Edit
                </Button>
              )}
              {user === createdUser && onDelete ? (
                <Button
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();

                    onDelete();
                  }}
                >
                  Delete
                </Button>
              ) : null}
            </div>
          ) : null}
        </div>
      ) : null}

      {isComposing ? (
        <div className="comment-edit">
          <form>
            <textarea
              type="text"
              onChange={(e) => setComposeValue(e.currentTarget.value)}
              value={composeValue}
            />
            <div className="flex-row">
              <div
                className="button-group"
                style={{ display: "flex", justifyContent: "flex-start" }}
              >
                <Button onClick={() => setIsComposing(false)}>Cancel</Button>
                <Button
                  className="primary"
                  onClick={handleSubmit}
                  disabled={!composeValue.length || composeValue === content}
                >
                  Accept
                </Button>
              </div>
            </div>
          </form>
        </div>
      ) : null}
    </div>
  );
};
