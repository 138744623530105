import { message } from "antd";
import useDatacallswithtoken from "../../../../Data Apis/apifunctions";
import { useAuth0 } from "@auth0/auth0-react";

const useJDAPI = () => {
  const { user, getAccessTokenSilently } = useAuth0();
  const userID = user?.sub;
  const getconfig = async () => {
    const headers = {
      accept: "application/json",
      Authorization: `Bearer ${await getAccessTokenSilently()}`,
      "Content-Type": "application/json",
    };
    return { headers: headers };
  };
  const GenerateJD = async (body) => {
    try {
      const res = await useForGenerateJD(JSON.stringify(body));
      if (res?.status === 200) {
        return res; // Return the response data
      } else {
        throw new Error("Error generating job description");
      }
    } catch (err) {
      message.error("Error generating job description");
      console.error(err);
      throw err; // Re-throw error to handle it in onFinish
    }
  };

  const FetchPositionList = async (body) => {
    // Await the API call to ensure it completes before moving on
    const res = await useForPositionList(JSON.stringify(body));
    if (res?.status === 200) {
      return res; // Return the response data
    } else {
      throw new Error("Error fetching position list");
    }
  };

  const FetchPositionDetails = async (body) => {
    const res = await useForPositionDetails(JSON.stringify(body));
    if (res?.status === 200) {
      return res; // Return the response data
    } else {
      throw new Error("Error fetching position details");
    }
  };

  const SaveJD = async (body) => {
    const res = await useForSaveJD(body);
    if (res?.status === 200) {
      return res; // Return the response data
    } else {
      throw new Error("Error saving job description");
    }
  };

  const SubmitForApproval = async (body) => {
    const res = await useForSubmitForApproval(body);
    if (res?.status === 200) {
      return res; // Return the response data
    } else {
      throw new Error("Error submitting job description for approval");
    }
  };

  const DownloadWordFile = async (body) => {
    const res = await useForDownloadWordFile(body);

    if (res?.status !== 200) {
      throw new Error("Error downloading Word file");
    }

    // Get filename from Content-Disposition header
    const contentDisposition = res?.headers.get("Content-Disposition");

    let fileName = "downloaded_file"; // Default filename

    // Check if Content-Disposition exists and contains the `filename*` part
    if (contentDisposition && contentDisposition?.includes("filename*=")) {
      const filenamePart = contentDisposition?.split("filename*=")[1];
      // Remove the 'utf-8'' prefix and decode the URL-encoded filename
      fileName = decodeURIComponent(filenamePart?.split("''")[1]);
    }

    // Convert the response to a blob (binary large object)
    const blob = new Blob([res?.data], {
      type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    });

    // Create a link element and use it to trigger the download
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    // link.setAttribute("download", fileName); // Set file name from response

    link.download = `${fileName}`;
    document.body.appendChild(link);
    link.click();

    // Clean up the URL and remove the link
    window.URL.revokeObjectURL(url);
    link.remove();

    message.success("Word file downloaded successfully!");
  };

  const DownloadExcelFile = async (body) => {
    const res = await useForDownloadExcelFile(body);

    if (res?.status !== 200) {
      throw new Error("Error downloading Excel file");
    }

    // Get filename from Content-Disposition header
    const contentDisposition = res?.headers.get("Content-Disposition");

    let fileName = "downloaded_file"; // Default filename

    // Check if Content-Disposition exists and contains the `filename*` part
    if (contentDisposition && contentDisposition?.includes("filename*=")) {
      const filenamePart = contentDisposition?.split("filename*=")[1];
      // Remove the 'utf-8'' prefix and decode the URL-encoded filename
      fileName = decodeURIComponent(filenamePart?.split("''")[1]);
    }

    // Convert the response to a blob (binary large object)
    const blob = new Blob([res?.data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    // Create a link element and use it to trigger the download
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName); // Set file name from response
    document.body.appendChild(link);
    link.click();

    // Clean up the URL and remove the link
    window.URL.revokeObjectURL(url);
    link.remove();

    message.success("Excel file downloaded successfully!");
  };

  const FetchJobDetails = async (body) => {
    // Await the API call to ensure it completes before moving on
    const res = await useForLoadJobDetails(body);
    if (res?.status === 200) {
      return res; // Return the response data
    } else {
      throw new Error("Error fetching job details");
    }
  };

  const ApproveJD = async (body) => {
    const res = await useForApproveJD(body);
    if (res?.status === 200) {
      return res; // Return the response data
    } else {
      throw new Error("Error approving job description");
    }
  };

  const ReviseJD = async (body) => {
    const res = await useForReviseJD(body);
    if (res?.status === 200) {
      return res; // Return the response data
    } else {
      throw new Error("Error revising job description");
    }
  };

  const ShareJD = async (body) => {
    const res = await useForShareJD(body);
    if (res?.status === 200) {
      return res; // Return the response data
    } else {
      throw new Error("Error sharing job description");
    }
  };

  const FetchUnitHRList = async () => {
    const res = await useForFetchingUnitHR();
    if (res.status === 200) {
      return res; // Return the response data
    } else {
      throw new Error("Error fetching drafts list");
    }
  };

  const FetchCoreHRList = async () => {
    const res = await useForFetchingCoreHR();
    if (res.status === 200) {
      return res; // Return the response data
    } else {
      throw new Error("Error fetching drafts list");
    }
  };

  const FetchUploaderList = async () => {
    const res = await useForFetchingUploader();
    if (res.status === 200) {
      return res; // Return the response data
    } else {
      throw new Error("Error fetching drafts list");
    }
  };

  const FetchBusinessUnits = async () => {
    const res = await useForFetchingBusinessUnits();
    if (res.status === 200) {
      return res; // Return the response data
    } else {
      throw new Error("Error fetching drafts list");
    }
  };

  const FetchFunctionsByBU = async (body) => {
    const res = await useForFetchingFunctionsByBU(JSON.stringify(body));
    if (res.status === 200) {
      return res; // Return the response data
    } else {
      throw new Error("Error fetching drafts list");
    }
  };

  const FetchDepartmentsByBUAndFN = async (body) => {
    const res = await useForFetchingDepartmentsByBUAndFN(JSON.stringify(body));
    if (res.status === 200) {
      return res; // Return the response data
    } else {
      throw new Error("Error fetching drafts list");
    }
  };

  const AddUnitHR = async (body) => {
    const res = await useForAddingUnitHR(JSON.stringify(body));
    if (res.status === 200) {
      return res; // Return the response data
    } else {
      throw new Error("Error fetching drafts list");
    }
  };

  const AddCoreHR = async (body) => {
    const res = await useForAddingCoreHR(JSON.stringify(body));
    if (res.status === 200) {
      return res; // Return the response data
    } else {
      throw new Error("Error fetching drafts list");
    }
  };

  const AddUploader = async (body) => {
    const res = await useForAddingUploader(JSON.stringify(body));
    if (res.status === 200) {
      return res; // Return the response data
    } else {
      throw new Error("Error fetching drafts list");
    }
  };

  const EditUnitHR = async (body) => {
    const res = await useForUpdatingUnitHR(JSON.stringify(body));
    if (res.status === 200) {
      return res; // Return the response data
    } else {
      throw new Error("Error fetching drafts list");
    }
  };

  const EditCoreHR = async (body) => {
    const res = await useForUpdatingCoreHR(JSON.stringify(body));
    if (res.status === 200) {
      return res; // Return the response data
    } else {
      throw new Error("Error fetching drafts list");
    }
  };

  const EditUploader = async (body) => {
    const res = await useForUpdatingUploader(JSON.stringify(body));
    if (res.status === 200) {
      return res; // Return the response data
    } else {
      throw new Error("Error fetching drafts list");
    }
  };

  const DeleteUnitHR = async (body) => {
    const res = await useForDeletingUnitHR(JSON.stringify(body));
    if (res.status === 200) {
      return res; // Return the response data
    } else {
      throw new Error("Error fetching drafts list");
    }
  };

  const DeleteCoreHR = async (body) => {
    const res = await useForDeletingCoreHR(JSON.stringify(body));
    if (res.status === 200) {
      return res; // Return the response data
    } else {
      throw new Error("Error fetching drafts list");
    }
  };

  const DeleteUploader = async (body) => {
    const res = await useForDeletingUploader(JSON.stringify(body));
    if (res.status === 200) {
      return res; // Return the response data
    } else {
      throw new Error("Error fetching drafts list");
    }
  };

  const SubmitFeedback = async (body) => {
    const res = await useForSubmitFeedback(body);

    if (res.status === 200) {
      return res; // Return the response data
    } else {
      throw new Error("Error submitting feedback list");
    }
  };
  const RefineContent = async (body) => {
    const res = await useForRefineContent(body);

    if (res.status === 200) {
      return res; // Return the response data
    } else {
      throw new Error("Error submitting feedback list");
    }
  };

  const {
    useForGenerateJD,
    useForPositionList,
    useForPositionDetails,
    useForSaveJD,
    useForSubmitForApproval,
    useForDownloadWordFile,
    useForDownloadExcelFile,
    useForLoadJobDetails,
    useForApproveJD,
    useForReviseJD,
    useForShareJD,
    useForFetchingUnitHR,
    useForFetchingCoreHR,
    useForFetchingUploader,
    useForFetchingBusinessUnits,
    useForFetchingFunctionsByBU,
    useForFetchingDepartmentsByBUAndFN,
    useForAddingUnitHR,
    useForAddingCoreHR,
    useForAddingUploader,
    useForUpdatingUnitHR,
    useForUpdatingCoreHR,
    useForUpdatingUploader,
    useForDeletingUnitHR,
    useForDeletingCoreHR,
    useForDeletingUploader,
    useForSubmitFeedback,
    useForRefineContent,
  } = useDatacallswithtoken();

  return {
    GenerateJD,
    FetchPositionList,
    FetchPositionDetails,
    SaveJD,
    SubmitForApproval,
    DownloadWordFile,
    DownloadExcelFile,
    FetchJobDetails,
    ApproveJD,
    ReviseJD,
    ShareJD,
    FetchUnitHRList,
    FetchCoreHRList,
    FetchUploaderList,
    FetchBusinessUnits,
    FetchFunctionsByBU,
    FetchDepartmentsByBUAndFN,
    AddUnitHR,
    AddCoreHR,
    AddUploader,
    EditUnitHR,
    EditCoreHR,
    EditUploader,
    DeleteUnitHR,
    DeleteCoreHR,
    DeleteUploader,
    SubmitFeedback,
    RefineContent,
  };
};
export default useJDAPI;
