import React, { useEffect, useState } from 'react';
import { Collapse, Input, notification } from 'antd';
import { CheckOutlined, CopyOutlined, EditOutlined, Loading3QuartersOutlined, LoadingOutlined } from '@ant-design/icons';
import { Flex, Button } from 'antd'; // Assuming Flex is correctly imported from your custom components;
import "../../../style/style.css";
import useDatacallswithtoken from '../../../../../Data Apis/apifunctions';

const { TextArea } = Input;
const { Panel } = Collapse;

const AccordionItem = ({ topicObj, analysesId, setPromptsSaved, setSessionFlag,
    question, prompt: initialPrompt, sessionPrompt: initialSessionPrompt, example: initialExample, setSystemPrompt, systemPrompt }) => {
    const { useForUpdateFSPrompt, useForUpdateFSExample } = useDatacallswithtoken()
    const [isEditing, setIsEditing] = useState(false);
    const [activeKey, setActiveKey] = useState([]);
    const [initialAnswer, setInitialAnswer] = useState(null);

    const [saveResponseLoading, setSaveResponseLoading] = useState(false)


    const [prompt, setPrompt] = useState(initialPrompt);
    const [example, setExample] = useState(initialExample);
    const [sessionPrompt, setSessionPrompt] = useState(initialSessionPrompt);

    const [currentAnswer, setCurrentAnswer] = useState();
    // console.log(question)
    useEffect(() => {
        if (question == "System Prompt") { setCurrentAnswer(prompt); setInitialAnswer(prompt) }
        else if (question == "Session Prompt") { setCurrentAnswer(sessionPrompt); setInitialAnswer(sessionPrompt) }
        else if (question == "Examples") { setCurrentAnswer(example); setInitialAnswer(example) }

    }, [question])

    // console.log(topicObj)

    const handleSave = (e) => {
        e.stopPropagation();
        setIsEditing(false);
        setPromptsSaved(prev => ({ ...prev, [question]: true }));
        if (question == "Session Prompt") setSessionFlag(true)
        // console.log(topicObj)
        const data = {
            analysisId: analysesId,
            TopicId: topicObj?.analysis_topic_id,
            analysisStageId: topicObj?.analysis_stages_id,
            promptId: topicObj?.prompt_id,
            prompt: question == "System Prompt" ? currentAnswer : null,
            example: question == "Examples" ? currentAnswer : null,
            exampleId: topicObj.example_id,
            sessionPrompt: question == "Session Prompt" ? currentAnswer : null,
            sessionPromptId: topicObj.session_prompt_id,
            sessionFlag: question == "Session Prompt"
        };
        if (currentAnswer != initialAnswer) {
            if (question == "System Prompt" || question == "Session Prompt") {
                SavePrompt(data);
            }
            else if (question == "Examples") {
                SaveExample(data);
            }
            setInitialAnswer(currentAnswer)
        }

        console.log(question)

    };

    const SavePrompt = (data) => {
        setSaveResponseLoading(true)
        useForUpdateFSPrompt(data).then(res => {
            setSaveResponseLoading(false)
            console.log(res)

            notification.success({
                message: 'Saved',
                description: `${question} has been saved`,
                placement: 'topRight',
            });
        }).catch(err => {
            setSaveResponseLoading(false)
            notification.error({
                message: 'Error',
                description: err.message,
                placement: 'topRight',
            });
            console.log(err.message)
        })

    }

    const SaveExample = (data) => {

        setSaveResponseLoading(true)
        useForUpdateFSExample(data).then(res => {
            setSaveResponseLoading(false)
            console.log(res)

            notification.success({
                message: 'Saved',
                description: `${question} has been saved`,
                placement: 'topRight',
            });
        }).catch(err => {
            setSaveResponseLoading(false)
            notification.error({
                message: 'Error',
                description: err.message,
                placement: 'topRight',
            });
            console.log(err.message)
        })

    }

    const handleEdit = (e) => {
        if (!activeKey.includes('1')) {
            setActiveKey(['1'])
        }
        else e.stopPropagation();
        setIsEditing(true);

        // setActiveKey(['1']);
    };

    const handleChange = (e) => {
        setCurrentAnswer(e.target.value);
        // if (question == "System Prompt") setSystemPrompt(e.target.value)

        setPromptsSaved(prev => ({ ...prev, [question]: false }))
    };
    // const handleButtonClick = () => {
    //     // Manually control which panel to open (e.g., open panel '2')
    //     setActiveKey(['2']);
    //   };
    // console.log(activeKey)
    const handleCopySystemPromptToSessionPrompt = (e, systemPrompt) => {
        if (!activeKey.includes('1')) {
            setActiveKey(['1'])
        }
        else e.stopPropagation();
        setIsEditing(true)
        setPromptsSaved(prev => ({ ...prev, [question]: false }));
        setCurrentAnswer(systemPrompt)
    }
    return (
        <>
            {question &&
                <Collapse accordion style={{ width: "100%" }} onChange={(key) => setActiveKey(key)} activeKey={activeKey}>
                    <Panel
                        key='1'
                        header={(
                            <Flex justify='space-between'>
                                <Flex className='font-col-666'>{question}</Flex>
                                <Flex gap={20}>
                                    {question == "Session Prompt" &&
                                        <Button size='small' type='text' 
                                            style={{ boxShadow: "none" }}
                                            onClick={(e) => handleCopySystemPromptToSessionPrompt(e, systemPrompt)}>
                                            Copy System Prompt <CopyOutlined/>
                                        </Button>}
                                    {saveResponseLoading ? <LoadingOutlined /> :
                                        isEditing ? (
                                            <CheckOutlined
                                                className='cursor-pointer'
                                                style={{ color: '#b3b3b3' }}
                                                onClick={handleSave}
                                            />
                                        ) : (
                                            <EditOutlined
                                                className='cursor-pointer'
                                                style={{ color: '#b3b3b3' }}
                                                onClick={handleEdit}
                                            />
                                        )}
                                </Flex>

                            </Flex>
                        )}
                    >
                        <TextArea
                            className='editable-text-area'
                            autoSize
                            allowClear
                            variant='borderless'
                            value={currentAnswer}
                            placeholder={!currentAnswer || currentAnswer.length ? "No data" : ""}
                            disabled={!isEditing}
                            onChange={handleChange}
                        />
                    </Panel>
                </Collapse>
            }
        </>
    );
};

export default AccordionItem;
