import { Alert, Button, Descriptions, Flex, Skeleton, Steps } from 'antd'
import React, { useEffect, useState } from 'react'
import StepSidebar from './StepSidebar'
import "../../../style/style.css"
import { step1, step2 } from '../../data'
// import Step1 from './Step1'
import Step1 from './Step1'
import Step2 from './Step2'
import SimpleBar from 'simplebar-react';
import useDatacallswithtoken from '../../../../../Data Apis/apifunctions'
import { useNotification } from '../NotificationProvider'
import Step3 from './Step3'
import { useAuth0 } from '@auth0/auth0-react'
import BlackBtn from './Elements/BlackBtn'
import { CloseOutlined } from '@ant-design/icons'


const StepComponent = ({ singleAnalysis, selecTedTopicItem: initialSelectedItem, analysisId, handleDisplaySteps, allQuestionsSaved, setAllQuestionsSaved, fileList }) => {
  // console.log("analysisId",analysisId )
  const { user } = useAuth0();
  // console.log(user)
  const notification = useNotification();

  const { useForAnalysisFullDataByStep } = useDatacallswithtoken();
  const [selecTedTopicItem, setSelecTedTopicItem] = useState(initialSelectedItem);
  // const [allQuestionsSaved, setAllQuestionsSaved] = useState({});

  const [step1Data, setStep1Data] = useState();
  const [step2Data, setStep2Data] = useState();
  const [step3Data, setStep3Data] = useState();
  const [isPdfDownloading, setisPdfDownloading] = useState(false);
  const titleForNotification='Unsaved changes detected'


  const emailLast = user?.email ? user?.email.split("@")[user.email.split("@").length - 1] : "user";
  // const emailLast="mail.com"
  const steps = emailLast == "actalyst.ai" ? [
    {
      title: 'Data',
      content: 'First-content',
      description: ""
    },
    {
      title: 'Drafts',
      content: '',
      description: ""
    },
    {
      title: 'Summary',
      content: 'Last-content',
      description: ""
    },
  ]
    :
    [
      {
        title: 'Drafts',
        content: 'Second-content',
        description: ""
      },
      {
        title: 'Summary',
        content: 'Last-content',
        description: ""
      },
    ];


  const [current, setCurrent] = useState(steps.length-1);
  const stepName = steps[current]?.title;
  const [loading, setLoading] = useState(true);
  const [curStepname, setCurStepname] = useState(steps[current]?.title);

  console.log(curStepname)

  // console.log(emailLast);

  useEffect(() => {
    setCurStepname(stepName)
  }, [current])


  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
    description: item.description,
  }))





  const FetchStepData = (analysesId, selecTedTopicItem, step) => {
    setLoading(true);
    useForAnalysisFullDataByStep(analysesId, selecTedTopicItem, step).then((res) => {
      setLoading(false);
      console.log(res);
      let result = res
      if (res?.topic_stage_data && res.topic_stage_data.length == 0) {
        result = null;
      }
      if (step == "Data") setStep1Data(result);
      else if (step == "Drafts") setStep2Data(result);
      else if (step == "Summary") setStep3Data(result);

    }).catch((err) => {

      if (step == "Data") setStep1Data(null);
      else if (step == "Drafts") setStep2Data(null);
      else if (step == "Summary") setStep3Data(null);

      setLoading(false)
      console.log(err)
      notification.error({
        message: "Error",
        description: err.message,
        placement: 'topRight',
      });

    })
  }




  useEffect(() => {

    FetchStepData(analysisId, selecTedTopicItem?.analysis_topic_id, stepName)

  }, [selecTedTopicItem, current, analysisId])

  const handleChangeSelectedTopic = (selecTedTopicItem) => {
    setSelecTedTopicItem(selecTedTopicItem)
  }

  const handleStepsComplete = () => {
    const key = `open${Date.now()}`;

    if (!Object.values(allQuestionsSaved).every(value => value === true)) {
      const btn = <BlackBtn name={"Close Editing"} onBtnClick={() => {
        notification.destroy(key)
        handleDisplaySteps(false)
        setAllQuestionsSaved({})
      }} />

      notification.warning({
        key,
        message: titleForNotification,
        description: `Save your changes to complete editing or click 'Close Editing' to discard them`,
        btn,
        placement: 'topRight',
      })

    } else {
      handleDisplaySteps(false)
      notification.success({
        message: 'Editing is complete',
        // description: 'This is demo description',
        placement: 'topRight',
        key,
      });
    }

  }


  const next = () => {
    const key = `open${Date.now()}`;
  
    if (!Object.values(allQuestionsSaved).every(value => value === true)) {
      const btn = <BlackBtn name={"Move to next"} onBtnClick={() => { notification.destroy(key); setCurrent(current + 1); setAllQuestionsSaved({}) }} />

      notification.warning({
        message: titleForNotification,
        description: `Save your changes before moving to the next step or click 'Move to next' to continue without saving`,
        btn,
        placement: 'topRight',
      })

    } else {
      setCurrent(current + 1);
    }

  };
  const prev = () => {
    const key = `open${Date.now()}`;
    if (!Object.values(allQuestionsSaved).every(value => value === true)) {
      const btn = <BlackBtn name={"Move back"} onBtnClick={() => {notification.destroy(key); setCurrent(current - 1); setAllQuestionsSaved({}) }} />

      notification.warning({
        key,
        message: titleForNotification,
        description: `Save your changes before moving to the previous step or click 'Move back' to continue without saving`,
        btn,
        placement: 'topRight',
      })

    } else {

      setCurrent(current - 1);
    }
  };
  const handleCloseSteps = () => {
    const key = `open${Date.now()}`;
    if (!Object.values(allQuestionsSaved).every(value => value === true)) {
      const btn = <BlackBtn name={"Close Analysis"} onBtnClick={() => { notification.destroy(key); handleDisplaySteps(false); setAllQuestionsSaved({}) }} />

      notification.warning({
        key,
        message: titleForNotification,
        description: `Save your changes before closing or click 'Close Analysis' to exit without saving.`,
        btn,
        placement: 'topRight',
      })

    } else {
      handleDisplaySteps(false)
    }
  }
  const handleStepChange = (newStep) => {
    const key = `open${Date.now()}`;
    if (!Object.values(allQuestionsSaved).every(value => value === true)) {
      const btn = <BlackBtn name={"Move to " + steps[newStep].title} onBtnClick={() => {notification.destroy(key); setCurrent(newStep); setAllQuestionsSaved({}) }} />

      notification.warning({
        key,
        message: titleForNotification,
        description: `Save your changes before switching steps or click 'Move to ${steps[newStep].title}' to continue without saving` ,
        btn,
        placement: 'topRight',
      })

    } else {
      setCurrent(newStep);
    }

  };
  const handleTopicChange = (item) => {
    setCurrent(steps.length-1)
    setSelecTedTopicItem(item)
    // console.log(item)
  }
  
  function saveChanges(promptsSaved) {
    promptsSaved && setAllQuestionsSaved(prev => ({ ...prev, ...promptsSaved }))
  }

 

  return (
    <Flex className='w100-h100' style={{ position: "relative" }}>
      <CloseOutlined style={{ position: "absolute", top: "15px", right: "15px", fontSize: "17px", color: "#999" }} onClick={() => { handleCloseSteps() }} />
      <StepSidebar
        fileList={fileList}
        singleAnalysis={singleAnalysis}
        selecTedTopicItem={selecTedTopicItem}
        onTopicChange={handleTopicChange}
        allQuestionsSaved={allQuestionsSaved}
        setAllQuestionsSaved={setAllQuestionsSaved}
      />

      <Flex className='w100-h100 ' justify='space-between' vertical gap={20} style={{ padding: "20px 20px 10px 20px" }} >
        <Steps current={current} items={items} style={{ padding: "0px 40px 0px 20px" }} onChange={handleStepChange} />

        <SimpleBar style={{ height: "70vh", maxHeight: "70vh" }}>
          <Flex className='w100-h100 '  >

            {
              curStepname == "Data" ?
                (loading ? <Skeleton title={false} paragraph={{ rows: 3 }} /> :
                  step1Data && <Step1 step1Data={step1Data} analysisId={analysisId} setAllQuestionsSaved={setAllQuestionsSaved} />) :
                curStepname == "Drafts" ?
                  (loading ? <Skeleton title={false} paragraph={{ rows: 3 }} /> :
                    step2Data && <Step2 step2Data={step2Data} analysisId={analysisId} saveChanges={saveChanges} />) :
                  curStepname == "Summary" ?
                    (loading ? <Skeleton title={false} paragraph={{ rows: 3 }} /> :
                      step3Data && <Step3 step3Data={step3Data} analysisId={analysisId} setAllQuestionsSaved={setAllQuestionsSaved} />) :
                    null

            }
            {!step1Data?.topic_stage_data &&
              !step2Data?.topic_stage_data &&
              !step3Data?.topic_stage_data
              && !loading && <Alert type='info' message='No analysis to display. Try to change topic to get analysis' />
            }

          </Flex>
        </SimpleBar>

        <Flex gap={20} justify='end' style={{ bottom: "0px", right: "0px", width: "100%", zIndex: "3", background: "white" }}>
          {current > 0 && (
            <Button
              style={{
                margin: '0 8px',
              }}
              onClick={() => prev()}
            >
              Previous
            </Button>
          )}
          {current < steps.length - 1 && (
            <Button type="primary" onClick={() => next()}>
              Next
            </Button>
          )}
          {current == steps.length - 1 && (
            <Button type="primary" onClick={() => { handleStepsComplete() }}>
              Editing Complete
            </Button>
          )}
        </Flex>
      </Flex>
    </Flex>
  )
}

export default StepComponent

