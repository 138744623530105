import React from 'react'

const SingleQuestion = ({chatQuestion}) => {
  return (
    <div style={{width:"100%",display:"flex"}}>
        <div style={{ fontSize: "16px",fontStyle: "normal",fontWeight: "600",lineHeight: "22px",paddingLeft:"5px"}}>

      {chatQuestion}
        </div>
    </div>
  )
}

export default SingleQuestion
