import React from "react";
import { Navigate } from "react-router-dom";

//Dashboard
import Dashboard from "../Pages/Dashboard";

// demo dashboard
import DemoDashboard from "../Pages/Demo Dashboard";

import Explore from "../Pages/Explore";

// Unstructured
import Unstructured from "../Pages/Unstructured";

// Summary Page

//Database
import ConnectToDb from "../Pages/Database/connecttodatabase";
import EditDbConnection from "../Pages/Database/editdbconnection";
import HomeRedirect from "../Layout/VerticalLayout/HomeRedirect";
// verify email
import VerifyEmailMessage from "../Pages/VerifyEmail";

// Subscribe Message
import SubscribeMessage from "../Pages/SubscribeMessage";

// Content Search
import ContentSearchNew from "../Pages/New Content Search";

import ContentSearch from "../Pages/Content Search";

// Writing Asistant
import WritingAsistant2 from "../Pages/Writing Asistant2";
import WritingAssistant from "../Pages/WritingAssistant";

// Admin Dashboard
import AdminDashboard from "../Pages/Admin Dashboard"

import Layout from "../Layout/VerticalLayout/index"

// addFile as connection
import AddFileAsConnection from "../Pages/AddFileAsConnection";
// chat with data
import ChatWithData from "../Pages/Chat with data";
// Import Authentication pages
// import Login from "../Pages/Authentication/Login";
// import ForgetPasswordPage from "../Pages/Authentication/ForgetPassword";
// import Logout from "../Pages/Authentication/Logout";
// import Register from "../Pages/Authentication/Register";
import UserProfile from "../Pages/Authentication/user-profile";

import Contact from "../Pages/Contact_Us";

import Trailexpaired from "../Pages/trailExpaired";
import Filesense from "../Pages/Filesense/Filesense";
const authProtectedRoutes = [
  //dashboard
  // { path: "/app/home", component: <Dashboard /> },
  { path: "/app/connect-to-db", component: <ConnectToDb /> },
  { path: "/edit-db-connection", component: <EditDbConnection /> },
  { path: "/app/filesense/*", component: <Filesense /> },
  { path: "/app/subscribe", component: <SubscribeMessage /> },
  { path: "/app/user-status", component: <Trailexpaired /> },
  { path: "/app/explore", component: <Explore /> },
  { path: "/app/insights/*", component: <ContentSearch /> },
  { path: "/app/insights-new", component: <ContentSearchNew /> },
  { path: "/app/writing-asistant", component: <WritingAsistant2 /> },
  { path: "/app/writing-assistant/*", component: <WritingAssistant /> },
  
  { path: "/app/add-file-as-connection", component: <AddFileAsConnection /> },
  { path: "/app/demo-home", component: <DemoDashboard /> },
  { path: "/app/assistant", component: <ChatWithData /> },
  { path: "/app/analytics", component: <AdminDashboard /> },
  { path: "app/contact-us", component: <Contact /> },
  // { path: "/app/legal", component: <Legal /> },
  // Profile
  { path: "/userprofile", exact: true, component: <UserProfile /> },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: <HomeRedirect/>,
  },
  {
    path: "/app",
    exact: true,
    component: <HomeRedirect/>,
  },
  {
    path: "/app/home",
    exact: true,
    component: <Navigate to="/app" />,
  },
];



const publicRoutes = [
  // Authentication Page
  // { path: "/logout", component: <Logout /> },
  // { path: "/login", component: <Login /> },
  // { path: "/forgot-password", component: <ForgetPasswordPage /> },
  // { path: "/register", component: <Register /> },
  { path: "/verify-email", component: <VerifyEmailMessage /> },
];

export { authProtectedRoutes, publicRoutes };
