import React, { useEffect, useState } from 'react'
import { Flex, Typography, Input, notification } from 'antd'
import "../../../style/style.css"
import { useNotification } from '../NotificationProvider'
import useDatacallswithtoken from '../../../../../Data Apis/apifunctions'
import { CheckOutlined, EditOutlined } from '@ant-design/icons'
import { update } from 'lodash'


const { TextArea } = Input

const { Title, Text } = Typography

const SingleQuestion = ({ questionObj:initialQuestionObj, analysisId, setAllQuestionsSaved }) => {
    const [questionObj,setQuestionObj] = useState(initialQuestionObj)
    console.log(questionObj,analysisId)

    const [question, setQuestion] = useState(questionObj?.question)
    const [answer, setAnswer] = useState(questionObj?.output);
    const { useForUpdateFSQuestion, useForUpdateFSAnswer } = useDatacallswithtoken();
    const notification = useNotification()
    const [questionchange, setQuestionChange] = useState(false);
    const [answerChange, setAnswerChange] = useState(false);
    const [expand, setExpand] = useState(false);

    const [ansLength, setAnsLenth] = useState(300);
    const [edit, setEdit] = useState(false);
    // console.log(questionObj)
    const HandleSave = (questionObj, analysisId,question,answer) => {
        console.log(question)
        setEdit(false);
        const data = {
            analysisId: analysisId,
            TopicId: questionObj.analysis_topic_id,
            analysisStageId: questionObj.analysis_stages_id,
            questionId: questionObj.question_id,
            question: question,
            output: answer
        };
        setAllQuestionsSaved(prev => ({...prev,[questionObj.question_id]:true}))
        if (questionchange) UpdateQuestion(data)
        if (answerChange) UpdateAnswer(data)




    }
    const UpdateAnswer = (data) => {
        useForUpdateFSAnswer(data).then(res => {
            setEdit(false);
            console.log(res);
            setAnswerChange(false);
            notification.success({
                message: 'Saved',
                description: `${question} is been saved`,
                placement: 'topRight',
            });
        }).catch(err => {
            setEdit(false)
            notification.success({
                message: `${question} is not been saved`,
                description: err.message,
                placement: 'topRight',
            });
        })
    }


    const UpdateQuestion = (data) => {
        useForUpdateFSQuestion(data).then(res => {
            setEdit(false);
            console.log(res);
            setQuestionChange(false);
            notification.success({
                message: 'Saved',
                description: `${question} is been saved`,
                placement: 'topRight',
            });
        }).catch(err => {
            setEdit(false)
            notification.success({
                message: `${question} is not been saved`,
                description: err.message,
                placement: 'topRight',
            });
        })
    }
    const handleEdit = () => {
      setEdit(true);
      
    }


    const handleTextArea = (e) => {
        // console.log(e.target.value)
        setAllQuestionsSaved(prev => ({...prev,[questionObj.question_id]:false}))
        setAnswer(e.target.value)
        setAnswerChange(true)
    }
    return (
        <Flex className='p15' vertical gap={5}>
            <Flex justify='space-between'>
                <Title className='font-col-666' level={5} style={{ margin: "0px" }}>{question}</Title>
                {edit ?
                    <CheckOutlined className='cursor-pointer' style={{ color: "#b3b3b3" }} onClick={() => HandleSave(questionObj, analysisId,question,answer)} /> :
                    <EditOutlined className='cursor-pointer' style={{ color: "#b3b3b3" }} onClick={() => handleEdit()} />
                }
            </Flex>
            <TextArea className='editable-text-area'
                autoSize allowClear variant='borderless'
                disabled={!edit}
                value={answer}
                onChange={(e) => handleTextArea(e)}
            />
        </Flex>
    )
}

export default SingleQuestion
