import React, { useEffect } from "react";
// import ReactGA from 'react-ga';
import Routes from "./Routes/index";
import { Helmet } from "react-helmet";
import CustomSnackbar from "./components/Common/CustomSnackbar";
// Import Scss
import "./assets/scss/theme.scss";
import fakeBackend from "./helpers/AuthType/fakeBackend";
import { useLocation } from "react-router-dom";
// import RouteChangeTracker from './RouteChangeTracker'
// Initialize React Ga with your tracking ID
// ReactGA.initialize('UA-261534490-1');

// Fake Backend

// Activating fake backend
fakeBackend();

function App() {
  const location = useLocation();
  const newrelic = "https://actstaticfiles.s3.amazonaws.com/newrelic.js";
  // Store current URL in session storage when the component first mounts
  useEffect(() => {
    // Check if 'initialUrl' is already set in session storage
    const storedUrl = sessionStorage.getItem("initialUrl");
    if (!storedUrl) {
      // Only set 'initialUrl' if it is not already present in session storage
      const currentUrl = location.pathname;
      sessionStorage.setItem("initialUrl", currentUrl);
      console.log(currentUrl, "initial url set");
    } else {
      console.log(storedUrl, "initial url already present");
    }
  }, []); // Empty dependency array ensures this runs only once
  const shouldReturnNewrelic = () => {
    if (process.env.REACT_APP_NULERIC_ENABLED === "true") {
      return (
        <Helmet async defer src={newrelic}>
          <script src={newrelic}></script>
        </Helmet>
      );
    }
    return null;
  };
  return (
    <React.Fragment>
      {shouldReturnNewrelic()}
      {/* return <div>
        <RouteChangeTracker />
      </div> */}
      <Routes />
      <CustomSnackbar />
    </React.Fragment>
  );
}

export default App;
