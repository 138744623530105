import React, { useCallback } from 'react';
import { useThreadsState } from './threadsProvider';
import { CommentCard } from './CommentCard';
import { ThreadCard } from './ThreadCard';
import { ThreadComposer } from './ThreadComposer';
import { useAuth0 } from '@auth0/auth0-react';
import { Button, notification } from 'antd';
import { useSelector } from 'react-redux';

export const ThreadsListItem = ({ thread, provider, active, open }) => {
  const { onClickThread, deleteThread, onHoverThread, onLeaveThread } =
    useThreadsState();
  const { user } = useAuth0();
  const editor = useSelector((state) => state.PageData.editor);

  const debugScrollToThread = useCallback(
    (threadId) => {
      if (!editor) {
        return;
      }

      // Get the DOM element of the editor content
      const editorDOM = editor.view.dom;

      // Find the element with data-thread-id == threadId
      const threadElement = editorDOM.querySelector(
        `span[data-thread-id="${threadId}"]`
      );

      if (threadElement) {
        // Scroll the element into view
        threadElement.scrollIntoView({ behavior: 'auto', block: 'center' });
      }
    },
    [editor]
  );

  const handleThreadClick = useCallback(() => {
    if (!open) {
      onClickThread(thread.id);
      debugScrollToThread(thread.id);
    }
  }, [open, onClickThread, debugScrollToThread, thread.id]);

  const handleDeleteClick = useCallback(() => {
    deleteThread(thread.id);
  }, [thread.id, deleteThread]);

  const editComment = useCallback(
    (commentId, val) => {
      const comment = thread.comments.find(
        (comment) => comment.id === commentId
      );

      // Check if the current user is the creator of the comment
      if (comment && comment.data.userName === user?.email) {
        provider.updateComment(thread.id, commentId, { content: val });
      } else {
        notification.error({
          message: 'Permission Denied',
          description: 'You are not allowed to edit this comment.',
        });
      }
    },
    [provider, thread.id, user?.email, thread.comments]
  );

  const deleteComment = useCallback(
    (commentId) => {
      const comment = thread.comments.find(
        (comment) => comment.id === commentId
      );

      // Check if the current user is the creator of the comment
      if (comment && comment.data.userName === user?.email) {
        provider.deleteComment(thread.id, commentId);
      } else {
        notification.error({
          message: 'Permission Denied',
          description: 'You are not allowed to delete this comment.',
        });
      }
    },
    [provider, thread.id, user?.email, thread.comments]
  );

  const handleClickOutside = useCallback(() => {
    onClickThread();
  }, [onClickThread]);

  return (
    <div
      onMouseEnter={() => onHoverThread(thread.id)}
      onMouseLeave={() => onLeaveThread(thread.id)}
      onClick={() => debugScrollToThread(thread.id)}
    >
      <ThreadCard
        id={thread.id}
        active={active}
        open={open}
        onClick={handleThreadClick}
        onClickOutside={handleClickOutside}
      >
        {open ? (
          <>
            <div className='header-group'>
              {user?.email === thread.data.userData.id && (
                <div className='button-group'>
                  <Button onClick={handleDeleteClick}>Delete Thread</Button>
                </div>
              )}
            </div>
            <div className='comments-group'>
              {thread.comments.map((comment) => (
                <CommentCard
                  key={comment.id}
                  name={comment.data.userName}
                  content={comment.content}
                  createdAt={comment.createdAt}
                  onEdit={(val) => editComment(comment.id, val)}
                  onDelete={() => deleteComment(comment.id)}
                  showActions={true}
                  user={user?.email}
                  createdUser={comment.data.userName}
                />
              ))}
            </div>
            <div className='reply-group'>
              <ThreadComposer threadId={thread.id} provider={provider} />
            </div>
          </>
        ) : (
          <div className='comments-group'>
            <CommentCard
              key={thread.comments[0].id}
              name={thread.comments[0].data.userName}
              content={thread.comments[0].content}
              createdAt={thread.comments[0].createdAt}
            />
            <div className='comments-count'>
              <label>
                {thread.comments.length - 1 || 0}{' '}
                {(thread.comments.length - 1 || 0) === 1 ? 'reply' : 'replies'}
              </label>
            </div>
          </div>
        )}
      </ThreadCard>
    </div>
  );
};
