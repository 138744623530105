import React, { useState } from "react";

const Tooltip = ({ text, children }) => {
  const [visible, setVisible] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });

  const showTooltip = (e) => {
    const { clientX, clientY } = e;
    setTooltipPosition({ top: clientY + 10, left: clientX + 10 }); // Adjust the position slightly below and to the right of the cursor
    setVisible(true);
  };

  const hideTooltip = () => {
    setVisible(false);
  };

  const tooltipContainerStyle = {
    position: "relative",
    display: "inline-block",
  };

  const tooltipBoxStyle = {
    position: "fixed", // Use fixed position to position relative to viewport
    top: `${tooltipPosition.top}px`,
    left: `${tooltipPosition.left}px`,
    backgroundColor: "#f4f4f4",
    color: "#333",
    textAlign: "center",
    padding: "5px",
    borderRadius: "3px",
    whiteSpace: "nowrap",
    zIndex: 1000,
    opacity: visible ? 1 : 0,
    transition: "opacity 0.3s",
    visibility: visible ? "visible" : "hidden",
    pointerEvents: "none", // Ensure the tooltip does not interfere with mouse events
  };

  return (
    <div
      style={tooltipContainerStyle}
      onMouseMove={showTooltip}
      onMouseLeave={hideTooltip}
    >
      {children}
      <div style={tooltipBoxStyle}>{text}</div>
    </div>
  );
};

export default Tooltip;


const temp=[
  {
      "Today": []
  },
  {
      "Last Week": []
  },
  {
      "Last month": [
          {
              "error": false,
              "analysis_list": [
                  {
                      "analysis_id": 2,
                      "analysis_name": "MBR",
                      "modified_date": "2024-08-30T00:20:17.105267"
                  },
                  {
                      "analysis_id": 9,
                      "analysis_name": "MBR",
                      "modified_date": "2024-09-11T11:04:30.215351"
                  },
                  {
                      "analysis_id": 10,
                      "analysis_name": "MBR",
                      "modified_date": "2024-09-11T11:05:13.145861"
                  },
                  {
                      "analysis_id": 11,
                      "analysis_name": "MBR",
                      "modified_date": "2024-09-11T11:19:04.094557"
                  },
                  {
                      "analysis_id": 12,
                      "analysis_name": "MBR",
                      "modified_date": "2024-09-11T11:52:46.625065"
                  },
                  {
                      "analysis_id": 13,
                      "analysis_name": "MBR",
                      "modified_date": "2024-09-11T11:53:05.788018"
                  },
                  {
                      "analysis_id": 22,
                      "analysis_name": "MBR",
                      "modified_date": "2024-09-11T14:44:43.333041"
                  },
                  {
                      "analysis_id": 23,
                      "analysis_name": "MBR",
                      "modified_date": "2024-09-11T14:46:02.113749"
                  },
                  {
                      "analysis_id": 24,
                      "analysis_name": "MBR",
                      "modified_date": "2024-09-11T14:54:27.080235"
                  },
                  {
                      "analysis_id": 25,
                      "analysis_name": "MBR",
                      "modified_date": "2024-09-11T14:54:36.358140"
                  },
                  {
                      "analysis_id": 26,
                      "analysis_name": "MBR",
                      "modified_date": "2024-09-11T14:56:39.663061"
                  },
                  {
                      "analysis_id": 27,
                      "analysis_name": "MBR",
                      "modified_date": "2024-09-11T15:03:21.977466"
                  },
                  {
                      "analysis_id": 28,
                      "analysis_name": "MBR",
                      "modified_date": "2024-09-12T10:04:24.995079"
                  },
                  {
                      "analysis_id": 29,
                      "analysis_name": "MBR",
                      "modified_date": "2024-09-12T10:05:39.242066"
                  },
                  {
                      "analysis_id": 30,
                      "analysis_name": "MBR",
                      "modified_date": "2024-09-12T10:32:33.592116"
                  },
                  {
                      "analysis_id": 31,
                      "analysis_name": "MBR",
                      "modified_date": "2024-09-12T10:34:12.034415"
                  },
                  {
                      "analysis_id": 32,
                      "analysis_name": "MBR",
                      "modified_date": "2024-09-12T10:36:43.843683"
                  },
                  {
                      "analysis_id": 33,
                      "analysis_name": "MBR",
                      "modified_date": "2024-09-12T10:38:26.060272"
                  },
                  {
                      "analysis_id": 34,
                      "analysis_name": "MBR",
                      "modified_date": "2024-09-12T10:39:13.466651"
                  }
              ]
          }
      ]
  }
]