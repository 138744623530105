import React from 'react';

import { Row, Col, Card, CardBody, CardText, CardTitle } from 'reactstrap';
import { Link } from 'react-router-dom';

const Defaultrender = (props) => {
  // default component for instructions
  return (
    <React.Fragment>
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody>
              <h5
                className='card-titlemb-4 text-center fw-bold'
                style={{ fontSize: '1.5rem' }}
              >
                FileSense Guidelines
              </h5>
              <Row className='justify-content-center mt-5'>
                <Col lg={12}>
                  <Row className='justify-content-center'>
                    <Col lg={5}>
                      <div className='faq-box d-flex mt-4'>
                        <div className='flex-shrink-0 me-3'>
                          <div className='avatar-xs'>
                            <span className='avatar-title rounded-circle bg-primary'>
                              01
                            </span>
                          </div>
                        </div>
                        <div className='flex-grow-1'>
                          <h5 className='font-size-15'>What is FileSense?</h5>
                          <p className='text-muted'>
                            FileSense is a powerful AI engine that is designed
                            to answer questions about the file you upload.
                          </p>
                        </div>
                      </div>
                      <div className='faq-box d-flex mt-4'>
                        <div className='flex-shrink-0 me-3'>
                          <div className='avatar-xs'>
                            <span className='avatar-title rounded-circle bg-primary'>
                              02
                            </span>
                          </div>
                        </div>
                        <div className='flex-grow-1'>
                          <h5 className='font-size-15'>How do I start?</h5>
                          <p className='text-muted'>
                            Upload your file, wait for it to load, then type
                            your question in the chat box. FileSense will
                            analyze the document and provide relevant answers.{' '}
                          </p>
                        </div>
                      </div>
                      <div className='faq-box d-flex mt-4'>
                        <div className='flex-shrink-0 me-3'>
                          <div className='avatar-xs'>
                            <span className='avatar-title rounded-circle bg-primary'>
                              03
                            </span>
                          </div>
                        </div>
                        <div className='flex-grow-1'>
                          <h5 className='font-size-15'>
                            What are FileSense’s limitations?
                          </h5>
                          <p className='text-muted'>
                            FileSense only answers based on the files you
                            upload. It doesn't have general intelligence or
                            perform real-time searches.{' '}
                          </p>
                        </div>
                      </div>
                    </Col>
                    <Col lg={5} className='offset-lg-1'>
                      <div className='faq-box d-flex mt-4'>
                        <div className='flex-shrink-0 me-3'>
                          <div className='avatar-xs'>
                            <span className='avatar-title rounded-circle bg-primary'>
                              04
                            </span>
                          </div>
                        </div>
                        <div className='flex-grow-1'>
                          <h5 className='font-size-15'>
                            Is the AI Chatbot secure?
                          </h5>
                          <p className='text-muted'>
                            Yes. Your files are encrypted, and are deleted after
                            each session.{' '}
                          </p>
                        </div>
                      </div>
                      {/* <div className='faq-box d-flex mt-4'>
                        <div className='flex-shrink-0 me-3'>
                          <div className='avatar-xs'>
                            <span className='avatar-title rounded-circle bg-primary'>
                              05
                            </span>
                          </div>
                        </div>
                        <div className='flex-grow-1'>
                          <h5 className='font-size-15'>
                            What is the response time?
                          </h5>
                          <p className='text-muted'>
                            Typically, questions are processed in about 60
                            seconds; however, longer documents may take longer.{' '}
                          </p>
                        </div>
                      </div> */}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default Defaultrender;
