import React, { useEffect, useState } from "react";
import Tablediv from "../../Components/Table/TableDiv";
import { useAuth0 } from "@auth0/auth0-react";
import { Button, message, Tooltip, Skeleton, Menu, Dropdown } from "antd";
import {
  EditOutlined,
  DownloadOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import useDatacallswithtoken from "../../../../Data Apis/apifunctions";

const Drafts = ({ onEditClick, setPagesCount }) => {
  const { useForDownloadWordFile, useForDownloadExcelFile, useForDraftsList } =
    useDatacallswithtoken();
  const { user, getAccessTokenSilently } = useAuth0();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true); // Loading state
  const FetchDraftsList = async (body) => {
    const res = await useForDraftsList(JSON.stringify(body));
    if (res.status === 200) {
      return res; // Return the response data
    } else {
      throw new Error("Error fetching drafts list");
    }
  };
  // Utility function to create unique filter options based on data
  const createUniqueFilterOptions = (data, key) => {
    const uniqueValues = [...new Set(data.map((item) => item[key]))];
    return uniqueValues.map((value) => ({
      text: value,
      value: value,
    }));
  };
  const DownloadWordFile = async (body) => {
    const res = await useForDownloadWordFile(body);

    if (res.status !== 200) {
      throw new Error("Error downloading Word file");
    }

    // Convert the response to a blob (binary large object)
    const blob = new Blob([res.data], {
      type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    });

    // Create a link element and use it to trigger the download
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "job_description.docx"); // Set file name
    document.body.appendChild(link);
    link.click();

    // Clean up the URL and remove the link
    window.URL.revokeObjectURL(url);
    link.remove();

    message.success("Word file downloaded successfully!");
  };
  const DownloadExcelFile = async (body) => {
    const res = await useForDownloadExcelFile(body);

    if (res.status !== 200) {
      throw new Error("Error downloading Excel file");
    }

    // Convert the response to a blob (binary large object)
    const blob = new Blob([res.data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    // Create a link element and use it to trigger the download
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "job_description.xlsx"); // Set file name
    document.body.appendChild(link);
    link.click();

    // Clean up the URL and remove the link
    window.URL.revokeObjectURL(url);
    link.remove();

    message.success("Excel file downloaded successfully!");
  };
  const handleDownloadFile = async (jd_key, fileType) => {
    try {
      const body = {
        user_id: user?.sub,
        jd_key: jd_key,
      };

      if (fileType === "word") {
        await DownloadWordFile(body);
      } else if (fileType === "excel") {
        await DownloadExcelFile(body);
      }
    } catch (error) {
      console.error(`Error during ${fileType} file download:`, error);
      message.error(`Error downloading ${fileType} file`);
    }
  };
  const columns = [
    {
      title: "Position ID",
      dataIndex: "positionId",
      sorter: (a, b) => a.positionId.localeCompare(b.positionId),
      filters: createUniqueFilterOptions(data, "positionId"),
      onFilter: (value, record) => record.positionId.includes(value),
    },
    {
      title: "Position Title",
      dataIndex: "positionTitle",
      filters: createUniqueFilterOptions(data, "positionTitle"),
      onFilter: (value, record) => record.positionTitle.includes(value),
    },
    {
      title: "Department",
      dataIndex: "department",
      filters: createUniqueFilterOptions(data, "department"),
      onFilter: (value, record) => record.department.includes(value),
    },
    {
      title: "Location",
      dataIndex: "positionLocation",
      sorter: (a, b) => a.positionLocation.localeCompare(b.positionLocation),
      filters: createUniqueFilterOptions(data, "positionLocation"),
      onFilter: (value, record) => record.positionLocation.includes(value),
    },
    {
      title: "Creation Date",
      dataIndex: "creationDate",
      sorter: (a, b) => new Date(a.creationDate) - new Date(b.creationDate),
    },
    {
      title: "Actions",
      dataIndex: "actions",
      render: (_, record) => (
        <div style={{ margin: "0px", padding: "0px", gap: "0px" }}>
          <Tooltip title="Edit">
            <Button
              type="button"
              icon={<EditOutlined />}
              onClick={(e) => {
                e.stopPropagation();
                onEditClick(record);
              }}
            />
          </Tooltip>
          {/* <Tooltip title="Download"> */}
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item
                  key="1"
                  onClick={(e) => {
                    e.domEvent.stopPropagation(); // Stop event from propagating to the row
                    handleDownloadFile(record.key, "excel");
                  }}
                >
                  Download Excel
                </Menu.Item>
                <Menu.Item
                  key="2"
                  onClick={(e) => {
                    e.domEvent.stopPropagation(); // Stop event from propagating to the row
                    handleDownloadFile(record.key, "word");
                  }}
                >
                  Download Word
                </Menu.Item>
              </Menu>
            }
            trigger={["hover"]}
            onClick={(e) => e.stopPropagation()} // Prevent click event from propagating to parent row
          >
            <Button
              type="button"
              icon={<DownloadOutlined />}
              style={{ padding: "4px", margin: "0" }}
            />
          </Dropdown>
          {/* </Tooltip> */}
          <Tooltip title="Delete">
            <Button
              type="button"
              icon={<DeleteOutlined />}
              onClick={(e) => {
                e.stopPropagation();
                console.log("Delete clicked", record);
              }}
            />
          </Tooltip>
        </div>
      ),
    },
  ];

  useEffect(() => {
    const fetchDraftsPageData = async () => {
      setLoading(true); // Start loading
      const jsonData = { userId: user?.sub };
      try {
        const res = await FetchDraftsList(jsonData);

        const fetchedData = res.data;
        setPagesCount({ drafts: fetchedData.length });

        const formattedData = fetchedData.map((item) => ({
          key: item?.jd_key,
          positionId: item?.position_id,
          positionTitle: item?.process_title,
          department: item?.department,
          positionLocation: item?.position_location,
          // creationDate: new Date(item?.created_at).toISOString().split("T")[0], // Format as YYYY-MM-DD
          creationDate: item?.created_at
            ? new Date(item?.created_at).toISOString().split("T")[0]
            : null,
        }));
        setData(formattedData);
      } catch (error) {
        console.error("Error during fetch:", error);
        message.error("Error fetching data");
      } finally {
        setLoading(false); // End loading
      }
    };
    fetchDraftsPageData();
  }, [getAccessTokenSilently, user]);

  return (
    <div className="draftsmain" style={{ flex: "1" }}>
      {loading ? (
        <Skeleton active /> // Display the skeleton when loading
      ) : (
        <Tablediv
          title={`Drafts (${data.length})`}
          columns={columns}
          dataSource={data}
          rowSelectionEnabled={true}
          initialPageSize={10} // Set initial page size if needed
          onEditClick={onEditClick}
        />
      )}
    </div>
  );
};

export default Drafts;
