import { useAuth0 } from "@auth0/auth0-react";
import { notification } from "antd";

export const generateTableFromJson = (data, headers = []) => {
  if (
    (!data || !Array.isArray(data) || data.length === 0) &&
    headers.length > 0
  ) {
    let table =
      '<table border="1" cellspacing="0" cellpadding="10" style="border-collapse: collapse; width: 100%; border: 1px solid black;">';
    table += "<thead><tr>";
    headers.forEach((header) => {
      table += `<th>${header}</th>`;
    });
    table += "</tr></thead>";
    table +=
      '<tbody><tr><td colspan="' +
      headers.length +
      '">No data available</td></tr></tbody>';
    table += "</table>";
    return table;
  }

  if (data && Array.isArray(data) && data.length > 0) {
    const columnHeaders = Object.keys(data[0]).filter(
      (key) => !key.toLowerCase().includes("id")
    );

    let table =
      '<table border="1" cellspacing="0" cellpadding="10" style="border-collapse: collapse; width: 100%; border: 1px solid black;">';

    // Add headers, if provided
    if (headers.length > 0) {
      table += "<thead><tr>";
      headers.forEach((header) => {
        table += `<th>${header}</th>`;
      });
      table += "</tr></thead>";
    }

    table += "<tbody>";
    data.forEach((item) => {
      table += "<tr>";
      columnHeaders.forEach((header) => {
        table += `<td>${item[header] || ""}</td>`;
      });
      table += "</tr>";
    });
    table += "</tbody></table>";

    return table;
  }

  return "<p>No data available</p>";
};
export const generateOrgRelationshipTable = (orgRelationship) => {
  // If no data is available, return a table with headers and "No data available" message
  if (!orgRelationship || Object.keys(orgRelationship).length === 0) {
    let table =
      '<table border="1" cellspacing="0" cellpadding="10" style="border-collapse: collapse; width: 100%; border: 1px solid black;">';

    // Add table headers
    table += `<thead>
              <tr>
                <th>Position that It reports into</th>
                <th>Position and its peers (Nop = 0 )</th>
                <th>Position/s that reports into (Nop = 0 )</th>
              </tr>
            </thead>`;

    // Add "No data available" row
    table += '<tbody><tr><td colspan="3">No data available</td></tr></tbody>';
    table += "</table>";

    return table;
  }

  // Extract the data
  const reportsTo = orgRelationship.reports_into || "N/A";
  const peers = orgRelationship.peers || [];
  const reportees = orgRelationship.reportees || [];
  // Calculate the number of peers and reportees
  const peersCount = peers.length;
  const reporteesCount = reportees.length;
  // Find the maximum number of rows required (max of peers or reportees)
  const maxRows = Math.max(peers.length, reportees.length, 1);

  // Start building the table HTML
  let table =
    '<table border="1" cellspacing="0" cellpadding="10" style="border-collapse: collapse; width: 100%; border: 1px solid black;">';

  // Add table headers
  table += `<thead>
              <tr>
                <th>Position that It reports into</th>
                <th>Position and its peers (Nop = ${peersCount} )</th>
                <th>Position/s that reports into (Nop = ${reporteesCount} )</th>
              </tr>
            </thead>`;

  // Table body
  table += "<tbody>";

  // Iterate up to the maximum number of rows (based on peers and reportees length)
  for (let i = 0; i < maxRows; i++) {
    // Open a new row
    table += "<tr>";

    // Add "Reports To" only in the first row (span across multiple rows if necessary)
    if (i === 0) {
      table += `<td rowspan="${maxRows}">${reportsTo}</td>`;
    }

    // Add a peer if available, otherwise leave the cell empty
    const peer = peers[i]
      ? `${peers[i].position_number} - ${peers[i].position_title}`
      : "";
    table += `<td>${peer}</td>`;

    // Add a reportee if available, otherwise leave the cell empty
    const reportee = reportees[i]
      ? `${reportees[i].position_number} - ${reportees[i].position_title}`
      : "";
    table += `<td>${reportee}</td>`;

    // Close the row
    table += "</tr>";
  }

  table += "</tbody></table>";

  return table;
};
export const generateKeyValueTable = (data) => {
  if (!data || typeof data !== "object") {
    return "<p>No dimensions data available</p>";
  }

  // Start building the table HTML
  let table =
    '<table border="1" cellspacing="0" cellpadding="10" style="border-collapse: collapse; width: 100%; border: 1px solid black;">';

  // // Add table headers
  // table += `<thead>
  //             <tr>
  //               <th>Dimension</th>
  //               <th>Value</th>
  //             </tr>
  //           </thead>`;

  // Table body
  table += "<tbody>";

  // Iterate over key-value pairs in the object
  Object.entries(data).forEach(([key, value]) => {
    // Convert null, undefined, or "NaN" values to "N/A"
    const displayValue =
      value === null || value === undefined || value === "NaN" ? "N/A" : value;
    table += `<tr>
                <td>${key
                  .replace(/_/g, " ")
                  .replace(/\b\w/g, (char) => char.toUpperCase())}</td>
                <td>${displayValue}</td>
              </tr>`;
  });

  table += "</tbody></table>";

  return table;
};

export const getRandomElement = (list) =>
  list[Math.floor(Math.random() * list.length)];
// Extract domain from an email address
