import React, { useState, useEffect } from "react";
import { Select, Collapse, message, Skeleton } from "antd";
import { useAuth0 } from "@auth0/auth0-react";
import useDatacallswithtoken from "../../../../Data Apis/apifunctions";
import * as Y from "yjs";
import { TiptapCollabProvider } from "@hocuspocus/provider";
import "./JobDescription.css";

import JobDescriptionDisplayTipTap from "../TipTap/Index";

import { generateTableFromJson } from "../../Utils/Utils";
import { getCollabToken } from "../TipTap/Api/api";
import { useNavigate } from "react-router-dom";
import JobDescriptionForm from "./Utils/JDForm";
import useJDAPI from "./Utils/api";
import { parseJobDescriptionHTML } from "./Utils/parseJobDescriptionHTML";
import { useJobDescriptionHandlers } from "./Utils/JobDescriptionHandlers";

const JobDescription = ({ title = "" }) => {
  const {
    DownloadExcelFile,
    DownloadWordFile,
    FetchPositionDetails,
    FetchPositionList,
    GenerateJD,
    SaveJD,
    SubmitForApproval,
  } = useJDAPI();
  const { user, getAccessTokenSilently } = useAuth0();
  const [isPositionIdSelected, setIsPositionIdSelected] = useState(false);
  const [positionList, setPositionList] = useState([]);
  const [positionDetails, setPositionDetails] = useState([]);
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);

  const navigate = useNavigate();

  const [selectedQualifications, setSelectedQualifications] = useState([]);
  const [selectedSpecializations, setSelectedSpecializations] = useState([]);
  const [content, setContent] = useState(null);
  const [generatedJobDescription, setGeneratedJobDescription] = useState([]); // State to store generated job description

  const [selectedIndustryPreferences, setSelectedIndustryPreferences] =
    useState([]);
  const [selectedExperience, setSelectedExperience] = useState("");
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [internalRelationship, setInternalRelationship] = useState("");
  const [externalRelationship, setExternalRelationship] = useState("");
  const [doc, setDoc] = useState(null);
  const [currentDocumentThreads, setCurrentDocumentThreads] = useState([]);
  const [documentName, setdocumentName] = useState("");
  const [provider, setProvider] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const accessToken = await getAccessTokenSilently();
        const body = {
          userId: user?.sub,
        };

        const res = await FetchPositionList(body);
        const positionListData = res.data;

        setPositionList(positionListData);
      } catch (error) {
        console.error("Error during fetch:", error);
        message.error("Error fetching position list");
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [getAccessTokenSilently, user]);

  const handlePositionIdChange = async (value) => {
    if (value !== "select") {
      setIsPositionIdSelected(true);
      try {
        const accessToken = await getAccessTokenSilently();
        const positionId = String(value); // Ensure position_id is treated as a string
        const body = { position_id: positionId };
        const res = await FetchPositionDetails(body);
        const positionDetailsData = res.data;

        setPositionDetails(positionDetailsData[0]);
      } catch (error) {
        console.error("Error during fetch:", error);
        message.error("Error fetching job details");
      }
    } else {
      setIsPositionIdSelected(false);
      setPositionDetails(null);
    }
  };

  // Use the custom hook to get the handlers
  const {
    onFinish,
    handleSaveJD,
    handleSubmitForApproval,
    handleDownloadFile,
  } = useJobDescriptionHandlers({
    user,
    getAccessTokenSilently,
    positionDetails,
    selectedSkills,
    selectedQualifications,
    selectedIndustryPreferences,
    selectedSpecializations,
    internalRelationship,
    externalRelationship,
    selectedExperience,
    setSubmitting,
    setContent,
    setDoc,
    setProvider,
    setCurrentDocumentThreads,
    setGeneratedJobDescription,
    navigate,
    generatedJobDescription,
    positionList,
    FetchPositionDetails,
    GenerateJD,
    SaveJD,
    SubmitForApproval,
    DownloadWordFile,
    DownloadExcelFile,
  });
  if (loading) {
    return (
      <div className="CJDmain">
        <Skeleton active paragraph={{ rows: 15 }} />
      </div>
    );
  }
  return (
    <div className="CJDmain">
      <JobDescriptionForm
        positionList={positionList}
        positionDetails={positionDetails}
        onSubmit={onFinish}
        isPositionIdSelected={isPositionIdSelected}
        handlePositionIdChange={handlePositionIdChange}
        selectedQualifications={selectedQualifications}
        setSelectedQualifications={setSelectedQualifications}
        selectedSpecializations={selectedSpecializations}
        setSelectedSpecializations={setSelectedSpecializations}
        selectedIndustryPreferences={selectedIndustryPreferences}
        setSelectedIndustryPreferences={setSelectedIndustryPreferences}
        selectedExperience={selectedExperience}
        setSelectedExperience={setSelectedExperience}
        internalRelationship={internalRelationship}
        setInternalRelationship={setInternalRelationship}
        externalRelationship={externalRelationship}
        setExternalRelationship={setExternalRelationship}
        selectedSkills={selectedSkills}
        setSelectedSkills={setSelectedSkills}
        submitting={submitting}
        generatedJobDescription={generatedJobDescription}
        title={title}
        // Pass the necessary props for the form
      />

      {generatedJobDescription &&
        Object.keys(generatedJobDescription)?.length > 0 && (
          <div
            style={{
              padding: "20px",
              backgroundColor: "#fff",
              margin: "20px 0px 0px 0px",
              borderRadius: "7px",
              border: "1px solid #E6E6E6", // Assuming var(--Primary-1) is #E6E6E6
              background: "#FFF",
              flex: "1",
            }}
          >
            {/* Display generated job description */}

            <>
              {/* <JobDescriptionDisplay jobDetails={generatedJobDescription} /> */}
              <JobDescriptionDisplayTipTap
                content={content}
                onCommentSubmit={handleSubmitForApproval}
                onSave={handleSaveJD}
                isEditable={true}
                provider={provider}
                initialThreads={currentDocumentThreads}
                setCurrentDocumentThreads={setCurrentDocumentThreads}
                doc={doc}
                onDownload={handleDownloadFile}
                page="CreateJD"
                actions={["Save", "Submit For Approval", "Download"]}
              />
            </>
          </div>
        )}
    </div>
  );
};

export default JobDescription;
