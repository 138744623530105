import React, {
  useState,
  useCallback,
  useEffect,
  useRef,
  useMemo,
} from "react";
import { Card, CardBody, Col, Row } from "reactstrap";

import { useAuth0 } from "@auth0/auth0-react";
import { Alert, Snackbar } from "@mui/material";
import "./Index.css";
// import InboxSidebar from "./Sidebar";
import CommentPop from "./Components/addcommentpop";
import { BubbleMenu, EditorContent, useEditor } from "@tiptap/react";
// import { watchPreviewContent } from '@tiptap-pro/extension-collaboration-history';
import StarterKit from "@tiptap/starter-kit";
import Table from "@tiptap/extension-table";
import TableRow from "@tiptap/extension-table-row";
import TableCell from "@tiptap/extension-table-cell";
import TableHeader from "@tiptap/extension-table-header";
import Underline from "@tiptap/extension-underline";
import Collaboration from "@tiptap/extension-collaboration";
import CollaborationCursor from "@tiptap/extension-collaboration-cursor";
import { TiptapCollabProvider } from "@hocuspocus/provider";
import { CommentsKit } from "@tiptap-pro/extension-comments";
import * as Y from "yjs";
// import "./style1.css";
import { useSelector } from "react-redux";
import { ThreadsProvider } from "./Components/threadsProvider";
import { ThreadsList } from "./Components/threadsList";
import { useThreads } from "./Hooks/useThreads";
import { getRandomElement } from "../../Utils/Utils";
// import "./tiptap.css";
import { v4 as uuidv4 } from "uuid";
import { generateTableFromJson } from "../../Utils/Utils";
import {
  Dropdown,
  FloatButton,
  Menu,
  Modal,
  Input,
  Tooltip,
  notification,
} from "antd";
import { AiOutlineFileDone } from "react-icons/ai";

import {
  DownloadOutlined,
  DownOutlined,
  FilePdfOutlined,
  FileWordOutlined,
  SaveOutlined,
} from "@ant-design/icons";
// import Ai from "@tiptap-pro/extension-ai";
const colors = [
  "#958DF1",
  "#F98181",
  "#FBBC88",
  "#FAF594",
  "#70CFF8",
  "#94FADB",
  "#B9F18D",
];
const JobDescriptionDisplayTipTap = (props) => {
  const {
    content,
    onSave,
    onApprove,
    onRevise,
    isEditable,
    initialThreads,
    setCurrentDocumentThreads,
    provider,
    doc,
    onDownload,
    onCommentSubmit,
    page,
    actions,
  } = props;
  const { user } = useAuth0();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [comment, setComment] = useState("");
  const [func, setFunc] = useState(null);
  const [saveWarningVisible, setSaveWarningVisible] = useState(false); // For the save warning modal

  const handleSaveClick = () => {
    console.log("save");
    onSave(editor.getHTML()); // Pass the tiptapContent to the parent component
    setHasChanged(false);
  };
  // Show the save warning modal
  const showSaveWarningModal = () => {
    setSaveWarningVisible(true);
  };

  // Handle closing the save warning modal
  const handleSaveWarningOk = () => {
    handleSaveClick();
    setSaveWarningVisible(false);
  };

  const handleSaveWarningCancel = () => {
    setSaveWarningVisible(false);
  };

  const handleApproveClick = () => {
    if (hasChanged) {
      showSaveWarningModal(); // Show save warning if there are unsaved changes
    } else {
      setIsModalOpen(true);
      setFunc("approve");
    }
  };

  const handleReviseClick = () => {
    if (hasChanged) {
      showSaveWarningModal(); // Show save warning if there are unsaved changes
    } else {
      setIsModalOpen(true);
      setFunc("revise");
    }
  };

  const handleSubmitForApprovalButtonClick = () => {
    if (hasChanged) {
      showSaveWarningModal(); // Show save warning if there are unsaved changes
    } else {
      onCommentSubmit();
    }
  };
  const handleDownloadExcel = () => {
    if (hasChanged && isEditable === true) {
      showSaveWarningModal(); // Show save warning if there are unsaved changes
    } else {
      onDownload(content, "excel");
    }
  };

  const handleDownloadWord = () => {
    if (hasChanged && isEditable === true) {
      showSaveWarningModal(); // Show save warning if there are unsaved changes
    } else {
      onDownload(content, "word");
    }
  };

  const handleModalOk = () => {
    if (func === "submit") {
      onCommentSubmit(comment); // Send the comment back to JobDescription
    } else if (func === "approve") {
      onApprove(comment);
    } else if (func === "revise") {
      onRevise(comment);
    }
    setIsModalOpen(false); // Close the modal after submission
    setComment(""); // Reset the comment field
  };

  const handleModalCancel = () => {
    setIsModalOpen(false); // Close the modal without submission
    setComment("");
  };

  const handleCommentChange = (e) => {
    setComment(e.target.value); // Update the comment input
  };
  // const [streamMode, setStreamMode] = useState(true);
  // const [state, setState] = useState({
  //   isLoading: false,
  //   errorMessage: null,
  //   response: null,
  // });
  const [message, setMessage] = useState("");
  const [snackBarState, setSnackBarState] = useState({
    open: false,
    vertical: "bottom",
    horizontal: "left",
  });
  const [ydoc] = useState(() => doc || new Y.Doc());

  const userdetails = useMemo(
    () => ({ name: user.email, color: getRandomElement(colors) }),
    [user.email]
  );

  const [selectedThread, setSelectedThread] = useState(null);
  const [hoveredThreadId, setHoveredThreadId] = useState(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [popupPosition, setPopupPosition] = useState({ x: 0, y: 0 });
  const [showSaveStatus, setshowSaveStatus] = useState("");
  const [hasChanged, setHasChanged] = useState(false); // Track changes in the editor
  // This flag ensures we are aware of when the initial load has finished
  const [initialLoadComplete, setInitialLoadComplete] = useState(false);
  const [count, setCount] = useState(0);

  const editorConfig = useMemo(
    () => ({
      extensions: [
        StarterKit.configure({
          history: false,
        }),
        Underline,
        Table,
        TableHeader,
        TableRow,
        TableCell,
        Collaboration.configure({
          document: ydoc,
        }),
        CollaborationCursor.configure({
          provider,
          user: {
            name: userdetails.name,
            color: userdetails.color,
          },
        }),

        // Ai.configure({
        //   appId: "APP_ID_HERE",
        //   token: "TOKEN_HERE",
        //   // ATTENTION: This is for demo purposes only
        //   baseUrl: variables.tiptapAiBaseUrl,
        //   autocompletion: true,
        //   onLoading: () => {
        //     setState({
        //       isLoading: true,
        //       errorMessage: null,
        //       response: "",
        //     });
        //   },
        //   onChunk: ({ response }) => {
        //     setState({
        //       isLoading: true,
        //       errorMessage: null,
        //       response,
        //     });
        //   },
        //   onSuccess: ({ response }) => {
        //     setState({
        //       isLoading: false,
        //       errorMessage: null,
        //       response,
        //     });
        //   },
        //   onError: (error) => {
        //     setState({
        //       isLoading: false,
        //       errorMessage: error.message,
        //       response: null,
        //     });
        //   },
        // }),

        CommentsKit.configure({
          provider,
          onThreadHover: (threadId) => {
            setHoveredThreadId(threadId);
          },
          onThreadLeave: () => {
            setHoveredThreadId(null);
          },
          onThreadSelect: (threadId) => {
            setSelectedThread(threadId);
          },
        }),
      ],
      editable: isEditable,

      onUpdate: ({ editor }) => {
        // Only mark as changed if the initial load has completed
        setCount((prevCount) => {
          if (prevCount > 0) {
            setHasChanged(true);
          }
          return prevCount + 1; // Ensure count is incremented properly
        });
      },
    }),
    [ydoc, provider, userdetails]
  );
  const editor = useEditor(editorConfig);

  const {
    threads = initialThreads,
    createThread,
    removeThread,
  } = useThreads(provider, editor, userdetails);

  // if (provider) {
  //   const unbindWatchContent = watchPreviewContent(provider, (content) => {
  //     editor.commands.setContent(content);
  //   });
  // }

  useEffect(() => {
    return () => {
      if (provider) {
        provider.destroy();
      }
    };
  }, [provider]);

  // const requestVersion = (version) => {
  //   console.log('Triggered');
  //   provider.sendStateless(
  //     JSON.stringify({
  //       action: 'version.preview',
  //       // Include your version number here
  //       version,
  //     })
  //   );
  // };

  useEffect(() => {
    if (editor && content !== null) {
      editor.commands.setContent(content);
    }
  }, [editor, content]);

  //   const handleSnackBarClose = () => {
  //     setSnackBarState({ ...snackBarState, open: false });
  //   };

  //   const convertToIndianTime = (isoTimestamp) => {
  //     const date = new Date(isoTimestamp);
  //     const options = {
  //       timeZone: "Asia/Kolkata",
  //       hour: "2-digit",
  //       minute: "2-digit",
  //       hour12: true,
  //     };
  //     return date.toLocaleTimeString("en-IN", options);
  //   };

  const handleAddCommentClick = (e) => {
    setIsPopupOpen(true);
    setPopupPosition({ x: e.clientX, y: e.clientY });
  };

  const handleCommentSubmit = (comment) => {
    createThread(comment);
  };

  const refineContent = async (selection) => {
    if (selection.empty) return;

    const from = selection.from;
    const to = selection.to;
    const selectedText = editor.state.doc.textBetween(from, to);
    editor.commands.setTextSelection({ from, to });
    editor.commands.insertContent("Refining...");

    try {
      await new Promise((resolve) => setTimeout(resolve, 5000));
      const loaderFrom = editor.state.doc
        .textBetween(0, editor.state.doc.content.size)
        .indexOf("Refining...");
      const loaderTo = loaderFrom + "Refining...".length;
      editor.commands.setTextSelection({
        from: loaderFrom + 3,
        to: loaderTo - 5,
      });
      editor.commands.insertContent("This is the refined text");
    } catch (error) {
      console.error("Error refining content:", error);
      editor.commands.setTextSelection({ from, to });
      editor.commands.insertContent(selectedText);
    }
  };

  const selectThreadInEditor = useCallback(
    (threadId) => {
      editor.chain().selectThread({ id: threadId }).run();
    },
    [editor]
  );

  const deleteThread = useCallback(
    (threadId) => {
      provider.deleteThread(threadId);
      editor.commands.removeThread({ id: threadId });
    },
    [editor, provider]
  );

  const updateComment = useCallback(
    (threadId, commentId, content, metaData) => {
      editor.commands.updateComment({
        threadId,
        id: commentId,
        content,
        data: metaData,
      });
    },
    [editor]
  );

  const onHoverThread = useCallback(
    (threadId) => {
      const { tr } = editor.state;
      tr.setMeta("threadMouseOver", threadId);
      editor.view.dispatch(tr);
    },
    [editor]
  );

  const onLeaveThread = useCallback(
    (threadId) => {
      const { tr } = editor.state;
      tr.setMeta("threadMouseOut", threadId);
      editor.view.dispatch(tr);
    },
    [editor]
  );

  const handleThreadClick = useCallback(
    (threadId) => {
      if (editor) {
        editor.commands.focusThread({ id: threadId });
        setSelectedThread(threadId);
      }
    },
    [editor]
  );

  const editComment = useCallback(
    (threadId, commentId, newContent) => {
      if (provider) {
        provider.updateComment(threadId, commentId, newContent);
      }
    },
    [provider]
  );

  const deleteComment = useCallback(
    (threadId, commentId) => {
      if (provider) {
        provider.deleteComment(threadId, commentId);
        const thread = threads.find((t) => t.id === threadId);
        if (thread && thread.comments.length === 1) {
          removeThread(threadId);
        }
      }
    },
    [provider, threads, removeThread]
  );

  const handleThreadOutsideClick = useCallback(() => {
    setSelectedThread(null);
  }, []);

  // Function to display a warning notification
  const showWarningNotification = () => {
    notification.warning({
      message: "Save Required",
      description: "Please save the file before performing this action.",
    });
  };
  // Ant Design Menu for Dropdown
  const menu = (
    <Menu style={{ background: "black" }}>
      <Menu.Item
        key="1"
        onClick={handleDownloadExcel}
        style={{ color: "white" }}
      >
        <FilePdfOutlined /> Download Excel
      </Menu.Item>
      <Menu.Item
        key="2"
        onClick={handleDownloadWord}
        style={{ color: "white" }}
      >
        <FileWordOutlined /> Download Word
      </Menu.Item>
    </Menu>
  );
  // Define AI tool actions
  // const handleRefineContent = async () => {
  //   const selection = editor.state.selection;
  //   if (selection.empty) return;

  //   editor
  //     .chain()
  //     .focus()
  //     .aiRestructure({ stream: true, format: "rich-text", modelName: "gpt4-o" })
  //     .run();
  // };

  // const handleSimplifyContent = async () => {
  //   const selection = editor.state.selection;
  //   if (selection.empty) return;

  //   editor.chain().focus().aiSimplify({ stream: streamMode }).run();
  // };

  // AI Tools Dropdown Menu
  // const aiMenu = (
  //   <Menu>
  //     <Menu.Item key="1" onClick={handleRefineContent}>
  //       Refine Content
  //     </Menu.Item>
  //     <Menu.Item key="2" onClick={handleSimplifyContent}>
  //       Simplify Content
  //     </Menu.Item>
  //   </Menu>
  // );
  const buttonActions = [
    {
      key: "download",
      tooltip: "Download",
      icon: <DownloadOutlined style={{ color: "white", fill: "white" }} />,
      onClick: null,
      dropdown: true, // Flag to differentiate download button
      // condition: actions?.includes("Download"),
    },

    {
      key: "submitForApproval",
      tooltip: "Submit For Approval",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="23"
          height="23"
          viewBox="4 1.5 25 25"
          fill="none"
        >
          <path
            d="M17.875 2.89581V4.33331C17.875 5.6886 17.875 6.36624 18.296 6.78728C18.7171 7.20831 19.3947 7.20831 20.75 7.20831H22.1875"
            stroke="white"
            stroke-width="1.7"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M7.33333 15.8334V8.16669C7.33333 5.45611 7.33333 4.10082 8.1754 3.25876C9.01747 2.41669 10.3728 2.41669 13.0833 2.41669H17.0811C17.4728 2.41669 17.6687 2.41669 17.8448 2.48964C18.0209 2.56258 18.1594 2.70108 18.4364 2.97807L22.1053 6.64697C22.3822 6.92396 22.5208 7.06246 22.5937 7.23857C22.6667 7.41468 22.6667 7.61055 22.6667 8.00227V15.8334C22.6667 18.5439 22.6667 19.8992 21.8246 20.7413C20.9825 21.5834 19.6272 21.5834 16.9167 21.5834H13.0833C10.3728 21.5834 9.01747 21.5834 8.1754 20.7413C7.33333 19.8992 7.33333 18.5439 7.33333 15.8334Z"
            stroke="white"
            stroke-width="1.7"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M11.1667 11.0417H18.8333M11.1667 13.9167H18.8333M11.1667 16.7917H15.1637"
            stroke="white"
            stroke-width="1.7"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M14.0416 12.9579H2.54167"
            stroke="black"
            stroke-width="3.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M10.2084 9.125C10.2084 9.125 14.0417 11.9482 14.0417 12.9583C14.0417 13.9685 10.2083 16.7917 10.2083 16.7917"
            stroke="black"
            stroke-width="3.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M14.0416 12.9579H2.54167"
            stroke="white"
            stroke-width="1.7"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M10.2084 9.125C10.2084 9.125 14.0417 11.9482 14.0417 12.9583C14.0417 13.9685 10.2083 16.7917 10.2083 16.7917"
            stroke="white"
            stroke-width="1.7"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      ),
      onClick: handleSubmitForApprovalButtonClick,
      condition: actions?.includes("Submit For Approval"),
    },
    {
      key: "revise",
      tooltip: "Revise",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 22 30"
          fill="none"
        >
          <path
            d="M21.93 6.67C21.9342 6.64015 21.9342 6.60985 21.93 6.58C21.8819 6.47718 21.8178 6.38267 21.74 6.3L15.74 0.3C15.6573 0.222216 15.5628 0.158081 15.46 0.11H15.36C15.2659 0.0589272 15.1648 0.0218697 15.06 0H1C0.734784 0 0.48043 0.105357 0.292893 0.292893C0.105357 0.48043 0 0.734784 0 1V29C0 29.2652 0.105357 29.5196 0.292893 29.7071C0.48043 29.8946 0.734784 30 1 30H21C21.2652 30 21.5196 29.8946 21.7071 29.7071C21.8946 29.5196 22 29.2652 22 29V7C22 7 22 7 22 6.94C21.9863 6.84777 21.9629 6.75725 21.93 6.67ZM18.59 6H16V3.41L18.59 6ZM20 28H2V2H14V7C14 7.26522 14.1054 7.51957 14.2929 7.70711C14.4804 7.89464 14.7348 8 15 8H20V28Z"
            fill="white"
          />
          <path
            d="M12.3004 20.83C11.5592 21.0296 10.782 21.0558 10.029 20.9067C9.27602 20.7576 8.56748 20.437 7.95829 19.97C7.3491 19.503 6.85563 18.9019 6.51611 18.2135C6.17659 17.525 6.00015 16.7676 6.00045 16C5.99927 15.343 6.12761 14.6921 6.37813 14.0847C6.62865 13.4773 6.99643 12.9252 7.46045 12.46C7.49588 12.4244 7.52618 12.384 7.55045 12.34C7.59277 12.2872 7.62963 12.2303 7.66045 12.17C7.68636 12.1095 7.70321 12.0455 7.71045 11.98C7.71981 11.9204 7.71981 11.8596 7.71045 11.8C7.72084 11.7271 7.72084 11.653 7.71045 11.58C7.71045 11.53 7.71045 11.49 7.71045 11.44C7.68924 11.377 7.65519 11.3191 7.61045 11.27C7.58351 11.2046 7.54639 11.1438 7.50045 11.09L7.40045 11.02C7.34014 10.9676 7.27283 10.9238 7.20045 10.89H7.03045H6.84045C6.77056 10.8845 6.70034 10.8845 6.63045 10.89C6.58057 10.8851 6.53033 10.8851 6.48045 10.89L4.36045 11.6C4.19926 11.6527 4.05412 11.7455 3.93866 11.8697C3.82321 11.9939 3.74123 12.1454 3.70045 12.31C3.6612 12.4767 3.66541 12.6507 3.71269 12.8153C3.75997 12.9799 3.84872 13.1296 3.97045 13.25L4.45045 13.73C4.17852 14.4573 4.02654 15.224 4.00045 16C4.00074 17.0734 4.24789 18.1324 4.72278 19.095C5.19767 20.0576 5.88758 20.8982 6.73918 21.5516C7.59077 22.205 8.58122 22.6539 9.63397 22.8634C10.6867 23.0729 11.7736 23.0376 12.8104 22.76C13.0522 22.68 13.2543 22.5108 13.3755 22.2869C13.4968 22.0631 13.5281 21.8013 13.4631 21.5552C13.398 21.309 13.2415 21.0969 13.0255 20.9622C12.8094 20.8274 12.5501 20.7802 12.3004 20.83Z"
            fill="white"
          />
          <path
            d="M9.1897 9.23999C8.94798 9.31999 8.74588 9.48919 8.62461 9.71307C8.50335 9.93695 8.47204 10.1987 8.53709 10.4448C8.60214 10.691 8.75864 10.9031 8.97468 11.0378C9.19071 11.1726 9.45002 11.2198 9.6997 11.17C10.549 10.9455 11.4421 10.9464 12.2909 11.1726C13.1397 11.3989 13.9148 11.8426 14.5397 12.46C15.0053 12.9245 15.3748 13.4762 15.6268 14.0837C15.8789 14.6911 16.0086 15.3423 16.0086 16C16.0086 16.6577 15.8789 17.3089 15.6268 17.9163C15.3748 18.5238 15.0053 19.0755 14.5397 19.54C14.5397 19.54 14.4897 19.61 14.4597 19.65C14.4129 19.7052 14.3726 19.7656 14.3397 19.83C14.3138 19.8905 14.2969 19.9545 14.2897 20.02C14.2803 20.0796 14.2803 20.1404 14.2897 20.2C14.2793 20.273 14.2793 20.347 14.2897 20.42C14.2897 20.42 14.2897 20.51 14.2897 20.56L14.3397 20.66C14.3782 20.7351 14.4251 20.8056 14.4797 20.87C14.4797 20.87 14.4797 20.87 14.4797 20.92H14.5297C14.5929 20.9789 14.6635 21.0293 14.7397 21.07L14.8397 21.12C14.9487 21.1596 15.0637 21.1799 15.1797 21.18C15.2855 21.1993 15.3939 21.1993 15.4997 21.18L17.6197 20.47C17.7821 20.4164 17.928 20.3221 18.0436 20.1961C18.1591 20.07 18.2404 19.9164 18.2797 19.75C18.3171 19.5848 18.312 19.4128 18.2648 19.2501C18.2176 19.0875 18.1298 18.9395 18.0097 18.82L17.5297 18.34C17.816 17.5917 17.9749 16.8008 17.9997 16C18.0131 14.1506 17.2941 12.371 15.9997 11.05C15.126 10.1731 14.0354 9.54359 12.8391 9.22563C11.6427 8.90766 10.3835 8.91262 9.1897 9.23999Z"
            fill="white"
          />
        </svg>
      ),
      onClick: handleReviseClick,
      condition: actions?.includes("Revise"),
    },
    {
      key: "accept",
      tooltip: "Accept",
      icon: <AiOutlineFileDone style={{ color: "white", fill: "white" }} />,
      onClick: handleApproveClick,
      condition: actions?.includes("Accept"),
    },

    {
      key: "save",
      tooltip: "Save",
      icon: <SaveOutlined style={{ color: "white", fill: "white" }} />,
      onClick: handleSaveClick,
      condition: isEditable === true,
    },
  ];

  // Create dynamic button positions based on the actual count of rendered buttons
  const getButtonStyle = (position) => ({
    position: "fixed",
    bottom: "24px", // Keep the same distance from the bottom
    right: `${40 + position * 60}px`, // Dynamic right position based on the index of the rendered button
    zIndex: 1000,
  });
  return (
    <div id="jdtiptap">
      <ThreadsProvider
        onClickThread={selectThreadInEditor}
        onDeleteThread={deleteThread}
        onHoverThread={onHoverThread}
        onLeaveThread={onLeaveThread}
        onUpdateComment={updateComment}
        selectedThreads={editor?.storage.comments.focusedThreads}
        threads={threads}
      >
        <div
          className="w-100 mt-4"
          style={{
            height: "fit-content",
            overflow: "visible", // Allow the column to overflow outside of the parent
          }}
        >
          <Row
            className="align-items-start"
            style={{
              height: "fit-content",
              flex: "1",
              position: "relative",
              overflow: "visible",
            }}
            lg={12}
          >
            {threads?.length > 0 && (
              <Col
                className="border rounded"
                style={{ position: "absolute", zIndex: 100000, left: "-310px" }}
                xl={3}
              >
                <Card className="h-100" style={{ boxShadow: "none" }}>
                  <h4 className="text-center mt-3 mb-0">Comments</h4>
                  <ThreadsList provider={provider} threads={threads} />
                </Card>
              </Col>
            )}
            <Col
              xl={9}
              style={{ height: "fit-content", overflow: "auto", flex: "1" }}
              className="rounded"
            >
              <CommentPop
                isOpen={isPopupOpen}
                onClose={() => setIsPopupOpen(false)}
                onSubmit={handleCommentSubmit}
                position={popupPosition}
              />
              <Card>
                <CardBody>
                  <div className="d-flex " style={{ flex: "1" }}>
                    {/* <p className="text-bold">{showSaveStatus}</p> */}
                    {/* {versions &&
                      <Dropdown isOpen={showDropDown}
                          toggle={() => setshowDropDown(!showDropDown)}
                          // direction="up"
                          >
                        <DropdownToggle>Versions <i className='mdi mdi-chevron-down'></i></DropdownToggle>
                        <DropdownMenu>
                          {versions?.map((version, index) => (
                            <DropdownItem key={index} onClick={() => requestVersion(version.version)}>
                              Version {index + 1}: {convertToIndianTime(version.createdAt)}
                            </DropdownItem>
                          ))}
                        </DropdownMenu>
                        </Dropdown>
                        } */}
                  </div>
                  {editor && (
                    <BubbleMenu
                      editor={editor}
                      tippyOptions={{ duration: 100 }}
                    >
                      {/* ... your existing bubble menu buttons ... */}
                      <div
                        className="d-flex align-items-center p-2 border-bottom bg-secondary rounded-2"
                        style={{ flex: "1" }}
                      >
                        {/* AI Tools Dropdown */}
                        {/* <Dropdown overlay={aiMenu} trigger={["click"]}>
                        <button
                          className="btn btn-sm btn-light me-1"
                          title="AI Tools"
                        >
                          <i className="mdi mdi-robot"></i> AI Tools{" "}
                          <DownOutlined />
                        </button>
                      </Dropdown> */}
                        {/* Your existing buttons */}
                        <button
                          onClick={() =>
                            editor?.chain()?.focus()?.toggleBold()?.run()
                          }
                          disabled={
                            !editor
                              ?.can()
                              ?.chain()
                              ?.focus()
                              ?.toggleBold()
                              ?.run()
                          }
                          className="btn btn-sm btn-light me-1"
                          title="Bold"
                        >
                          <i className="mdi mdi-format-bold"></i>
                        </button>
                        <button
                          onClick={() =>
                            editor?.chain()?.focus()?.toggleItalic()?.run()
                          }
                          disabled={
                            !editor
                              ?.can()
                              ?.chain()
                              ?.focus()
                              ?.toggleItalic()
                              ?.run()
                          }
                          className="btn btn-sm btn-light me-1"
                          title="Italic"
                        >
                          <i className="mdi mdi-format-italic"></i>
                        </button>
                        <button
                          onClick={() =>
                            editor?.chain()?.focus()?.toggleUnderline()?.run()
                          }
                          disabled={
                            !editor
                              ?.can()
                              ?.chain()
                              ?.focus()
                              ?.toggleUnderline()
                              ?.run()
                          }
                          className="btn btn-sm btn-light me-1"
                          title="Underline"
                        >
                          <i className="mdi mdi-format-underline"></i>
                        </button>
                        <button
                          onClick={() =>
                            editor?.chain()?.focus()?.toggleStrike()?.run()
                          }
                          disabled={
                            !editor
                              ?.can()
                              ?.chain()
                              ?.focus()
                              ?.toggleStrike()
                              ?.run()
                          }
                          className="btn btn-sm btn-light me-1"
                          title="Strikethrough"
                        >
                          <i className="mdi mdi-format-strikethrough-variant"></i>
                        </button>
                        {/* <button
                onClick={() => editor?.chain()?.focus()?.undo()?.run()}
                disabled={!editor?.can()?.chain()?.focus()?.undo()?.run()}
                className='btn btn-sm btn-light me-1'
                title='Undo'
              >
                <i className='mdi mdi-undo'></i>
              </button>
              <button
                onClick={() => editor?.chain()?.focus()?.redo()?.run()}
                disabled={!editor?.can()?.chain()?.focus()?.redo()?.run()}
                className='btn btn-sm px-2 btn-light'
                title='Redo'
              >
                <i className='mdi mdi-redo'></i>
              </button> */}
                        {/* <button
                        onClick={() => refineContent(editor.state.selection)}
                        disabled={editor.state.selection.empty}
                        className='btn btn-sm btn-light me-1'
                        title='Refine Content'
                      >
                        <i className={'mdi mdi-autorenew'}></i>
                      </button> */}
                        <button
                          onClick={handleAddCommentClick}
                          disabled={editor.state.selection.empty}
                          className="btn btn-sm btn-light me-1"
                          title="Add Comment"
                        >
                          <i className="mdi mdi-comment-plus-outline"></i>
                        </button>
                        {/* <button
                        onClick={removeThread}
                        disabled={!selectedThread}
                        className='btn btn-sm btn-light me-1'
                        title='Remove Comment'
                      >
                        <i className='mdi mdi-comment-remove-outline'></i>
                      </button> */}
                      </div>
                    </BubbleMenu>
                  )}
                  <EditorContent editor={editor} />
                </CardBody>
              </Card>
              {/* <div
              style={{
                position: "fixed",
                bottom: "60px",
                right: "5px",
                display: "flex",
                gap: "10px",
              }}
            >
              <Tooltip title="Approve" placement="top">
                <button
                  className="btn"
                  style={{
                    backgroundColor: "#505d69",
                    color: "white",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "4rem",
                    height: "4rem",
                    borderRadius: "50%",
                    border: "none",
                  }}
                  // onClick={() => {
                  //   setmessage('Sent for approval');

                  //   handleClick({ vertical: 'bottom', horizontal: 'left' });
                  // }}
                >
                  <i className="mdi mdi-check" style={{ fontSize: "20px" }}></i>
                </button>
              </Tooltip>

              <Tooltip title="Save This Version" placement="top">
                <button
                  className="btn"
                  style={{
                    backgroundColor: "#505d69",
                    color: "white",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "4rem",
                    height: "4rem",
                    borderRadius: "50%",
                    border: "none",
                  }}
                  // onClick={() => {
                  //   // handleSaveDraft();
                  //   handleSaveDraftTiptap()
                  // }}

                  onClick={saveCurrentVersion}
                >
                  <i
                    className="mdi mdi-content-save-all"
                    style={{ fontSize: "20px" }}
                  ></i>
                </button>
              </Tooltip>
              <Tooltip title="Download" placement="top">
                <button
                  className="btn"
                  style={{
                    backgroundColor: "#505d69",
                    color: "white",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "4rem",
                    height: "4rem",
                    borderRadius: "50%",
                    border: "none",
                  }}
                >
                  <i className="mdi mdi-download"></i>
                </button>
              </Tooltip>
            </div> */}
            </Col>
          </Row>
          <Modal
            title="Please Add comment"
            visible={isModalOpen}
            onOk={handleModalOk}
            onCancel={handleModalCancel}
            okText="Submit"
            cancelText="Cancel"
          >
            <Input.TextArea
              placeholder="Add your comment"
              value={comment}
              onChange={handleCommentChange}
              rows={4}
            />
          </Modal>
          <Modal
            title="Unsaved Changes"
            visible={saveWarningVisible}
            onOk={handleSaveWarningOk}
            onCancel={handleSaveWarningCancel}
            okText="Save"
            cancelText="Cancel"
          >
            <p>
              You have unsaved changes. Please save your work before proceeding.
            </p>
          </Modal>
          {actions &&
            buttonActions
              .filter(
                (action) => action.condition === undefined || action.condition
              ) // Filter out only those actions which have conditions that return false
              .map((action, index) => {
                return action.dropdown ? (
                  <Dropdown
                    key={action.key}
                    overlay={menu}
                    trigger={["hover"]}
                    placement="topCenter"
                  >
                    {/* <Tooltip title={action.tooltip} placement="top"> */}
                    <FloatButton
                      icon={action.icon}
                      shape="square"
                      style={{
                        ...getButtonStyle(index),
                        background: "rgb(50,50,50)", // Ensure the consistent background
                      }}
                    />
                    {/* </Tooltip> */}
                  </Dropdown>
                ) : (
                  <Tooltip
                    title={action.tooltip}
                    placement="top"
                    key={action.key}
                  >
                    <FloatButton
                      icon={action.icon}
                      shape="square"
                      onClick={action.onClick}
                      style={{
                        ...getButtonStyle(index),
                        background: "rgb(50,50,50)", // Ensure the consistent background
                      }}
                    />
                  </Tooltip>
                );
              })}
        </div>
      </ThreadsProvider>
    </div>
  );
};
export default JobDescriptionDisplayTipTap;
