import {
  UserAddOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { Button, Col, Form, Input, Row, Select, Table, Space } from "antd";
import React, { useState } from "react";
const { Option } = Select;

const Admin = () => {
  // Sample data for the table
  const generateData = (num) => {
    const names = [
      "John Brown",
      "Jim Green",
      "Alice Johnson",
      "Michael Smith",
      "Sarah Davis",
      "David Lee",
      "Emily Wilson",
      "James Taylor",
      "Jessica Brown",
      "Daniel Harris",
    ];
    const emails = [
      "john.brown@example.com",
      "jim.green@example.com",
      "alice.johnson@example.com",
      "michael.smith@example.com",
      "sarah.davis@example.com",
      "david.lee@example.com",
      "emily.wilson@example.com",
      "james.taylor@example.com",
      "jessica.brown@example.com",
      "daniel.harris@example.com",
    ];
    const data = [];
    for (let i = 0; i < num; i++) {
      data.push({
        key: (i + 1).toString(),
        name: names[i % names.length],
        email: emails[i % emails.length],
      });
    }
    return data;
  };

  const [dataSource, setDataSource] = useState(generateData(100));

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
      onFilter: (value, record) =>
        record.name.toLowerCase().includes(value.toLowerCase()),
    },
    {
      title: "Email ID",
      dataIndex: "email",
      key: "email",
      sorter: (a, b) => a.email.localeCompare(b.email),
      onFilter: (value, record) =>
        record.email.toLowerCase().includes(value.toLowerCase()),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <EditOutlined
            onClick={() => console.log("Edit", record.key)}
            style={{ cursor: "pointer" }}
          />
          <DeleteOutlined
            onClick={() => console.log("Delete", record.key)}
            style={{ cursor: "pointer" }}
          />
        </Space>
      ),
    },
  ];

  return (
    <div
      className="adminmain"
      style={{ display: "flex", flexDirection: "column", width: "98%",flex:"1" }}
    >
      {/* Part 1 form */}
      <div className="part1" style={{ margin: "0px 0px 20px 0px", width: "100%" }}>
        <Form
          layout="vertical"
          style={{
            maxWidth: "100%",
            margin: "0 auto",
            padding: "20px",
            border: "solid 1px #d9d9d9",
            borderRadius: "15px",
          }}
        >
          <h2>Approver Settings</h2>
          <Row style={{ padding: "30px 0px 0px 0px" }} gutter={10}>
            <Col span={8}>
              <Form.Item
                label="Business Unit"
                tooltip="This is the Business unit"
              >
                <Input placeholder="Metals Corporate" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Location" tooltip="This is the Location">
                <Select defaultValue="Select a Location">
                  <Option>Hyderabad</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Number of approvers"
                tooltip="This is number of approvers"
              >
                <Select defaultValue="Select">
                  <Option>1</Option>
                  <Option>2</Option>
                  <Option>3</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row style={{ padding: "10px 0px 0px 0px" }} gutter={[16, 16]}>
            <Col span={24} md={8}>
              <Form.Item
                label="Uploader Email"
                tooltip="This is the email of uploader"
              >
                <Input placeholder="Enter Email" />
              </Form.Item>
            </Col>
            <Col
              span={24}
              md={{ span: 15, offset: 1 }}
              style={{
                display: "flex",
                justifyContent: "flex-end",
                gap: "20px",
                marginTop: "24px",
              }}
            >
              <Button
                style={{
                  backgroundColor: "black",
                  color: "white",
                  width: "150px",
                }}
                type="submit"
              >
                Save
              </Button>
              <Button style={{ width: "150px" }} type="dashed">
                Cancel
              </Button>
            </Col>
          </Row>
        </Form>
      </div>

      {/* Part 2 with heading and button */}
      <div
        className="part2"
        style={{
          margin: "0px 0px",
          padding: "20px",
          border: "solid 1px #d9d9d9",
          borderRadius: "15px",
          width: "100%",
        }}
      >
        <Row gutter={[16, 16]} align="middle">
          <Col span={24} md={8}>
            <h2 style={{ margin: 0 }}>Core HR Team 45</h2>
          </Col>
          <Col
            span={24}
            md={{ span: 15, offset: 1 }}
            style={{
              display: "flex",
              justifyContent: "flex-end",
              gap: "20px",
            }}
          >
            <Button
              style={{
                backgroundColor: "black",
                color: "white",
                width: "150px",
              }}
              type="submit"
            >
              Add new HR <UserAddOutlined />
            </Button>
          </Col>
        </Row>

        {/* Table with sorting, filtering, and actions */}
        <Table
          columns={columns}
          dataSource={dataSource}
          pagination={{
            pageSizeOptions: ["5", "10", "20"],
            showSizeChanger: true,
            pageSize: 5,
          }}
          style={{ marginTop: "20px" }}
        />
      </div>
    </div>
  );
};

export default Admin;
