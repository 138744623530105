import React, { useCallback } from "react";
import { useThreadsState } from "./threadsProvider";
import { CommentCard } from "./CommentCard";
import { ThreadCard } from "./ThreadCard";
import { ThreadComposer } from "./ThreadComposer";

export const ThreadsListItem = ({ thread, provider, active, open }) => {
  const { onClickThread, deleteThread, onHoverThread, onLeaveThread } =
    useThreadsState();

  const handleDeleteClick = useCallback(() => {
    deleteThread(thread.id);
  }, [thread.id, deleteThread]);

  const editComment = useCallback(
    (commentId, val) => {
      provider.updateComment(thread.id, commentId, { content: val });
    },
    [provider, thread.id]
  );

  const deleteComment = useCallback(
    (commentId) => {
      provider.deleteComment(thread.id, commentId);
    },
    [provider, thread.id]
  );

  const handleClickOutside = useCallback(() => {
    onClickThread();
  }, []);

  return (
    <div
      onMouseEnter={() => onHoverThread(thread.id)}
      onMouseLeave={() => onLeaveThread(thread.id)}
    >
      <ThreadCard
        id={thread.id}
        active={active}
        open={open}
        onClick={!open ? onClickThread : null}
        onClickOutside={handleClickOutside}
      >
        {open ? (
          <>
            <div className="header-group">
              <div className="button-group">
                <button onClick={handleDeleteClick}>Delete</button>
              </div>
            </div>
            <div className="comments-group">
              {thread.comments.map((comment) => (
                <CommentCard
                  key={comment.id}
                  name={comment.data.userName}
                  content={comment.content}
                  createdAt={comment.createdAt}
                  onEdit={(val) => editComment(comment.id, val)}
                  onDelete={() => deleteComment(comment.id)}
                  showActions={true}
                />
              ))}
            </div>
            <div className="reply-group">
              <ThreadComposer threadId={thread.id} provider={provider} />
            </div>
          </>
        ) : (
          <div className="comments-group">
            <CommentCard
              key={thread.comments[0].id}
              name={thread.comments[0].data.userName}
              content={thread.comments[0].content}
              createdAt={thread.comments[0].createdAt}
            />
            <div className="comments-count">
              <label>
                {thread.comments.length - 1 || 0}{" "}
                {(thread.comments.length - 1 || 0) === 1 ? "reply" : "replies"}
              </label>
            </div>
          </div>
        )}
      </ThreadCard>
    </div>
  );
};
