import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import Tooltip from './Groups/Legal/component/Tooltip';
import {
  Container,
  Spinner,
  Card,
  CardBody,
  Row,
  Col,
  Button,
  CardImg,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import Header from '../../../components/Common/NewHeader';
import NewMainContent from '../../New Content Search/Components/kx-components/mainStyleCopy';
import Defaultrender from './kx-components/Defaultrender';
import { UncontrolledCarousel } from 'reactstrap';
import { getDefaultObject } from './Utils';

import SingleAnserPage from '../SingleAnserPage';
import {
  changeSearchDisabled,
  changeFeatureList,
  changeTrailExpiredMessage,
  changeChatQuestions,
  appDispayHandler,
  changeGroups,
  changeCurrentApp,
} from '../../../store/actions';
import { Navigate } from 'react-router-dom';
import useDatacallswithtoken from '../../../Data Apis/apifunctions';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import SearchBar from '../../New Content Search/Components/searchBar';
import SubscribeMessage from '../../SubscribeMessage/index';
import NewChatRender from '../../New Content Search/Components/stru-components/Chatrender';
import RenderPdf from '../../New Content Search/Components/kx-components/renderPdf';
import CommonAnswersLayout from './AnswersLayout/CommonAnswersLayout';
import HeaderWithoutGreet from '../../../components/Common/HeaderWithoutGreet';
import MainContent from './kx-components/mainContent';
import SearchEngineLayout from './SearchEngineLayout.js/SearchEngineLayout';
import LegalHeader from '../../../components/Common/LegalHeader';

const NewContentSearch = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const ref = useRef(null);
  const { user, getAccessTokenSilently } = useAuth0();
  const {
    useForFeatureList,
    useForLoadKx,
    useForLoadForGroupsKx,
    useForPdfDownload,
    useForCompositSummary,
    useForReleventQuestionsKX,
  } = useDatacallswithtoken();
  const mainController = new AbortController();
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [tempImgUrl, settempImgUrl] = useState('');
  const [sourcesList, setsourcesList] = useState([]);
  const [showAllImages, setshowAllImages] = useState([]);
  const [isError, setIsError] = useState(false);
  const [isSocketError, setIsSocketError] = useState(false);
  const [isLikedList, setisLikedList] = useState([[false, false]]);
  const contentSearchQuestions = useSelector(
    (state) => state.PageData.chatQuestions
  );
  const currentgroup = useSelector((state) => state.PageData.currentApp.folder);
  const [showSearchBar, setshowSearchBar] = useState(false);
  const [singleAnswerColapse, setSingleAnswerColapse] = useState([]);
  const [imagesUrl, setimagesUrl] = useState([]);
  const [activeGalleryImages, setactiveGalleryImages] = useState([]);
  const [photoIndex, setphotoIndex] = useState(0);
  const [sourceFileName, setsourceFileName] = useState('');
  const [fileSummary, setfileSummary] = useState([]);
  const [isGalleryZoom, setisGalleryZoom] = useState(false);
  const [releventQuestions, setReleventQuestions] = useState([]);
  const [questionsToRender, setQuestionsToRender] = useState([]);
  const [renderPdf, setrenderPdf] = useState([]);
  const [isPdfLoading, setisPdfLoading] = useState([]);
  const [timeTaken, settimeTaken] = useState([]);
  const [pdfByteArray, setpdfByteArray] = useState([]);
  const [fileUrl, setfileUrl] = useState([]);
  const [btnsuccess1, setBtnsuccess1] = useState(false);
  const [abortController, setAbortController] = useState(null);

  const [abortControllersArray, setabortControllersArray] = useState([]);

  const [dropup1, setDropup1] = useState([]);

  const featureList = useSelector((state) => state.PageData.featureList);
  const apps = useSelector((state) => state.PageData.apps);
  const appDisplay = useSelector((state) => state.PageData.appDisplay);
  const isSideBarOpen = useSelector((state) => state.PageData.isSideBarOpen);
  const [streamingResponse, setstreamingResponse] = useState([]);
  const [tempTimeTakenArray, settempTimeTakenArray] = useState([]);
  const [columnsToCancle, setColumnsToCancle] = useState([]);

  const [questionIdList, setquestionIdList] = useState([]);

  const [news, setnews] = useState(null);
  const [insights, setinsights] = useState(null);

  const currentApp = useSelector((state) => state.PageData.currentApp);
  const groupName = useSelector((state) => state.PageData.appDisplay);

  const contentSearchQuestion = useSelector(
    (state) => state.PageData.contentSearchQuestion
  );

  useEffect(() => {
    // Initialize the AbortController when the component mounts
    setAbortController(new AbortController());
    if (abortControllersArray?.length > 0) {
      abortControllersArray?.forEach((eachController) => {
        if (eachController) {
          eachController?.abort();
        }
      });
      setabortControllersArray([]);
    }

    return () => {
      abortControllersArray?.forEach((eachController) => {
        if (eachController) {
          eachController?.abort();
        }
      });
      setabortControllersArray([]);
    };
  }, []);

  const HandleReleventQuestions = (question, data, fileName) => {
    useForReleventQuestionsKX({ question: question, data: data }).then(
      (res) => {
        // console.log(res)
        // get all values from res and set it to releventQuestions
        let questions = [];
        Object.keys(res).map(function (key) {
          const obj = {
            fileName: fileName,
            question: res[key],
          };
          questions.push(obj);
        });
        const questionsArray = Object.values(res);
        setReleventQuestions(questionsArray);
        // setReleventQuestions((q) => {
        //   return [...q, ...questions];
        // });
      }
    );
  };

  const fetchStreamingResponse = async (question, files, index) => {
    const startTime = new Date().getTime();
    let helperValue = null;
    setshowSearchBar(false);

    // Abort the current fetch request if it exists
    if (abortController) {
      abortController.abort();
    }

    // Create a new AbortController for the current request
    const newController = new AbortController();
    setAbortController(newController);

    if (abortControllersArray[index]) {
      abortControllersArray[index].abort();
    }

    // Create a new AbortController for the current request
    const controller = new AbortController();
    setabortControllersArray((prevControllers) => {
      const newControllers = [...prevControllers];
      newControllers[index] = controller;
      return newControllers;
    });

    const data_config = {
      question: question,
      filekey: currentgroup,
      ques_id: questionIdList[index],
      count_k: 3,
      fileName: files,
      type: 'Multi-search',
    };

    const options = {
      method: 'POST',
      headers: {
        accept: 'application/json',
        Authorization: `Bearer ${await getAccessTokenSilently()}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data_config),
      signal: controller.signal,
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_AUTH0_URL}/contentSearch`,
        options
      );

      const reader = response.body.getReader();
      const decoder = new TextDecoder('utf-8');
      let done = false;
      let fullResponse = ''; // Variable to accumulate the streamed data

      while (!done) {
        const { value, done: readerDone } = await reader.read();
        done = readerDone;

        if (value) {
          const chunk = decoder.decode(value, { stream: true });
          fullResponse += chunk; // Accumulate the chunk to fullResponse
          setstreamingResponse((prevResponses) => {
            const newResponses = [...prevResponses];
            newResponses[index] += chunk;
            return newResponses;
          });
          if (helperValue === null) {
            const endTime = new Date().getTime();
            const timeTaken = (endTime - startTime) / 1000;
            settimeTaken((prev) => {
              const newTimeTaken = [...prev];
              newTimeTaken[index] = formatTime(timeTaken);
              return newTimeTaken;
            });
            helperValue = timeTaken;
          }
        }

        if (done) {
          setshowSearchBar(true);
          HandleReleventQuestions(question, fullResponse); // Call the function with the accumulated data
        }
      }
    } catch (error) {
      if (error.name === 'AbortError') {
        console.log('Fetch aborted');
      } else {
        console.error('Fetch error:', error);
      }
    }
  };
  const handleRemoveSource = (index, source, i) => {
    const updatedSources = sourcesList[index]?.filter(
      (src, index) => index !== i
    );
    const newSourceList = [...sourcesList];
    newSourceList[index] = updatedSources;
    setsourcesList(newSourceList);

    const newStreamingResponses = [...streamingResponse];
    newStreamingResponses[index] = '';
    setstreamingResponse(newStreamingResponses);
    fetchStreamingResponse(
      contentSearchQuestions[index],
      updatedSources,
      index,
      new AbortController()
    );
  };

  document.title = 'Actalyst AI Platform';
  const [isPageExist, setIsPageExist] = useState(true);

  const GetFeatureList = () => {
    useForFeatureList().then((res) => {

      if (typeof res === 'object') {
        dispatch(changeFeatureList(res));
      } else {
        dispatch(changeTrailExpiredMessage(res));
      }
    });
  };

  const GetLoadKx = (lastPartOfPath) => {
    setIsPageLoading(true);
    useForLoadKx()
      .then((res) => {
        if (lastPartOfPath) {
          setnews(res?.news);
          setinsights(res?.insights);
          const group = res?.dataSrc_list ?
            res?.dataSrc_list.filter((eachGroup) => {
              if (
                eachGroup?.group_name?.toLowerCase()?.replaceAll(' ', '-') ===
                lastPartOfPath
              ) {
                return eachGroup;
              }
            }) : null
          
            console.log(group)
          
          dispatch(changeGroups(res?.dataSrc_list));

          dispatch(changeCurrentApp(group[0]));

          dispatch(appDispayHandler(group[0]?.group_name));
          setIsPageLoading(false);
        } else {
          setnews(res?.news);
          setinsights(res?.insights);

          const defaultGroup = getDefaultObject('IN', featureList);
          dispatch(changeGroups(res?.dataSrc_list));
          dispatch(changeCurrentApp(defaultGroup));
          dispatch(appDispayHandler(defaultGroup?.group_name));
          setIsPageLoading(false);
        }
      })
      .catch((err) => {
        console.error('Error fetching KnowledgeX data: ', err);
        setIsPageLoading(false);
      });
  };

  useEffect(() => {
    // if (featureList?.length === 0) {
    //   // GetFeatureList();
    // }
    // // if "IN" not in featureList, then navigate to subscribe
    // else if (!featureList.find((item) => item.code === 'IN')) {
    //   // navigate("/app/subscribe", { replace: true });
    //   if (featureList.find((item) => item.code === 'FS')) {
    //     navigate('/app/filesense', { replace: true });
    //     //setIsPageExist(false)
    //   } else {
    //     setIsPageExist(false);
    //   }
    // }

    const lastPartOfPath = location.pathname.split('/')[location.pathname.split('/').length - 1];
    console.log("last part", lastPartOfPath)

    if (lastPartOfPath !== 'insights') {
      GetLoadKx(lastPartOfPath);
    } else {
      GetLoadKx();
    }
  }, []);

  useEffect(() => {
    singleAnswerColapse.map((item) => {
      if (
        item.structuredSummary &&
        item.unstructuredSummary &&
        item.compositSummaryLoading
      ) {
        GetCompositSummary(
          item.question,
          item.structuredSummary,
          item.unstructuredSummary
        );
      }
    });
  }, [singleAnswerColapse]);

  useEffect(() => {
    if (contentSearchQuestions.length > 0) {
      addItem();
      setSingleAnswerColapse((q) => {
        let q1 = q.map((item) => {
          return { ...item, isColapse: true };
        });
        return [
          ...q1,
          {
            question: contentSearchQuestions[contentSearchQuestions.length - 1],
            isColapse: true,
            isSocketError: false,
            unstructuredSummary: '',
            unstructuredSummaryLoading: true,
            structuredSummary: '',
            structuredSummaryLoading: true,
            compositSummary: '',
            compositSummaryLoading: true,
            source: [],
            imagesUrl: [],
          },
        ];
      });
    } else {
      setSingleAnswerColapse([]);
      setReleventQuestions([]);
      settimeTaken([]);
      setstreamingResponse([]);
      setisLikedList([[false, false]]);
      setsourcesList([]);
    }
    if (ref.current) {
      ref.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      });
    }
  }, [contentSearchQuestions]);

  useEffect(() => {
    // let dumObj = releventQuestions.map((item) => {
    //   return item?.question;
    // });
    // setQuestionsToRender(
    //   dumObj.sort(() => Math.random() - Math.random()).slice(0, 5)
    // );
    setQuestionsToRender(releventQuestions);
  }, [releventQuestions]);

  const addItem = () => {
    setstreamingResponse([...streamingResponse, '']);
    setisLikedList([...isLikedList, [false, false]]);
    setrenderPdf([...renderPdf, false]);
    setshowAllImages([...showAllImages, false]);
    setisPdfLoading([...isPdfLoading, false]);
  };

  const toggleRenderPdf = (index, value) => {
    const updatedRenderPdf = [...renderPdf];
    updatedRenderPdf[index] = value;
    setrenderPdf(updatedRenderPdf);
  };

  const isPdf = (value, index) => {
    return value.split('/')[index].slice(0, 40) + '...';
  };

  const handleSetshowAllImages = (index, value) => {
    const updatedshowAllImages = [...showAllImages];
    updatedshowAllImages[index] = value;
    setshowAllImages(updatedshowAllImages);
  };

  const handleSetIsPdfLoading = (index, value) => {
    const updatedIsPdfLoading = [...isPdfLoading];
    updatedIsPdfLoading[index] = value;
    setisPdfLoading(updatedIsPdfLoading);
  };

  const handleSettimeTaken = (value) => {
    const updatedtimeTaken = [...timeTaken, value];
    settimeTaken(updatedtimeTaken);
  };

  const handleSetStreamingResponse = (value, timeTaken) => {
    let helperValue = null;
    const timeTakenInSeconds = timeTaken / 1000;
    settempTimeTakenArray((prevArray) => {
      const newArray = [...prevArray, timeTakenInSeconds];

      if (newArray.length === 1) {
        handleSettimeTaken(formatTime(newArray[0]));
      }

      return newArray;
    });

    if (helperValue === null) {
      helperValue = false;
    }

    setstreamingResponse((prevResponse) => {
      // Make a copy of the previous state array
      const newArray = [...prevResponse];

      if (!newArray[contentSearchQuestions.length - 1]) {
        newArray[contentSearchQuestions.length - 1] = value;
      } else {
        // If there's already a value, append the new value to it
        newArray[contentSearchQuestions.length - 1] += value;
      }

      return newArray;
    });
  };

  const setUnstructuredSummary = (
    question,
    summary,
    imagesUrl,
    source,
    timeTaken,
    fileUrl,
    fileSummary,
    questionId
  ) => {
    setDropup1((prev) => [...prev, false]);
    let isSorceString = null;
    if (typeof source === 'string') {
      isSorceString = [source];
    } else {
      isSorceString = source;
    }

    setquestionIdList((prev) => [...prev, questionId]);
    setsourcesList((prev) => [...prev, isSorceString]);
    settempTimeTakenArray([]);
    setSingleAnswerColapse((q) => {
      return q.map((item) => {
        if (item.question === question) {
          return {
            ...item,
            unstructuredSummary: summary,
            unstructuredSummaryLoading: false,
            imagesUrl: imagesUrl,
            source: isSorceString,
            fileUrl: fileUrl,
          };
        } else {
          return item;
        }
      });
    });
    setfileSummary((prev) => [...prev, fileSummary]);
    const filteredImagesUrl = imagesUrl?.filter((each) => each[0] !== null);
    if (filteredImagesUrl.length > 0) {
      setimagesUrl((prev) => [...prev, filteredImagesUrl[0]]);
    } else {
      setimagesUrl((prev) => [...prev, filteredImagesUrl]);
    }

    setfileUrl((prev) => [...prev, fileUrl]);
  };

  const GetCompositSummary = (
    question,
    structurdSummary,
    unStructuredSummary
  ) => {
    const data = {
      question: question,
      structuredSummary: structurdSummary,
      unstructuredSummary: unStructuredSummary,
    };
    useForCompositSummary(data).then((res) => {
      setCompositSummary(question, res?.summary);
    });
  };

  const setCompositSummary = (question, summary) => {
    setSingleAnswerColapse((q) => {
      return q.map((item) => {
        if (item.question === question) {
          return {
            ...item,
            compositSummary: summary,
            compositSummaryLoading: false,
          };
        } else {
          return item;
        }
      });
    });
  };

  const setStructuredSummary = (question, summary) => {
    setSingleAnswerColapse((q) => {
      return q.map((item) => {
        if (item.question === question) {
          return {
            ...item,
            structuredSummary: summary,
            structuredSummaryLoading: false,
          };
        } else {
          return item;
        }
      });
    });
  };

  const setisSocketError = (question) => {
    setSingleAnswerColapse((q) => {
      return q.map((item) => {
        if (item.question === question) {
          return { ...item, isSocketError: true };
        } else {
          return item;
        }
      });
    });
  };

  const handleSetPdfByte = (byteArray, index) => {
    const updatePdfByte = [...pdfByteArray];
    updatePdfByte[index] = byteArray;
    setpdfByteArray(updatePdfByte);
  };

  const DownloadPdf = async (filePath, index, value) => {
    handleSetIsPdfLoading(index, true);
    const dataConfig = {
      filekey: filePath,
      group: currentApp.folder,
      userId: user?.sub,
    };
    useForPdfDownload(dataConfig).then((res) => {
      handleSetPdfByte(res?.data, index);
      toggleRenderPdf(index, value);
      handleSetIsPdfLoading(index, false);
    });
  };

  const handleSetActiveGallery = (gallery) => {
    setactiveGalleryImages(gallery);
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = (seconds % 60).toFixed(2);

    if (minutes > 0) {
      return `${minutes}m ${remainingSeconds}s`;
    } else {
      return `${remainingSeconds}s`;
    }
  };

  useEffect(() => {
    setIsSocketError(false);
  }, [currentApp]);
  return (
    <React.Fragment>
      <div className='page-content' style={{ padding: '0px' }}>
        {isPageLoading ? (
          <Spinner
            style={{ position: 'absolute', top: '50%', left: '50%' }}
            animation='border'
            variant='primary'
          />
        ) : contentSearchQuestions.length === 0 ? (
          currentApp.group_name === 'Legal' ?
            <div>
              <LegalHeader />
              <Defaultrender
                insights={insights}
                news={news}
                userGroup={currentApp.group_name}
              />
            </div> :
            <div style={{ padding: '1em 2em' }}>
              <Header />
              <Defaultrender
                insights={insights}
                news={news}
                userGroup={currentApp.group_name}
              />
            </div>
        ) : currentApp.group_name === 'Legal' ||
          currentApp.group_name == 'Blue Star' ||
          currentApp.group_name == 'Ekaayan-SOP' ||
          currentApp.group_name == 'Aluminium Market Outlook' || currentApp.group_name == 'ATL Research' ? (
          <div style={{ paddingTop: '1em' }}>
            <HeaderWithoutGreet />
            <CommonAnswersLayout currentApp={currentApp} />
          </div>
        ) : currentApp.group_name === 'ATL Marketing Intelligence' ||
          currentApp.group_name === 'Sambalpur-KPI' ? (
          <SearchEngineLayout
            contentSearchQuestion={contentSearchQuestion}
            currentApp={currentApp}
          />
        ) : (
          <SingleAnserPage />
        )}
      </div>
    </React.Fragment>
  );
};

export default NewContentSearch;

// <MainContent  userGroup={currentApp.folder}setColumnsToCancle={setColumnsToCancle}/>:
