import React, { useCallback, useEffect, useState } from "react";
import { Table, Flex, Button, Row, Col, Typography, Checkbox } from "antd";

import "./TableDiv.css";
import { FiSend } from "react-icons/fi";

const Tablediv = ({
  title = "Table",
  columns = [],
  dataSource = [],
  rowSelectionEnabled,
  pageSize = 10,
  onEditClick,
}) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const onSelectChange = (newSelectedRowKeys) => {
    console.log("Selected keys:", newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = rowSelectionEnabled
    ? {
        selectedRowKeys,
        onChange: onSelectChange,
      }
    : null;

  // Add rowClassName function to alternate row colors
  const rowClassName = (record, index) => {
    return index % 2 === 0 ? "table-row-light" : "table-row-dark";
  };

  const buttonStyles = {
    backgroundColor: selectedRowKeys?.length > 0 ? "black" : "#d9d9d9",
    color: selectedRowKeys?.length > 0 ? "white" : "#a0a0a0",
    cursor: selectedRowKeys?.length > 0 ? "pointer" : "not-allowed",
    border: "none",
  };

  return (
    <div className="tabledivmain">
      <div
        className="tabledivdashboard"
        style={{ padding: "20px 0px 20px 15px" }}
      >
        <Row
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
            alignItems: "center",
            gap: "30px",
          }}
        >
          <Col align="start">
            <Typography
              style={{
                color: "var(--Primary-6, #666)",
                fontSize: "1.4rem",
                fontWeight: 500,
              }}
            >
              {title}
            </Typography>
          </Col>

          <Col align="start">
            <Button
              type="primary"
              style={buttonStyles}
              disabled={selectedRowKeys?.length === 0} // Disable button if no rows are selected
            >
              <FiSend /> Share Selected JD
            </Button>
          </Col>
          <Col align="start">
            {selectedRowKeys?.length > 0
              ? `Selected ${selectedRowKeys?.length} items`
              : null}
          </Col>
        </Row>
        <Flex
          gap="middle"
          vertical
          style={{ margin: "1.5em 0.9em 0px 0px", whiteSpace: "wrap" }}
        >
          <Table
            rowSelection={rowSelection}
            columns={columns}
            dataSource={dataSource}
            pagination={{ pageSize }}
            rowClassName={rowClassName} // Apply row class names for alternating colors
            onRow={(record) => ({
              onClick: (event) => {
                event.stopPropagation(); // Stop the event from bubbling up to the body
                if (onEditClick) onEditClick(record);
              },
            })}
          />
        </Flex>
      </div>
    </div>
  );
};

export default Tablediv;
