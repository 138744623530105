import { Alert, Button, Descriptions, Flex, Skeleton, Steps } from 'antd'
import React, { useEffect, useState } from 'react'
import StepSidebar from './StepSidebar'
import "../../../style/style.css"
import { step1, step2 } from '../../data'
// import Step1 from './Step1'
import Step1 from './Step1'
import Step2 from './Step2'
import SimpleBar from 'simplebar-react';
import useDatacallswithtoken from '../../../../../Data Apis/apifunctions'
import { useNotification } from '../NotificationProvider'
import Step3 from './Step3'
import { set } from 'lodash'
import { CloseCircleFilled, CloseOutlined } from '@ant-design/icons'
import BlackBtn from './Elements/BlackBtn'
import { all } from 'axios'

const StepComponent = ({ singleAnalysis, selecTedTopicItem: initialSelectedItem, analysisId, handleDisplaySteps, allQuestionsSaved, setAllQuestionsSaved,fileList }) => {
  // console.log("analysisId",analysisId )
  const notification = useNotification();
  
  const { useForAnalysisFullDataByStep } = useDatacallswithtoken();
  const [selecTedTopicItem, setSelecTedTopicItem] = useState(initialSelectedItem);
  // const [allQuestionsSaved, setAllQuestionsSaved] = useState({});
  const [current, setCurrent] = useState(0);
  const [step1Data, setStep1Data] = useState();
  const [step2Data, setStep2Data] = useState();
  const [step3Data, setStep3Data] = useState();
  const [isPdfDownloading, setisPdfDownloading] = useState(false);
  const [loading, setLoading] = useState(true);








  const FetchStepData = (analysesId, selecTedTopicItem, step) => {
    setLoading(true);
    useForAnalysisFullDataByStep(analysesId, selecTedTopicItem, step).then((res) => {
      setLoading(false);
      console.log(res);
      let result =res
      if(res?.topic_stage_data && res.topic_stage_data.length==0 ){
      result=  null;
      }
      if (step == "Data") setStep1Data(result);
      else if (step == "Drafts") setStep2Data(result);
      else if (step == "Summary") setStep3Data(result);

    }).catch((err) => {

      if (step == "Data") setStep1Data(null);
      else if (step == "Drafts") setStep2Data(null);
      else if (step == "Summary") setStep3Data(null);

      setLoading(false)
      console.log(err)
      notification.error({
        message: "Error",
        description: err.message,
        placement: 'topRight',
      });

    })
  }


 

  useEffect(() => {
    console.log(analysisId);
    
    FetchStepData(analysisId,selecTedTopicItem?.analysis_topic_id , steps[current]?.title)

  }, [selecTedTopicItem, current, analysisId])

  const handleChangeSelectedTopic = (selecTedTopicItem) => {
    setSelecTedTopicItem(selecTedTopicItem)
  }

  const handleStepsComplete = () => {
    if (!Object.values(allQuestionsSaved).every(value => value === true)) {
      const btn = <BlackBtn name={"Close Editing"} onBtnClick={() => { handleDisplaySteps(false); setAllQuestionsSaved({}) }} />

      notification.warning({
        message: 'Save all the changes to complete your edting else changes will be lost',
        description: 'If you want to Close Editing  with out saving click on Close Editing',
        btn,
        placement: 'topRight',
      })

    } else {
      handleDisplaySteps(false)
      notification.success({
        message: 'Edit is complete',
        description: 'This is demo description',
        placement: 'topRight',
      });
    }

  }

  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
    description: item.description,
  }));
  const next = () => {
    console.log(allQuestionsSaved)
    if (!Object.values(allQuestionsSaved).every(value => value === true)) {
      const btn = <BlackBtn name={"Move to next"} onBtnClick={() => { setCurrent(current + 1); setAllQuestionsSaved({}) }} />

      notification.warning({
        message: 'Save all the changes to move to next steps else changes will be lost',
        description: 'If you want to move to next step with out saving click on Move to next',
        btn,
        placement: 'topRight',
      })

    } else {
      setCurrent(current + 1);
    }

  };
  const prev = () => {
    if (!Object.values(allQuestionsSaved).every(value => value === true)) {
      const btn = <BlackBtn name={"Move back"} onBtnClick={() => { setCurrent(current - 1); setAllQuestionsSaved({}) }} />

      notification.warning({
        message: 'Save all the changes to move to previous steps else changes will be lost',
        description: 'If you want to move to previous step with out saving click on Move back',
        btn,
        placement: 'topRight',
      })

    } else {

      setCurrent(current - 1);
    }
  };
  const handleTopicChange = (item) => {
    setCurrent(0)
    setSelecTedTopicItem(item)
    // console.log(item)
  }
  const handleCloseSteps = () => {

    if (!Object.values(allQuestionsSaved).every(value => value === true)) {
      const btn = <BlackBtn name={"Close Analysis"} onBtnClick={() => { handleDisplaySteps(false); }} />

      notification.warning({
        message: 'Save all the changes to close steps',
        description: 'If you want to close with out saving click on close Analyis',
        btn,
        placement: 'topRight',
      })

    } else {
      handleDisplaySteps(false)
    }
  }
  function  saveChanges  (promptsSaved) {
    promptsSaved && setAllQuestionsSaved(prev => ({ ...prev, ...promptsSaved }))
} 

  return (
    <Flex className='w100-h100' style={{ position: "relative" }}>
      <CloseOutlined style={{ position: "absolute", top: "15px", right: "15px", fontSize: "17px", color: "#999" }} onClick={() => { handleCloseSteps() }} />
      <StepSidebar
        fileList={fileList}
        singleAnalysis={singleAnalysis}
        selecTedTopicItem={selecTedTopicItem}
        onTopicChange={handleTopicChange}
        allQuestionsSaved={allQuestionsSaved}
        setAllQuestionsSaved={setAllQuestionsSaved}
      />

      <Flex className='w100-h100 ' justify='space-between' vertical gap={20} style={{ padding: "20px 20px 10px 20px" }} >
        <Steps current={current} items={items} style={{ padding: "0px 40px 0px 20px" }} />

        <SimpleBar style={{ height: "70vh", maxHeight: "70vh" }}>
          <Flex className='w100-h100 '  >

            {current == 0 ?
              (loading ? <Skeleton title={false} paragraph={{ rows: 3 }} /> :
                step1Data && <Step1 step1Data={step1Data} analysisId={analysisId} setAllQuestionsSaved={setAllQuestionsSaved} />) :
              current == 1 ?
                (loading ? <Skeleton title={false} paragraph={{ rows: 3 }} /> :
                  step2Data && <Step2 step2Data={step2Data} analysisId={analysisId} saveChanges={saveChanges} />) :
                current == 2 ?
                  (loading ? <Skeleton title={false} paragraph={{ rows: 3 }} /> :
                    step3Data && <Step3 step3Data={step3Data} analysisId={analysisId} setAllQuestionsSaved={setAllQuestionsSaved} />) :
                  null

            }
            {!step1Data?.topic_stage_data &&
              !step2Data?.topic_stage_data &&
              !step3Data?.topic_stage_data
              && !loading && <Alert type='info' message='No analysis to display. Try to change topic to get analysis'/>
            }

          </Flex>
        </SimpleBar>

        <Flex gap={20} justify='end' style={{ bottom: "0px", right: "0px", width: "100%", zIndex: "3", background: "white" }}>
          {current > 0 && (
            <Button
              style={{
                margin: '0 8px',
              }}
              onClick={() => prev()}
            >
              Previous
            </Button>
          )}
          {current < steps.length - 1 && (
            <Button type="primary" onClick={() => next()}>
              Next
            </Button>
          )}
          {current === steps.length - 1 && (
            <Button type="primary" onClick={() => { handleStepsComplete() }}>
              Editing Complete
            </Button>
          )}
        </Flex>
      </Flex>
    </Flex>
  )
}

export default StepComponent

const steps = [
  {
    title: 'Data',
    content: 'First-content',
    description: "1st desc"
  },
  {
    title: 'Drafts',
    content: 'Second-content',
    description: "2nd desc"
  },
  {
    title: 'Summary',
    content: 'Last-content',
    description: "3rd desc"
  },
];
