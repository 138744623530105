/*
Copyright 2020 Adobe
All Rights Reserved.

NOTICE: Adobe permits you to use, modify, and distribute this file in
accordance with the terms of the Adobe license agreement accompanying
it. If you have received this file from a source other than Adobe,
then your use, modification, or distribution of it requires the prior
written permission of Adobe.
*/

import React, { useState, useEffect } from 'react';
import ViewSDKClient from './ViewSDKClient';

import { useAuth0 } from '@auth0/auth0-react';
import FileSharing from './FileSharing';

const FullWindow = ({ pdfBlob, fileDetails, setopenSnackbar, handleClick }) => {
  const [showModal, setshowModal] = useState(false);
  const { user, getAccessTokenSilently } = useAuth0();
  useEffect(() => {
    const viewSDKClient = new ViewSDKClient();
    console.log('full window');

    console.log(viewSDKClient);

    const pdfUrl = URL.createObjectURL(pdfBlob);
    viewSDKClient.ready().then(() => {
      /* Invoke file preview */
      /* By default the embed mode will be Full Window */
      viewSDKClient.previewFile(
        'pdf-div',
        fileDetails,
        pdfUrl,
        user,
        getAccessTokenSilently,
        setopenSnackbar
      );
    });
  }, [pdfBlob]);

  const shareButton = () => {
    setshowModal(!showModal);
  };

  return (
    <div>
      <div id='pdf-div' style={{ height: '80vh' }} className='full-window-div'>
        <FileSharing
          fileDetails={fileDetails}
          showModal={showModal}
          setshowModal={setshowModal}
          updatedBlob={pdfBlob}
          handleClick={handleClick}
        />
      </div>
      <button
        style={{
          position: 'absolute',
          top: '15px',
          right: '80px',
          zIndex: '1000',
          color: 'black',
          backgroundColor: 'transparent',
          border: 'none',
          cursor: 'pointer',
          marginLeft: '1em',
        }}
        onClick={shareButton}
      >
        <i style={{ color: 'gray' }} className='fa fa-share'></i>
      </button>
    </div>
  );
};

export default FullWindow;
