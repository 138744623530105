import React, { useEffect, useState } from "react";
import Tablediv from "../../Components/Table/TableDiv";
import {
  Button,
  Tooltip,
  Divider,
  message,
  Skeleton,
  Menu,
  Dropdown,
  Modal,
  Input,
} from "antd";
import { FiSend } from "react-icons/fi";
import { DownloadOutlined } from "@ant-design/icons";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import useDatacallswithtoken from "../../../../Data Apis/apifunctions";

const PendingApproval = ({ onEditClick, setPagesCount }) => {
  const {
    useForDownloadWordFile,
    useForDownloadExcelFile,
    useForPendingApprovalList,
    useForApproveJD,
    useForReviseJD,
  } = useDatacallswithtoken();
  const { user, getAccessTokenSilently } = useAuth0();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true); // Add loading state
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [comment, setComment] = useState("");
  const [func, setFunc] = useState(null);
  const [clickedRecord, setClickedRecord] = useState([]);
  const navigate = useNavigate();
  const approversList = [
    "S. Verma",
    "Purnesh Gali",
    "Aravind K",
    "Javeed M",
    "Srinivas R",
    "Nina Patel",
    "Raj Kumar",
    "Anita Singh",
  ];
  const FetchPendingApprovalList = async (body) => {
    const res = await useForPendingApprovalList(JSON.stringify(body));
    if (res.status === 200) {
      return res; // Return the response data
    } else {
      throw new Error("Error fetching pending approval list");
    }
  };
  const DownloadWordFile = async (body) => {
    const res = await useForDownloadWordFile(body);

    if (res.status !== 200) {
      throw new Error("Error downloading Word file");
    }

    // Convert the response to a blob (binary large object)
    const blob = new Blob([res.data], {
      type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    });

    // Create a link element and use it to trigger the download
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "job_description.docx"); // Set file name
    document.body.appendChild(link);
    link.click();

    // Clean up the URL and remove the link
    window.URL.revokeObjectURL(url);
    link.remove();

    message.success("Word file downloaded successfully!");
  };
  const DownloadExcelFile = async (body) => {
    const res = await useForDownloadExcelFile(body);

    if (res.status !== 200) {
      throw new Error("Error downloading Excel file");
    }

    // Convert the response to a blob (binary large object)
    const blob = new Blob([res.data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    // Create a link element and use it to trigger the download
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "job_description.xlsx"); // Set file name
    document.body.appendChild(link);
    link.click();

    // Clean up the URL and remove the link
    window.URL.revokeObjectURL(url);
    link.remove();

    message.success("Excel file downloaded successfully!");
  };
  const handleDownloadFile = async (jd_key, fileType) => {
    try {
      const body = {
        user_id: user?.sub,
        jd_key: jd_key,
      };

      if (fileType === "word") {
        await DownloadWordFile(body);
      } else if (fileType === "excel") {
        await DownloadExcelFile(body);
      }
    } catch (error) {
      console.error(`Error during ${fileType} file download:`, error);
      message.error(`Error downloading ${fileType} file`);
    }
  };
  const ApproveJD = async (body) => {
    const res = await useForApproveJD(body);
    if (res.status === 200) {
      return res; // Return the response data
    } else {
      throw new Error("Error approving job description");
    }
  };
  const ReviseJD = async (body) => {
    const res = await useForReviseJD(body);
    if (res.status === 200) {
      return res; // Return the response data
    } else {
      throw new Error("Error revising job description");
    }
  };
  const handleApproveClick = (record) => {
    setClickedRecord(record);
    setIsModalOpen(true);
    setFunc("approve");
  };
  const handleReviseClick = (record) => {
    setClickedRecord(record);
    setIsModalOpen(true);
    setFunc("revise");
  };
  const handleModalOk = () => {
    if (func === "approve") {
      handleApprove(comment);
    } else if (func === "revise") {
      handleRevise(comment);
    }
    setIsModalOpen(false); // Close the modal after submission
    setComment(""); // Reset the comment field
  };
  const handleModalCancel = () => {
    setIsModalOpen(false); // Close the modal without submission
    setComment("");
  };
  const handleCommentChange = (e) => {
    setComment(e.target.value); // Update the comment input
  };
  const handleApprove = async (comment) => {
    try {
      const accessToken = await getAccessTokenSilently();
      const body = { jd_key: clickedRecord?.key, comments: comment };
      const res = await ApproveJD(body);

      const data = res.data;

      message.success("Submitted for approval successfully!");
      setTimeout(() => {
        navigate("/app/writing-assistant/my-task");
      }, [300]);

      setClickedRecord([]);
    } catch (error) {
      message.error("Error submitting job description for approval");
    }
  };
  const handleRevise = async (comment) => {
    try {
      const accessToken = await getAccessTokenSilently();

      const body = { jd_key: clickedRecord?.key, comments: comment };
      const res = await ReviseJD(body);

      const data = res.data;

      message.success("Submitted for approval successfully!");
      setTimeout(() => {
        navigate("/app/writing-assistant/my-task");
      }, [300]);

      setClickedRecord([]);
    } catch (error) {
      message.error("Error submitting job description for approval");
    }
  };
  useEffect(() => {
    const fetchPendingApprovalPageData = async () => {
      setLoading(true); // Set loading to true before fetching data
      const jsonData = { userId: user?.sub };
      try {
        const res = await FetchPendingApprovalList(jsonData);

        const fetchedData = res.data;

        await setPagesCount({ pendingApproval: fetchedData.length });
        const formattedData = fetchedData.map((item) => ({
          key: item.jd_key,
          positionId: item.position_id,
          processTitle: item.process_title,
          department: item.department,
          positionLocation: item.position_location,
          submittedDate: item?.submitted_dt
            ? new Date(item?.submitted_dt).toISOString().split("T")[0]
            : null,
          submittedBy: item.submited_by, // Placeholder
        }));
        setData(formattedData);
      } catch (error) {
        console.error("Error during fetch:", error);
        message.error("Error fetching data");
      } finally {
        setLoading(false); // Set loading to false after data fetching is done
      }
    };
    fetchPendingApprovalPageData();
  }, [getAccessTokenSilently, user]);

  const columns = [
    {
      title: "Position ID",
      dataIndex: "positionId",
      sorter: (a, b) => a.positionId.localeCompare(b.positionId),
      filters: [
        { text: "ID 1", value: "1" },
        { text: "ID 2", value: "2" },
      ],
      onFilter: (value, record) => record.positionId.includes(value),
    },
    {
      title: "Position Title",
      dataIndex: "processTitle",
      filters: [
        { text: "Manager", value: "Manager" },
        { text: "Developer", value: "Developer" },
      ],
      onFilter: (value, record) => record.processTitle.includes(value),
    },
    {
      title: "Department",
      dataIndex: "department",
      filters: [
        { text: "HR", value: "HR" },
        { text: "Engineering", value: "Engineering" },
      ],
      onFilter: (value, record) => record.department.includes(value),
    },
    {
      title: "Location",
      dataIndex: "positionLocation",
      sorter: (a, b) => a.positionLocation.localeCompare(b.positionLocation),
      filters: [
        { text: "London", value: "London" },
        { text: "New York", value: "New York" },
      ],
      onFilter: (value, record) => record.positionLocation.includes(value),
    },
    {
      title: "Submitted Date",
      dataIndex: "submittedDate",
      sorter: (a, b) => new Date(a.submittedDate) - new Date(b.submittedDate),
    },
    {
      title: "Submitted By",
      dataIndex: "submittedBy",
      sorter: (a, b) => a.submittedBy.localeCompare(b.submittedBy),
      filters: approversList.map((name) => ({ text: name, value: name })),
      onFilter: (value, record) => record.submittedBy.includes(value),
    },
    {
      title: "Actions",
      dataIndex: "actions",
      render: (_, record) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "0px",
            padding: "0px",
            margin: "0px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              color: "black",
              gap: "0px",
              padding: "0px",
              margin: "0px",
            }}
          >
            <Button
              style={{
                textDecoration: "underline",
                gap: "1px",
                padding: "1px",
                margin: "1px",
              }}
              type="button"
              onClick={(e) => {
                e.stopPropagation();
                handleApproveClick(record);
              }}
            >
              Accept
            </Button>
            <Button
              style={{
                textDecoration: "underline",
                gap: "1px",
                padding: "1px",
                margin: "1px",
              }}
              type="button"
              onClick={(e) => {
                e.stopPropagation();
                handleReviseClick(record);
              }}
            >
              Revise
            </Button>
          </div>
          <Divider
            type="vertical"
            style={{ height: "24px", margin: "0 8px" }}
          />
          <div style={{ display: "flex" }}>
            <Tooltip title="Share">
              <Button
                type="button"
                icon={<FiSend />}
                style={{ gap: "0px", padding: "0px", margin: "0px" }}
                onClick={() => console.log("Share clicked", record)}
              />
            </Tooltip>
            {/* <Tooltip title="Download"> */}
            <Dropdown
              overlay={
                <Menu>
                  <Menu.Item
                    key="1"
                    onClick={(e) => {
                      e.domEvent.stopPropagation(); // Stop event from propagating to the row
                      handleDownloadFile(record.key, "excel");
                      console.log(record);
                    }}
                  >
                    Download Excel
                  </Menu.Item>
                  <Menu.Item
                    key="2"
                    onClick={(e) => {
                      e.domEvent.stopPropagation(); // Stop event from propagating to the row
                      handleDownloadFile(record.key, "word");
                    }}
                  >
                    Download Word
                  </Menu.Item>
                </Menu>
              }
              trigger={["hover"]}
              onClick={(e) => e.stopPropagation()} // Prevent click event from propagating to parent row
            >
              <Button
                type="button"
                icon={<DownloadOutlined />}
                style={{ padding: "4px", margin: "0" }}
              />
            </Dropdown>
            {/* </Tooltip> */}
          </div>
        </div>
      ),
    },
  ];

  return (
    <div className="pendingapprovalmain" style={{ flex: "1" }}>
      {loading ? (
        <Skeleton active />
      ) : (
        <Tablediv
          title={`Pending For Approval (${data.length})`}
          columns={columns}
          dataSource={data}
          rowSelectionEnabled={true}
          initialPageSize={10} // Set initial page size if needed
          onEditClick={onEditClick}
        ></Tablediv>
      )}
      <Modal
        title="Please Add comment"
        visible={isModalOpen}
        onOk={handleModalOk}
        onCancel={handleModalCancel}
        okText="Submit"
        cancelText="Cancel"
      >
        <Input.TextArea
          placeholder="Enter your comment"
          value={comment}
          onChange={handleCommentChange}
          rows={4}
        />
      </Modal>
    </div>
  );
};

export default PendingApproval;
