import React, { useState, useEffect } from 'react';

import { Container, Card, CardBody, CardHeader } from 'reactstrap';
import FileUpload from './Components/uploadfile';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  changeFeatureList,
  changeTrailExpiredMessage,
} from '../../store/actions';
import useDatacallswithtoken from '../../Data Apis/apifunctions';
import { useAuth0 } from '@auth0/auth0-react';
import amplitude from 'amplitude-js';

const WritingAsistant2 = () => {
  const dispatch = useDispatch();
  const amplitudeInstance = amplitude.getInstance();
  const { user, logout } = useAuth0();
  console.log('WA - User = ', user.name);
  amplitudeInstance.setUserId(user.name);
  amplitudeInstance.logEvent('WRITING ASSITANT', {
    page_name: 'Writing Assistant',
  });
  document.title = 'Writing Asistant | Actalyst AI Platform';
  const featureList = useSelector((state) => state.PageData.featureList);
  const { useForFeatureList } = useDatacallswithtoken();
  const GetFeatureList = () => {
    useForFeatureList().then((res) => {
      if (typeof res === 'object') {
        dispatch(changeFeatureList(res));
      } else {
        dispatch(changeTrailExpiredMessage(res));
      }
    });
  };
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [uploadColapsed, setUploadColapsed] = useState(false);

  const formatData = (data) => {
    // find words with pattern **anyword:**  and make them bold
    let datatoedit = JSON.parse(JSON.stringify(data));
    let boldPattern = /\*\*.*?.*?\*\*/g;
    let boldWords = data.match(boldPattern);
    console.log(boldWords);
    if (boldWords) {
      boldWords.forEach((word) => {
        let newWord = word.replace(/\*\*|:/g, '');
        console.log(newWord);
        datatoedit = datatoedit.replace(word, `<b>${newWord}</b>`);
      });
    }
    return datatoedit;
  };

  // useEffect(() => {
  //   // ReactGA.pageview(window.location.pathname)
  //   ReactGA.send({
  //     hitType: 'pageview',
  //     page: 'window.location.pathname',
  //   });
  // }, []);
  useEffect(() => {
    if (data) {
      setUploadColapsed(true);
    }
  }, [data]);

  useEffect(() => {
    if (featureList?.length === 0) {
      GetFeatureList();
    }
    // if "WA" not in featureList, then navigate to subscribe
    else if (!featureList.find((item) => item.code === 'WA')) {
      navigate('/app/subscribe', { replace: true });
    }
  }, [featureList]);

  return (
    <React.Fragment>
      <div className='page-content'>
        <Container fluid={true}>
          <Card>
            <CardHeader className='bg-transparent border-bottom text-uppercase'>
              <i className='mdi mdi-filter-variant mr-2'></i>
              Upload File
              <button
                className='btn btn-sm btn-link text-dark float-right'
                onClick={() => setUploadColapsed(!uploadColapsed)}
              >
                <i
                  className={
                    uploadColapsed
                      ? 'mdi mdi-18px mdi-chevron-down'
                      : 'mdi mdi-18px mdi-chevron-up'
                  }
                ></i>
              </button>
            </CardHeader>
            {!uploadColapsed === true ? (
              <CardBody style={{ whiteSpace: 'pre-wrap' }}>
                <FileUpload setData={setData} />
                {data && (
                  <div dangerouslySetInnerHTML={{ __html: formatData(data) }} />
                )}
              </CardBody>
            ) : (
              <CardBody style={{ whiteSpace: 'pre-wrap' }}>
                {data && (
                  <div dangerouslySetInnerHTML={{ __html: formatData(data) }} />
                )}
              </CardBody>
            )}
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default WritingAsistant2;
