import React, { useEffect, useState } from "react";
import Tablediv from "../../Components/Table/TableDiv";
import { Button, message, Tooltip, Skeleton, Menu, Dropdown } from "antd";
import { useAuth0 } from "@auth0/auth0-react";
import {
  EditOutlined,
  DownloadOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import useDatacallswithtoken from "../../../../Data Apis/apifunctions";

const NeedRevision = ({ onEditClick, setPagesCount }) => {
  const {
    useForDownloadWordFile,
    useForDownloadExcelFile,
    useForNeedRevisionList,
  } = useDatacallswithtoken();
  const { user, getAccessTokenSilently } = useAuth0();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true); // Loading state
  // Utility function to create unique filter options based on data
  const createUniqueFilterOptions = (data, key) => {
    const uniqueValues = [...new Set(data.map((item) => item[key]))];
    return uniqueValues.map((value) => ({
      text: value,
      value: value,
    }));
  };
  const FetchNeedRevisionList = async (body) => {
    const res = await useForNeedRevisionList(JSON.stringify(body));
    if (res.status === 200) {
      return res; // Return the response data
    } else {
      throw new Error("Error fetching need revision list");
    }
  };
  const DownloadWordFile = async (body) => {
    const res = await useForDownloadWordFile(body);

    if (res.status !== 200) {
      throw new Error("Error downloading Word file");
    }

    // Convert the response to a blob (binary large object)
    const blob = new Blob([res.data], {
      type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    });

    // Create a link element and use it to trigger the download
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "job_description.docx"); // Set file name
    document.body.appendChild(link);
    link.click();

    // Clean up the URL and remove the link
    window.URL.revokeObjectURL(url);
    link.remove();

    message.success("Word file downloaded successfully!");
  };
  const DownloadExcelFile = async (body) => {
    const res = await useForDownloadExcelFile(body);

    if (res.status !== 200) {
      throw new Error("Error downloading Excel file");
    }

    // Convert the response to a blob (binary large object)
    const blob = new Blob([res.data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    // Create a link element and use it to trigger the download
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "job_description.xlsx"); // Set file name
    document.body.appendChild(link);
    link.click();

    // Clean up the URL and remove the link
    window.URL.revokeObjectURL(url);
    link.remove();

    message.success("Excel file downloaded successfully!");
  };
  const handleDownloadFile = async (jd_key, fileType) => {
    try {
      const body = {
        user_id: user?.sub,
        jd_key: jd_key,
      };

      if (fileType === "word") {
        await DownloadWordFile(body);
      } else if (fileType === "excel") {
        await DownloadExcelFile(body);
      }
    } catch (error) {
      console.error(`Error during ${fileType} file download:`, error);
      message.error(`Error downloading ${fileType} file`);
    }
  };
  const columns = [
    {
      title: "Position ID",
      dataIndex: "positionId",
      sorter: (a, b) => a.positionId.localeCompare(b.positionId),
      filters: createUniqueFilterOptions(data, "positionId"),
      onFilter: (value, record) => record.positionId.includes(value),
    },
    {
      title: "Position Title",
      dataIndex: "positionTitle",
      filters: createUniqueFilterOptions(data, "positionTitle"),
      onFilter: (value, record) => record.positionTitle.includes(value),
    },
    {
      title: "Department",
      dataIndex: "department",
      filters: createUniqueFilterOptions(data, "department"),
      onFilter: (value, record) => record.department.includes(value),
    },
    {
      title: "Location",
      dataIndex: "location",
      sorter: (a, b) => a.location.localeCompare(b.location),
      filters: createUniqueFilterOptions(data, "location"),
      onFilter: (value, record) => record.location.includes(value),
    },
    {
      title: "Submitted Date",
      dataIndex: "submittedDate",
      sorter: (a, b) => new Date(a.submittedDate) - new Date(b.submittedDate),
    },
    {
      title: "Actions",
      dataIndex: "actions",
      render: (_, record) => (
        <div
          style={{
            display: "flex",
            margin: "0px",
            padding: "0px",
            gap: "0px",
          }}
        >
          <Tooltip title="Edit">
            <Button
              type="button"
              icon={<EditOutlined />}
              onClick={() => onEditClick(record)}
            />
          </Tooltip>
          {/* <Tooltip title="Download"> */}
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item
                  key="1"
                  onClick={(e) => {
                    e.domEvent.stopPropagation(); // Stop event from propagating to the row
                    handleDownloadFile(record.key, "excel");
                    console.log(record);
                  }}
                >
                  Download Excel
                </Menu.Item>
                <Menu.Item
                  key="2"
                  onClick={(e) => {
                    e.domEvent.stopPropagation(); // Stop event from propagating to the row
                    handleDownloadFile(record.key, "word");
                  }}
                >
                  Download Word
                </Menu.Item>
              </Menu>
            }
            trigger={["hover"]}
            onClick={(e) => e.stopPropagation()} // Prevent click event from propagating to parent row
          >
            <Button
              type="button"
              icon={<DownloadOutlined />}
              style={{ padding: "4px", margin: "0" }}
            />
          </Dropdown>
          {/* </Tooltip> */}
          <Tooltip title="Delete">
            <Button
              type="button"
              icon={<DeleteOutlined />}
              onClick={() => console.log("Delete clicked", record)}
            />
          </Tooltip>
        </div>
      ),
    },
  ];

  useEffect(() => {
    const fetchNeedRevisionPageData = async () => {
      setLoading(true); // Start loading
      const jsonData = { userId: user?.sub };
      try {
        const res = await FetchNeedRevisionList(jsonData);

        const fetchedData = res.data;
        setPagesCount({ needRevision: fetchedData.length });

        const formattedData = fetchedData.map((item) => ({
          key: item.jd_key,
          positionId: item.position_id,
          positionTitle: item.process_title,
          department: item.department,
          location: item.position_location,
          submittedDate: item?.submitted_dt
            ? new Date(item?.submitted_dt).toISOString().split("T")[0]
            : null,
        }));
        setData(formattedData);
      } catch (error) {
        console.error("Error during fetch:", error);
        message.error("Error fetching data");
      } finally {
        setLoading(false); // End loading
      }
    };
    fetchNeedRevisionPageData();
  }, [getAccessTokenSilently, user]);
  const handleDownloadExcel = () => {
    console.log("Download Excel");
    // Implement logic to download Excel file
  };

  const handleDownloadWord = () => {
    console.log("Download Word");
    // Implement logic to download Word file
  };

  const menu = (
    <Menu>
      <Menu.Item key="1" onClick={handleDownloadExcel}>
        Download Excel
      </Menu.Item>
      <Menu.Item key="2" onClick={handleDownloadWord}>
        Download Word
      </Menu.Item>
    </Menu>
  );
  return (
    <div className="needrevisionmain" style={{ flex: "1" }}>
      {loading ? (
        <Skeleton active /> // Display the skeleton when loading
      ) : (
        <Tablediv
          title={`Need Revision (${data.length})`}
          columns={columns}
          dataSource={data}
          rowSelectionEnabled={true}
          initialPageSize={10} // Set initial page size if needed
          onEditClick={onEditClick}
        />
      )}
    </div>
  );
};

export default NeedRevision;
