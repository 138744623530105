import React, { useEffect, useState } from 'react';
import { Button, ConfigProvider, Typography, Flex, notification, Spin, Skeleton } from 'antd';
import "../style/style.css";
import { analysisArr } from './data';
import { PlusOutlined } from '@ant-design/icons';
import CreateNewAnalysis from './Components/CreateNewAnalysis';
import Analysis from './Components/Analysis';
import SimpleBar from 'simplebar-react';
import { set } from 'lodash';
import { useNotification } from './Components/NotificationProvider';
import useDatacallswithtoken from '../../../Data Apis/apifunctions';
// Assuming Flex is a custom component, ensure it's imported correctly

const { Text, Title } = Typography;

const AdvancedFilesense = () => {
  const [categorizedAnalysis, setCategorizedAnalysis] = useState();
  const [analysisItem, setAnalysisItem] = useState();

  const [displayCreateAnalysis, setDisplayCreateAnalysis] = useState(true);
  const [displayAnalysis, setDisplaAnalysis] = useState(false);
  const [loading, setLoading] = useState(true)
  const notification = useNotification();
  const { useForFilesenseAdvAnalysisList } = useDatacallswithtoken();



  const groupByDateCategory = (analyses) => {
    const today = new Date();
    const oneWeekAgoStr = new Date(today);
    oneWeekAgoStr.setDate(today.getDate() - 7);
    const oneMonthAgoStr = new Date(today);
    oneMonthAgoStr.setMonth(today.getMonth() - 1);

    const todayIso = today.toISOString()
    const oneWeekAgo = oneWeekAgoStr.toISOString()
    const oneMonthAgo = oneMonthAgoStr.toISOString()

    console.log(todayIso)
    console.log(oneWeekAgo)
    console.log(oneMonthAgo)

    console.log(analyses[0].analysis_list)

    const listByDate = [
      {
        "Today": analyses[0]?.analysis_list
          .filter(item => item.modified_date === todayIso)
          .sort((a, b) => new Date(b.modified_date) - new Date(a.modified_date))
      },
    
      {
        "Last Week": analyses[0]?.analysis_list
          .filter(item => item.modified_date > oneWeekAgo && item.modified_date < todayIso)
          .sort((a, b) => new Date(b.modified_date) - new Date(a.modified_date))
      },
    
      {
        "Last month": analyses[0]?.analysis_list
          .filter(item => item.modified_date > oneMonthAgo && item.modified_date <= oneWeekAgo)
          .sort((a, b) => new Date(b.modified_date) - new Date(a.modified_date))
      }
    ];
    console.log("listbydate", listByDate)

    return listByDate;
  };

  const FetchAnalysisList = () => {
    useForFilesenseAdvAnalysisList().then(res => {
      // console.log(res,analysisArr)
      !res ? setCategorizedAnalysis(res) :
        setCategorizedAnalysis(groupByDateCategory([res]));
      setLoading(false)

    }).catch(err => {
      console.log(err)
      setLoading(false)

      notification.error({
        message: "Error",
        description: err.message,
        placement: 'topRight',
      });

    })
  }


  useEffect(() => {

    FetchAnalysisList();
  }, []);

  function handleAnalysisDisplay(item) {
    setAnalysisItem(item)

    setDisplayCreateAnalysis(false);
    setDisplaAnalysis(true);
  }
  function handleCreateAnalysisDisplay() {
    setDisplayCreateAnalysis(true);
    setDisplaAnalysis(false);
    setAnalysisItem(undefined)

  }
  // {console.log( analysisItem?.analysis_list[0].analysis_id)}

  return (

    <Flex gap={20} style={{ height: "90vh", padding: "0px 20px" }}>
      <Flex className="border-radius15" gap={14} vertical style={{ background: "#f2f2f2", width: "20%", minWidth: "200px", padding: "23px 15px" }}>
        <Button type='primary' value='large' onClick={() => handleCreateAnalysisDisplay()}><PlusOutlined /> New analysis</Button>

        {
          loading ?
            <Skeleton /> :
            (categorizedAnalysis ?
              <SimpleBar style={{ maxHeight: "95%" }}>

                <Flex className=' w100-h100'>
                  <Flex vertical gap={14} style={{ padding: "0px 5px", width: "100%" }}>


                    {categorizedAnalysis.length > 0 && (
                      categorizedAnalysis.map((item, index) => (
                        <>
                          

                          {Object.values(item) && Object.values(item)[0].length > 0 &&
                            <Flex gap={7} key={index} vertical style={{ width: "100%" }}>

                              
                              <Title className='margin0' level={5} style={{ padding: "0px 10px" }}>{Object.keys(item)[0]}</Title>


                              <Flex className='margin0' vertical gap={8}>
                              
                                {Object.values(item)[0] && Object.values(item)[0].length > 0 && Object.values(item)[0]?.map((item, index) => (
                                  <Title key={index} className='font-col-666-weight-400 margin0' level={5}
                                    style={{
                                      cursor: "pointer", padding: "3px 10px", borderRadius: "4px",
                                      background: item.analysis_id == analysisItem?.analysis_id ? "#e6e6e6" : ""
                                    }}
                                    onClick={() => handleAnalysisDisplay(item)}
                                  >
                                   

                                    {item?.analysis_name}

                                    {
                                      item?.modified_date &&
                                      <Text style={{ color: "#999", fontSize: "12px", paddingLeft: "10px" }}>
                                        {new Date(item?.modified_date).toLocaleDateString('en-US', { weekday: 'short', month: 'short', day: 'numeric' })}
                                      </Text>
                                    }
                                  </Title>
                                ))}
                              </Flex>
                            </Flex>
                          }
                        </>
                      ))
                    )}
                  </Flex>
                </Flex>


              </SimpleBar>
              : <Text>{categorizedAnalysis}</Text>
            )}

      </Flex>
      {displayCreateAnalysis && <CreateNewAnalysis />}
      {displayAnalysis && <Analysis analysisItem={analysisItem} />}
    </Flex>

  )
}

export default AdvancedFilesense;


const temp = [
  {
    "Today": []
  },
  {
    "Last Week": [
      {
        "analysis_id": 22,
        "analysis_name": "MBR",
        "modified_date": "2024-09-11T14:44:43.333041"
      },
      {
        "analysis_id": 23,
        "analysis_name": "MBR",
        "modified_date": "2024-09-11T14:46:02.113749"
      },



    ]
  },
  {
    "Last month": [
      {
        "analysis_id": 2,
        "analysis_name": "MBR",
        "modified_date": "2024-08-30T00:20:17.105267"
      },
      {
        "analysis_id": 9,
        "analysis_name": "MBR",
        "modified_date": "2024-09-11T11:04:30.215351"
      },
      {
        "analysis_id": 10,
        "analysis_name": "MBR",
        "modified_date": "2024-09-11T11:05:13.145861"
      }
    ]
  }
]