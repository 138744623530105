import React, { useEffect, useRef, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import gradientHero from '../../../src/assets/images/legal/gradient-herosection.png';
import rightImg from '../../../src/assets/images/legal/hero-right-3.png';
import Joyride, { STATUS } from 'react-joyride';
import {
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
} from 'reactstrap';
import {
  changeCurrentApp,
  changeChatQuestions,
  appDispayHandler,
  changeContentSearchQuestion,
  changeIsSidebarOpen,
} from '../../store/actions';
import SearchBar from './SearchBar';
import * as SpeechSDK from 'microsoft-cognitiveservices-speech-sdk';

const SUBSCRIPTION_KEY = process.env.REACT_APP_SPEECH_SDK_KEY;
const REGION = 'centralindia';

const LegalHeader = (props) => {
  const { user, getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();
  const appDisplay = useSelector((state) => state.PageData.appDisplay);
  const groups = useSelector((state) => state.PageData.groups);
  console.log(groups);
  const featureList = useSelector((state) => state.PageData.featureList);
  const currentApp = useSelector((state) => state.PageData.currentApp);
  const [greeting, setGreeting] = useState(getGreeting());
  const [search, setSearch] = useState('');
  const questions = useSelector((state) => state.PageData.chatQuestions);
  const isSideBarOpen = useSelector((state) => state.PageData.isSideBarOpen);
  const inputRef = useRef(null);
  const [btnsuccess1, setBtnsuccess1] = useState(false);
  const [isListening, setIsListening] = useState(false);
  const recognizerRef = useRef(null);
  const [isTranslationComplete, setIsTranslationComplete] = useState(false);
  const [translatedText, setTranslatedText] = useState('');

  const [run, setRun] = useState(false);

  const featurelistGroupNames=featureList["IN"]?featureList["IN"].map(item => item.group_name):undefined;
  const filteredGroups= featurelistGroupNames && groups? groups.filter(groupName => {  return featurelistGroupNames.includes(groupName.group_name)}):undefined
  useEffect(() => {
    const hasSeenGuide = localStorage.getItem('hasSeenGuide');

    if (!hasSeenGuide) {
      setRun(true);
      localStorage.setItem('hasSeenGuide', 'true');
    }
  }, []);

  let username = user?.email?.split('@')[0];
  if (username.includes('.')) {
    username = username.split('.')[0];
  }
  function getGreeting() {
    const now = new Date();
    const hours = now.getHours();

    if (hours < 12) {
      return 'Good morning';
    } else if (hours >= 12 && hours < 16) {
      return 'Good afternoon';
    } else {
      return 'Good evening';
    }
  }

  useEffect(() => {
    // Update the greeting immediately on component mount
    setGreeting(getGreeting());

    // Set up an interval to update the greeting every minute
    const interval = setInterval(() => {
      setGreeting(getGreeting());
    }, 60000); // 60000ms = 1 minute
    document.documentElement.style.backgroundColor = '#fff';
    // Cleanup the interval on component unmount
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    initializeRecognizer();
    return () => {
      if (recognizerRef.current) {
        recognizerRef.current.close();
      }
    };
  }, []);

  useEffect(() => {
    if (isTranslationComplete) {
      handleSearch(null, translatedText);
      setIsTranslationComplete(false);
    }
  }, [isTranslationComplete, translatedText]);

  const initializeRecognizer = () => {
    const speechConfig = SpeechSDK.SpeechConfig.fromSubscription(
      SUBSCRIPTION_KEY,
      REGION
    );
    speechConfig.speechRecognitionLanguage = 'en-US';
    const audioConfig = SpeechSDK.AudioConfig.fromDefaultMicrophoneInput();
    recognizerRef.current = new SpeechSDK.SpeechRecognizer(
      speechConfig,
      audioConfig
    );

    recognizerRef.current.recognizing = (s, e) => {
      setSearch(e.result.text);
    };

    recognizerRef.current.recognized = (s, e) => {
      if (e.result.reason == SpeechSDK.ResultReason.RecognizedSpeech) {
        setTimeout(() => {
          translateText(e.result.text);
        }, 500);
      } else if (e.result.reason == SpeechSDK.ResultReason.NoMatch) {
        setIsListening(false);
      }
    };

    recognizerRef.current.canceled = (s, e) => {
      if (e.reason == SpeechSDK.CancellationReason.Error) {
        console.log(`ERROR: ${e.errorDetails}`);
      }
      setIsListening(false);
    };

    recognizerRef.current.sessionStopped = (s, e) => {
      setIsListening(false);
    };
  };

  const handleSearch = (e, query) => {
    e?.preventDefault();
    const searchQuery = query || search;
    if (searchQuery.length > 0) {
      dispatch(changeChatQuestions([...questions, searchQuery]));
      dispatch(changeContentSearchQuestion(searchQuery));
      setSearch('');
    }
  };

  const translateText = async (text) => {
    try {
      const options = {
        method: 'POST',
        headers: {
          accept: 'application/json',
          Authorization: `Bearer ${await getAccessTokenSilently()}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ question: text }),
      };
      const response = await fetch(
        `${process.env.REACT_APP_AUTH0_URL}/translate/`,
        options
      );
      const data = await response.json();
      setTranslatedText(data.translated_text);
      setSearch(data.translated_text);
      setIsTranslationComplete(true);
    } catch (error) {
      console.error('Translation failed:', error);
      setTranslatedText('Translation failed. Please try again.');
      setIsTranslationComplete(true);
    } finally {
      stopListening();
    }
  };

  const startListening = () => {
    if (recognizerRef.current) {
      setIsListening(true);
      recognizerRef.current.recognizeOnceAsync(
        (result) => {
          if (result.reason === SpeechSDK.ResultReason.RecognizedSpeech) {
            // console.log(`RECOGNIZED: Text=${result.text}`);
          } else {
            console.log(
              'ERROR: Speech was cancelled or could not be recognized. Ensure your microphone is working properly.'
            );
          }
          setIsListening(false);
        },
        (err) => {
          console.log(`ERROR: ${err}`);
          setIsListening(false);
        }
      );
    }
  };

  const stopListening = () => {
    if (recognizerRef.current) {
      recognizerRef.current.stopContinuousRecognitionAsync();
      setIsListening(false);
    }
  };

  const suggestedQuestions = () => {
    switch (appDisplay) {
      case 'Aluminium Market Outlook':
        return {
          firstQuestion:
            'LME upside and downside potential for September 2024?',
          secondQuestion:
            'Reasons for MJP aluminium premium increase in august 2024?',
          placeholder: 'Ask any business question...',
        };
      case 'ATL Marketing Intelligence':
        return {
          firstQuestion: 'Reasons for natural rubber price increase',
          secondQuestion: 'Tyres for EVs',
          placeholder: 'Ask any business question...',
        };
      case 'Legal':
        return {
          firstQuestion: 'What is section 17B of industrial disputes act?',
          secondQuestion: 'Who is covered under ESIC act?',
          placeholder: 'Ask any legal question...',
        };
      case 'Ekaayan-SOP':
        return {
          firstQuestion: 'IRN QR Code Generation?',
          secondQuestion: 'TDS Agreement procedure?',
          placeholder: 'Ask any business question...',
        };
      case 'Blue Star':
        return {
          firstQuestion: 'How to fix Magnet pressure < 15.3 psi?',
          secondQuestion: 'Test procedure for the gradient power amplifier?',
          placeholder: 'Ask any technical question...',
        };
      default:
        return {
          firstQuestion: '',
          secondQuestion: '',
          placeholder: '',
        };
    }
  };

  const steps = [
    {
      target: '#groupDropdown',
      content: 'Use this dropdown to ask questions on different topics.',
      disableBeacon: true,
    },
    // Add more steps if necessary
  ];

  const joyrideStyles = {
    options: {
      arrowColor: '#000',
      backgroundColor: '#ffffff',
      overlayColor: 'rgba(0, 0, 0, 0.5)',
      primaryColor: '#007bff',
      textColor: '#333333',
      zIndex: 1000,
      marginTop: '1em',
      height: '5rem',
    },
    tooltip: {
      padding: '20px',
      borderRadius: '8px',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
      backgroundColor: '#000',
      color: '#fff',
    },
    tooltipContent: {
      padding: '0',
      fontSize: '1.2rem',
      lineHeight: '1.5',
      height: '8rem',
      display: 'flex',
      alignItems: 'center',
    },
    tooltipFooter: {
      display: 'none', // This hides the footer where buttons are usually placed
    },
    buttonClose: {
      top: '-.75em',
      fontSize: '0.5rem', // Add this line to adjust the close button position
      color: 'white',
    },
  };

  function tToggle() {
    var body = document.body;
    dispatch(changeIsSidebarOpen(!isSideBarOpen));
    if (window.screen.width <= 998) {
      body.classList.toggle('sidebar-enable');
    } else {
      body.classList.toggle('vertical-collpsed');
      body.classList.toggle('sidebar-enable');
    }
  }

  return (
    <div
      style={{
        background: `url(${gradientHero}) no-repeat center center / cover`,
        height: '500px',
        overflow: 'visible',
      }}
    >
      <div className='bg-transparent' style={{ padding: '1em 2em' }}>
        <div className='header-container'>
          <button
            type='button'
            className='menu-toggle-btn btn btn-sm px-3 font-size-24 header-item waves-effect'
            id='vertical-menu-btn'
            onClick={tToggle}
          >
            <i className='ri-menu-2-line align-middle'></i>
          </button>
          <Dropdown
            isOpen={btnsuccess1}
            toggle={() => setBtnsuccess1(!btnsuccess1)}
            id='groupDropdown'
          >
            <DropdownToggle
              style={{
                backgroundColor: btnsuccess1 ? '#f4f4f4' : '',
                fontSize:
                  appDisplay === 'ATL Marketing Intelligence'
                    ? '0.830rem'
                    : '1rem',
              }}
              tag='button'
              className='btn'
            >
              {appDisplay} <i className='mdi mdi-chevron-down' />
            </DropdownToggle>
            <DropdownMenu>
              {filteredGroups.map((item, i) => (
                <Link
                  key={i}
                  onClick={() => {
                    dispatch(appDispayHandler(item.group_name));
                    dispatch(changeCurrentApp(item));
                    setBtnsuccess1(!btnsuccess1);
                    if (currentApp !== item) {
                      dispatch(changeChatQuestions(''));
                    }
                  }}
                  to={
                    item?.group_name
                      ? `/app/insights/${item?.group_name
                          ?.toLowerCase()
                          ?.replaceAll(' ', '-')}`
                      : '/'
                  }
                >
                  <DropdownItem>{item?.group_name}</DropdownItem>
                </Link>
              ))}
            </DropdownMenu>
          </Dropdown>
          <style jsx>{`
        .header-container {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        .menu-toggle-btn {
          display: block;
        }

        @media (min-width: 768px) {
          .header-container {
            justify-content: flex-end;
          }

          .menu-toggle-btn {
            display: none;
          }
        }
      `}</style>
        </div>
      </div>
      <Row
        style={{
          margin: '2em auto 0 auto',
          // maxWidth: "1280px",
          padding: '0 170px',
          overflow: 'visible',
        }}
      >
        <Col
          lg={11}
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <h2
            className='gradient-text mt-5'
            style={{ fontSize: '1.5rem', width: 'fit-content' }}
          >
            {greeting},{' '}
            <span style={{ textTransform: 'capitalize' }}> {username}.</span>
          </h2>
          <h2 style={{ fontSize: '2rem' }}>
            Turn your <span className='gradient-text'>legal</span> questions
            into answers.
          </h2>

          <div>
            <div
              style={{
                display: 'flex',
                borderRadius: '25px',
                justifyContent: 'space-between',
                // backgroundColor: '#0284c7',
                marginTop: '1em',
                width: '80%',
                padding: '0.25em',
                border: '2px solid #ccc',
                // boxShadow: "0px 3px 35px 0px rgba(0, 0, 0, 0.10)"
              }}
              className='ncs-search'
            >
              <form
                onSubmit={(e) => handleSearch(e)}
                autoComplete='off'
                style={{
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  color: 'white',
                  backgroundColor: 'transparent',
                }}
              >
                <i
                  style={{
                    padding: '10px',
                    fontSize: '20px',
                    color: '#aaa',
                    cursor: 'pointer',
                  }}
                  onClick={(e) => handleSearch(e)}
                  className='bx bx-search'
                />
                <input
                  style={{
                    width: '100%',
                    height: '100%',
                    border: 'none',
                    borderRadius: '25px',
                    color: 'black',
                    backgroundColor: 'transparent',
                    fontSize: '1rem',
                  }}
                  className='myInput'
                  type='text'
                  placeholder={'Ask any business question...'}
                  name='search'
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  ref={inputRef}
                />
                {search && (
                  <i
                    style={{
                      padding: '10px',
                      fontSize: '20px',
                      color: '#aaa',
                      cursor: 'pointer',
                    }}
                    onClick={() => setSearch('')}
                    className='bx bx-x'
                  />
                )}
                {SpeechSDK && (
                  <i
                    style={{
                      padding: '10px',
                      fontSize: '20px',
                      color: '#aaa',
                      cursor: 'pointer',
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      if (!isListening) {
                        startListening();
                      } else {
                        stopListening();
                      }
                    }}
                    className={
                      isListening ? 'bx bx-microphone' : 'bx bx-microphone-off'
                    }
                  />
                )}
              </form>
            </div>
          </div>
          <div
            className='d-flex align-items-center mb-4'
            style={{
              color: '#505D69',
              margin: '.75em 1em 0em 4px',
              padding: '0px',
              gap: '0.75em',
            }}
          >
            <p style={{ fontWeight: 'bolder' }}>Suggestions:</p>
            <p
              onClick={(e) => {
                const question = suggestedQuestions()?.firstQuestion;
                handleSearch(e, question);
              }}
              style={{ cursor: 'pointer', fontWeight: 'bold' }}
            >
              {suggestedQuestions()?.firstQuestion}
              <span>
                <i className='mdi mdi-arrow-top-right'></i>
              </span>
            </p>
            <p
              onClick={(e) => {
                const question = suggestedQuestions()?.secondQuestion;
                handleSearch(e, question);
              }}
              style={{ cursor: 'pointer', fontWeight: 'bold' }}
            >
              {suggestedQuestions()?.secondQuestion}
              <span>
                <i className='mdi mdi-arrow-top-right'></i>
              </span>
            </p>
          </div>
        </Col>
        <Joyride
          steps={steps}
          run={run}
          continuous={false}
          showSkipButton={false}
          showProgress={false}
          disableOverlayClose={false}
          disableCloseOnEsc={false}
          styles={joyrideStyles}
          floaterProps={{
            disableAnimation: true,
            styles: {
              arrow: {
                length: 8,
                spread: 12,
              },
            },
          }}
          callback={(data) => {
            const { status, type } = data;
            if (
              [STATUS.FINISHED, STATUS.SKIPPED].includes(status) ||
              type === 'step:after'
            ) {
              setRun(false);
            }
          }}
        />

        <Col lg={1} style={{ overflow: 'visible' }}>
          {/* <img src={rightImg}  style={{width:"650px"}}/> */}
        </Col>
      </Row>
    </div>
  );
};

export default LegalHeader;
