import React, { useEffect, useState } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import {
  changeFeatureList,
  changeTrailExpiredMessage,
} from "../../store/actions";
import { FaTasks } from "react-icons/fa";
import { UserOutlined, TeamOutlined, FundOutlined } from "@ant-design/icons";
import { MdOutlineDashboard } from "react-icons/md";

import Drafts from "./Pages/Self.js/Drafts";
import Approved from "./Pages/Self.js/Approved";
import SubmittedForApproval from "./Pages/Self.js/SubmittedForApproval";
import NeedRevision from "./Pages/Self.js/NeedRevision";
import Withdrawn from "./Pages/Self.js/Withdrawn";
import PendingApproval from "./Pages/Team.js/PendingApproval";
import TeamApproved from "./Pages/Team.js/TeamApproved";
import Admin from "./Pages/Admin";
import Dashboard from "./Pages/Dashboard";
import MyTask from "./Pages/MyTask";
import MenuWithButton from "./Components/Menu/MenuWithButton";
import CreateJobDescription from "./Pages/CreateJobDescription";
import EditJobDescription from "./Pages/EditJobDescription";
import useDatacallswithtoken from "../../Data Apis/apifunctions";

import "./index.css";

import { useDispatch, useSelector } from "react-redux";

// Helper function to convert kebab-case to PascalCase
const kebabToPascalCase = (str) => {
  return str
    .split("-")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join("");
};

const WritingAssistant = () => {
  const navigate = useNavigate();
  const location = useLocation(); // Get the current URL
  const dispatch = useDispatch();
  const featureList = useSelector((state) => state.PageData.featureList);
  const [selectedComponent, setSelectedComponent] = useState("1");
  const [editRecord, setEditRecord] = useState(null); // State to hold the record being edited
  const [lastPage, setLastPage] = useState("1"); // Track the last visited page
  const [pagesCount, setPagesCount] = useState({
    myTask: 0,
    drafts: 0,
    approved: 0,
    submittedForApproval: 0,
    needRevision: 0,
    withdrawn: 0,
    pendingApproval: 0,
    teamApproved: 0,
  }); // State to hold total tasks

  const { useForFeatureList } = useDatacallswithtoken();

  // Update the selected component based on the current URL
  useEffect(() => {
    const path = location.pathname.split("/app/writing-assistant/")[1];
    const component = kebabToPascalCase(path || "my-task");
    setSelectedComponent(component);
  }, [location]);

  const GetFeatureList = () => {
    useForFeatureList()
      .then((res) => {
        if (typeof res === "object") {
          dispatch(changeFeatureList(res));
          const containsIN = res.some((item) => item.code === "IN");
          if (!containsIN) {
            navigate("/app/subscribe", { replace: true });
          }
        } else {
          dispatch(changeTrailExpiredMessage(res));
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch(changeTrailExpiredMessage(err?.response?.data));
      });
  };

  useEffect(() => {
    if (featureList?.length === 0) {
      GetFeatureList();
    }
  }, [featureList]);

  const onMenuItemClick = (e) => {
    setSelectedComponent(e.key); // Update selectedComponent based on the key
    setLastPage(e.key); // Update lastPage on menu item click
    navigate(e.route); // Navigate to the selected route
  };

  const onButtonClick = () => {
    setSelectedComponent("CreateJobDescription"); // Set the selectedComponent to render CreateJobDescription
    navigate("/app/writing-assistant/create-job-description");
  };

  const onViewMore = (section) => {
    if (section === "pendingApproval") {
      setSelectedComponent("PendingApproval");
      setLastPage("3-2"); // Update last page for pending approval
      navigate("/app/writing-assistant/pending-approval");
    } else if (section === "needRevision") {
      setSelectedComponent("NeedRevision");
      setLastPage("2-4"); // Update last page for need revision
      navigate("/app/writing-assistant/need-revision");
    }
  };

  const onEditClick = (record) => {
    setEditRecord(record); // Store the record being edited
    setSelectedComponent("EditJobDescription"); // Set the component to EditJobDescription
    navigate("/app/writing-assistant/edit-job-description");
  };

  const menuItems = [
    {
      key: "MyTask",
      label: "My Task",
      icon: <FaTasks />,
      route: "/app/writing-assistant/my-task",
      count: pagesCount?.myTask, // Pass totalTasks to the menu item
    },
    {
      key: "Self",
      label: "Self",
      icon: <UserOutlined />,
      children: [
        {
          key: "Drafts",
          label: "Drafts",
          route: "/app/writing-assistant/drafts",
          count: pagesCount?.drafts,
        },
        {
          key: "Approved",
          label: "Approved",
          route: "/app/writing-assistant/approved",
          count: pagesCount?.approved,
        },
        {
          key: "SubmittedForApproval",
          label: "Submitted for Approval",
          route: "/app/writing-assistant/submitted-for-approval",
          count: pagesCount?.submittedForApproval,
        },
        {
          key: "NeedRevision",
          label: "Need Revision",
          route: "/app/writing-assistant/need-revision",
          count: pagesCount?.needRevision,
        },
        {
          key: "Withdrawn",
          label: "Withdrawn",
          route: "/app/writing-assistant/withdrawn",
          count: pagesCount?.withdrawn,
        },
      ],
    },
    {
      key: "Team",
      label: "Team",
      icon: <TeamOutlined />,
      children: [
        {
          key: "TeamApproved",
          label: "Approved",
          route: "/app/writing-assistant/team-approved",
          count: pagesCount?.teamApproved,
        },
        {
          key: "PendingApproval",
          label: "Pending Approval",
          route: "/app/writing-assistant/pending-approval",
          count: pagesCount?.pendingApproval,
        },
      ],
    },
    {
      key: "Admin",
      label: "Admin",
      icon: <MdOutlineDashboard />,
      route: "/app/writing-assistant/admin",
    },
    {
      key: "Dashboard",
      label: "Dashboard",
      icon: <FundOutlined />,
      route: "/app/writing-assistant/dashboard",
    },
  ];

  return (
    <div className="main">
      <div className="indexmenu">
        <MenuWithButton
          menuItems={menuItems}
          onButtonClick={onButtonClick}
          onMenuItemClick={onMenuItemClick}
          selectedKeys={[selectedComponent]} // Use the transformed selectedComponent
        />
      </div>
      <div className="dashboard">
        <Routes>
          <Route
            index
            element={
              <MyTask
                onViewMore={onViewMore}
                onEditClick={onEditClick}
                setPagesCount={setPagesCount}
              />
            }
          />
          <Route
            path="/my-task"
            element={
              <MyTask
                onViewMore={onViewMore}
                onEditClick={onEditClick}
                setPagesCount={setPagesCount}
              />
            }
          />
          <Route
            path="/drafts"
            element={
              <Drafts onEditClick={onEditClick} setPagesCount={setPagesCount} />
            }
          />
          <Route
            path="/approved"
            element={
              <Approved
                setPagesCount={setPagesCount}
                onEditClick={onEditClick}
              />
            }
          />
          <Route
            path="/submitted-for-approval"
            element={
              <SubmittedForApproval
                onEditClick={onEditClick}
                setPagesCount={setPagesCount}
              />
            }
          />
          <Route
            path="/need-revision"
            element={
              <NeedRevision
                onEditClick={onEditClick}
                setPagesCount={setPagesCount}
              />
            }
          />
          <Route
            path="/withdrawn"
            element={
              <Withdrawn
                onEditClick={onEditClick}
                setPagesCount={setPagesCount}
              />
            }
          />
          <Route
            path="/pending-approval"
            element={
              <PendingApproval
                onEditClick={onEditClick}
                setPagesCount={setPagesCount}
              />
            }
          />
          <Route
            path="/team-approved"
            element={
              <TeamApproved
                onEditClick={onEditClick}
                setPagesCount={setPagesCount}
              />
            }
          />
          <Route path="/admin" element={<Admin />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route
            path="/create-job-description"
            element={<CreateJobDescription />}
          />
          <Route
            path="/edit-job-description"
            element={<EditJobDescription record={editRecord} />}
          />

          {/* Default route to redirect to my-task */}
        </Routes>
      </div>
    </div>
  );
};

export default WritingAssistant;
